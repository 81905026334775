import React from 'react'
import {browserHistory} from 'react-router'
import DPCarGridItemModernView from '../DPCarItems/DPCarGridItemModernView'

export const LayoutCarItemCarousel = ({
                                        car,
                                        style,
                                        indexCar,
                                        carImageHeight,
                                        index,
                                        siteColor,
                                        carfaxValue,
                                        isAdminTemplate = false
                                      }) => {
  const viewMore = (vin) => {
    if (isAdminTemplate)
      return
    browserHistory.push(`/catalog/car/${vin}`)
  };

  if (!car)
    return null;

  return (
    <DPCarGridItemModernView
      key={car.id}
      car={car}
      styleContainer={style}
      indexCar={indexCar}
      carImageHeight={carImageHeight}
      viewMore={viewMore}
      indexDesc={index}
      siteColor={siteColor}
      carfaxValue={carfaxValue}
      isAdminTemplate={isAdminTemplate}
    />
    )
}
