import React from 'react'
import {Modal} from '@material-ui/core'
import ReactPlayer from 'react-player'

const ReactPlayerModal = ({showModal, onClose, playing, url}) => {
  return (
    <div>
      {
        showModal &&
        <Modal
          open={showModal}
          onClose={onClose}
          className={'modal-react-player'}
          onBackdropClick={onClose}
          style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '85%', height: '85%'}}
        >
          <ReactPlayer url={url}
                       width="100%"
                       height="100%"
                       controls={true}
                       className="react-player"
                       playIcon={true}
                       playing={playing}/>

        </Modal>
      }
    </div>
  )
}

export default ReactPlayerModal