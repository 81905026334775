import React from 'react'
import ReactDOM from 'react-dom'
//@ts-ignore
import Catalog from './catalog'

ReactDOM.render(
  <React.StrictMode>
    <Catalog/>
  </React.StrictMode>,
  document.getElementById('root')
)
