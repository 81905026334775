import React, {Component} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader'
import {
  TEMPLATE_MAIN_NAME, SECTION_CONTACT_US_ID, SECTION_TESTIMONIAL_ID, BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN,
  EASY_NAVIGATION_B_TEMPLATE_CODE, DARK_COLORS, DARK_THEME, EASY_NAVIGATION_C_TEMPLATE_CODE,
  EASY_NAVIGATION_TEMPLATE_CODE, LIGHT_COLORS, LIGHT_THEME, PAGE_FINANCE_ID, PAGE_HOME_ID, SITE_BACKGROUND_COLOR,
  PRIMARY_COLOR, SECONDARY_COLOR,
} from '../../common/Constants'
import LayoutFooter from '../../common/template/LayoutFooter'
import {connect} from 'react-redux'
import {loadLocationsPublic, selectedLocationId, saveEmailMessage} from '../actions'
import LayoutFinanceViewForm from '../../common/LayoutFinanceViewForm.jsx'
import {browserHistory} from 'react-router'
import get from 'lodash/get'
import find from 'lodash/find'
import LayoutCustomerHorizontalTestimonials from '../../common/LayoutCustomerHorizontalTestimonials'
import LayoutGetInTouch from '../../common/template/LayoutGetInTouch'
import LayoutCustomerVerticalTestimonial from '../../common/LayoutCustomerVerticalTestimonial'
import {getBackgroundEasyNavigation, getClassContainerEasyNavigation, getSiteColor} from '../../common/Util'
import DPBackToTop, {handleToTop} from '../../common/DPBackToTop'

class LayoutFinanceContainer extends Component {

  componentWillMount() {
    const {locations, loadLocationsPublic} = this.props
    if (!locations)
      loadLocationsPublic()
  }

  onHome() {
    browserHistory.push('/catalog')
  }

  render() {
    const {
      locations, templateContent, dealerId, templateCode, locationId, selectedLocationId, saveEmailMessage
    } = this.props

    if (!locations)
      return null

    const content = JSON.parse(templateContent)
    // getting data from template
    const {generalSetting, pages} = content
    const {socialMedia, footer, currentTheme, banner, site} = generalSetting
    const {backgroundMain: {url: backgroundMainUrl, maxHeight: backgroundMainMaxHeight}} = banner
    const backgroundMain = getBackgroundEasyNavigation(templateCode, backgroundMainUrl)
    const {logo = '', maxHeight: maxHeightLogo} = site
    // getting pages
    const financePage = find(pages, page => page.id === PAGE_FINANCE_ID)
    const homePage = find(pages, page => page.id === PAGE_HOME_ID)
    // getting data from finance page
    const {header: {menu, sections: pageSections = []} = {}} = financePage
    const sectionContact = find(pageSections, sec => sec.id === SECTION_CONTACT_US_ID)
    const sectionTestimonies = find(pageSections, sec => sec.id === SECTION_TESTIMONIAL_ID)
    // getting data from home page
    const {sections: homePageSections} = homePage
    const sectionContactUs = find(homePageSections, sec => sec.id === SECTION_CONTACT_US_ID)
    const sectionTestimonial = find(homePageSections, sec => sec.id === SECTION_TESTIMONIAL_ID)

    const siteColor = getSiteColor(generalSetting)
    const {[PRIMARY_COLOR]: primaryColor, [SECONDARY_COLOR]: secondaryColor} = siteColor
    const classTemplateContainer = getClassContainerEasyNavigation(templateCode, 'layout-finance-container')

    if (sectionContact)
      sectionContact.title = sectionContactUs?.title
    if (sectionTestimonies)
      sectionTestimonies.title = sectionTestimonial?.title ?? ''
    const showContactSection = sectionContact?.showSection ?? true
    const showTestimonialSection = sectionTestimonies?.showSection ?? true

    const hideTestimonial = !!sectionTestimonial.hideSection
    const hideContact = !!sectionContactUs.hideSection

    const getInTouchComponent = (
      <LayoutGetInTouch
        title={sectionContactUs?.title}
        siteColor={siteColor}
        dealerId={dealerId}
        templateCode={templateCode}
        section={sectionContactUs}
      />
    )

    return (
      <div
        className={`${classTemplateContainer} ${currentTheme === DARK_THEME ? 'layout-finance-container-dark' : ''} container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}
        style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
      >
        <LayoutHeader
          menu={menu}
          logo={logo}
          maxHeight={maxHeightLogo}
          menuSelected={'finance'}
          locations={locations}
          siteColor={siteColor}
          templateCode={templateCode}
          locationId={locationId}
          selectLocationId={selectedLocationId}
          baseTemplateCode={TEMPLATE_MAIN_NAME}
        />
        <div className='header-content'>
          <img
            className='background'
            style={{maxHeight: backgroundMainMaxHeight >= BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN ? backgroundMainMaxHeight : BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN}}
            src={backgroundMain}
          />
          <div className='content-separator'>
            <div className='car-separator1' style={{backgroundColor: primaryColor}}/>
            <div className='content-separator2'>
              <div className='car-separator2' style={{backgroundColor: secondaryColor}}/>
            </div>
          </div>
        </div>
        <div id='separatorHeader' className='separator-header'/>
        <div className='finance-form-main' style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
          <LayoutFinanceViewForm
            primaryColor={primaryColor}
            formView={financePage.formView}
            borderColor='#D9D9D9'
            onClickUp={handleToTop}
            siteColor={siteColor}
            templateCode={templateCode}
          />
        </div>
        {
          (showContactSection || showTestimonialSection) &&
          <section id={'getInTouch'} style={{width: '100%', position: 'relative'}}>
            {
              (templateCode === EASY_NAVIGATION_TEMPLATE_CODE || templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE) &&
              <div
                id='contactMain'
                className='lay-get-in-touch'
                style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
              >
                <div className='layout-contact-testimonial'>
                  <div className={`layout-contact-${!hideContact? 'full' : 'hide'}`}>
                    {/*todo: this has no condition before refactor, maybe it was wrong*/}
                    {getInTouchComponent}
                  </div>
                  <div className={`layout-testimonials-${!hideTestimonial? 'full' : 'hide'}`}>
                    {
                      !hideTestimonial ?
                        sectionTestimonial && get(sectionTestimonial, ['showSection'], true) &&
                        <LayoutCustomerVerticalTestimonial
                          from={'home'}
                          sectionTestimonial={sectionTestimonial}
                          siteColor={siteColor}
                        />:null
                    }
                  </div>
                </div>



              </div>
            }
            {
              (templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE) &&
              <>
                {
                  !hideTestimonial &&
                  <LayoutCustomerHorizontalTestimonials
                    hideTitle={false}
                    heightCurrentBar={12}
                    heightTotalBar={3}
                    from={'home'}
                    title={sectionTestimonial.title ?? 'CHECK OUR NEW INVENTORY'}
                    siteColor={siteColor}
                  />
                }
                {
                  !hideContact &&
                  getInTouchComponent
                }
              </>
            }
          </section>
        }
        {/*<DPBackToTop*/}
        {/*  siteColor={siteColor}*/}
        {/*  templateCode={templateCode}*/}
        {/*/>*/}
        {
          currentTheme === DARK_THEME &&
          <div className='container-banner-car' style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}/>
        }
        <div className={financePage.formView === 'vertical' ? 'finance-view-vertical' : 'finance-view-wizard'}>
          <LayoutFooter
            backgroundMain={backgroundMain}
            dealerId={dealerId}
            footer={footer}
            socialMedia={socialMedia}
            siteColor={siteColor}
            templateCode={templateCode}
            saveEmailMessage={saveEmailMessage}
            currentTheme={currentTheme}
            isButtonSticky={true}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    locations: state.locationsPublic,
    templateContent: state.templateContent,
    templateCode: state.templateCode,
    locationId: state.locationId
  }
}

export default connect(
  mapStateToProps,
  {loadLocationsPublic, selectedLocationId, saveEmailMessage}
)(LayoutFinanceContainer)