import classNames from 'classnames'
import {ReactSVG} from 'react-svg'
import './DPButton.scss'
import '../../styles/utils.scss';
import {pathServer} from '../../../common/Constants'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useState} from 'react'

export const BUTTON_TYPE = {
  PRIMARY: 'dp-button--primary',
  OUTLINE: 'dp-button--outline',
  NO_BORDER: 'dp-button--no-border',
}

export const BUTTON_COLOR = {
  ACCEPT: 'dp-button--accept',
  DECLINE: 'dp-button--decline',
  NORMAL: 'dp-button--normal',
  CLASSIC: 'dp-button--classic'
}

export const BUTTON_SIZE = {
  SMALL: 'DP-SMALL',
  MEDIUM: 'DP-MEDIUM',
  LARGE: 'DP-LARGE'
}

export const BUTTON_TEXT_CAP = {
  UPPERCASE: 'dp-button--text-uppercase',
  CAPITALIZE: 'dp-button--text-capitalize',
  LOWERCASE: 'dp-button--text-lowercase'
}

const getButtonAndIconSize = (size) => {
  switch (size) {
    case 'DP-SMALL':
      return {buttonSize: 'dp-button--small', iconSize: 'icon--small'}
    case 'DP-LARGE':
      return {buttonSize: 'dp-button--large', iconSize: 'icon--large'}
    default:
      return {buttonSize: 'dp-button--medium', iconSize: 'icon--medium'}
  }
}
const StyledButton = styled.button`
        background-color: ${(props) => props.backgroundColor};
        border: ${(props) => props.borderStyle};
        &:hover {
          background-color: ${(props) => props.isAdmin ? null : props.backgroundHoverColor};
          border: ${(props) => props.isAdmin ? null : props.borderHoverStyle};
        }
      `

const StyledSpan = styled.span`
        color: ${(props) => props.textColor};
        &:hover {
          color: ${(props) => props.hoverTextColor};
        }
      `

const DPButton = ({
                    label,
                    type,
                    color,
                    size,
                    textCap,
                    onClick,
                    startIcon,
                    endIcon,
                    iconClassName,
                    buttonClassName,
                    textColor,
                    textHoverColor = null,
                    backgroundColor,
                    backgroundHoverColor,
                    borderStyle,
                    borderHoverStyle,
                    isDisabled,
                    isLoading,
                    isAdmin,
                    id,
                    hoverTextColor= null,
                    ...rest
                  }) => {

  const [isHovered, setIsHovered] = useState(false);

  const {buttonSize, iconSize} = getButtonAndIconSize(size)

  const buttonClassNames = classNames(
    'dp-button',
    {'dp-button--disabled': isDisabled},
    buttonSize,
    buttonClassName,
    {'dp-button--icon-only': (startIcon || endIcon) && !label},
    type,
    color,
    {size: true},
    textCap,
  )

  const buttonClick = isAdmin || isDisabled || isLoading ? () => {
  } : onClick

  const defaultId = id || 'default'

  const [buttonId, buttonTestId, leftIconTestId, rightIconTestId, labelTestId] = [
    `${defaultId}`,
    `dp-button--${defaultId}`,
    `dp-button--${defaultId}--left-icon`,
    `dp-button--${defaultId}--right-icon`,
    `dp-button--${defaultId}--label`,
  ]

  const currentColor = textHoverColor != null ? (isHovered ? textHoverColor : textColor) : textColor

  return (
    <div className='flex-row'
    >
      <StyledButton
        id={buttonId}
        className={buttonClassNames}
        backgroundColor={backgroundColor}
        backgroundHoverColor={backgroundHoverColor}
        borderStyle={borderStyle}
        borderHoverStyle={borderHoverStyle}
        onClick={buttonClick}
        data-testid={buttonTestId}
        disabled={isDisabled}
        isAdmin={isAdmin}
        onMouseEnter={()=>setIsHovered(true)}
        onMouseLeave={()=>setIsHovered(false)}
        hoverTextColor={hoverTextColor}
      >
        {startIcon && (
          <ReactSVG
            src={pathServer.PATH_IMG + 'icon/' + startIcon}
            className={'button-icon-center'}
            beforeInjection={svg => {
              svg.classList.add(`${iconSize}`)
              svg.classList.add(`${iconClassName ? iconClassName : null}`)
            }
            }
            data-testid={leftIconTestId}
          />
        )}
        <StyledSpan
          textColor={textColor ? textColor : ''}
          hoverTextColor={hoverTextColor != null ? hoverTextColor : textColor}
          data-testid={labelTestId}
        >
          {label}
        </StyledSpan>
        {endIcon && (
          <ReactSVG
            src={pathServer.PATH_IMG + 'icon/' + endIcon}
            className={'button-icon-center'}
            beforeInjection={svg => {
              svg.classList.add(`${iconSize}`)
              svg.classList.add(`${iconClassName ? iconClassName : null}`)
            }
            }
            data-testid={rightIconTestId}
          />
        )}
      </StyledButton>
    </div>
  )
}

DPButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf([BUTTON_TYPE.PRIMARY, BUTTON_TYPE.OUTLINE, BUTTON_TYPE.NO_BORDER]),
  color: PropTypes.oneOf([BUTTON_COLOR.ACCEPT, BUTTON_COLOR.DECLINE, BUTTON_COLOR.NORMAL, BUTTON_COLOR.CLASSIC]),
  size: PropTypes.oneOf([BUTTON_SIZE.SMALL, BUTTON_SIZE.MEDIUM, BUTTON_SIZE.LARGE]),
  textCap: PropTypes.oneOf([BUTTON_TEXT_CAP.UPPERCASE, BUTTON_TEXT_CAP.CAPITALIZE, BUTTON_TEXT_CAP.LOWERCASE]),
  onClick: PropTypes.func,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  iconClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  customClass: PropTypes.string,
  textColor: PropTypes.string,
  textHoverColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundHoverColor: PropTypes.string,
  borderStyle: PropTypes.string,
  borderHoverStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  id: PropTypes.string,
}

export default DPButton
