import React, {useEffect, useState} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader/'
import {
  PAGE_ABOUT_US_ID,
  CURRENT_LOCATION,
  TEMPLATE_5_NAME,
  CURRENT_THEME,
  LIGHT_THEME,
  SITE_BACKGROUND_COLOR,
  BOX_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  BOX_BORDER_COLOR,
  DEFAULT_THEME_ID,
  SECTION_TESTIMONIAL_ID,
  PAGE_HOME_ID,
  SUCCESSFUL_CODE,
  defaultDealerImages,
  pathServer,
  WIDTH_VIEW_TABLET,
  WIDTH_VIEW_MOBILE,
} from '../../common/Constants'
import LayoutFooter from '../../common/template/LayoutFooter'
import {connect} from 'react-redux'
import LayoutCustomerHorizontalTestimonials from '../../common/LayoutCustomerHorizontalTestimonials.jsx'
import {loadLocationsPublic, selectedLocationId, saveEmailMessage} from '../actions'
import LayoutDealerMedia from '../../common/template/LayoutDealerMedia'
import LayoutAboutUsGoal from '../../common/template/LayoutAboutUsGoal'
import {getSiteColor, getBackgroundModernView, getBaseTemplateCode} from '../../common/Util'
import DPBackToTop from '../../common/DPBackToTop'
import DPCarousel, {ARROWS_BOTTOM, HORIZONTAL_CAROUSEL, SEGMENTS_BAR} from '../../common/genericComponents/DPCarousel'
import {loadDealerMedia} from "../Api";

const DEVICES = {
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  MOBILE: 'MOBILE'
}

const LayoutAboutUsContainer = ({
                                  loadLocationsPublic, locations, selectedLocationId, locationId, templateContent,
                                  dealerId, templateCode, saveEmailMessage
                                }) => {

  const content = JSON.parse(templateContent)
  const {generalSetting, pages} = content
  const {
    footer,
    socialMedia,
    currentTheme = DEFAULT_THEME_ID,
    site: {logo = '', maxHeight: maxHeightLogo},
    banner
  } = generalSetting
  const pageAboutUs = pages.find(page => page.id === PAGE_ABOUT_US_ID)
  const pageHome = content.pages.find(page => page.id === PAGE_HOME_ID)
  const testimonialSection = pageHome.sections.find( sec => sec.id === SECTION_TESTIMONIAL_ID)
  const hideTestimonial = !!testimonialSection.hideSection
  const backgroundMainUrl = getBackgroundModernView(templateCode, banner.backgroundMain.url)
  const {header, showDealerHours, sections} = pageAboutUs
  const siteColor = getSiteColor(generalSetting)

  const baseTemplate = getBaseTemplateCode(templateCode)
  const customBackgroundColor = currentTheme === LIGHT_THEME ? "#F5F6FA" : "#0B1524" //temporal until the colors in the setting style are defined
  const customBackgroundColorForAllTemplates = baseTemplate === TEMPLATE_5_NAME
    ? siteColor.boxBackground
    : customBackgroundColor
  const [dealerMedias, setDealerMedias] = useState([])
  const [currentDevice, setCurrentDevice] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      if (!locations) {
        const locationsPublic = await loadLocationsPublic()
        if (locationsPublic?.status) {
          console.error(locationsPublic.message)
          return
        }
        const locationCurrent = locationsPublic?.find(loc => {
          return loc.locationId === CURRENT_LOCATION
        })
        const location = locationCurrent ? locationCurrent : locationsPublic[0]
        selectedLocationId(location.locationId)
      }

      const response = await loadDealerMedia(dealerId)
      const {responseCode, data = {}} = response
      if (responseCode !== SUCCESSFUL_CODE) {
        return
      }
      let dealerMedias = data?.dealerMedias.length > 0 ? data?.dealerMedias : defaultDealerImages
      setDealerMedias(dealerMedias)
    }
    fetchData().then()
    window.addEventListener('resize', handleCurrentDevice);

    return () => window.removeEventListener('resize', handleCurrentDevice);
  }, [currentDevice])

  const onReady = (url) => {
    setUrlSelected(url)
    setIsReady(true)
  }

  const onClose = () => {
    setUrlSelected(null)
    setIsReady(false)
    setPlaying(false)
  }

  let hoursOfOperation = []
  let locationAddress = ''
  if (locations) {
    const locationFound = locations.find(location => {
      return location.locationId === locationId
    })
    const location = locationFound ? locationFound : locations[0]
    if (location && location.info) {
      const locationInfo = JSON.parse(location.info)
      const {name, city, state, address} = location
      hoursOfOperation = locationInfo.hoursOfOperation
      locationAddress = `${name}: ${address}, ${city}. ${state}.`
    }
  }

  let showTestimonialSection = false
  if (sections) {
    const sectionTestimonial = sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID)
    showTestimonialSection = !!sectionTestimonial?.showSection
  }

  const {
    [PRIMARY_COLOR]: primaryColor,
    [SITE_BACKGROUND_COLOR]: siteBackground,
    [BOX_BACKGROUND_COLOR]: boxBackground,
    [BOX_BORDER_COLOR]: boxBorder
  } = siteColor

  const customBackground = currentTheme === LIGHT_THEME ? "#FFFFFF" : "#162335" //temporal until the colors in the setting style are defined
  const ItemDealerMedia = ({item}) => {
    return(
        <img className="dealer-image-item" src={item.url || pathServer.PATH_IMG +`carImageComingSoon.jpg` } /*style={{transition: "0.8s"}}*//>
    )
  }
  const withinTabletDimensions = window.innerWidth <= WIDTH_VIEW_TABLET && window.innerWidth > WIDTH_VIEW_MOBILE;
  const numberOfItemsPerPage = withinTabletDimensions ? 3 : window.innerWidth <= WIDTH_VIEW_MOBILE ? 1 : 4
  const numberOfItemsToShift = withinTabletDimensions ? 3 : window.innerWidth <= WIDTH_VIEW_MOBILE ? 1 : 4
  const imagesCarouselHeight = withinTabletDimensions ? "22vw" : window.innerWidth <= WIDTH_VIEW_MOBILE ? "65vw" : "17vw"
  const customBackgroundContrast = currentTheme === LIGHT_THEME ? "#F5F6FA" : "#0B1524" //temporal until the colors in the setting style are defined

  const handleCurrentDevice = () => {
    let currentDevice = DEVICES.DESKTOP
    if (window.innerWidth <= WIDTH_VIEW_TABLET && window.innerWidth > WIDTH_VIEW_MOBILE) {
      currentDevice = DEVICES.TABLET
    } else if ( window.innerWidth <= WIDTH_VIEW_MOBILE) {
      currentDevice = DEVICES.MOBILE
    }

    setCurrentDevice(currentDevice)
  }

  return (
    <div className={`layout-about-container-5 container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}>
      <LayoutHeader
        menu={header.menu}
        logo={logo}
        maxHeight={maxHeightLogo}
        menuSelected='aboutUs'
        locations={locations}
        siteColor={siteColor}
        templateCode={templateCode}
        locationId={locationId}
        selectLocationId={selectedLocationId}
        baseTemplateCode={TEMPLATE_5_NAME}
      />
      <div id='bannerSeparator' className='banner-separator'/>
      <div
        id='layoutAbout'
        className='layout-about'
        style={{backgroundColor: customBackgroundColorForAllTemplates}}
      >
        <div
          className='layout-about-box'
          style={{
            backgroundColor: customBackgroundColorForAllTemplates,
            borderStyle: 'none'
            // border: `1px solid ${boxBorder}`
          }}
        >
          {
            !withinTabletDimensions &&
            <div className='about-testimonial'>
              <LayoutDealerMedia
                  primaryColor={primaryColor}
                  dealerId={dealerId}
                  templateCode={TEMPLATE_5_NAME}
              />
            </div>
          }

          <LayoutAboutUsGoal
            currentTheme={currentTheme}
            siteColor={siteColor}
            page={pageAboutUs}
            templateCode={templateCode}
            hoursOfOperation={hoursOfOperation}
            showDealerHours={showDealerHours}
          />

          {
            withinTabletDimensions &&
              <div className="carousel-dealer-images-container" style={{backgroundColor: customBackgroundColorForAllTemplates}}>
                <DPCarousel
                    barSelectedPageColor={primaryColor}
                    direction={HORIZONTAL_CAROUSEL}
                    itemList={dealerMedias}
                    ItemComponent={({item}) => <ItemDealerMedia item={item}/>}
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    numberOfItemsToShift={numberOfItemsToShift}
                    barType={SEGMENTS_BAR}
                    width={"100%"}
                    height={imagesCarouselHeight}
                    customisedArrowPosition={ARROWS_BOTTOM}
                    arrowContainerBackgroundColor={customBackgroundContrast}
                />
              </div>
          }

          {/*** Testimonials active only in mobile view  ( <= 758px) ***/}

        </div>
      </div>
      <div className="layout-about-testimonials">
        <div className="testimonials-section">
          {/*** Testimonials active only in web and tablet view ( > 768px)***/}
          {
            !hideTestimonial &&
              <LayoutCustomerHorizontalTestimonials
                hideTitle={false}
                heightCurrentBar={7}
                heightTotalBar={3}
                from={'aboutUs'}
                siteColor={siteColor}
                barType={SEGMENTS_BAR}
                numberOfItemsPerPage={5}
                numberOfItemsToShift={1}
                width={"600px"}
                height={"120px"}
              />

          }
        </div>
      </div>
      <LayoutFooter
        backgroundMain={backgroundMainUrl}
        dealerId={dealerId}
        footer={footer}
        socialMedia={socialMedia}
        siteColor={siteColor}
        templateCode={templateCode}
        saveEmailMessage={saveEmailMessage}
        isButtonSticky={true}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    locations: state.locationsPublic,
    locationId: state.locationId,
    templateContent: state.templateContent,
    templateCode: state.templateCode
  }
}

export default connect(
  mapStateToProps,
  {loadLocationsPublic, selectedLocationId, saveEmailMessage}
)(LayoutAboutUsContainer)
