import React from 'react'
import {ReactSVG} from 'react-svg'
import {GRID_VIEW, LIST_VIEW, pathServer} from '../../Constants'

export const InventoryViewMode = ({
                                       inventoryView,
                                       primaryColor,
                                       handleInventoryView
                                      }) => {
  return (
    <>
      <ReactSVG
        src={pathServer.PATH_IMG + 'icon/ic_view_list.svg'}
        beforeInjection={svg => {
          inventoryView === LIST_VIEW
            ? svg.classList.add('icon-list-selected')
            : svg.classList.add('icon-list-unselected')
          inventoryView === LIST_VIEW && svg.setAttribute('style', `fill: ${primaryColor}`)
        }}
        onClick={() => handleInventoryView(LIST_VIEW)}
        className={'icon-list'}
      />
      <ReactSVG
        src={pathServer.PATH_IMG + 'icon/ic_view_grid.svg'}
        beforeInjection={svg => {
          inventoryView === GRID_VIEW
            ? svg.classList.add('icon-grid-selected')
            : svg.classList.add('icon-grid-unselected')
          inventoryView === GRID_VIEW && svg.setAttribute('style', `fill: ${primaryColor}`)
        }}
        onClick={() => handleInventoryView(GRID_VIEW)}
        className={'icon-grid'}
      />
    </>
  )
}
