import React, { useEffect } from 'react'
import { StyledReactSVG } from '../StyledComponentUtil'
import { getPosition, getScrollTop, scrollTo, getBaseTemplateCode } from '../Util'
import { pathServer, PRIMARY_COLOR, SECONDARY_COLOR, TEMPLATE_5_NAME } from '../Constants'
import './DPBackToTop.scss'

export const handleToTop = () => {
  const backToTopElement = document.querySelector('backToTop')
  const pointTo = getPosition(backToTopElement)
  const pointFrom = {
    x: 0,
    y: getScrollTop()
  }
  scrollTo(pointFrom, pointTo, 600)
}

const setUpBackToTopElement = (siteColor = {}, isArrowColorInverted, contrastColor) => {
  const backToTopElement = document.getElementById('circleStack')
  const { [PRIMARY_COLOR]: primary, [SECONDARY_COLOR]: secondary } = siteColor

  if (backToTopElement) {
    const backToTopJQueryElement = $(backToTopElement)
    backToTopJQueryElement.css('background-color', primary)
    backToTopJQueryElement.hover(
      // handlerIn
      (e) => {
        if ('ontouchstart' in window) {
          e.removeClass('hover')
        }
        backToTopJQueryElement.css('background-color', 'transparent')
        const iconSVG = backToTopElement.childNodes[0].childNodes[0]
        iconSVG.style.fill = secondary
        backToTopElement.style.borderColor = secondary
      },
      // handlerOut
      () => {
        backToTopJQueryElement.css('background-color', `${primary}`)
        const iconSVG = backToTopElement.childNodes[0].childNodes[0]
        iconSVG.style.fill = contrastColor
        backToTopElement.style.borderColor = isArrowColorInverted ? primary : contrastColor
      }
    )
  }
}

const DPBackToTop = ({ siteColor, templateCode, isAdmin, isArrowColorInverted = false }) => {

  const baseTemplate = getBaseTemplateCode(templateCode)
  const isTemplate5 = baseTemplate === TEMPLATE_5_NAME

  const whiteElements = (currentColor) => {
    if (currentColor === '#ffffff' || currentColor === '#fff')
      return '#000'
    return '#fff'
  }

  const blackElements = (currentColor) => {
    if (currentColor === '#000000' || currentColor === '#000')
      return '#fff'
    return '#000'
  }

  const contrastColor = isArrowColorInverted ? whiteElements(siteColor[PRIMARY_COLOR]) : blackElements(siteColor[PRIMARY_COLOR])


  useEffect(() => {
    if (isAdmin)
      return

    window.onscroll = () => {
      const elementToCheckScroll = document.elementFromPoint(0, isTemplate5 ? 170 : 120)
      const { navigationBarBackground = '#FFFFFF' } = siteColor
      const backToTop = document.getElementById('backToTop')
      switch (elementToCheckScroll.id) {
        case 'bannerSeparator':
        case 'titleBanner5':
          if (backToTop)
            backToTop.className = 'off'
          break
        case 'separatorHeader':
          if (backToTop)
            backToTop.className = 'off'
          break
        case 'footerLimiter':
          document.getElementById('backToTop').className = 'on hold'
          break
        case 'bannerContainer':
        case 'bannerTitle':
        case 'bannerRedSocial' :
        case 'background':
        case 'carSeparator1' :
        case 'contentSeparator2' :
        case 'carSeparator2' :
        case 'layCar' :
          if (backToTop)
            backToTop.className = 'on'
          break
        default :
          if (backToTop)
            backToTop.className = 'on'
          const headerElement = document.getElementById('header')
          if (headerElement !== null) {
            headerElement.className = 'header-container'
            headerElement.style.backgroundColor = navigationBarBackground
          }
          break
      }
    }

    setUpBackToTopElement(siteColor, isArrowColorInverted, contrastColor)
  }, [])

  return (
    <a
      id="backToTop"
      className={isAdmin ? 'on' : 'off'}
      onClick={() => isAdmin ? {} : handleToTop()}
      style={{
        position: isAdmin ? 'absolute' : undefined,
        bottom: isAdmin ? '15px' : undefined,
      }}
    >
      <StyledReactSVG
        id="circleStack"
        src={pathServer.PATH_IMG + 'icon/ic_arrow_upward.svg'}
        beforeInjection={svg => svg.setAttribute('style', `fill: ${contrastColor}`)}
        style={{
          border: `2px solid ${isArrowColorInverted ? siteColor[PRIMARY_COLOR] : contrastColor}`,
          backgroundColor: siteColor[PRIMARY_COLOR]
        }}
      />
    </a>
  )
}

export default DPBackToTop