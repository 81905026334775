import React from 'react'
import {ModalBox, PopoverBox} from './ComponentUtils'
import {pathServer, TEXT_COLOR, optionSocialNetwork} from './Constants'
import isEmpty from 'lodash/isEmpty'

const DPTestimonial = ({testimonials,handlePopover,sliderLeft,sliderRight, id = ''}) => {
  return(
    testimonials.length !== 0 ?
      <div id={id} className='container-carousel-testimonials'>
        {
          testimonials.map((testimonial,index) => {
            const socialNetwork = optionSocialNetwork.find(x => x.code === testimonial.socialNetwork);
            return(
              testimonial.styleContainer !== '' ?
                <div key={index} className={testimonial.styleContainer}
                     onClick={(evt) => handlePopover ? handlePopover(testimonial.content, evt) : {}}>
                  <div className={testimonial.styleText}>
                    <div className={testimonial.styleImage}>
                      <img src={(testimonial.userPicture !== "") ? testimonial.userPicture : pathServer.PATH_IMG+"ic_account_circle.png"}
                           alt=""
                           onError={(e)=>{e.target.src = `${pathServer.PATH_IMG}ic_account_circle.png`}}
                      />
                    </div>
                    <div >
                      <div id={"testimonialContent"+index} className="testimonial-content">{testimonial.content}</div>
                    </div>
                    <div className="content-username">
                      <h4 className="user-name">{testimonial.userName ? testimonial.userName : "User name unavailable"}</h4>
                    </div>
                    {
                      !isEmpty(socialNetwork) ?
                        <div className="carousel-content-social-network">
                          <label className="label-social">From Facebook</label>
                        </div> : null
                    }
                  </div>
                </div>: null)
          })
        }
        {sliderLeft}
        {sliderRight}
      </div>
      : null
  )
};

const DPCustomerTestimonial = ({testimonials,handlePopover,sliderLeft,sliderRight,widthLayoutContainer,showPopover,
                                 positionPopoverX,positionPopoverY,offsetWidth,displayValue,handleRequestClose, id,
                                 title, siteColor = {} }) => {
  return(
    testimonials.length !== 0 &&
      <div id={id} className="layout-customer-testimonial">
        {
          title &&
          <span className={"testimonials-title"} style={{color: siteColor[TEXT_COLOR] ? siteColor[TEXT_COLOR]: '#7F7F7F'}}>{title}</span>
        }
          <DPTestimonial testimonials={testimonials}
                         handlePopover={handlePopover}
                         sliderLeft={sliderLeft}
                         sliderRight={sliderRight}
                         id={'carouselTestimonialsContainer'}/>
          {
            widthLayoutContainer > 940 ?
              showPopover ?
                <PopoverBox positionPopoverX={positionPopoverX} positionPopoverY={positionPopoverY} handleRequestClose={handleRequestClose}
                            offsetWidth={offsetWidth} displayValue={displayValue} borderRadius={18}/>
                : null
              :
              showPopover ?
                <ModalBox show={showPopover} handleRequestClose={handleRequestClose} displayValue={displayValue} />
                : null
          }
        </div>
  )
};

export default DPCustomerTestimonial;