import React, {useState} from 'react'
import {unstable_batchedUpdates} from 'react-dom'
// @ts-ignore
import {PopoverSearchable} from '../newComponents/util/ComponentUtils'
// @ts-ignore
import {DPPopover} from '../../common/DPPopover'
// @ts-ignore
import classnames from 'classnames'
// @ts-ignore
import {EmptyFunc} from '../../common/Constants'
// @ts-ignore
import DPIcon, {Icons} from '../../admin/common/DPIcon'
// @ts-ignore
import {getRandomId} from '../../admin/Utils'

require('./DPSeleciontDropDown.scss')

const defaultOptions = [
  { id: '1', displayValue: 'Option 1'},
  { id: '2', displayValue: 'Option 2'},
  { id: '3', displayValue: 'Option 3'},
]

interface OptionDropDown  {
  id: string
  displayValue: string
}

interface AnchorEl {
  x?: number
  y?: number
  offsetWidth?: number
}

interface DropDownProps {
  className?: string
  label?: string
  isRequired?: boolean
  id?: string
  onClick?: any
  value?: any
  errorMessage?: string
  placeholder?: string
  options?: OptionDropDown[]
  isSearchable?: boolean
  disabled?: boolean
  labelStyle?: object
  style?: object
  selectStyle?: object
  textStyle?: object
  heightOfSelect?: number
  testId?: string
}

const DPSelectionDropDown = (props: DropDownProps) => {
  const [showPopover, setShowPopover] = useState(false)
  const [anchorElStatus, setAnchorElStatus] = useState<AnchorEl>({})
  const { className, label, isRequired = false, id = getRandomId(),
    value = defaultOptions[0].id, errorMessage, placeholder = 'Select one', options = defaultOptions,
    isSearchable = false, disabled = false, labelStyle = {}, style,
    selectStyle, textStyle, testId, heightOfSelect = 36,
    onClick = EmptyFunc } = props

  const getPosition = () => {
    const target = document.getElementById(id);
    if(target){
      return {
        x: target.offsetLeft,
        y: isSearchable ? target.offsetTop : target.offsetTop + heightOfSelect,
        offsetWidth: target.offsetWidth
      }
    }
  }

  const handleShowPopover = (event: { preventDefault: () => void; stopPropagation: () => void })=>  {
    event.preventDefault()
    event.stopPropagation()

    const anchorEl = getPosition();
    if(anchorEl){
      unstable_batchedUpdates( () => {
          setShowPopover(!showPopover)
          setAnchorElStatus(anchorEl)
        }
      )
    }
  }

  const getPopoverContentBody = () => {
    const itemsToDisplay = options && options.map((option, index) => {
      let className = "container-option-for-select"
      if(value === option.id){
        className = "container-option-for-select-checked"
      }
      return (
        <div
          key={index}
          id={"option" + index}
          className={className}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            closePopover()
            onClick(option.id)
          }}
        >
          <label className="option-label-for-select">
            {option.displayValue}
          </label>
        </div>
      )
    });

    return (
      <div className="popover-body-for-select">
        <div
          id="options-make"
          className="options-for-select"
        >
          {itemsToDisplay}
        </div>
      </div>
    )
  }

  const closePopover = () => {
    setShowPopover(false)
  }

  // This method is used only for PopoverSearchable component
  const onChangePopoverSearchable = (idOption: string) => {
    const selectedOption = options
      && options.find(option => option.id === idOption)

    if(selectedOption){
      setShowPopover(false)
      onClick(selectedOption.id)
    }
  }

    const selectedOption = options && options.find(el => el.id === value)

    return (
      <div style={style} className={classnames(['dp-select-dropdown',className])}>
        <div className="container-title">
          {
            label
              ? <label className="label-make" style={labelStyle}>{label}</label>
              : null
          }
          {
            isRequired
              ? <span className="label-error">*</span>
              : null
          }
        </div>
        <div
          style={selectStyle}
          className={errorMessage ? "select has-error" : disabled ? "select select-disabled" : "select"}
          id={id}
          onClick={!disabled ? handleShowPopover : EmptyFunc}
        >
          <div className="container-label">
            <label className="label-make" style={textStyle} data-testid={`select-value-${testId}`}>
              { (selectedOption && selectedOption['displayValue']) || placeholder}
            </label>
          </div>
          <div className="container-icon">
            <DPIcon icon={Icons.TRIANGLE_DOWN}/>
          </div>
        </div>
        {
          errorMessage
            ? <p className="help-block">{errorMessage}</p>
            : null
        }

        {
          showPopover
            ? isSearchable
              ? <PopoverSearchable
                id={'popoverSearchable-id'}
                anchorEl={anchorElStatus}
                options={options}
                classPopover=""
                onChangeDropDown={closePopover}
                onChange={onChangePopoverSearchable}
                foundValue={selectedOption}
              />
              :
            <DPPopover
                anchorEl={anchorElStatus}
                classNameWrapper="popover-for-select"
                handleRequestClose={closePopover}>
              {getPopoverContentBody()}
            </DPPopover>
          : null
        }
      </div>
    )
}

export default DPSelectionDropDown;