import React from 'react'
import {
  BOX_BACKGROUND_COLOR,
  CONTROL_BACKGROUND_COLOR, DARK_THEME,
  SITE_BACKGROUND_COLOR,
  TEMPLATE_5_NAME,
  TEXT_COLOR
} from '../../Constants'
import {TableRow} from '../../ComponentUtils'

const CarDescriptionAndTable = ({templateCode, siteColor, car, currentTheme}) => {
  const isTemplate5 = (templateCode === TEMPLATE_5_NAME)
  const styleClassesByTemplateCode = isTemplate5 ?
    {
      classFeature: 'cell-feature',
      classValue: 'cell-value'
    } :
    {
      classFeature: 'cell-specifications-left',
      classValue: 'cell-specifications-right'
    }

  const firstRowCustomStyle = {
    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
    color: siteColor[TEXT_COLOR],
  }

  const secondRowCustomStyle = {
    backgroundColor: siteColor[SITE_BACKGROUND_COLOR],
    color: siteColor[TEXT_COLOR],
  }

  const boxBackgroundColor = siteColor[BOX_BACKGROUND_COLOR]

  const Description = () => (
    <>
      {
        isTemplate5 ? (
          <div className='car-description'>
            <p style={{color: siteColor[TEXT_COLOR]}}>{car.description}</p>
          </div>
        ) : (
          <div className='car-des'>
            <h3 style={{color: siteColor[TEXT_COLOR]}}>Dealer Description</h3>
            <div className='inventory-description-wrapper'>
              <label style={{whiteSpace: 'pre-line', color: siteColor[TEXT_COLOR]}}>{car.description}</label>
            </div>
          </div>
        )
      }
    </>
  )

  return (
    <>
      <div className={isTemplate5 ? 'row-base' : 'inv-car-des'}
           style={!isTemplate5 ? {
             backgroundColor: boxBackgroundColor,
             borderTop: `1px solid ${currentTheme === DARK_THEME ? '#E2E2E2' : '#00000029'}`
           } : null}>
        <Description/>
        <div className='car-feature'>
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Year'
                    value={(car.year && car.year !== '') ? car.year : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Make'
                    value={(car.makeName && car.makeName !== '') ? car.makeName : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Model'
                    value={(car.modelName && car.modelName !== '') ? car.modelName : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Trim'
                    value={(car['car.trim'] && car['car.trim'].attributeValue !== '') ? car['car.trim'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Body'
                    value={(car['car.body'] && car['car.body'].attributeValue !== '') ? car['car.body'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Mileage'
                    value={(car.mileage && car.mileage !== null) ? car.mileage : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='VIN'
                    value={car.vin ? car.vin : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Title Type'
                    value={(car['car.title'] && car['car.title'].attributeValue !== '') ? car['car.title'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Exterior Color'
                    value={(car['car.exteriorColor'] && car['car.exteriorColor'].attributeValue !== '') ? car['car.exteriorColor'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Interior Color'
                    value={(car['car.interiorColor'] && car['car.interiorColor'].attributeValue !== '') ? car['car.interiorColor'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Transmission'
                    value={(car['car.transmission'] && car['car.transmission'].attributeValue !== '') ? car['car.transmission'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Liters'
                    value={(car['car.liter'] && car['car.liter'].attributeValue !== '') ? car['car.liter'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Cylinders'
                    value={(car['car.cylinder'] && car['car.cylinder'].attributeValue !== '') ? car['car.cylinder'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Fuel Type'
                    value={(car['car.fuel'] && car['car.fuel'].attributeValue !== '') ? car['car.fuel'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Numbers of Doors'
                    value={(car['car.numberDoor'] && car['car.numberDoor'].attributeValue !== '') ? car['car.numberDoor'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Exterior Condition'
                    value={(car['car.exteriorCondition'] && car['car.exteriorCondition'].attributeValue !== '') ? car['car.exteriorCondition'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
          <TableRow classRow='row-specifications-one' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Interior Condition'
                    value={(car['car.interiorCondition'] && car['car.interiorCondition'].attributeValue !== '') ? car['car.interiorCondition'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? firstRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
                    isBackgroundContrasted={!isTemplate5 ? currentTheme === 'dark' : null}
          />
          <TableRow classRow='row-specifications-two' classFeature={styleClassesByTemplateCode.classFeature}
                    classValue={styleClassesByTemplateCode.classValue}
                    displayValue='Drive'
                    value={(car['car.drive'] && car['car.drive'].attributeValue !== '') ? car['car.drive'].attributeValue : 'Not Specified'}
                    customStyle={isTemplate5 ? secondRowCustomStyle : null}
                    siteColor={!isTemplate5 ? siteColor : null}
          />
        </div>
      </div>
    </>
  )
}

export default CarDescriptionAndTable
