/**
 * Created by Jhonny Apolinario on 20/10/2016
 */

import React, {useState, useEffect} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader'
import LayoutFooter from '../../common/template/LayoutFooter'
import LayoutFinance from '../../common/template/LayoutFinance.jsx'
import {
  loadMakeAndModel, loadLocationsPublic, searchInventoryPublic, selectedLocationId, saveEmailMessage,
  toggleCarAdvancedSearchMenu
} from '../actions'
import {connect, useDispatch, useSelector} from 'react-redux'
import LayoutLocation from '../../common/template/LayoutLocation.jsx'
import LayoutBanner from '../../common/template/LayoutBanner'
import {
  TEMPLATE_5_NAME, TEMPLATE_MAIN_NAME, pathServer, SECTION_FINANCE_ID, BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN,
  SECTION_CONTACT_US_ID, PAGE_HOME_ID, SECTION_INVENTORY_ID, SECTION_LOCATION_ID, SECTION_TESTIMONIAL_ID,
  EASY_NAVIGATION_TEMPLATE_CODE, EASY_NAVIGATION_B_TEMPLATE_CODE, EASY_NAVIGATION_C_TEMPLATE_CODE,
  SITE_BACKGROUND_COLOR, LIGHT_THEME, PRIMARY_COLOR, SECONDARY_COLOR, LIGHT_COLORS, DARK_COLORS
} from '../../common/Constants'
import LayoutCustomerHorizontalTestimonials from '../../common/LayoutCustomerHorizontalTestimonials.jsx'
import LayoutGetInTouch from '../../common/template/LayoutGetInTouch'
import get from 'lodash/get'
import LayoutCustomerVerticalTestimonial from '../../common/LayoutCustomerVerticalTestimonial.jsx'
import DPBackToTop from '../../common/DPBackToTop'
import {
  compareIds,
  getBackgroundEasyNavigation, getClassContainerEasyNavigation, getSiteColor
} from '../../common/Util'
import LayoutCar from '../../common/template/LayoutCar'

function LayoutContainer({
                           carsInformation,
                           makes,
                           locations,
                           templateContent,
                           dealerId,
                           templateCode,
                           locationId,
                           selectedLocationId,
                           saveEmailMessage,
                           searchInventoryPublic,
                           models,
                           toggleCarAdvancedSearchMenu,
                           loadMakeAndModel,
                           loadLocationsPublic,
                         }) {


  const [selectedPage, setSelectedPage] = useState('home')

  useEffect(() => {
    if (!carsInformation) {
      loadMakeAndModel()
      loadLocationsPublic()
    }
    searchInventoryPublic(dealerId, {})
  }, [])

  if (!carsInformation || !makes || !locations) return null

  const content = JSON.parse(templateContent)
  const {generalSetting, pages} = content
  const {
    socialMedia,
    footer,
    site: {logo = '', maxHeight: maxHeightLogo},
    banner,
    header,
    currentTheme
  } = generalSetting
  const backgroundMain = getBackgroundEasyNavigation(templateCode, banner.backgroundMain.url)
  const homePage = pages.find(page => page.id === PAGE_HOME_ID)
  const sectionFinance = homePage.sections.find(sec => sec.id === SECTION_FINANCE_ID)
  const sectionContactUs = homePage.sections.find(sec => sec.id === SECTION_CONTACT_US_ID)
  const sectionInventory = homePage.sections.find(sec => sec.id === SECTION_INVENTORY_ID)
  const sectionLocation = homePage.sections.find(sec => sec.id === SECTION_LOCATION_ID)
  const sectionTestimonial = homePage.sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID)
  const hideFinanceSection = !!sectionFinance.hideSection
  const hideTestimonialSection = !!sectionTestimonial.hideSection
  const hideContactSection = !!sectionContactUs.hideSection
  const siteColor = (currentTheme === LIGHT_THEME) ? generalSetting[LIGHT_COLORS] : generalSetting[DARK_COLORS]
  const {
    [PRIMARY_COLOR]: primaryColor,
    [SECONDARY_COLOR]: secondaryColor,
    [SITE_BACKGROUND_COLOR]: siteBackgroundColor
  } = siteColor
  const {menu} = header
  const {maxHeight} = banner.backgroundMain
  const backgroundFinance = banner.backgroundFinance && banner.backgroundFinance.url !== '' ? generalSetting.banner.backgroundFinance.url : pathServer.PATH_IMG + 'landscape.jpg'
  const classTemplateContainer = getClassContainerEasyNavigation(templateCode, 'layout-container-main')

  const bannerBaseTemplateCode = templateCode === EASY_NAVIGATION_TEMPLATE_CODE || templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE ?
    TEMPLATE_MAIN_NAME : (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE ? TEMPLATE_5_NAME : null)


  const getInTouchComponent = (
    <LayoutGetInTouch
      title={sectionContactUs?.title}
      siteColor={siteColor}
      dealerId={dealerId}
      templateCode={templateCode}
      section={sectionContactUs}
    />
  )

  const financeComponent = (
    <LayoutFinance
      section={sectionFinance}
      src={backgroundFinance}
      siteColor={siteColor}
      currentTheme={currentTheme}
      templateCode={templateCode}
    />
  )
  const locationComponent = (
    <LayoutLocation
      sectionLocation={sectionLocation}
      siteColor={siteColor}
      locations={locations}
      selectedLocationId={locationId}
      key={locationId}
      templateCode={templateCode}
      currentTheme={currentTheme}
      baseTemplateCode={TEMPLATE_MAIN_NAME}
    />
  )

  return (
    <div
      className={`${classTemplateContainer} layout-container-main-${currentTheme === LIGHT_THEME ? 'light' : 'dark'} container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}
      data-testid={'dp-home-container'}
    >
      <LayoutHeader
        menu={menu}
        logo={logo}
        maxHeight={maxHeightLogo}
        menuSelected={selectedPage}
        changeSelectedMenu={setSelectedPage}
        locations={locations}
        siteColor={siteColor}
        currentPage={homePage}
        templateCode={templateCode}
        locationId={locationId}
        selectLocationId={selectedLocationId}
        baseTemplateCode={TEMPLATE_MAIN_NAME}
      />
      <div id='headerContent' className='header-content'>
        <img
          id='background'
          className='background'
          style={{maxHeight: maxHeight >= BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN ? maxHeight : BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN}}
          src={backgroundMain}
          alt='background logo'
          data-testid={'dp-home-background'}
        />
        <div id='separatorHeader' className='separator-header'/>
        {
          (templateCode === EASY_NAVIGATION_TEMPLATE_CODE) &&
          <>
            <div id='carSeparator1' className='car-separator1' style={{backgroundColor: primaryColor}}/>
            <div id='contentSeparator2' className='content-separator2'>
              <div id='carSeparator2' className='car-separator2' style={{backgroundColor: secondaryColor}}/>
            </div>
          </>
        }
      </div>
      <div
        className='container-sections'
        style={{
          position: 'absolute',
          width: '100%',
          left: 0,
          top: 0,
          backgroundColor: siteBackgroundColor || '#f4f4f4'
        }}
      >
        <div id='contentBannerCar' className={`container-banner-car`}>
          {
            bannerBaseTemplateCode &&
            <LayoutBanner
              slogan={generalSetting.banner.slogan}
              backgroundMain={backgroundMain}
              maxHeight={maxHeight}
              templateCode={templateCode}
              siteColor={siteColor}
              searchInventoryPublic={searchInventoryPublic}
              dealerId={dealerId}
              makes={makes}
              models={models}
              socialMedia={socialMedia}
              isShowOptionsClickable={true}
              toggleCarAdvancedSearchMenu={toggleCarAdvancedSearchMenu}
              currentTheme={currentTheme}
            />
          }
          {
            <LayoutCar
            sectionInventory={sectionInventory}
            templateCode={templateCode}
            siteColor={siteColor}
            carsInformation={carsInformation.cars}
            locationId={locationId}
            />
          }

        </div>
        {/* Uncomment and set to false isButtonSticky prop from footer if you want to use the regular back to top button */}
        {/*<DPBackToTop*/}
        {/*  siteColor={siteColor}*/}
        {/*  templateCode={templateCode}*/}
        {/*/>*/}
        {
          (templateCode === EASY_NAVIGATION_TEMPLATE_CODE || templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE) &&
          <>
            {!hideFinanceSection && financeComponent}
            {locationComponent}
          </>
        }
        {
          (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE) &&
          <>
            {locationComponent}
            {!hideFinanceSection && financeComponent}
          </>
        }
        <div className={`content-box-get-in-touch-${currentTheme ? currentTheme : ''}`}>
          {
            (templateCode === EASY_NAVIGATION_TEMPLATE_CODE || templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE) &&
            <div id='contactMain' className='lay-get-in-touch' style={{backgroundColor: siteBackgroundColor}}>
              <div className='layout-contact-testimonial'>
                <div className={`layout-contact-${!hideContactSection? 'full' : 'hide'}`}>
                  {getInTouchComponent}
                </div>
                <div className={`layout-testimonials-${!hideTestimonialSection? 'full' : 'hide'}`}>
                  {
                    !hideTestimonialSection ?
                      sectionTestimonial && get(sectionTestimonial, ['showSection'], true) &&
                      <LayoutCustomerVerticalTestimonial
                        from={'home'}
                        sectionTestimonial={sectionTestimonial}
                        siteColor={siteColor}
                      />:null
                  }
                </div>
              </div>
            </div>
          }
          {
            (templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE) &&
            <>
              {!hideTestimonialSection ?
                <LayoutCustomerHorizontalTestimonials
                  hideTitle={false}
                  heightCurrentBar={12}
                  heightTotalBar={3}
                  from={'home'}
                  title={(sectionTestimonial && sectionTestimonial.title) ? sectionTestimonial.title : 'CHECK OUR NEW INVENTORY'}
                  siteColor={siteColor}
                /> : null
              }
              {getInTouchComponent}
            </>
          }
        </div>
        <LayoutFooter
          backgroundMain={backgroundMain}
          dealerId={dealerId}
          footer={footer}
          socialMedia={socialMedia}
          siteColor={siteColor}
          templateCode={templateCode}
          saveEmailMessage={saveEmailMessage}
          currentTheme={currentTheme}
          isButtonSticky={true}
        />
      </div>
      {/*<DPChat/>*/}
    </div>
  )

}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    carsInformation: state.carsInformation,
    makes: state.makes,
    models: state.models,
    locations: state.locationsPublic,
    templateContent: state.templateContent,
    templateCode: state.templateCode,
    locationId: state.locationId,
  }
}

export default connect(mapStateToProps,
  {
    loadMakeAndModel,
    loadLocationsPublic,
    searchInventoryPublic,
    selectedLocationId,
    saveEmailMessage,
    toggleCarAdvancedSearchMenu,
  }
)(LayoutContainer)