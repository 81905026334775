import React, {Component} from 'react'
import {InputTypes} from '../Constants'
import DynamicNumber from 'react-dynamic-number'
import InputElement from 'react-input-mask'
import _ from 'lodash'
import {sortBy} from 'lodash/collection'
import {checkValidEmail, checkValidPhone, checkValidVIN, checkRequired} from '../ValidationUtil'
import Select from 'react-select'
import Moment from 'moment'
import InputAutoComplete from '../UI/InputAutoComplete.jsx'
import DPKeyboardDatePicker from '../UI/DPKeyboardDatePicker'
import get from 'lodash/get'
import {defaultString, addZeros} from '../Util'
import {
  VALIDATION_VIN,
  VALIDATION_EMAIL,
  VALIDATION_PHONE,
  VALIDATION_REQUIRED,
  BOX_BACKGROUND_COLOR,
  TEXT_COLOR
} from '../Constants'

const formRenderer = (conf = {} )=>{
  return class extends Component {
    constructor(props) {
      super();
      this.renderField = this.renderField.bind(this);
      this.renderSectionSubs = this.renderSectionSubs.bind(this);
      this._onChange = this._onChange.bind(this);
      this.onChangeNumber = this.onChangeNumber.bind(this);
      this.handleChangeDate = this.handleChangeDate.bind(this);
      this.setErrors = this.setErrors.bind(this);
      this._validate = this._validate.bind(this);
      this.onChangeRadioButton = this.onChangeRadioButton.bind(this);
      this._onChangeMultiSelect=this._onChangeMultiSelect.bind(this);
      this.onBlurInput = this.onBlurInput.bind(this);
      this._onChangePopover = this._onChangePopover.bind(this);
      this.onChangeDropDown = this.onChangeDropDown.bind(this);
      this.handleBlurDate   = this.handleBlurDate.bind(this);
      this.copyResidence = this.copyResidence.bind(this);
      this.state = {
        data: props.data || {},
        errors: {},
        numberValue: {},
        numberView: {},
        date: null,
        format: "MM-DD-YYYY",
        value:'',
        showDropDown:{},
        keywords:null
      }
    }

    componentDidUpdate(){
      const {selectedId} = this.state;
      if(selectedId){
        const searchableInput = document.getElementById("searchableInput"+selectedId);
        if(searchableInput){
          $(searchableInput).focus();
        }
      }
    }

    render() {
      const {attributes,section,data,errorShow, siteColor} = this.props;
      let attributesBySection = _.filter(attributes,function (form) {
        return _.find(section.attributeIds,function(attributeId){ return form.id === attributeId})
      });
      return(
        <form>
          {this.getHeader(section.header)}
          <div className={"col100 panel-body " + (section.className ? section.className:"")}
               style={{backgroundColor: get(siteColor, [BOX_BACKGROUND_COLOR], null)}}
          >
            {this.renderSectionSubs(section,attributesBySection,this, siteColor)}
          </div>
        </form>
      )
    }

    getHeader(header){
      if(conf.getHeader && _.isFunction(conf.getHeader)){
        return conf.getHeader(header);
      }
      return(
        <div className="col100">
          {
            header.title ?
              <div className={"panel-heading " + (header.className ? header.className:"")}>
                <h5>{header.title}</h5>
                {header.subTitle ? <label>{header.subTitle}</label> : null}
              </div>:null
          }
        </div>
      )
    }

    renderSectionSubs(section,attributesBySection,that, siteColor) {
      //let that = this;
      let renderSectionSubs = [];
      if(_.isArray(section.sectionSubs)){
        _.forEach(section.sectionSubs,function (sectionSub,index) {
          let attributesBySectionSub = _.filter(attributesBySection,function (form) {
            return _.find(sectionSub.attributeIds,function(attributeId){ return form.id === attributeId})
          });
          renderSectionSubs.push(that.renderSectionSub(sectionSub,index,section.sectionSubs.length,attributesBySectionSub,null,that,section.name, siteColor));
        });
      }else{
        renderSectionSubs.push(that.renderSectionSub(section.sectionSubs,null,section.sectionSubs.length,attributesBySection,section.classRadioButton,that,section.name, siteColor));
      }

      return renderSectionSubs
    }

    renderSectionSub(sectionSub,index,length,attributesBySection,classRadioButton,that,sectionName, siteColor){
      let renderSection;
      //let attributesBySectionCopy = JSON.parse(JSON.stringify(attributesBySection));
      let renderBySection = _.map(sectionSub.rows,function(row,rowIndex){
        //let numberOfColumns = row.columns.length;
        //let attributesByRow = _.slice(attributesBySectionCopy,0,numberOfColumns);
        //attributesBySectionCopy = _.slice(attributesBySectionCopy,numberOfColumns);
        let renderByRow = _.map(row.columns,function (column,columnIndex) {
          const attributeFound = attributesBySection.find(attribute => {return attribute.id === column.status});
          return attributeFound ?
            <div key={columnIndex}
                 className={row.columns[columnIndex].width}>
              {that.renderField(attributeFound,index ? index:"0",classRadioButton)}
            </div> : null
        });
        return(
          <div key={rowIndex} className="col100">
            {renderByRow}
          </div>
        )
      });

      if (sectionName === 'applicant_employment' && sectionSub.name === 'current') {
        if (sectionSub.linkName === undefined) {
          sectionSub.linkName = 'Add second employment'
        }
      }

      renderSection = (
        <div key={index ? index:"0"} className="col-sec-sub">
          {
            sectionSub.title ?
              <div className="sec-sub-title">
                <h3 style={ (siteColor && siteColor[TEXT_COLOR]) ? {color: siteColor[TEXT_COLOR]} : {}}>{sectionSub.title}</h3>
                {sectionSub.subTitle ? <label style={ (siteColor && siteColor[TEXT_COLOR]) ? {color: siteColor[TEXT_COLOR]} : {}}>{sectionSub.subTitle}</label> : null}
                {
                  (conf.getHeaderButton && _.isFunction(conf.getHeaderButton)) ?
                      conf.getHeaderButton(sectionName,sectionSub.name,that.copyResidence) : null
                }
              </div> : null
          }
          <div>{renderBySection}</div>
          {
            sectionSub.linkName ?
              <div className="link-style">
                <a className="btn-link"
                   onClick={
                     sectionSub.linkName.includes('Add')
                     ? that.props.addSectionSub(length, sectionSub.name)
                     : that.props.removeSectionSub(index, sectionSub.name)
                   }
                >
                  {sectionSub.linkName}
                </a>
              </div> : null
          }
        </div>
      );

      return renderSection;
    }

    getValueData(data,name,index){
      let value;
      let dataValue;
      let dataValueFinal;
      if(_.isArray(data)){
        let item = data[index];
        dataValue = item[name];
      }else{
        dataValue = data[name];
      }
      if (dataValue && _.isArray(dataValue)) {
        dataValueFinal = dataValue[0];
      }else{
        dataValueFinal = dataValue
      }
      value = defaultString(dataValueFinal);
      return value;
    }

    hasParentChild(data,index,parentIds){
      let renderElement = -1;
      let item;
      if(_.isArray(data)){
        item = data[index];
      }else{
        item = data;
      }

      for(let key in item){
        if(_.isArray(item[key])){
          item[key].forEach(value => {
            renderElement = _.findIndex(parentIds,(id) => { return id === value});
            if(renderElement !== -1)
              return renderElement;
          });
        }
      }
      return renderElement;
    }

    renderField(input,index,classRadioButton) {
      const calendar = (<i aria-hidden="true" className="fa fa-calendar"/>);
      const {inputType, id, name, displayValue, showRow, showLabel, showInput, defaultSelect,
        onKeyPress, readOnly,placeholder,src, withSymbol, attributeValues,rows,maxLength,parentIds} = input;

      let {section,data,indexApp,onKeyPressEnter,errorShow,foundVin, siteColor} = this.props;
      let value = this.getValueData(data,id,index);

      let {date,showDropDown,anchorEl} = this.state;

      let elementHasValidation = this.elementHasValidation(input, VALIDATION_REQUIRED);
      const options = sortBy(attributeValues, [function (o) {
        return o.order;
      }]) || [];
      const {classNameLabel,classNameInput} = section;
      const error = this.state.errors[id];
      if(parentIds && parentIds.length > 0){
        let renderChild = this.hasParentChild(data,index,parentIds);
        if(renderChild === -1)
          return null;
      }

      let that = this;

      switch (inputType) {
        case InputTypes.TEXT:
        case InputTypes.PASSWORD:
          if(conf.getTextInput && _.isFunction(conf.getTextInput)){
            return conf.getTextInput(input,value,elementHasValidation,this._onChange,this.onBlurInput,index,error,this.getValidationMessages, siteColor, indexApp);
          }
          return (
            <div key={'field_' + id} className={(error ? 'has-error' : errorShow && id === 'vin' ? 'has-error-vin' : foundVin && id === 'vin' ? 'has-found-vin' : '')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div>: null
                }
                <div className={(classNameInput ? classNameInput:" ")}>
                  <input type={inputType.toLowerCase()}
                         className={"form-control" + (elementHasValidation ? " colRequired":"")}
                         id={id}
                         ref={id}
                         name={id}
                         onChange={this._onChange.bind(this,id,index)}
                         onBlur={this.onBlurInput.bind(this,id)}
                         onKeyPress={onKeyPressEnter ? this._onKeyPress.bind(this,id) : null}
                         value={value}
                         placeholder={placeholder ? placeholder:""}
                         readOnly={readOnly ? readOnly:false}
                         maxLength={maxLength? maxLength : null}/>
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;
        case InputTypes.TEXT_AREA:
          if(conf.getTextAreaInput && _.isFunction(conf.getTextAreaInput)){
            return conf.getTextAreaInput(input,value,this._onChange,this.onBlurInput,index);
          }
          return (
            <div key={'field_'+id} className={(error ?' has-error':'')}>
              <div className={showRow} >
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                    </div>: null
                }
                <div className={classNameInput ? classNameInput:" "}>
                <textarea type={inputType}
                          className="form-control"
                          id={id}
                          ref={id}
                          name={id}
                          readOnly={readOnly ? readOnly:false}
                          onBlur={this.onBlurInput.bind(this,id)}
                          onChange={this._onChange.bind(this,id,index)}
                          value={value}
                          placeholder={placeholder ? placeholder:""}
                          rows={rows}/>
                </div>
              </div>
            </div>
          );
          break;
        case InputTypes.TEXT_HIDDEN:
          return (
            <div key={'field_' + id}>
              <input type={inputType ? inputType : null}
                     className="form-control"
                     id={id}
                     ref={id}
                     name={id}
                     readOnly={readOnly ? readOnly:false}
                     onChange={this._onChange.bind(this,id,index)}
                     value={value}
                     placeholder={placeholder ? placeholder:""}/>
            </div>
          );
          break;
        case InputTypes.SELECT:
        case InputTypes.STATE:
          if(conf.getSelectInput && _.isFunction(conf.getSelectInput)){
            return conf.getSelectInput(input,classNameLabel,elementHasValidation, classNameInput,value, showDropDown[id],
              this.onChangeDropDown,index,error,this.getValidationMessages, options,this._onChangePopover,anchorEl, siteColor, indexApp);
          }
          return (
            <div key={'field_'+id} className={(error ?' has-error':'')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={id} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div>: null
                }
                <div className={(classNameInput ? classNameInput:" ")}>
                  <select className={"form-control" + (elementHasValidation ? " colRequired":"")}
                          ref={id}
                          name={id}
                          disabled={readOnly ? readOnly:false}
                          onChange={this._onChange.bind(this,id,index)}
                          value={value}
                          id={id}>
                    <option value="">{defaultSelect}</option>
                    {options.map((at) => {
                      return <option key={id+at.name} value={at.id}>{at.displayValue}</option>
                    })}
                  </select>
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;
        case InputTypes.RADIO_BUTTON:
          if(conf.getRadioButton && _.isFunction(conf.getRadioButton)){
            return conf.getRadioButton(input,value,options,index,this.onChangeRadioButton,showDropDown[id],this.onChangeDropDown,this._onChangePopover,anchorEl, siteColor, indexApp, classNameLabel, classNameInput, this.getValidationMessages, error);
          }
          return (
            <div key={'field_'+id}>
              {
                options.map((option,idx) => {
                  return (
                    <div key={idx} className={classRadioButton ? classRadioButton:""}>
                      <button id={option.id}
                              ref={id}
                              readOnly={readOnly ? readOnly:false}
                              onClick={this.onChangeRadioButton.bind(this,id,option.id,index)}
                              className={"btn btn-default col100"+ (value === option.id ? " radio-button":"")}
                              disabled={readOnly}>{option.displayValue}</button>
                    </div>
                  )
                })
              }
            </div>
          );
          break;
        case InputTypes.LABEL:
          return (
            <div key={'field_' + id}>
              <div className={showRow}>
                <label htmlFor={id} className={showLabel}>{displayValue}</label>
              </div>
            </div>
          );
          break;
        case InputTypes.TEXT_AUTOCOMPLETE:
          const optionsAutocomplete = input.options || [];
          return (
            <div key={'field_' + id}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div> : null
                }
                <div className={showInput + " " + (classNameInput ? classNameInput:" ")}>
                  <InputAutoComplete type="text"
                                     className={"form-control" + (elementHasValidation ? " colRequired":"")}
                                     id={id}
                                     ref={id}
                                     name={id}
                                     readOnly={readOnly ? readOnly:false}
                                     options={optionsAutocomplete}
                                     onChange={this._onChange.bind(this,id,index)}
                                     value={value}
                                     placeholder={placeholder ? placeholder:""}/>
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;
        case InputTypes.DYNAMIC_NUMBER:
          if(conf.getDoubleInput && _.isFunction(conf.getDoubleInput)){
            return conf.getDoubleInput(input,value,elementHasValidation,this.onChangeNumber,this.handleBlurNumber,index,error,this.getValidationMessages,that,siteColor,indexApp);
          }
          return (
            <div key={'field_' + id} className={(error ?' has-error':' ')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div> : null
                }
                <div className={showInput + " " + (classNameInput ? classNameInput:" ")}>
                  {(withSymbol) ? <i className="fa fa-dollar"/> : null}
                  <DynamicNumber id={id}
                                 ref={id}
                                 name={id}
                                 className={"form-control" + (elementHasValidation ? " colRequired":"")}
                                 placeholder={withSymbol ? '0.00' : ''}
                                 value={value}
                                 positive={true}
                                 negative={false}
                                 thousand={true}
                                 readOnly={readOnly ? readOnly:false}
                                 onChange={this.onChangeNumber.bind(this,id,index)}
                                 onBlur={withSymbol ? this.handleBlurNumber.bind(this,id,index,value) : null}
                                 separator={'.'}
                                 integer={maxLength ? maxLength : 8}
                                 fraction={2}/>
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;
        case InputTypes.NUMBER:
          if(conf.getNumberInput && _.isFunction(conf.getNumberInput)){
            return conf.getNumberInput(input,value,elementHasValidation,this.onChangeNumber,this.handleBlurNumber,index,error,this.getValidationMessages,that, siteColor, indexApp);
          }
          return (
            <div key={'field_' + id} className={(error?' has-error':' ')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div> : null
                }
                <div className={showInput + " " + (classNameInput ? classNameInput:" ")}>
                  <DynamicNumber id={id}
                                 ref={id}
                                 name={id}
                                 className={"form-control" + (elementHasValidation ? " colRequired":"")}
                                 placeholder=""
                                 value={value}
                                 positive={true}
                                 negative={false}
                                 thousand={true}
                                 readOnly={readOnly ? readOnly:false}
                                 onChange={this.onChangeNumber.bind(this,id,index)}
                                 onBlur={withSymbol ? this.handleBlurNumber.bind(this,id,index,value) : null}
                                 integer={maxLength ? maxLength : 2}
                                 fraction={0}/>
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;
        case InputTypes.DATE_PICKER:
          let dateObject;
          if(value && value !== ""){
            const valueDate = Moment(value,"MM-DD-yyyy");
            dateObject = new Date(date ? date:valueDate);
          } else {
            dateObject = new Date();
          }

          if(conf.getDateInput && _.isFunction(conf.getDateInput)){
            return conf.getDateInput(input,dateObject,elementHasValidation,this.handleChangeDate,this.handleBlurDate,index,error,this.getValidationMessages, siteColor, indexApp);
          }

          return (
            <div key={'field_' + id} className={(error ?' has-error':' ')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div> : null
                }
                <div className={showInput + " " + (classNameInput ? classNameInput:" ")}>
                  <DPKeyboardDatePicker
                    id="dateId"
                    ref={id}
                    name={id}
                    value={Moment(dateObject)}
                    className={"form-control" + (elementHasValidation ? " colRequired":"")}
                    onChange={(date) => this.handleChangeDate(id, index, null, date)}
                    onBlur={(event) => this.handleBlurDate(id, index, event)}
                  />
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;
        case InputTypes.PHONE_MASK:
          if(conf.getPhoneInput && _.isFunction(conf.getPhoneInput)){
            return conf.getPhoneInput(input,value,elementHasValidation,this._onChange,this.onBlurInput,index,error,this.getValidationMessages, siteColor, indexApp);
          }
          return(
            <div key={'field_' + id} className={(error ?' has-error':' ')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div> : null
                }
                <div className={showInput + " " + (classNameInput ? classNameInput:" ")}>
                  <InputElement type="text"
                                className={"form-control" + (elementHasValidation ? " colRequired":"")}
                                id={id}
                                ref={id}
                                mask="(999)999-9999"
                                value={value}
                                placeholder={placeholder ? placeholder:""}
                                readOnly={readOnly ? readOnly:false}
                                onChange={this._onChange.bind(this,id,index)}
                                onBlur={this.onBlurInput.bind(this,id)}
                                onKeyPress={onKeyPress ? this.handlePressEnter.bind(null) : null}/>
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;

        case InputTypes.IMAGE:
          return(
            <div key={'field_' + id} className={(error ?' has-error':'')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div> : null
                }
                <div className={showInput + " " + (classNameInput ? classNameInput:" ")}>
                  <div>
                    <img src={src ? src:""} className="img-circle" width="100px" height="100px"/>
                  </div>
                </div>
              </div>
            </div>
          );
          break;

        case InputTypes.MULTI_SELECT:
          return(
            <div key={'field_' + id} className={(error ?' has-error':' ')}>
              <div className={showRow}>
                {
                  displayValue ?
                    <div className={classNameLabel ? classNameLabel:" "}>
                      <label htmlFor={name} className={showLabel}>{displayValue}</label>
                      {elementHasValidation ? <span className="label-error">*</span>: null}
                    </div> : null
                }
                <div className={showInput + " " + (classNameInput ? classNameInput:" ")}>
                  <Select className={" " + (elementHasValidation ? " colRequired":"")}
                          id={id}
                          ref={id}
                          name={id}
                          placeholder={defaultSelect}
                          value={value}
                          multi={true}
                          simpleValue={true}
                          options={options.map(option => ({value: option.id, label: option.displayValue }))}
                          onChange={this._onChangeMultiSelect.bind(this,name)}/>
                  {error ?
                    <p className="help-block">{this.getValidationMessages(input, error)}</p> : null}
                </div>
              </div>
            </div>
          );
          break;
      }
    }

    _onChangeMultiSelect(value,name){
      let data = {[value]:name};
      this.props.onChange(data);
    }

    _onChange(id,index) {
      let data = this.getValues(id);
      this.props.onChange(data,index);
    }

    _onKeyPress(id,event){
      let data = this.getValues(id);
      if(data.vin && event.key === "Enter"){
        this.props.onKeyPressEnter(data);
      }
    }

    onBlurInput(id){
      const {attributes} = this.props;
      let data = this.getValues(id);
      let errors;
      let attributeFound = _.find(attributes,function (attribute) { return attribute.id === id});
      if(attributeFound){
        errors = this.validateInput(data[id], attributeFound);
        this.setErrors(id, errors);
      }
      if(data.vin && (data.vin.length > 10 && data.vin.length < 18)){
        if(this.props.onBlur)
          this.props.onBlur(data);
      }
    }

    onChangeRadioButton(id,value,index, evt) {
      //evt.stopPropagation();
      evt.preventDefault();
      let data = {};
      data[id] = value;
      this.props.onChange(data,index);
    }

    onChangeNumber(id, index, evt, modelValue, viewValue) {
      let {numberValue, numberView} = this.state;
      numberValue[id] = modelValue;
      numberView[id] = viewValue;
      this.setState({numberValue: numberValue, numberView: numberView}, ()=> {
        //let values = this.getValues(id);
        this._onChange(id,index)
      });
    }

    handleChangeDate(id,index,event, newDate) {
      this.setState({date: newDate}, ()=> {
        this._onChange(id,index)
      });
    }

    handleBlurDate(id,index, event) {
      const valueDate = Moment(event.target.value,"MM-DD-YYYY");
      const newDate = new Date(valueDate);
      this.setState({date: newDate},()=>{
        //this.onBlurInput(id);
        this._onChange(id,index)
      });
    }

    handleBlurNumber(id,index,value) {
      let {numberValue, numberView} = this.state;
      numberValue[id] = Number(value.replace(/,/g, ''));
      numberView[id] = addZeros(value.replace(/,/g, ''));
      this.setState({numberValue: numberValue, numberView: numberView}, ()=> {
        //this._onChange(id,index)
      })
    }

    _onChangePopover(id,index,idOption) {
      let data = this.getValues(id);
      data[id] = idOption;
      let {showDropDown} = this.state;
      showDropDown[id] = !showDropDown[id];
      this.setState({showDropDown:showDropDown},()=>{
        this.props.onChange(data,index);
        //this.setErrors(id, errors);
        const {attributes} = this.props;
        let attributeFound = _.find(attributes,function (attribute) { return attribute.id === id});
        let errors = this.validateInput(data[id], attributeFound);
        this.setErrors(id, errors);
      });
    }

    onChangeDropDown(e,id){
      let {showDropDown} = this.state;

      for(let key in showDropDown){
        if(key !== id)
          delete showDropDown[key]
      }

      showDropDown[id] = !showDropDown[id];
      this.setState({showDropDown:showDropDown,anchorEl: e.currentTarget,selectedId:id});
    }

    getValues(id) {
      const {attributes} = this.props;
      let values = {};
      let errors;
      attributes.forEach((item)=> {
        let elementRef = this.refs[item.id];
        if (item.id === id) {
          if (typeof elementRef !== 'undefined') {
            switch (item.inputType) {
              case InputTypes.TEXT_AUTOCOMPLETE:
                values[item.id] = elementRef.getValue();
                break;
              case InputTypes.LABEL:
                break;
              case InputTypes.DYNAMIC_NUMBER:
              case InputTypes.NUMBER:
                let numberView = this.state.numberView;
                values[id] = numberView[id];
                errors = this.validateInput(values[id] === '' ? 0 : values[id], item);
                this.setErrors(id, errors);
                break;
              case InputTypes.DATE_PICKER:
                values[item.id] = Moment(this.state.date).format('MM-DD-YYYY');
                errors = this.validateInput(values[item.id], item);
                this.setErrors(item.id, errors);
                break;
              case InputTypes.PHONE_MASK:
                values[item.id] = elementRef.getInputValue();
                //errors = this.validateInput(elementRef.getInputValue(), item);
                //this.setErrors(item.id, errors);
                break;

              case InputTypes.TEXT:
                values[item.id] = elementRef.value;
                break;
              case InputTypes.MULTI_SELECT:
                //values[item.id] = elementRef.getInputValue();
                // errors = this.validateInput(elementRef.getInputValue(), item);
                //this.setErrors(item.id, errors);
                break;
              default:
                values[item.id] = elementRef.value;
                errors = this.validateInput(elementRef.value, item);
                this.setErrors(item.id, errors);
                break;
            }
          }
        }
      });

      return values;
    }

    getValue(item) {
      let elementRef = this.refs[item.id];
      if (typeof elementRef !== 'undefined') {
        switch (item.type) {
          case InputTypes.TEXT_AUTOCOMPLETE:
            return elementRef.getValue();
            break;
          case InputTypes.LABEL:
            break;
          case InputTypes.DYNAMIC_NUMBER:
            return this.state.numberView[item.id];
            break;
          case InputTypes.DATE_PICKER:
            if (this.state.date === null || !this.state.date) {
              return elementRef.getValue();
            } else {
              return this.state.date;
            }
            break;
          default:
            return elementRef.value;
            break;
        }
      }

    }

    _validate() {
      //var properties = this._getElementsStateFromProps();
      let result = _.map(this.props.attributes, function (attribute, key) {
        if (attribute.validation) {
          let errors = this.validateInput(this.getValue(attribute), attribute);
          if (errors.length > 0) {
            this.setErrors(attribute.id, errors);
            return false;
          }
        }
        return true;
      }.bind(this));

      result = _.reduce(result, function (acc, n) {
        return acc && n;
      });
      return result;
    }

    getValidationMessages(prop, errors) {

      if (!errors || errors.length === 0 || !prop.validation) {
        return '';
      }
      let messages = _.map(errors, function (error) {
        return _.map(_.filter(prop.validation, function(val){ return val.type === error}), 'message');
      });
      return _.flatten(messages).join(',')
    }

    elementHasValidation(prop, validation) {
      return _.find(prop.validation, {type: validation});
    }

    validateInput(value, attribute) {
      let errors = _.map(attribute.validation, function (validation) {
        switch (validation.type) {
          case VALIDATION_VIN:
            if(value !== ""){
              if(!checkValidVIN(value))
                return validation.type;
            }
            break;
          case VALIDATION_REQUIRED:
            if(!checkRequired(value)){
              return validation.type;
            }
            break;
          case VALIDATION_EMAIL:
            if(!value && value !== ""){
              if(!checkValidEmail(value))
                return validation.type;
            }
            break;
          case VALIDATION_PHONE:
            if(value !== ""){
              if(!checkValidPhone(value))
                return validation.type;
            }
            break;
          default:
            return;
        }
      }.bind(this));
      return _.without(errors, undefined);
    }

    setErrors(id, errors) {
      let newState = {errors: this.state.errors};
      if (errors.length > 0) {
        newState.errors[id] = errors;
      } else {
        newState.errors[id] = null;
      }
      this.setState(newState);
    }

    handlePressEnter(evt) {
      evt.stopPropagation();
      let theEvent = evt || window.event;
      let key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode( key );
      let regex = /^[0-9a-np-zA-NP-Z]+$/;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    }

    copyResidence(evt,type,index){
      evt.preventDefault();
      let {data,dataApplicant} = this.props;
      if(dataApplicant[index])
        data[index] = dataApplicant[index];
      else{
        for (let attribute in  data[index]){
          data[index][attribute] = ""
        }
      }

      this.props.copyDataResidence(data[index],index);
    }
  }
};

export default formRenderer;