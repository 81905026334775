import React, {Component} from 'react'
import {
  parseObjectForm,
  parseValues,
  getAttributesByAttributeIds,
  getAttributeJSON,
  setErrors,
  validate, getButtonTextCapByTemplate
} from './Util'
import {isArray, find, findIndex, cloneDeep, sortBy} from 'lodash'
import {connect} from 'react-redux'
import {browserHistory} from 'react-router'
import Popover from 'material-ui/Popover'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import {
  BUTTON_TEXT_COLOR,
  ATTRIBUTE_APPLICANT_RELATION,
  ATTRIBUTE_RESIDENCE_YEAR,
  ATTRIBUTE_RESIDENCE_MONTH,
  optionState,
  ATTRIBUTE_EMPLOYER_YEAR,
  ATTRIBUTE_EMPLOYER_MONTH,
  ATTRIBUTE_APPLICANT_TYPE,
  ATTRIBUTE_RESIDENCE_STATE,
  ATTRIBUTE_EMPLOYER_STATE,
  ATTRIBUTE_APPLICANT_TYPE_CODE,
  pathServer,
  FORM_APPLICANT_CODE_PUBLIC,
  ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS,
  ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS,
  ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS,
  ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS,
  ATTRIBUTE_EMPLOYER_YEAR_SECOND,
  ATTRIBUTE_EMPLOYER_MONTH_SECOND,
  ATTRIBUTE_EMPLOYER_YEAR_THIRD,
  ATTRIBUTE_EMPLOYER_MONTH_THIRD,
  BOX_BACKGROUND_COLOR,
  TEXT_COLOR,
  CONTROL_BACKGROUND_COLOR,
  CONTROL_BORDER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  BOX_BORDER_COLOR,
  imageDate,
  MODERN_VIEW_2_TEMPLATE_CODE,
  EASY_NAVIGATION_TEMPLATE_CODE,
  EASY_NAVIGATION_B_TEMPLATE_CODE,
  EASY_NAVIGATION_C_TEMPLATE_CODE, MODERN_VIEW_TEMPLATE_CODE, MODERN_VIEW_3_TEMPLATE_CODE, SUCCESSFUL_CODE
} from './Constants'
import FormRender from './form/FormRender.jsx'
import InputElement from 'react-input-mask'
import Moment from 'moment'
import {Tabs, Tab} from 'react-bootstrap'
import DynamicNumber from 'react-dynamic-number'
import Checkbox from 'material-ui/Checkbox'
import {ReactSVG} from 'react-svg'
import Loader from './UI/Loader.jsx'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import DPKeyboardDatePicker from './UI/DPKeyboardDatePicker.js'
import styled from 'styled-components'
import {DPPopover} from './DPPopover'
import {getPosition, getScrollTop, scrollTo} from './Util'
import {elementCheck, elementUnCheck} from './ComponentUtils'
import {clearFinanceForm, loadPublicFormByCode, processFinanceForm} from '../catalog/actions'
import DPButton from './genericComponents/DPButton'

const FormFinance = FormRender({
  getTextInput(attribute, value, elementHasValidation, onChange, onBlur, index, error, getValidationMessages, siteColor) {
    const {displayValue, showRow, inputType, id, placeholder, maxLength, readOnly} = attribute

    return (
      <div key={attribute.id} className={'col-finance' + (error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className='label-finance'>
                <label style={{color: siteColor[TEXT_COLOR]}}>{displayValue}</label>
                {elementHasValidation ? <span className='label-error'>*</span> : null}
              </div> : null
          }
          <div className=''>
            <input type={inputType.toLowerCase()}
                   className='form-control finance-input'
                   id={id} ref={id} name={id} value={value}
                   onChange={() => onChange(id, index)}
                   onBlur={() => onBlur(id)}
                   placeholder={placeholder ? placeholder : ''}
              //readOnly={readOnly ? readOnly:false}
                   disabled={readOnly}
                   maxLength={maxLength ? maxLength : null}
                   style={{
                     backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                     border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                     color: siteColor[TEXT_COLOR]
                   }}
            />
            {error ?
              <p className='help-block'>{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getSelectInput(input, classNameLabel, elementHasValidation,
                 classNameInput, idSelected, showDropDown, onChangeDropDown, index, error,
                 getValidationMessages, options, _onChange, anchorEl, siteColor) {
    const foundValue = options.find(op => {
      return op.id === idSelected
    })
    const {id, displayValue, showRow} = input

    const anchorElUpdate = {...anchorEl}
    anchorElUpdate.y = 46
    anchorElUpdate.offsetWidth = '100%'
    return (
      <div key={'field_' + id} className={'col-finance' + (error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className='label-finance'>
                <label htmlFor={id}
                       style={{color: siteColor[TEXT_COLOR]}}>
                  {displayValue}
                </label>
                {elementHasValidation ? <span className='label-error'>*</span> : null}
              </div> : null
          }
          <div className={(classNameInput ? classNameInput : ' ')} style={{position: 'relative'}}>
            <a className='btn-finance-dp'
               onClick={(e) => onChangeDropDown(e, id)}
               style={{
                 backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                 border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                 color: siteColor[TEXT_COLOR]
               }}
            >
              <span style={{color: siteColor[TEXT_COLOR]}}>{foundValue ? foundValue.displayValue : ''}</span>
              {
                (showDropDown) ? <i className='fa fa-chevron-up'/> : <i className='fa fa-chevron-down'/>
              }
            </a>
            {error ?
              <p className='help-block'>{getValidationMessages(input, error)}</p> : null}

            {
              showDropDown && (
                <DPPopover anchorEl={anchorElUpdate}
                           classNameWrapper={'popover-scroll'}
                           handleRequestClose={(e) => onChangeDropDown(e, id)}
                           styleWrapper={{
                             backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                             border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                             color: siteColor[TEXT_COLOR],
                             maxHeight: 300,
                             overflowX: 'hidden'
                           }}>
                  <Menu>
                    {
                      options.map((op, i) => {
                        return (
                          <MenuItem key={i}
                                    onClick={() => _onChange(id, index, op.id)}
                                    primaryText={op.displayValue}
                                    style={{
                                      color: siteColor[TEXT_COLOR]
                                    }}
                          />
                        )
                      })
                    }
                  </Menu>
                </DPPopover>
              )
            }
          </div>
        </div>
      </div>
    )
  },
  getPhoneInput(attribute, value, elementHasValidation, onChange, onBlur, index, error, getValidationMessages, siteColor) {
    const {displayValue, showRow, id, placeholder, readOnly} = attribute
    return (
      <div key={attribute.id} className={'col-finance' + (error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className='label-finance'>
                <label style={{color: siteColor[TEXT_COLOR]}}>{displayValue}</label>
                {elementHasValidation ? <span className='label-error'>*</span> : null}
              </div> : null
          }
          <div className=''>
            <InputElement type='text' id={id} ref={id}
                          className='form-control finance-input'
                          mask='(999)999-9999'
                          placeholder={placeholder ? placeholder : ''}
              //readOnly={readOnly ? readOnly:false}
                          disabled={readOnly}
                          onChange={() => onChange(id, index)}
                          onBlur={() => onBlur(id)}
                          value={value}
                          style={{
                            backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                            border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                            color: siteColor[TEXT_COLOR]
                          }}
            />
            {error ?
              <p className='help-block'>{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getDateInput(attribute, dateObject, elementHasValidation, onChange, onBlur, index, error, getValidationMessages, siteColor) {
    const {displayValue, showRow, id, readOnly} = attribute
    return (
      <div key={id} className={'col-finance' + (error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className='label-finance'>
                <label style={{color: siteColor[TEXT_COLOR]}}>{displayValue}</label>
                {elementHasValidation ? <span className='label-error'>*</span> : null}
              </div> : null
          }
          <div className=''>
            {
              !readOnly
                ? <DPKeyboardDatePicker
                  id='dateId'
                  ref={id}
                  name={id}
                  value={Moment(dateObject)}
                  className='form-control finance-input'
                  onChange={(date) => onChange(id, index, null, date)}
                  onBlur={(event) => onBlur(id, index, event)}
                  disabled={readOnly}
                  style={{
                    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                    border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                    color: siteColor[TEXT_COLOR]
                  }}
                />
                : <div style={{position: 'relative'}}>
                  <input
                    className='form-control finance-input'
                    value={Moment(dateObject).format('MM-DD-YYYY')}
                    disabled={readOnly}
                    style={{
                      backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                      color: siteColor[TEXT_COLOR]
                    }}
                  />
                  <img
                    src={imageDate}
                    id='icon-attribute'
                    className='form-field-img'
                    alt=''
                    style={{position: 'absolute', top: '13px', float: 'right', right: '0px', height: '15px'}}
                  />
                </div>
            }
            {error ?
              <p className='help-block'>{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getNumberInput(attribute, value, elementHasValidation, onChange, onBlur, index, error, getValidationMessages, that, siteColor) {
    const {displayValue, showRow, id, readOnly} = attribute
    return (
      <div key={attribute.id} className={'col-finance' + (error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className='label-finance'>
                <label style={{color: siteColor[TEXT_COLOR]}}>{displayValue}</label>
                {elementHasValidation ? <span className='label-error'>*</span> : null}
              </div> : null
          }
          <div className=''>
            <DynamicNumber id={id} ref={id} name={id} placeholder=''
                           className='form-control finance-input'
                           value={value} positive={true} negative={false} thousand={true}
              //readOnly={readOnly ? readOnly:false}
                           disabled={readOnly}
                           onChange={onChange.bind(that, id, index)}
                           onBlur={onBlur.bind(that, id, index, value)}
                           integer={2} fraction={0}
                           style={{
                             backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                             border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                             color: siteColor[TEXT_COLOR]
                           }}
            />
            {error ?
              <p className='help-block'>{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getDoubleInput(attribute, value, elementHasValidation, onChange, onBlur, index, error, getValidationMessages, that, siteColor) {
    const {displayValue, showRow, id, readOnly} = attribute
    return (
      <div key={attribute.id} className={'col-finance' + (error ? ' has-error' : '')}>
        <div className={showRow}>
          {
            displayValue ?
              <div className='label-finance'>
                <label style={{color: siteColor[TEXT_COLOR]}}>{displayValue}</label>
                {elementHasValidation ? <span className='label-error'>*</span> : null}
              </div> : null
          }
          <div className=''>
            <DynamicNumber id={id} ref={id} name={id} placeholder=''
                           className='form-control finance-input'
                           value={value} positive={true} negative={false} thousand={true}
              //readOnly={readOnly ? readOnly:false}
                           disabled={readOnly}
                           onChange={onChange.bind(that, id, index)}
                           onBlur={onBlur.bind(that, id, index, value)}
                           separator={'.'} integer={8} fraction={2}
                           style={{
                             backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                             border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                             color: siteColor[TEXT_COLOR]
                           }}
            />
            {error ?
              <p className='help-block'>{getValidationMessages(attribute, error)}</p> : null}
          </div>
        </div>
      </div>
    )
  },
  getRadioButton(attribute, value, options, index, onChange, showDropDown, onChangeDropDown, onChangePopover, anchorEl, siteColor, indexApp,classNameLabel, classNameInput, getValidationMessages, error) {
    const {id, readOnly} = attribute;
    const code = attribute.name.split(".");
    const foundValue = options.find(op => {
      return op.id === value
    })
    return (
      <div key={attribute.id} className='col100'>
        {
          options.map((option, idx) => {
            return (
              <div key={idx} className={'radio-button-main ' + code[1]}>
                <button id={code[1] + idx + '_' + indexApp}
                        ref={id}
                  //readOnly={readOnly ? readOnly:false}
                        onClick={(e) => onChange(id, option.id, index, e)}
                        className={'btn btn-default col100' + (value === option.id ? ' radio-button' : '')}
                        disabled={readOnly}
                        style={{
                          backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                          border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                          color: siteColor[TEXT_COLOR]
                        }}
                >{option.displayValue}</button>
              </div>
            )
          })
        }
        <div className="radio-button-select">
          {
            this.getSelectInput(attribute,classNameLabel, false, classNameInput, value, showDropDown,
          onChangeDropDown,index,error, getValidationMessages, options,onChangePopover,anchorEl, siteColor, indexApp)
          }
        </div>
      </div>
    )
  },
  getHeader() {
    return null
  },
  getHeaderButton(sectionName, sectionSubName, copyResidence) {
    let index = 0
    if (sectionSubName === 'co_previous') {
      index = 1
    } else if (sectionSubName === 'co_another') {
      index = 2
    }

    return (
      <button id={`${sectionName}_${sectionSubName}_btnCopy`} className={'btn-copy-finance'}
              onClick={(evt) => copyResidence(evt, sectionSubName, index)}>Copy
      </button>
    )
  }
})

function goTopScroll() {
  let el = document.querySelector('buttonTop')
  let pointTo = getPosition(el)
  let y = getScrollTop()
  let pointFrom = {
    x: 0,
    y: y //window.scrollY
  }
  scrollTo(pointFrom, pointTo, 400)
}

const NUMBER_MONTHS_FOR_ONE_YEAR = 12
const NUMBER_MONTHS_FOR_TWO_YEAR = 24
const APPLICANT_RESIDENCE = 'applicant_residence'

class LayoutFinanceViewForm extends Component {

  constructor() {
    super()
    this.state = {
      applicants: [],
      applicantForm: {},
      type: '',
      messageSuccess: null,
      errors: {},
      submitting: false,
      readOnly: false,
      attributeValueId: '',
      addedApplicantResidence: false,
      addedApplicantEmployer: false,
      key: 0,
      checked: false,
      indexApp: 0,
      messageError: null,
      applicantsInitial: [],
      showMessageTermsAndConditions: '',
      indexSectionOnWizard: '',
      isLoading: false,
      applicantFormsInitial: []
    }
  }

  async componentDidMount() {
    const  formAdmin = await this.loadFormData()
    if (!this.props.loadFormByCodeData && !formAdmin) {
      return
    }
    const {form} = this.props.loadFormByCodeData ?? formAdmin ?? {}
    const {formAttributes} = form || {}
    const {sections} = formAttributes || {}

    const attributes = this.getAttributes(formAttributes)

    const applicantForms = [parseObjectForm(sections)]
    const applicants = [parseValues(attributes, sections)]

    const applicantsInitial = cloneDeep(applicants)
    const applicantFormsInitial = cloneDeep(applicantForms)

    applicants.forEach((app, index) => {
      this.applyHoverEffects(index)
    })

    this.setState({
      applicants,
      applicantForms,
      applicantFormsInitial,
      applicantsInitial,
      attributes,
      sections,
      formCode: form?.code,
      attributeValueId: this.getApplicantTypeId(attributes)
    })
  }

  componentDidUpdate() {
    const {siteColor, borderColor} = this.props
    const primaryColor = siteColor[PRIMARY_COLOR]
    const {applicants} = this.state
    let btnCopy
    applicants.forEach((app, index) => {
      for (let i = 0; i < 8; i++) {
        this.hoverButton('creditScore' + i + '_' + index)
        this.hoverButton('typeCredit' + i + '_' + index)
        this.hoverButton('typeId' + i + '_' + index)
      }
    })
    if (applicants.length > 1) {
      applicants.forEach((app, index) => {
        btnCopy = window.document.getElementById('applicant_residence_co_current_btnCopy')
        this.hoverBtnCopy(btnCopy, primaryColor, borderColor)
        btnCopy = window.document.getElementById('applicant_residence_co_previous_btnCopy')
        this.hoverBtnCopy(btnCopy, primaryColor, borderColor)
        btnCopy = window.document.getElementById('applicant_residence_co_another_btnCopy')
        this.hoverBtnCopy(btnCopy, primaryColor, borderColor)
      })
    }

  }

  async loadFormData()  {
    const {loadFormByCodeData,loadFormByCodeAdmin, loadFormByCodeFetch} = this.props
    let formAdmin = null
    if (!loadFormByCodeData && !loadFormByCodeAdmin) {
      await loadFormByCodeFetch(FORM_APPLICANT_CODE_PUBLIC, 'new')
    }else if (!loadFormByCodeData){
      const response = await loadFormByCodeAdmin(FORM_APPLICANT_CODE_PUBLIC, "new")
      const {responseCode, data} = response
      formAdmin = responseCode === SUCCESSFUL_CODE ? data : null
    }
    return formAdmin
  }

  getAttributes = (formAttributes) => {
    const {readOnly} = this.props
    const {attributes} = formAttributes

    if (readOnly) {
      attributes.forEach(attribute => {
        attribute.readOnly = true;
      });
    }

    this.addOption(attributes);
    return attributes;
  }

  applyHoverEffects= (index) => {
    for (let i = 0; i < 8; i++) {
      this.hoverButton(`creditScore${i}_${index}`);
      this.hoverButton(`typeCredit${i}_${index}`);
      this.hoverButton(`typeId${i}_${index}`);
    }
  }

  hoverButton = (id) => {
    const el = window.document.getElementById(id)
    const {siteColor = {}} = this.props

    if (!el) return

    if ($(el).hasClass('radio-button')) {
      el.style.backgroundColor = siteColor[PRIMARY_COLOR]
      el.style.borderColor = siteColor[PRIMARY_COLOR]
      el.style.color = siteColor[BUTTON_TEXT_COLOR]
    } else {
      el.style.backgroundColor = siteColor[CONTROL_BACKGROUND_COLOR]
      el.style.borderColor = siteColor[CONTROL_BORDER_COLOR]
      el.style.color = siteColor[TEXT_COLOR]
    }

    $(el).hover(function () {
      if ($(el).hasClass('radio-button')) {
        el.style.backgroundColor = siteColor[SECONDARY_COLOR]
        el.style.borderColor = siteColor[SECONDARY_COLOR]
        el.style.color = siteColor[BUTTON_TEXT_COLOR]
      } else {
        el.style.backgroundColor = siteColor[PRIMARY_COLOR]
        el.style.borderColor = siteColor[PRIMARY_COLOR]
        el.style.color = siteColor[BUTTON_TEXT_COLOR]
      }
    }, function () {
      if ($(el).hasClass('radio-button')) {
        el.style.backgroundColor = siteColor[PRIMARY_COLOR]
        el.style.borderColor = siteColor[PRIMARY_COLOR]
        el.style.color = siteColor[BUTTON_TEXT_COLOR]
      } else {
        el.style.backgroundColor = siteColor[CONTROL_BACKGROUND_COLOR]
        el.style.borderColor = siteColor[CONTROL_BORDER_COLOR]
        el.style.color = siteColor[TEXT_COLOR]
      }
    })
  }

  hoverRadioButton(el, primaryColor, borderColor) {

    if (el) {
      if ($(el).hasClass('radio-button')) {
        el.style.backgroundColor = primaryColor
        el.style.borderColor = primaryColor
        el.style.color = '#ffffff'
      } else {
        el.style.backgroundColor = '#ffffff'
        el.style.borderColor = borderColor
        el.style.color = '#7F7F7F'
      }
    }

    $(el).hover(function () {
      el.style.backgroundColor = primaryColor
      el.style.borderColor = primaryColor
      el.style.color = '#ffffff'
    }, function () {
      if ($(el).hasClass('radio-button')) {
        el.style.backgroundColor = primaryColor
        el.style.borderColor = primaryColor
        el.style.color = '#ffffff'
      } else {
        el.style.backgroundColor = '#ffffff'
        el.style.borderColor = borderColor
        el.style.color = '#7F7F7F'
      }
    })
  }

  hoverBtnCopy(btnCopy, primaryColor, borderColor) {
    if (btnCopy) {
      if ($(btnCopy).hasClass('btn-copy-finance')) {
        btnCopy.style.backgroundColor = primaryColor
        btnCopy.style.borderColor = primaryColor
        btnCopy.style.color = '#ffffff'
        btnCopy.style.display = 'block'
      } else {
        btnCopy.style.display = 'none'
      }
    }
  }

  getApplicantTypeId(attributes) {
    let attributeApplicantType = find(attributes, function (attribute) {
      return attribute.name === ATTRIBUTE_APPLICANT_TYPE
    })
    let attributeValueFound = find(attributeApplicantType?.attributeValues, function (attributeValue) {
      return attributeValue.name === ATTRIBUTE_APPLICANT_TYPE_CODE
    })
    return get(attributeValueFound, ['id'], '')
  }

  addOption(attributes) {
    attributes.forEach(attribute => {
      switch (attribute.name) {
        case ATTRIBUTE_RESIDENCE_STATE :
        case ATTRIBUTE_RESIDENCE_STATE + 'Previous' :
        case ATTRIBUTE_RESIDENCE_STATE + 'Another' :
        case ATTRIBUTE_EMPLOYER_STATE :
        case ATTRIBUTE_EMPLOYER_STATE + 'Previous' :
        case ATTRIBUTE_EMPLOYER_STATE + 'Another' :
          attribute.attributeValues = optionState
          break
      }
    })
  }

  onFormChange = (sectionName, index, data, sectionSubIndex) => {
    if (!this.props.readOnly) {
      let {applicants, applicantsInitial} = this.state
      let applicant = applicants[index]
      let applicantSection = applicant[sectionName]
      let applicantSectionSub
      if (isArray(applicantSection)) {
        applicantSectionSub = applicantSection[sectionSubIndex]
      } else {
        applicantSectionSub = applicantSection
      }

      let idFound = ''
      for (let key in applicantSectionSub) {
        if (isArray(applicantSectionSub[key])) {
          if (typeof data[key] !== 'undefined') {
            idFound = key
            if (applicantSectionSub[key].length === 0)
              applicantSectionSub[key].push(data[key])
            else
              applicantSectionSub[key][0] = data[key]
          }
        } else {
          if (typeof data[key] !== 'undefined') {
            idFound = key
            applicantSectionSub[key] = data[key]
          }
        }
      }

      let {attributes, applicantForms, attributeValueId} = this.state
      let attributeApplicantType = find(attributes, function (attribute) {
        return attribute.name === ATTRIBUTE_APPLICANT_TYPE
      })
      if (attributeApplicantType && attributeApplicantType.id === idFound) { //
        if (data[idFound] === attributeValueId) {
          if (applicants.length < 2) {
            let sections = cloneDeep(this.state.sections)
            sections.forEach(section => {
              if (section.name === sectionName) {
                const attributeFound = find(attributes, function (attribute) {
                  return attribute.name === ATTRIBUTE_APPLICANT_RELATION
                })
                const indexOfApplicantType = findIndex(section.attributeIds, function (attributeId) {
                  return attributeId === attributeApplicantType.id
                })
                section.attributeIds[indexOfApplicantType] = attributeFound.id
                section.sectionSubs.rows.forEach(row => {
                  row.columns.forEach(column => {
                    if (column.status === attributeApplicantType.id) {
                      column.status = attributeFound.id
                    }
                  })
                })
              }
              //Add prefix "Co-" for the
              if (isArray(section.sectionSubs)) {
                section.sectionSubs.forEach(subsection => {
                  subsection.title = 'CO-Applicant ' + subsection.title
                  subsection.name = 'co_' + subsection.name
                })
              } else {
                section.sectionSubs.title = 'CO-' + section.sectionSubs.title
              }

            })
            applicants.push(parseValues(attributes, sections))
            applicantsInitial.push(parseValues(attributes, sections))
            applicantForms.push(parseObjectForm(sections))
          }
        } else {
          if (applicants.length === 2) {
            applicants.splice(1, 1)
            applicantForms.splice(1, 1)
            applicantsInitial.splice(1, 1)
          }
        }
        this.setState({
          applicants: applicants,
          applicantForms: applicantForms,
          errors: null,
          applicantsInitial: applicantsInitial
        })
      } else {
        let attributeApplicantYear = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_RESIDENCE_YEAR
        })
        let attributeApplicantMonth = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_RESIDENCE_MONTH
        })
        let attributeApplicantYearEmployer = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_EMPLOYER_YEAR
        })
        let attributeApplicantMonthEmployer = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_EMPLOYER_MONTH
        })
        let attributeApplicantYearPrevious = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS
        })
        let attributeApplicantMonthPrevious = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS
        })
        let attributeApplicantYearEmployerPrevious = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS
        })
        let attributeApplicantMonthEmployerPrevious = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS
        })
        let attributeApplicantYearEmployerSecond = find(attributes, attribute => attribute.name === ATTRIBUTE_EMPLOYER_YEAR_SECOND)
        let attributeApplicantMonthEmployerSecond = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_EMPLOYER_MONTH_SECOND
        })
        let attributeApplicantYearEmployerThird = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_EMPLOYER_YEAR_THIRD
        })
        let attributeApplicantMonthEmployerThird = find(attributes, function (attribute) {
          return attribute.name === ATTRIBUTE_EMPLOYER_MONTH_THIRD
        })

        if ((attributeApplicantYear && attributeApplicantYear.id === idFound) ||
          (attributeApplicantMonth && attributeApplicantMonth.id === idFound) ||
          (attributeApplicantYearEmployer && attributeApplicantYearEmployer.id === idFound) ||
          (attributeApplicantMonthEmployer && attributeApplicantMonthEmployer.id === idFound) ||
          (attributeApplicantYearEmployerSecond && attributeApplicantYearEmployerSecond.id === idFound) ||
          (attributeApplicantMonthEmployerSecond && attributeApplicantMonthEmployerSecond.id === idFound) ||
          (attributeApplicantYearEmployerThird && attributeApplicantYearEmployerThird.id === idFound) ||
          (attributeApplicantMonthEmployerThird && attributeApplicantMonthEmployerThird.id === idFound)
        ) {

          let previousIndex = 1
          let currentIndex = 0
          let secondIndex = -1
          let thirdIndex = -1

          if (sectionName === 'applicant_employment') {
            previousIndex = applicantForms[index][sectionName].sectionSubs.findIndex(el => el.name.includes('previous'))
            secondIndex = applicantForms[index][sectionName].sectionSubs.findIndex(el => el.name.includes('second'))
            thirdIndex = applicantForms[index][sectionName].sectionSubs.findIndex(el => el.name.includes('third'))
          }

          const appSec = applicantSection[previousIndex]
          const currentApplicantSection = applicantSection[currentIndex]
          const secondApplicantSection = applicantSection[secondIndex]
          const thirdApplicantSection = applicantSection[thirdIndex]

          this.setState({applicants: applicants}, () => {
            let idCurrentYear, idCurrentMonth, previousYear = 0, previousMonth = 0, secondYear = 0, secondMonth = 0,
              thirdYear = 0, thirdMonth = 0

            if (attributeApplicantYear.id === idFound || attributeApplicantMonth.id === idFound) {
              idCurrentYear = attributeApplicantYear.id
              idCurrentMonth = attributeApplicantMonth.id
              if (appSec) {
                previousYear = appSec[attributeApplicantYearPrevious.id] !== '' ? (parseInt(appSec[attributeApplicantYearPrevious.id]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
                previousMonth = appSec[attributeApplicantMonthPrevious.id] !== '' ? parseInt(appSec[attributeApplicantMonthPrevious.id]) : 0
              }
            } else {
              idCurrentYear = attributeApplicantYearEmployer.id
              idCurrentMonth = attributeApplicantMonthEmployer.id
              if (appSec) {
                previousYear = appSec[attributeApplicantYearEmployerPrevious.id] !== '' ? (parseInt(appSec[attributeApplicantYearEmployerPrevious.id]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
                previousMonth = appSec[attributeApplicantMonthEmployerPrevious.id] !== '' ? parseInt(appSec[attributeApplicantMonthEmployerPrevious.id]) : 0
              }
              if (secondApplicantSection) {
                secondYear = secondApplicantSection[attributeApplicantYearEmployerSecond.id] !== ''
                  ? (parseInt(secondApplicantSection[attributeApplicantYearEmployerSecond.id]) * NUMBER_MONTHS_FOR_ONE_YEAR)
                  : 0
                secondMonth = secondApplicantSection[attributeApplicantMonthEmployerSecond.id] !== ''
                  ? parseInt(secondApplicantSection[attributeApplicantMonthEmployerSecond.id])
                  : 0
              }
              if (thirdApplicantSection) {
                thirdYear = thirdApplicantSection[attributeApplicantYearEmployerThird.id] !== ''
                  ? (parseInt(thirdApplicantSection[attributeApplicantYearEmployerThird.id]) * NUMBER_MONTHS_FOR_ONE_YEAR)
                  : 0
                thirdMonth = thirdApplicantSection[attributeApplicantMonthEmployerThird.id] !== ''
                  ? parseInt(thirdApplicantSection[attributeApplicantMonthEmployerThird.id])
                  : 0
              }
            }
            const currentYear = currentApplicantSection[idCurrentYear] !== '' ? (parseInt(currentApplicantSection[idCurrentYear]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
            const currentMonth = currentApplicantSection[idCurrentMonth] !== '' ? parseInt(currentApplicantSection[idCurrentMonth]) : 0

            if ((currentYear + currentMonth + secondYear + secondMonth + thirdYear + thirdMonth) < NUMBER_MONTHS_FOR_TWO_YEAR
              && (currentApplicantSection[idCurrentYear] !== '' || currentApplicantSection[idCurrentMonth] !== '' ||
                (secondApplicantSection && secondApplicantSection[attributeApplicantYearEmployerSecond.id] !== '') ||
                (secondApplicantSection && secondApplicantSection[attributeApplicantMonthEmployerSecond.id] !== '') ||
                (thirdApplicantSection && thirdApplicantSection[attributeApplicantYearEmployerThird.id] !== '') ||
                (thirdApplicantSection && thirdApplicantSection[attributeApplicantMonthEmployerThird.id] !== ''))
            ) {
              if (applicantSection.length <= 1 || previousIndex === -1) {
                this.addSectionSub(index, sectionName, 1, 1, 'previous')
              } else {
                if ((currentYear + currentMonth + secondYear + secondMonth + thirdYear + thirdMonth + previousYear + previousMonth) >= NUMBER_MONTHS_FOR_TWO_YEAR) {
                  if (data[idFound] !== '')
                    this.removeSectionSub(index, sectionName, 2, 'previous')
                } else if (applicantSection.length !== 3 && (previousYear !== 0 || previousMonth !== 0))
                  this.addSectionSub(index, sectionName, 2, 2, 'another')
              }
            } else {
              this.removeSectionSub(index, sectionName, 1, 'previous')
            }
          })
        } else if ((attributeApplicantYearPrevious && attributeApplicantYearPrevious.id === idFound) ||
          (attributeApplicantMonthPrevious && attributeApplicantMonthPrevious.id === idFound) ||
          (attributeApplicantYearEmployerPrevious && attributeApplicantYearEmployerPrevious.id === idFound) ||
          (attributeApplicantMonthEmployerPrevious && attributeApplicantMonthEmployerPrevious.id === idFound)) {

          let anotherIndex = 0
          let secondIndex = -1
          let thirdIndex = -1
          if (sectionName === 'applicant_employment') {
            anotherIndex = applicantForms[index][sectionName].sectionSubs.findIndex(el => el.name.includes('another'))
            secondIndex = applicantForms[index][sectionName].sectionSubs.findIndex(el => el.name.includes('second'))
            thirdIndex = applicantForms[index][sectionName].sectionSubs.findIndex(el => el.name.includes('third'))
          }

          const appSec = applicantSection[0]
          const secondApplicantSection = applicantSection[secondIndex]
          const thirdApplicantSection = applicantSection[thirdIndex]

          this.setState({applicants: applicants}, () => {
            let idPreviousYear, idPreviousMonth, currentYear = 0, currentMonth = 0, secondYear = 0, secondMonth = 0,
              thirdYear = 0, thirdMonth = 0
            if (attributeApplicantYearPrevious.id === idFound || attributeApplicantMonthPrevious.id === idFound) {
              idPreviousYear = attributeApplicantYearPrevious.id
              idPreviousMonth = attributeApplicantMonthPrevious.id
              currentYear = appSec[attributeApplicantYear.id] !== '' ? (parseInt(appSec[attributeApplicantYear.id]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
              currentMonth = appSec[attributeApplicantMonth.id] !== '' ? parseInt(appSec[attributeApplicantMonth.id]) : 0
            } else {
              idPreviousYear = attributeApplicantYearEmployerPrevious.id
              idPreviousMonth = attributeApplicantMonthEmployerPrevious.id
              currentYear = appSec[attributeApplicantYearEmployer.id] !== '' ? (parseInt(appSec[attributeApplicantYearEmployer.id]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
              currentMonth = appSec[attributeApplicantMonthEmployer.id] !== '' ? parseInt(appSec[attributeApplicantMonthEmployer.id]) : 0

              if (secondApplicantSection) {
                secondYear = secondApplicantSection[attributeApplicantYearEmployerSecond.id] !== ''
                  ? (parseInt(secondApplicantSection[attributeApplicantYearEmployerSecond.id]) * NUMBER_MONTHS_FOR_ONE_YEAR)
                  : 0
                secondMonth = secondApplicantSection[attributeApplicantMonthEmployerSecond.id] !== ''
                  ? parseInt(secondApplicantSection[attributeApplicantMonthEmployerSecond.id])
                  : 0
              }
              if (thirdApplicantSection) {
                thirdYear = thirdApplicantSection[attributeApplicantYearEmployerThird.id] !== ''
                  ? (parseInt(thirdApplicantSection[attributeApplicantYearEmployerThird.id]) * NUMBER_MONTHS_FOR_ONE_YEAR)
                  : 0
                thirdMonth = thirdApplicantSection[attributeApplicantMonthEmployerThird.id] !== ''
                  ? parseInt(thirdApplicantSection[attributeApplicantMonthEmployerThird.id])
                  : 0
              }
            }

            const previousYear = applicantSectionSub[idPreviousYear] !== '' ? (parseInt(applicantSectionSub[idPreviousYear]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
            const previousMonth = applicantSectionSub[idPreviousMonth] !== '' ? parseInt(applicantSectionSub[idPreviousMonth]) : 0

            if ((previousYear + previousMonth + currentYear + currentMonth + secondYear + secondMonth + thirdYear + thirdMonth) < NUMBER_MONTHS_FOR_TWO_YEAR &&
              (applicantSectionSub[idPreviousYear] !== '' || applicantSectionSub[idPreviousMonth] !== '')
            ) {
              let showAnother = false
              if (applicantSection.length <= 2 || anotherIndex === -1)
                showAnother = true
              if (showAnother)
                this.addSectionSub(index, sectionName, 2, 2, 'another')
            } else {
              this.removeSectionSub(index, sectionName, 2, 'another')
            }
          })
        } else {
          this.setState({applicants: applicants, errors: null})
        }
      }
    }
  }

  getSectionSubByIndex = (sectionName, indexSubSect, index) => {
    let {sections} = this.state
    let sectionFound = null
    sections.forEach((section, index) => {
      if (section.name === sectionName)
        sectionFound = section
    })

    let sectionSubFound

    if (sectionFound) {
      let newSubSection = cloneDeep(sectionFound.sectionSubs[indexSubSect])
      if (index === 1) {
        newSubSection.title = 'CO-Applicant ' + newSubSection.title
        newSubSection.name = 'co_' + newSubSection.name
      }
      sectionSubFound = newSubSection
    }
    return sectionSubFound
  }

  getSectionSubByName = (sectionName, subSectionName, index) => {
    let {sections} = this.state
    let sectionFound = sections.find(section => section.name === sectionName)
    let originalSubSectionFound = sectionFound.sectionSubs.find(subSection => subSection.name === subSectionName)
    let updatedSubSectionFound

    if (originalSubSectionFound) {
      let newSubSection = cloneDeep(originalSubSectionFound)
      if (index === 1) {
        newSubSection.title = 'CO-Applicant ' + newSubSection.title
        newSubSection.name = 'co_' + newSubSection.name
      }
      updatedSubSectionFound = newSubSection
    }
    return updatedSubSectionFound
  }

  addSectionSub = (index, sectionName, sectionSubToFindIndex, positionToAddIndex, subSectionName) => {
    if (!this.props.readOnly) {
      let applicantForms = this.state.applicantForms
      let applicantForm = applicantForms[index]
      let sectionForm = applicantForm[sectionName]

      const nextSubSection = {
        'applicant_employment': {
          'current': 'second',
          'second': 'third',
          'previous': 'previous',
          'another': 'another'
        },
        'applicant_residence': {
          'previous': 'previous',
          'another': 'another'
        }
      }

      let sectionSubFound = this.getSectionSubByName(sectionName, nextSubSection[sectionName][subSectionName], index)

      sectionForm.sectionSubs.push(sectionSubFound)

      const residenceSubSectionsDisplayOrder = {
        'current': 0,
        'previous': 1,
        'another': 2
      }

      const employmentSubSectionsDisplayOrder = {
        'current': 0,
        'second': 1,
        'third': 2,
        'previous': 3,
        'another': 4
      }

      let newSortedSubSections

      if (sectionName === 'applicant_employment') {
        let newSubSections = sectionForm.sectionSubs.map(el => {
          const displayOrder = employmentSubSectionsDisplayOrder[el.name]
          return (
            {
              ...el,
              displayOrder
            }
          )
        })

        newSortedSubSections = sortBy(newSubSections, el => {
          return el.displayOrder
        })
      }

      if (sectionName === 'applicant_residence') {
        let newSubSections = sectionForm.sectionSubs.map(el => {
          const displayOrder = residenceSubSectionsDisplayOrder[el.name]
          return (
            {
              ...el,
              displayOrder
            }
          )
        })

        newSortedSubSections = sortBy(newSubSections, el => {
          return el.displayOrder
        })
      }

      sectionForm.sectionSubs = newSortedSubSections

      if (sectionSubFound.name === 'second') {
        if (!sectionForm.sectionSubs[1].linkName) {
          sectionForm.sectionSubs[0].linkName = 'Remove second employment'
          sectionForm.sectionSubs[1].linkName = 'Add third employment'
        }
      }

      if (sectionSubFound.name === 'third') {
        if (!sectionForm.sectionSubs[2].linkName) {
          sectionForm.sectionSubs[0].linkName = ''
          sectionForm.sectionSubs[1].linkName = 'Remove third employment'
        }
      }

      applicantForm[sectionName] = sectionForm

      let applicants = this.state.applicants
      let applicant = applicants[index]
      let attributesBySection = getAttributesByAttributeIds(this.state.attributes, sectionSubFound.attributeIds)

      if (sectionName === 'applicant_residence') {
        applicant[sectionName].push(getAttributeJSON(attributesBySection))
      }

      if (sectionName === 'applicant_employment') {
        let subSectionNameToFindOrder = subSectionName
        if (['current', 'second'].includes(subSectionName)) {
          subSectionNameToFindOrder = nextSubSection[sectionName][subSectionName]
        }

        if (employmentSubSectionsDisplayOrder[subSectionNameToFindOrder] < applicant[sectionName].length) {
          applicant[sectionName].splice(employmentSubSectionsDisplayOrder[subSectionNameToFindOrder], 0, getAttributeJSON(attributesBySection))
        } else {
          applicant[sectionName].push(getAttributeJSON(attributesBySection))
        }
      }

      this.setState({applicantForms: applicantForms, applicants: applicants})
    }
  }

  removeSectionSub = (index, sectionName, sectionSubIndex, subSectionName) => {
    if (!this.props.readOnly) {
      let {applicantForms, applicants, linkName} = this.state
      let applicantForm = applicantForms[index]
      let sectionForm = applicantForm[sectionName]

      if (sectionName === 'applicant_residence') {
        sectionForm.sectionSubs.splice(sectionSubIndex, 2)

        let applicant = applicants[index]
        applicant[sectionName].splice(sectionSubIndex, 2)
        this.setState({applicantForm: applicantForm, applicants: applicants})
      }

      if (sectionName === 'applicant_employment') {
        let applicant = applicants[index]

        if (subSectionName === 'previous') {
          let anotherIndex = sectionForm.sectionSubs.findIndex(el => el.name.includes('another'))
          if (anotherIndex > -1) {
            sectionForm.sectionSubs.splice(anotherIndex, 1)
            applicant[sectionName].splice(anotherIndex, 1)
          }

          let previousIndex = sectionForm.sectionSubs.findIndex(el => el.name.includes('previous'))
          if (previousIndex > -1 ) {
            sectionForm.sectionSubs.splice(previousIndex, 1);
            applicant[sectionName].splice(previousIndex, 1);
          }
        }

        if (subSectionName === 'another') {
          let anotherIndex = sectionForm.sectionSubs.findIndex(el => el.name.includes('another'))
          if (anotherIndex > -1) {
            sectionForm.sectionSubs.splice(anotherIndex, 1)
            applicant[sectionName].splice(anotherIndex, 1)
          }
        }

        this.setState({applicantForm: applicantForm, applicants: applicants})
      }
    }
  }

  removeJustOneSectionSub = (index, sectionName, sectionSubIndex) => {
    if (!this.props.readOnly) {
      let {applicantForms, applicants, linkName} = this.state
      let applicantForm = applicantForms[index]
      let sectionForm = applicantForm[sectionName]

      const sectionSubToBeDeleted = sectionForm.sectionSubs[sectionSubIndex]

      if (sectionSubToBeDeleted.name === 'second') {
        if (sectionForm.sectionSubs[0].linkName) {
          sectionForm.sectionSubs[0].linkName = 'Add second employment'
        }
      }

      if (sectionSubToBeDeleted.name === 'third') {
        if (sectionForm.sectionSubs[1].linkName) {
          sectionForm.sectionSubs[0].linkName = 'Remove second employment'
          sectionForm.sectionSubs[1].linkName = 'Add third employment'
        }
      }

      sectionForm.sectionSubs.splice(sectionSubIndex, 1)

      let applicant = applicants[index]
      applicant[sectionName].splice(sectionSubIndex, 1)
      this.setState({applicantForm: applicantForm, applicants: applicants})
    }
  }

  renderTitleTab(title, path, key, index, primaryColor) {
    let color = '#E2E2E2'
    if (key === index) {
      color = primaryColor ? primaryColor : '#d83501'
    } else if (index < key) {
      color = '#7F7F7F'
    }

    return (
      <div className='finance-icon'>
        <ReactSVG src={path} className='svg' beforeInjection={svg => {
          svg.classList.add('finance-tab-icon')
          svg.setAttribute('style', `fill: ${color}`)
        }}/>
        <span style={{color: color}}>{title}</span>
      </div>
    )
  }

  getIconPath(key) {
    switch (key) {
      case 'applicant' :
        return pathServer.PATH_IMG + 'icon/ic_assignment.svg'

      case 'applicant_residence' :
        return pathServer.PATH_IMG + 'icon/ic_home.svg'

      case 'applicant_employment' :
        return pathServer.PATH_IMG + 'icon/ic_card_travel.svg'
      case 'creditScore' :
        return pathServer.PATH_IMG + 'icon/ic_payment.svg'
    }

  }

  renderApplicant(app, applicantForm, attributes, key, index, primaryColor) {
    const {applicants} = this.state
    const {siteColor = {}} = this.props

    const addSectionSubFunc = (length, name) => {
      if (name === 'current') {
        return (() => this.addSectionSub(index, key, 1, length, name))
      }
      if (name === 'second') {
        return (() => this.addSectionSub(index, key, 2, length, name))
      }
    }

    const removeSectionSubFunc = (sectionSubIndex, name) => {
      if (name === 'current') {
        return (() => this.removeJustOneSectionSub(index, key, sectionSubIndex + 1))
      }
      if (name === 'second') {
        return (() => this.removeJustOneSectionSub(index, key, sectionSubIndex + 1))
      }
    }

    return (
      <div key={index}>
        <FormFinance section={applicantForm[key]}
                     attributes={attributes}
                     data={app[key]}
                     ref={key + index}
                     indexApp={index}
                     primaryColor={primaryColor}
                     addSectionSub={addSectionSubFunc}
                     removeSectionSub={removeSectionSubFunc}
                     onChange={this.onFormChange.bind(this, key, index)}
                     dataApplicant={applicants[0][APPLICANT_RESIDENCE]}
                     copyDataResidence={this.copyDataResidence.bind(this)}
                     siteColor={siteColor}
        />
      </div>
    )
  }

  setApplicant(app, values, index, attributes, errors, updateErrors, updateValues) {
    let temporalErrors = {}

    for (let key in app) {
      if (updateErrors) {
        this.refs[key + index].state.errors = {}
        errors[key + index] = this.refs[key + index].state.errors
      }
      if (isArray(app[key])) {
        app[key].forEach(item => {
          this.updateErrorsAndValues(values, item, attributes, errors[key + index], updateErrors, updateValues)
        })
      } else {
        this.updateErrorsAndValues(values, app[key], attributes, errors[key + index], updateErrors, updateValues)
      }
    }
  }

  updateErrorsAndValues(values, item, attributes, error, updateErrors, updateValues) {
    if (updateErrors)
      setErrors(attributes, item, error)

    if (updateValues)
      values = Object.assign(values, item)
  }

  handleSelect = (action, indexSection, sectionsQuantity, indexApp) => {

    if (action === 'previous') {
      if (indexSection === -1) {
        indexSection = sectionsQuantity
        indexApp = 0
      }
      this.setState({key: indexSection, indexApp, messageError: null})
    } else {
      const {applicants, attributes, sections} = this.state
      const app = applicants[indexApp]
      let errors = {}
      let values = {}
      let keyActualSection = sections[indexSection - 1].name
      let appCopy = cloneDeep(app)

      this.setApplicant({[keyActualSection]: appCopy[keyActualSection]}, values, indexApp, attributes, errors, true, false)
      const hasError = validate(errors)
      if (!hasError) {
        if (indexSection - 1 === sectionsQuantity && indexApp === 0) { // last section, first person
          indexSection = 0
          indexApp = 1
        }
        this.setState({errors: {}, key: indexSection, indexApp, messageError: null})
      } else {
        this.setState({errors: errors, messageError: 'Please fill up required information before continuing'})
      }
    }
    goTopScroll()
  }

  onHome = () => {
    browserHistory.push('/catalog/mainTemplate')
  }

  handleCheck = () => {
    this.setState({checked: !this.state.checked})
  }

  submit = () => {
    let {applicants, attributes, formCode, indexApp, checked, applicantsInitial, applicantFormsInitial} = this.state
    let requestForms = []
    let errors = {}
    let values
    let {formView} = this.props
    if (formView === 'vertical') {
      applicants.forEach((app, index) => {
        values = {}
        this.setApplicant(app, values, index, attributes, errors, true, true)
        requestForms.push({formCode: formCode, values: values})
      })
    } else {
      if (indexApp === 0) {
        values = {}
        this.setApplicant(applicants[indexApp], values, indexApp, attributes, errors, true, true)
        requestForms.push({formCode: formCode, values: values})
      } else {
        applicants.forEach((app, index) => {
          let values = {}
          this.setApplicant(app, values, index, attributes, errors, (index === 1), true)
          requestForms.push({formCode: formCode, values: values})
        })
      }
    }
    this.setState({isLoading: true, messageError: null})
    setTimeout(async () => {
      let hasError = validate(errors)
      if (hasError) {
        this.setState({messageError: 'Please fill up required information before continuing', isLoading: false})
        return
      }

      if (!checked) {
        this.setState({messageError: 'Accept Terms and conditions.', isLoading: false})
        return
      }

      this.props.onClickUp()
      await this.props.processFinanceFormFetch({requestForms: requestForms})
      if (this.props.processFinanceFormConfirmation?.error) {
        this.setState({submitting: false, isLoading: false})
        this.props.clearFinanceForm()
        return
      }

      if (this.props.processFinanceFormConfirmation) {
        const applicantsInitialNew = cloneDeep(applicantsInitial)
        const applicantFormsNew = cloneDeep(applicantFormsInitial)
        this.setState({
          messageSuccess: 'Your application has been submitted successfully a member of our sales staff will contact you shortly',
          submitting: false,
          applicants: applicantsInitialNew,
          applicantForms: applicantFormsNew,
          key: 0,
          checked: false,
          indexApp: 0,
          isLoading: false
        })
        setTimeout(() => {
          this.setState({messageSuccess: null, messageError: null})
        }, 3500)
        this.props.clearFinanceForm()
      }
    }, 300)
  }

  copyDataResidence = (data, index) => {
    let {applicants, attributes} = this.state
    let newApplicants = cloneDeep(applicants)
    newApplicants[1][APPLICANT_RESIDENCE][index] = data
    let attributeApplicantYear = find(attributes, function (attribute) {
      return attribute.name === ATTRIBUTE_RESIDENCE_YEAR
    })
    let attributeApplicantMonth = find(attributes, function (attribute) {
      return attribute.name === ATTRIBUTE_RESIDENCE_MONTH
    })
    let attributeApplicantYearPrevious = find(attributes, function (attribute) {
      return attribute.name === ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS
    })
    let attributeApplicantMonthPrevious = find(attributes, function (attribute) {
      return attribute.name === ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS
    })
    this.setState({applicants: newApplicants}, () => {
      let app = applicants[1][APPLICANT_RESIDENCE]
      let currentYear = app[0][attributeApplicantYear.id] !== '' ? (parseInt(app[0][attributeApplicantYear.id]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
      let currentMonth = app[0][attributeApplicantMonth.id] !== '' ? parseInt(app[0][attributeApplicantMonth.id]) : 0
      if (app[0][attributeApplicantYear.id] !== '' || app[0][attributeApplicantMonth.id] !== '') {
        if (app.length > 1) {
          let currentYearPrevious = app[1][attributeApplicantYearPrevious.id] !== '' ? (parseInt(app[1][attributeApplicantYearPrevious.id]) * NUMBER_MONTHS_FOR_ONE_YEAR) : 0
          let currentMonthPrevious = app[1][attributeApplicantMonthPrevious.id] !== '' ? parseInt(app[1][attributeApplicantMonthPrevious.id]) : 0
          if (app[1][attributeApplicantYearPrevious.id] !== '' || app[1][attributeApplicantMonthPrevious.id] !== '') {
            if (currentYear + currentMonth + currentYearPrevious + currentMonthPrevious < NUMBER_MONTHS_FOR_TWO_YEAR) {
              if (app.length === 2) {
                this.addSectionSub(1, APPLICANT_RESIDENCE, 2, 2, 'another')
              }
            } else {
              if (currentYear + currentMonth > NUMBER_MONTHS_FOR_TWO_YEAR) {
                this.removeSectionSub(1, APPLICANT_RESIDENCE, 1, 'previous')
              } else {
                this.removeSectionSub(1, APPLICANT_RESIDENCE, 2, 'another')
              }
            }
          }
        } else {
          if (currentYear + currentMonth < NUMBER_MONTHS_FOR_TWO_YEAR) {
            this.addSectionSub(1, APPLICANT_RESIDENCE, 1, 1, 'previous')
          } else {
            if (app.length > 2) {
              this.removeSectionSub(1, APPLICANT_RESIDENCE, 1, 'previous')
            } else {
              this.removeSectionSub(0, APPLICANT_RESIDENCE, 1, 'previous')
            }
          }
        }
      }
    })
  }

  render() {

    let {
      applicantForms,
      applicants,
      attributes,
      key,
      sections,
      checked,
      messageSuccess,
      indexApp,
      messageError,
      isLoading
    } = this.state
    let that = this

    if (applicants.length === 0)
      return null

    const indexEnd = sections.length - 1;
    const {readOnly, formView, siteColor = {}, styleWrapper, templateCode} = this.props;
    const primaryColor = siteColor[PRIMARY_COLOR]
    let messageValue
    if (messageSuccess) {
      messageValue = (<div style={{marginBottom: 10, color: 'green', fontSize: 16}}>{messageSuccess}</div>)
    }

    const labelCheck = (
      <div className='term-condition'>
        <span style={{color: siteColor[TEXT_COLOR]}}>I accept</span>
        <a>Terms and Conditions</a>
      </div>
    )

    const elCheck = elementCheck('icon/ic_check_box.svg', siteColor[PRIMARY_COLOR])

    const elUnCheck = elementUnCheck('icon/ic_uncheck_box.svg', siteColor[TEXT_COLOR])

    // Included by Hooks bug
    const StyledButton = styled.button`
      background-color: ${props => props.siteColor[PRIMARY_COLOR]} !important;
      border: 1px solid ${props => props.siteColor[PRIMARY_COLOR]} !important;
      color: ${props => props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
      font-weight: 600 !important;
      font-size: 14px !important;
  
      &:hover {
        background-color: ${props => props.siteColor[SECONDARY_COLOR]} !important;
        border: 1px solid ${props => props.siteColor[SECONDARY_COLOR]} !important;
        color: ${props => props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
        font-weight: 600 !important;
        font-size: 14px !important;
      }
    `

    const optionsBtnClasses = {
      [EASY_NAVIGATION_TEMPLATE_CODE]: {
        btnClassButton : 'btn btn-primary btn-submit',
      },
      [EASY_NAVIGATION_B_TEMPLATE_CODE]: {
        btnClassButton : 'btn btn-primary btn-submit',
      },
      [EASY_NAVIGATION_C_TEMPLATE_CODE]: {
        btnClassButton : 'btn btn-primary btn-submit',
      },
      [MODERN_VIEW_TEMPLATE_CODE]: {
        btnClassButton : 'btn btn-primary btn-submit',
      },
      [MODERN_VIEW_2_TEMPLATE_CODE]: {
        btnClassButton : 'btn-submit btn-finance-mv2',
      },
      [MODERN_VIEW_3_TEMPLATE_CODE]: {
        btnClassButton : 'btn btn-primary btn-submit',
      }
    }

    const {btnClassButton} = optionsBtnClasses[templateCode]


    return (
      <div id='financeForm' style={styleWrapper}
           className={formView === 'wizard' ? 'finance-form-wizard' : 'finance-form-vertical'}>
        {messageValue}
        {isLoading ? <Loader/> : null}
        {
          formView === 'wizard' ?
            <Tabs id='controlled-tab-example'
                  className='tab-finance'
                  activeKey={key}
                  onSelect={this.handleSelect}
                  animation={false}>
              {
                sections.map((sec, index) => {
                  const path = that.getIconPath(sec.name)
                  return (
                    <Tab key={index} eventKey={index}
                         title={that.renderTitleTab(sec.header.title, path, key, index, primaryColor)}
                         disabled={(key === 99)}>
                      {
                        sec.name !== 'creditScore' ?
                          <div className='label-required'>
                            <label>*Required Fields</label>
                          </div> : null
                      }
                      <div className='col100'>
                        {this.renderApplicant(applicants[indexApp], applicantForms[indexApp], attributes, sec.name, indexApp, primaryColor)}
                      </div>
                      <div className='finance-btn'>
                        <div className='content-check'>
                          {
                            (index === indexEnd && (applicants.length - 1 === indexApp)) ?
                              <Checkbox label={labelCheck} checked={checked} id='checkBox'
                                        onCheck={this.handleCheck}
                                        labelStyle={{color: '#777', fontSize: 16}}
                                        iconStyle={{borderColor: '#777', marginRight: 8}}
                                        checkedIcon={elCheck} uncheckedIcon={elUnCheck}
                              /> : null
                          }
                        </div>
                        {
                          messageError ?
                            <div className='label-error-message'>
                              <label>{messageError}</label>
                            </div> : null
                        }
                        {
                          (index !== 0 || indexApp !== 0) ?
                            <button className='btn btn-previous'
                                    style={{color: primaryColor, borderColor: primaryColor}}
                                    onClick={() => that.handleSelect('previous', (index - 1), indexEnd, indexApp)}>
                              PREVIOUS
                            </button> : null
                        }
                        {
                          (index === indexEnd && (applicants.length - 1 === indexApp)) ?
                            <button className='btn btn-primary btn-submit'
                                    style={{backgroundColor: primaryColor, borderColor: primaryColor}}
                                    disabled={readOnly} onClick={that.submit}>SEND MY APPLICATION</button> :
                            <button id='buttonTop' className='btn btn-primary'
                                    style={{backgroundColor: primaryColor, borderColor: primaryColor}}
                                    onClick={() => that.handleSelect('continue', (index + 1), indexEnd, indexApp)}>CONTINUE</button>
                        }
                      </div>
                    </Tab>
                  )
                })
              }
              <Tab eventKey={99} title=''>
                <div className='tab-applicant-sent'>
                  <div className='col100'>
                    <div className='dot-applicant-sent'>
                      <i className='fa fa-check'/>
                    </div>
                  </div>
                  <div className='col100'>
                    <h3>Your applicant has been sent</h3>
                    <h5>We'll contact you us soon as possible.</h5>
                  </div>
                  <div className='col-print'>
                    <div className='col-50-left'>
                      <span>Print applicant</span>
                      <i className='fa fa-print'/>
                    </div>
                    <div className='col-50-right'>
                      <i className='fa fa-save'/>
                      <span>Save PDF</span>
                    </div>
                  </div>
                  <div className='col100'>
                    <button className='btn btn-primary btn-home'
                            style={{color: primaryColor, borderColor: primaryColor}}
                            onClick={this.onHome}>HOME
                    </button>
                  </div>
                </div>
              </Tab>
            </Tabs> :
            <div className='container-general'>
              <div className='tab-finance view-vertical'
                   style={{
                     backgroundColor: siteColor[BOX_BACKGROUND_COLOR],
                     border: `1px solid ${siteColor[BOX_BORDER_COLOR]}`
                   }}
              >
                {
                  applicantForms.map((applicantForm, index) => {
                    let app = applicants[index]
                    return (
                      <div key={index}>
                        {
                          sections.map((sec, indexSection) => {
                            return (
                              <div key={indexSection} className='tab-content'>
                                {that.renderApplicant(app, applicantForm, attributes, sec.name, index, primaryColor)}
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
                <div className='content-check'>
                  <Checkbox label={labelCheck} checked={checked} id='checkBox'
                            onCheck={this.handleCheck}
                            labelStyle={{color: '#777', fontSize: 16}}
                            iconStyle={{borderColor: '#777', marginRight: 8}}
                            checkedIcon={elCheck} uncheckedIcon={elUnCheck}
                  />
                </div>
                {
                  messageError ?
                    <div className='label-error-message finance-message-error'>
                      <label>{messageError}</label>
                    </div> : null
                }
                <div className='finance-btn'>
                  <DPButton
                    buttonClassName={btnClassButton}
                    textColor={siteColor[BUTTON_TEXT_COLOR]}
                    backgroundColor={siteColor[PRIMARY_COLOR]}
                    backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                    size='DP-MEDIUM'
                    onClick={readOnly ? null : that.submit}
                    label={getButtonTextCapByTemplate('SEND', templateCode)}
                    isAdmin={readOnly}
                  />
                </div>
              </div>
            </div>
        }
      </div>
    )
  }
}

LayoutFinanceViewForm.defaultProps = {
  styleWrapper: {}
}

LayoutFinanceViewForm.propTypes = {
  processFinanceForm: PropTypes.func,
  loadFormByCodeAdmin: PropTypes.func,
  styleWrapper: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  dealerId: state.dealerId,
  loadFormByCodeData: state.loadFormByCodeData,
  processFinanceFormConfirmation: state.processFinanceFormConfirmation,
})

export default connect(
  mapStateToProps,
  {loadFormByCodeFetch: loadPublicFormByCode, processFinanceFormFetch: processFinanceForm, clearFinanceForm}
)(LayoutFinanceViewForm)