import React from 'react'
import {BUTTON_TEXT_COLOR, pathServer, PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR} from '../../../Constants'
import {ReactSVG} from 'react-svg'
import {StyledButton} from '../../../StyledComponentUtil'
import {browserHistory} from 'react-router'
import DPButton from '../../../genericComponents/DPButton'

const GetAFinance = ({siteColor}) => {
  const finance = () => {
    browserHistory.push(`/catalog/finance`)
  }

  return (
    <div className='container-left-apply'>
      <div className='container-get-finance'>
        <label
          className='label-get-finance'
          style={{color: siteColor[TEXT_COLOR]}}
        >
          Get a Finance
        </label>
        <p
          className='following-steps'
          style={{color: siteColor[TEXT_COLOR]}}
        >
          By following three easy steps
        </p>
      </div>

      <div className='container-apply-online'>
        <ReactSVG
          src={pathServer.PATH_IMG + 'icon/ic_text-line-form.svg'}
          className='container-icon'
          beforeInjection={svg => {
            svg.classList.add('icon-search')
            svg.setAttribute('style', `fill: ${siteColor[TEXT_COLOR]}`)
          }}
        />
        <label
          htmlFor=''
          className='apply-online'
          style={{color: siteColor[TEXT_COLOR]}}>
          Apply online
        </label>
      </div>
      <div className='container-choose-car'>
        <ReactSVG
          src={pathServer.PATH_IMG + 'icon/ic_directions_car.svg'}
          className='container-icon'
          beforeInjection={svg => {
            svg.classList.add('icon-search')
            svg.setAttribute('style', `fill: ${siteColor[TEXT_COLOR]}`)
          }}
        />
        <label className='choose-car' style={{color: siteColor[TEXT_COLOR]}}>Choose a car</label>
      </div>
      <div className='container-sign-go'>
        <ReactSVG
          src={pathServer.PATH_IMG + 'icon/ic_edit-pencil.svg'}
          className='container-icon'
          beforeInjection={svg => {
            svg.classList.add('icon-search')
            svg.setAttribute('style', `fill: ${siteColor[TEXT_COLOR]}`)
          }}
        />
        <label
          className='sing-go'
          style={{color: siteColor[TEXT_COLOR]}}
        >
          Sign and go
        </label>
      </div>
      <div className='container-button-apply'>
        <DPButton
          buttonClassName='btn-apply'
          textColor={siteColor[BUTTON_TEXT_COLOR]}
          backgroundColor={siteColor[PRIMARY_COLOR]}
          backgroundHoverColor={siteColor[SECONDARY_COLOR]}
          size='DP-MEDIUM'
          onClick={finance}
          label={'Apply now'}

        />
      </div>
    </div>
  )
}

export default GetAFinance
