import React, {useEffect} from 'react'
import {ReactSVG} from 'react-svg'
import {
  optionBody,
  optionCondition,
  pathServer,
  WIDTH_VIEW_MOBILE,
  BOX_BACKGROUND_COLOR,
  CONTROL_BACKGROUND_COLOR,
  TEXT_COLOR,
  CONTROL_BORDER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEMPLATE_MAIN_NAME,
  MODERN_VIEW_2_TEMPLATE_CODE,
  MODERN_VIEW_TEMPLATE_CODE,
  EASY_NAVIGATION_B_TEMPLATE_CODE,
  MODERN_VIEW_3_TEMPLATE_CODE,
  BUTTON_TEXT_COLOR,
  BOX_BORDER_COLOR
} from '../../Constants'
import Loader from '../../UI/Loader'
import PropTypes from 'prop-types'
import {getButtonTextCapByTemplate, getHoverButton} from '../../Util'
import {Wrapper} from '../../ComponentUtils'
import {isEmpty} from 'lodash'
import DPTextInput from '../../genericComponents/DPTextInput'
import {DPRangeNumberInput} from '../../DPRangeNumberInput'
import {DPSelectDropdown} from '../../../common/genericComponents/DPSelectDropdown/index'
import DPButton from '../../genericComponents/DPButton'


const CarSearch = ({
                     siteColor, search, handleFiltersCheck, loading, filterValues, baseTemplateCode,
                     makes, onClose, onChangeInputFilters, isAdminTemplate, modelsByMakeIds, carSearchContainerClasName,
                     templateCode, currentTheme
                   }) => {

  const {
    [PRIMARY_COLOR]: primaryColor,
    [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [BOX_BORDER_COLOR]: boxBorderColor,
    [CONTROL_BACKGROUND_COLOR]: controlBackgroundColor,
    [TEXT_COLOR]: textColor,
    [SECONDARY_COLOR]: secondaryColor,
    [CONTROL_BORDER_COLOR]: controlBorderColor
  } = siteColor

  const isDarkMode = currentTheme === 'dark'

  useEffect(() => {
    getHoverButton('btnSearch', primaryColor, secondaryColor)
    getHoverButton('btnSearchAdvanced', primaryColor, secondaryColor)
    getHoverButton('btnFilter', primaryColor, secondaryColor)
  })

  const selectDropdownStyle = {
    container: 'car-search-select-content-model',
    select: (isAdminTemplate && baseTemplateCode === TEMPLATE_MAIN_NAME) || (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE && isAdminTemplate) ? 'car-search-select-easy-navigation' : 'car-search-select',
    deployed: ('layoutBanner-select-deployed ' + (isDarkMode ? 'layoutBanner-DarkMode' : '')),
    paper: 'layoutBanner-detail'
  }

  const styleInput = {
    container: 'car-search-input-content',
    label: 'text-fields',
    filter: 'fields-boxes',
    input: (isAdminTemplate && baseTemplateCode === TEMPLATE_MAIN_NAME) || (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE && isAdminTemplate) ? 'input-search-content-easy-navigation' : 'input-search-content'
  }

  const styleInputPrice = {
    container: (isAdminTemplate && baseTemplateCode === TEMPLATE_MAIN_NAME) || (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE && isAdminTemplate) ? 'car-search-input-content' : 'car-search-input-content reduce-padding-right',
    label: 'text-fields',
    filter: 'fields-boxes',
    input: (isAdminTemplate && baseTemplateCode === TEMPLATE_MAIN_NAME) || (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE && isAdminTemplate) ? 'input-search-content-easy-navigation' : 'input-search-content-price'
  }


  const btnClassName = `btn-search-car ${(templateCode === MODERN_VIEW_2_TEMPLATE_CODE) ? "btn-search-car-mv2" : ""}`

  return (
    <Wrapper
      wrapper={
        children => (
          <div
            className={isEmpty(carSearchContainerClasName) ? 'car-search-container' : carSearchContainerClasName}
          >
            {children}
          </div>
        )
      }
    >
      {loading && <Loader/>}
      <div
        className={`car-search-box`}
        style={{
          backgroundColor: boxBackgroundColor,
          border: `1px solid ${boxBorderColor}`
        }}
      >
        <div className='car-search-row first-row-grid'>
          <DPTextInput
            containerClassName={isAdminTemplate ? 'col-keyboard-easy-navigation' : 'col-keyboard'}
            inputId='keyword'
            inputClassName='form-control'
            simpleInputClassname={true}
            placeholder='Keyword'
            value={filterValues?.keyword || ''}
            onChangeText={onChangeInputFilters}
            onKeyPress={(event) => event.key === 'Enter' ? search(false) : null}
            inputStyle={{
              backgroundColor: controlBackgroundColor,
              border: `1px solid ${controlBorderColor}`,
              color: textColor
            }}
            disabled={isAdminTemplate}
            rightElement={<ReactSVG
              src={pathServer.PATH_IMG + 'icon/ic_search.svg'}
              beforeInjection={svg => {
                svg.classList.add('icon-search')
                svg.setAttribute('style', `fill: ${textColor}`)
              }}
            />}
          />
            <DPSelectDropdown
              id='selectedMakes'
              options={makes}
              name='MAKE'
              placeholder='Make'
              onSelect={values => handleFiltersCheck('selectedMakes', values)}
              selectedValue={filterValues?.selectedMakes}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              isDisabled={isAdminTemplate}
              multiple={true}
              selectClassName={selectDropdownStyle.container}
              contentClassName={selectDropdownStyle.select}
              selectDeployedClassName={selectDropdownStyle.deployed}
              optionsClassName={selectDropdownStyle.paper}
            />
            <DPSelectDropdown
              id='selectedModels'
              options={modelsByMakeIds}
              name='MODELS'
              placeholder='Model'
              hasGroups={true}
              onSelect={values => handleFiltersCheck('selectedModels', values)}
              selectedValue={filterValues?.selectedModels}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              isDisabled={isAdminTemplate}
              multiple={true}
              selectClassName={selectDropdownStyle.container}
              contentClassName={selectDropdownStyle.select}
              selectDeployedClassName={selectDropdownStyle.deployed}
              optionsClassName={selectDropdownStyle.paper}
            />
            <DPSelectDropdown
              id='selectedBodies'
              options={optionBody}
              name='BODIES'
              placeholder='Body type'
              onSelect={values => handleFiltersCheck('selectedBodies', values)}
              selectedValue={filterValues?.selectedBodies}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              isDisabled={isAdminTemplate}
              multiple={true}
              selectClassName={selectDropdownStyle.container}
              contentClassName={selectDropdownStyle.select}
              selectDeployedClassName={selectDropdownStyle.deployed}
              optionsClassName={selectDropdownStyle.paper}
            />
            <DPSelectDropdown
              id='selectedConditions'
              options={optionCondition}
              name='CONDITIONS'
              placeholder='Condition'
              onSelect={values => handleFiltersCheck('selectedConditions', values)}
              selectedValue={filterValues?.selectedConditions}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              isDisabled={isAdminTemplate}
              multiple={true}
              selectClassName={selectDropdownStyle.container}
              contentClassName={selectDropdownStyle.select}
              selectDeployedClassName={selectDropdownStyle.deployed}
              optionsClassName={selectDropdownStyle.paper}
            />
        </div>
        <div className='car-search-row second-row' id='rowSearch2'>
          <DPRangeNumberInput
            id={{idFrom: 'yearFrom', idTo: 'yearTo'}}
            value={{valueFrom: filterValues?.yearFrom ?? '', valueTo: filterValues?.yearTo ?? ''}}
            displayValue={window.innerWidth > WIDTH_VIEW_MOBILE ? 'Year' : 'Year from'}
            integer={4}
            classNames={styleInput}
            onChangeNumber={(evt, value) => onChangeInputFilters(evt, value)}
            siteColor={siteColor}
            disabled={isAdminTemplate}
          />
          <DPRangeNumberInput
            id={{idFrom: 'milesFrom', idTo: 'milesTo'}}
            value={{valueFrom: filterValues?.milesFrom ?? '', valueTo: filterValues?.milesTo ?? ''}}
            displayValue={window.innerWidth > WIDTH_VIEW_MOBILE ? 'Miles' : 'Miles from'}
            integer={8}
            icon={<label className='k-miles' style={{color: textColor}}>k</label>}
            iconPosition='right'
            classNames={styleInput}
            onChangeNumber={(evt, value) => onChangeInputFilters(evt, value)}
            siteColor={siteColor}
            disabled={isAdminTemplate}
          />
          <DPRangeNumberInput
            id={{idFrom: 'priceFrom', idTo: 'priceTo'}}
            value={{valueFrom: filterValues?.priceFrom ?? '', valueTo: filterValues?.priceTo ?? ''}}
            displayValue={window.innerWidth > WIDTH_VIEW_MOBILE ? 'Price' : 'Price from'}
            integer={8}
            icon={<label className='prices' style={{color: textColor}}>$</label>}
            iconPosition='left'
            classNames={styleInputPrice}
            onChangeNumber={(evt, value) => onChangeInputFilters(evt, value)}
            siteColor={siteColor}
            disabled={isAdminTemplate}
          />
        </div>
        <div id='containerButtons' className='car-search-row'>
          <div className='btn-search-content'>
            <div className='btn-search-car-content'>
              <DPButton
                buttonClassName={btnClassName}
                textColor={siteColor[BUTTON_TEXT_COLOR]}
                backgroundColor={siteColor[PRIMARY_COLOR]}
                backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                size='DP-MEDIUM'
                onClick={isAdminTemplate ? () => {
                } : search}
                label={getButtonTextCapByTemplate('SEARCH', templateCode) }
                isAdmin={isAdminTemplate}
              />
            </div>
          </div>
          {
            onClose &&
            <div id='btn-close-mv' className='btn-search-content'>
              <div className='btn-search-car-content'>
                <DPButton
                  id='btnCloseFilter'
                  buttonClassName='btn-close-filter'
                  textColor='black'
                  backgroundColor='transparent'
                  onClick={isAdminTemplate ? () => {
                  } : onClose}
                  label='CLOSE'
                  isDisabled={isAdminTemplate}
                />
              </div>
            </div>
          }
        </div>
      </div>
    </Wrapper>
  )
}

CarSearch.propTypes = {
  isAdvancedSearch: PropTypes.bool,
  siteColor: PropTypes.object,
  keyColor: PropTypes.string,
  templateCode: PropTypes.string,
  makes: PropTypes.arrayOf(PropTypes.string),
  models: PropTypes.arrayOf(PropTypes.string),
  modelsByMakeIds: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      [PropTypes.string]: PropTypes.arrayOf(PropTypes.object)
    })
  ]),
  keyword: PropTypes.string,
  onClose: PropTypes.func,
  onChangeInputFilters: PropTypes.func,
  search: PropTypes.func,
  handleFiltersCheck: PropTypes.func,
  isAdminTemplate: PropTypes.bool,
  loading: PropTypes.bool,
  filterValues: PropTypes.object,
  carSearchContainerClasName: PropTypes.string
}

CarSearch.defaultProps = {
  isAdvancedSearch: false,
  siteColor: {},
  keyColor: '',
  templateCode: '',
  makes: [],
  models: [],
  modelsByMakeIds: [],
  keyword: '',
  isAdminTemplate: false,
  loading: false,
  onClose: null,
  filterValues: {},
  carSearchContainerClasName: '',
  onChangeInputFilters: () => {
  },
  search: () => {
  },
  handleFiltersCheck: () => {
  },
}

export default CarSearch