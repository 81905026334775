import React, {useEffect, useState} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader'
import {connect, useDispatch, useSelector} from 'react-redux'
import {
  BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN,
  BOX_BACKGROUND_COLOR,
  CONTROL_BACKGROUND_COLOR,
  DARK_COLORS,
  DARK_THEME,
  LIGHT_COLORS,
  LIGHT_THEME,
  PAGE_INVENTORY_ID,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEMPLATE_MAIN_NAME
} from '../../common/Constants'
import LayoutFooter from '../../common/template/LayoutFooter'
import {
  searchInventoryPublic,
  loadMakeAndModel,
  loadLocationsPublic,
  saveEmailMessage, CLEAR_CARS_BY_VIN_DATA, loadCarByVinPublic
} from '../actions'
import {
  getBackgroundEasyNavigation,
  getClassContainerEasyNavigation
} from '../../common/Util'
import LayoutInventoryCarViewTemplateCodeMain
  from '../../common/template/LayoutInventoryCar/LayoutInventoryCarViewTemplateCodeMain'
import {browserHistory} from 'react-router'
import find from 'lodash/find'

const LayoutCarContainer = ({
                              searchInventoryPublic, loadLocationsPublic, loadMakeAndModel, carsInformation,
                              makes, locations, templateContent, dealerId, templateCode,
                              saveEmailMessage, params: {carVin}, dealerName
                            }) => {

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch()
  const carByVinData = useSelector(state => state.carByVinData)

  useEffect(() => {
    if (!carsInformation) {
      loadMakeAndModel()
      loadLocationsPublic()
      searchInventoryPublic(dealerId, {})
    }

    if (carByVinData?.error) {
      browserHistory.push(`/catalog/inventory`)
      dispatch({type: CLEAR_CARS_BY_VIN_DATA})
    } else if (!carByVinData || carVin !== carByVinData.car.vin) {
      setIsLoading(true)
      dispatch(loadCarByVinPublic(carVin, dealerId))
      setTimeout(() => {
        setIsLoading(false)
      },200)
    }
    window.scrollTo(0,0)
  }, [carByVinData, carVin])

  if (isLoading || !carByVinData || carByVinData?.error || !carsInformation || !makes || !locations)
    return null

  const content = JSON.parse(templateContent)

  const {generalSetting, pages} = content
  const {socialMedia, footer, currentTheme, banner, site} = generalSetting
  const {backgroundMain: {url: backgroundMainUrl, maxHeight: backgroundMainMaxHeight}} = banner
  const backgroundMain = getBackgroundEasyNavigation(templateCode, backgroundMainUrl)
  const {logo = '', maxHeight: maxHeightLogo} = site
  const inventoryPage = pages.find(page => page.id === PAGE_INVENTORY_ID)
  const {header: {menu} = {}} = inventoryPage
  const siteColor = (currentTheme === LIGHT_THEME) ? generalSetting[LIGHT_COLORS] : generalSetting[DARK_COLORS]
  const {
    [PRIMARY_COLOR]: primaryColor,
    [SECONDARY_COLOR]: secondaryColor,
    [BOX_BACKGROUND_COLOR]: boxBackground,
    [CONTROL_BACKGROUND_COLOR]: controlBackground
  } = siteColor
  const classTemplateContainer = getClassContainerEasyNavigation(templateCode, 'layout-inventory-container-car')

  const {car, image: carImages} = carByVinData
  const locationFound = find(locations, location => location.locationId === car.locationId)

  const goBack = () => {
    browserHistory.push(`/catalog/inventory`)
    dispatch({type: CLEAR_CARS_BY_VIN_DATA})
    searchInventoryPublic(dealerId)
  }

  return (
    <div
      id='inventoryCar'
      className={`${classTemplateContainer} ${currentTheme === DARK_THEME ? 'layout-inventory-container-car-dark' : 'layout-inventory-container-car-light'} container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}
    >
      <div className='header-content'>
        <img
          id='background' className='background'
          style={{maxHeight: backgroundMainMaxHeight >= BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN ? backgroundMainMaxHeight : BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN}}
          src={backgroundMain}
        />
        <LayoutHeader
          menu={menu}
          logo={logo}
          maxHeight={maxHeightLogo}
          menuSelected={'inventory'}
          locations={locations}
          siteColor={siteColor}
          templateCode={templateCode}
          locationId={locationFound && locationFound.locationId}
          selectLocationId={() => {
          }}
          baseTemplateCode={TEMPLATE_MAIN_NAME}
          disableLocationsDropdown
        />
      </div>
      <div>
        <div className='car-separator1' style={{backgroundColor: primaryColor}}/>
        <div className='content-separator2'>
          <div className='car-separator2' style={{backgroundColor: secondaryColor}}/>
        </div>
      </div>
      <div id='separatorHeader' className='separator-header'/>
      {car &&
        <LayoutInventoryCarViewTemplateCodeMain
          currentTheme={currentTheme}
          dealerId={dealerId}
          logo={logo}
          siteColor={siteColor}
          templateCode={templateCode}
          allCarsWithAttributes={carsInformation.cars}
          location={locationFound}
          dealerName={dealerName}
          carWithAttributes={car}
          carImages={carImages}
          pages={pages}
          goBack={goBack}
        />
      }
      {
        currentTheme === DARK_THEME &&
        <div className='container-banner-car' style={{backgroundColor: controlBackground}}/>
      }
      <LayoutFooter
        currentTheme={currentTheme}
        backgroundMain={backgroundMain}
        dealerId={dealerId}
        footer={footer}
        socialMedia={socialMedia || {}}
        siteColor={siteColor}
        templateCode={templateCode}
        saveEmailMessage={saveEmailMessage}
        isButtonSticky={true}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    templateContent: state.templateContent,
    locations: state.locationsPublic,
    carsInformation: state.carsInformation,
    makes: state.makes,
    models: state.models,
    dealerName: state.dealerName,
    templateCode: state.templateCode
  }
}

export default connect(mapStateToProps,
  {searchInventoryPublic, loadMakeAndModel, loadLocationsPublic, saveEmailMessage})
(LayoutCarContainer)
