import React, {useState, memo, useEffect} from 'react'
import {
  EASY_NAVIGATION_C_TEMPLATE_CODE,
  MODERN_VIEW_3_TEMPLATE_CODE,
  MODERN_VIEW_TEMPLATE_CODE,
  SITE_BACKGROUND_COLOR,
  TIME_FIVE_SECONDS
} from '../../Constants'
import GetInTouch from './GetInTouch'
import {buildMessages, emailData, messageEmailWithSelectedCar} from '../../Util'
import { checkValidEmail, checkValidPhoneNumber } from '../../ValidationUtil'
import {Wrapper} from '../../ComponentUtils'
import './LayoutGetInTouch.scss'
import {saveMessages} from '../../../catalog/actions'
import {useDispatch, useSelector} from 'react-redux'

const initialGetInTouch = {
  name: '',
  email: '',
  text: '',
  phoneNumber: '',
}

const MemoGetInTouch = memo(GetInTouch)

const LayoutGetInTouch = ({
                            templateCode, title, siteColor, readOnly, car, dealerId, section
                          }) => {

  const [getInTouchForm, setGetInTouchForm] = useState(initialGetInTouch)
  const [errors, setErrors] = useState([])
  const [sampleErrors, setSampleErrors] = useState({})
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()
  const messageDataStatus = (useSelector(state => state.messageConfirmation))

  const hideContactSection = !!section.hideSection

  const inputChange = (prop, value) => {
    setGetInTouchForm({...getInTouchForm, [prop]: value ?? ''})
    setErrors([])
    setSampleErrors({})
  }

  const isFormValid = () => {
    const {name, email, text, phoneNumber} = getInTouchForm
    const isInvalidEmail = !email || !(email.trim()) || !checkValidEmail(email.toLowerCase())
    const isInvalidPhoneNumber = (!phoneNumber || !(phoneNumber.trim()) || !checkValidPhoneNumber(phoneNumber.trim()))

    let sampleErrorsJson = {}
    let errors = []

    if (!name || !(name.trim())) {
      sampleErrorsJson = ({ ...sampleErrorsJson, name: "Please enter required information"})
      errors.push('Incorrect name')
    }

    /*if (!text || !(text.trim())){
      errors.push('Incorrect text')
    }*/

    if (isInvalidEmail){
      errors.push('Incorrect email')
      sampleErrorsJson = ({ ...sampleErrorsJson, email: "Please enter required information"})
    }
    if (isInvalidPhoneNumber){
      sampleErrorsJson = ({ ...sampleErrorsJson, phoneNumber: "Please enter required information"})
      errors.push('Incorrect phone')
    }

    /*if (!isInvalidEmail && isInvalidPhoneNumber)
      errors.push('Incorrect phone number')*/

    if (errors.length > 0) {
      setErrors(errors)
      setSampleErrors(sampleErrorsJson)
      return false
    }

    return true
  }

  useEffect(() => {

    setErrors([])
    setMessage('')

    if (messageDataStatus?.error) {
      setErrors(['Error: Your message could not be sent.'])
      setTimeout(() => {
        setErrors(['Error: Your message could not be sent.'])
      }, TIME_FIVE_SECONDS)
    } else if (messageDataStatus) {
      setGetInTouchForm(initialGetInTouch)
      setMessage('Your message has been send successfully, NOTE: See your email for a copy of your message.')
      setTimeout(() => {
        setMessage('')
      }, TIME_FIVE_SECONDS)
    }

  }, [messageDataStatus])

  const sendMessage = (e) => {
    e.preventDefault()
    const {email, name, text, phoneNumber} = getInTouchForm
    const message = car ? messageEmailWithSelectedCar(text, car) : text
    const data = emailData(dealerId, email, message, name, phoneNumber)

    if (isFormValid()) {
      dispatch(saveMessages(data, TIME_FIVE_SECONDS))
    }

  }

  const onBlurPhoneField = () => {
    const {phoneNumber} = getInTouchForm
    const matches = phoneNumber.match(/\d+/g)

    if (matches) {
      const newPhoneNumber = matches.join('')
      const formattedNewPhoneNumber = `(${newPhoneNumber.substring(0, 3)})${newPhoneNumber.substring(3, 6)}-${newPhoneNumber.substring(6, 10)}`

      setGetInTouchForm(prevState => ({
        ...prevState,
        phoneNumber: formattedNewPhoneNumber
      }))
    }
  }

  const isCompact =
    templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE ||
    templateCode === MODERN_VIEW_TEMPLATE_CODE ||
    templateCode === MODERN_VIEW_3_TEMPLATE_CODE


  return (
    !hideContactSection ?
    <Wrapper
      wrapper={
        children => readOnly ? (
          <>
            {children}
          </>
        ) : (
          <div
            id={isCompact ? 'contact5': ''}
            className={isCompact ? 'layout-get-in-touch': 'get-in-touch-message-box'}
            style={isCompact ? {backgroundColor: siteColor[SITE_BACKGROUND_COLOR]} : {}}
          >
            {children}
          </div>
        )
      }
    >
      {buildMessages(errors, message)}
      <MemoGetInTouch
        title={title}
        sendMessage={sendMessage}
        inputChange={inputChange}
        getInTouchForm={getInTouchForm}
        siteColor={siteColor}
        onBlurPhoneField={onBlurPhoneField}
        readOnly={readOnly}
        isCompact={isCompact}
        sampleErrors={sampleErrors}
      />
    </Wrapper>
    :null
  )
}

export default LayoutGetInTouch