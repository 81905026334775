import React, {Component} from 'react'
import { ATTRIBUTE_CODE_CAR_STATUS, carStatus2, SITE_BACKGROUND_COLOR } from './Constants'
import {getMainImageURL} from "./Util";

export default class LayoutCarItemCarousel extends Component {
  constructor(){
    super();
  }

  render(){
    const {car, carItemHeight, siteColor} = this.props;

    if(!car)
      return null;

    const img = getMainImageURL(car);
    const attributeCarStatus = car[ATTRIBUTE_CODE_CAR_STATUS];

    return(
      <React.Fragment>
        <div
          className={(attributeCarStatus.attributeValue !== carStatus2.SOLD)
            ? "container-car-image"
            : "container-car-image-sold-carousel"
          }
          style={{
            height: carItemHeight,
            backgroundColor: siteColor[SITE_BACKGROUND_COLOR]
          }}>
          <img src={img}/>
        </div>
      </React.Fragment>
    )
  }
}