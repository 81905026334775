import {connect} from 'react-redux'
import React, {Component} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader'
import {
  TEMPLATE_MAIN_NAME, pathServer, PAGE_HOME_ID, PAGE_INVENTORY_ID, SECTION_FINANCE_ID,
  BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN, LIGHT_THEME, DARK_THEME, LIGHT_COLORS, DARK_COLORS,
  SITE_BACKGROUND_COLOR, CARS_BY_PAGE_DEFAULT, PRIMARY_COLOR, SECONDARY_COLOR
} from '../../common/Constants'
import LayoutFooter from '../../common/template/LayoutFooter'
import {
  loadLocationsPublic, searchInventoryPublic, loadMakeAndModel, selectedLocationId, saveEmailMessage
} from '../actions'
import {browserHistory} from 'react-router'
import LayoutFinance from '../../common/template/LayoutFinance.jsx'
import get from 'lodash/get'
import DPBackToTop from '../../common/DPBackToTop'
import {getBackgroundEasyNavigation, getClassContainerEasyNavigation} from '../../common/Util'
import LayoutInventory from '../../common/template/LayoutInventory'
import find from 'lodash/find'

class LayoutFinanceContainer extends Component{

  componentWillMount(){
    const {dealerId,searchInventoryPublic,loadLocationsPublic,loadMakeAndModel,carsInformation} = this.props;
    if(!carsInformation){
      loadMakeAndModel();
      loadLocationsPublic();
      searchInventoryPublic(dealerId, {from: 0, size: CARS_BY_PAGE_DEFAULT});
    }
  }

  onHome(){
    browserHistory.push("/catalog/mainTemplate");
  }

  render(){
    const {
      carsInformation, makes, locations, templateContent, dealerId, templateCode, locationId, selectedLocationId,
      saveEmailMessage, models, searchInventoryPublic
    } = this.props

    if(!carsInformation || !locations || !makes)
      return null;

    const {generalSetting, pages} = JSON.parse(templateContent)
    const {
      socialMedia, footer, site : {maxHeight: maxHeightLogo, logo = ''}, currentTheme, banner
    } = generalSetting
    const {
      backgroundMain : {url: backgroundMainUrl, maxHeight: backgroundMainMaxHeight},
      backgroundFinance: {url: backgroundFinanceUrl = pathServer.PATH_IMG + 'landscape.jpg'} = {}
    } = banner

    const homePage = pages.find(page => page.id === PAGE_HOME_ID)
    const inventoryPage = pages.find(page => page.id === PAGE_INVENTORY_ID)
    const sectionFinance = find(homePage.sections ,sec => sec.id === SECTION_FINANCE_ID)
    const backgroundMain = getBackgroundEasyNavigation(templateCode, backgroundMainUrl)

    const {showSection: showFinanceSection = true} = find(inventoryPage.sections, sec => sec.id === SECTION_FINANCE_ID) || {}
    const {menu} = inventoryPage.header

    const siteColor = (currentTheme === LIGHT_THEME) ? generalSetting[LIGHT_COLORS] : generalSetting[DARK_COLORS]
    const {
      [PRIMARY_COLOR]: primaryColor, [SECONDARY_COLOR]: secondaryColor, [SITE_BACKGROUND_COLOR]: siteBackgroundColor
    } = siteColor
    const classTemplateContainer = getClassContainerEasyNavigation(templateCode, 'layout-inventory-container')

    return (
      <div className={`${classTemplateContainer} ${currentTheme === DARK_THEME ? 'layout-inventory-container-dark' : ''} container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}>
        {/*todo: remove following style object, this is a hack*/}
        <LayoutHeader
          menu={menu}
          logo={logo}
          maxHeight={maxHeightLogo}
          menuSelected={"inventory"}
          locations={locations}
          siteColor={siteColor}
          templateCode={templateCode}
          locationId={locationId}
          selectLocationId={selectedLocationId}
          baseTemplateCode={TEMPLATE_MAIN_NAME}
        />
        <div className='header-content'>
          <img
            className='background'
            style={{maxHeight: backgroundMainMaxHeight >= BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN ? backgroundMainMaxHeight : BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN}}
            alt="background logo"
            src={backgroundMain}
          />
          <div id="separatorHeader" className="separator-header" />
          <div>
            <div className="car-separator1" style={{backgroundColor:primaryColor}}/>
            <div className="content-separator2" >
              <div className="car-separator2" style={{backgroundColor:secondaryColor}}/>
            </div>
          </div>
        </div>

        <LayoutInventory
          siteColor={siteColor}
          makes={makes.map(make => ({id: make.id, name: make.name, value: make.name}))}
          dealerId={dealerId}
          carsInformation={carsInformation}
          searchInventoryPublic={searchInventoryPublic}
          models={models}
          currentTheme={currentTheme}
          templateCode={templateCode}
          information={get(footer, ['information'], {})}
          locationId={locationId}
        />
        {
          currentTheme === DARK_THEME &&
          <div className="container-banner-car" style={{backgroundColor: siteBackgroundColor}}/>
        }
        {
          showFinanceSection &&
          <LayoutFinance
            section={sectionFinance}
            src={backgroundFinanceUrl}
            siteColor={siteColor}
            currentTheme={currentTheme}
            templateCode={templateCode}
          />
        }
        <LayoutFooter
          backgroundMain={backgroundMain}
          dealerId={dealerId}
          footer={footer}
          socialMedia={socialMedia}
          siteColor={siteColor}
          templateCode={templateCode}
          saveEmailMessage={saveEmailMessage}
          currentTheme={currentTheme}
          isButtonSticky={true}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId:state.dealerId,
    locations:state.locationsPublic,
    templateContent:state.templateContent,
    carsInformation: state.carsInformation,
    makes:state.makes,
    models:state.models,
    templateCode:state.templateCode,
    locationId:state.locationId
  }
};

export default  connect(
  mapStateToProps,
  {searchInventoryPublic,loadLocationsPublic,loadMakeAndModel, selectedLocationId, saveEmailMessage}
)(LayoutFinanceContainer)