import React from 'react'
import PropTypes from 'prop-types'
import {cover} from './Constants'

export const DPPopover = ({children, handleRequestClose, anchorEl, classNameWrapper, styleCover, id, width, styleWrapper}) => {

  let left = 0
  let top = 0
  let offsetWidth = 0

  if (anchorEl) {
    top = anchorEl.y
    left = anchorEl.x
    offsetWidth = width ? width : anchorEl.offsetWidth
  }

  const popover = {
    zIndex: 200,
    left: left,
    top: top,
    backgroundColor: "#fff",
    transition: "0.8s",
    position: "absolute",
    width: offsetWidth,
    marginBottom: 0,
    ...styleWrapper
  };

  return (
    <div id={id} style={popover} className={classNameWrapper}>
      <div
        style={styleCover}
        onClick={(event)=> {
          event.stopPropagation();
          event.preventDefault();
          handleRequestClose && handleRequestClose(event)
        }}
        data-test="overlay-popover"
      />
      {children}
    </div>
  )
}

DPPopover.defaultProps = {
  handleRequestClose:  () => {},
  anchorEl: null,
  styleCover: cover,
  width: null,
  id:'popover'
}

DPPopover.propTypes = {
  classNameWrapper: PropTypes.string,
  anchorEl: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    offsetWidth: PropTypes.number
  }),
  handleRequestClose: PropTypes.func,
  styleCover: PropTypes.object,
  id: PropTypes.string,
  width: PropTypes.number
}