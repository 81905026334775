import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {EmptyFunc} from '../../common/Constants'

const StyledButton = styled.button`
    width: ${(props) => (props.width)};
    height: ${(props) => (props.heigth)};
    box-shadow: 0px 2px 3px #0000000D;
    border: 1px solid #D7DAE2;
    border-radius: 4px;
    padding:10px 15px;
  
`;

const DPButton = React.forwardRef(({children, ...props}, ref) => (
    <StyledButton {...props} ref={ref}>
        {children}
    </StyledButton>
));

DPButton.displayName = 'DPButton';

DPButton.defaultProps = {
    onClick: EmptyFunc,
    className: null,
    width: '100%',
    height: '100%',
    disabled: false
};

DPButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children:  PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    type: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    disabled: PropTypes.bool
};

export default DPButton;