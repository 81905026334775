import React from 'react'
import PropTypes from 'prop-types'

const NavigationItems = ({items, navigationBarText, className, clicked, isMobile}) => (
  items.map(navItem => (
    !navItem.hidePage &&
    <li key={navItem.id} id={`li-${navItem.id}`} className={className}>
      <a
        data-testid={'nav-link-' + navItem.id + (isMobile ? '-mobile' : '-desktop')}
        id={navItem.id}
        style={{color: navigationBarText ?? '#FFF'}}
        onClick={() => clicked(navItem)}
      >
        {navItem.displayValue}
      </a>
    </li>
  ))
)

NavigationItems.propTypes = {
  items: PropTypes.array,
  clicked: PropTypes.func,
  locations: PropTypes.object
}

export default NavigationItems