/**
 * Created by johnny on 09/01/16
 */
import React from 'react'
import {
  CAR_IMAGE_COMING_SOON,
  CURRENT_THEME,
  DARK_COLORS,
  DARK_THEME,
  DEFAULT_THEME_ID,
  EASY_NAVIGATION_B_TEMPLATE_CODE,
  EASY_NAVIGATION_C_TEMPLATE_CODE,
  EASY_NAVIGATION_TEMPLATE_CODE,
  GRID_VIEW,
  InputTypes,
  INSERT_VIN_HERE,
  LIGHT_COLORS,
  LIGHT_THEME,
  LIST_VIEW,
  MODERN_VIEW_2_TEMPLATE_CODE,
  MODERN_VIEW_3_TEMPLATE_CODE,
  MODERN_VIEW_TEMPLATE_CODE,
  PAGE_ABOUT_US_ID,
  PAGE_CATALOG_ID,
  PAGE_FINANCE_ID,
  PAGE_HOME_ID,
  PAGE_INVENTORY_ID,
  pathServer,
  SECTION_CONTACT_US_ID,
  SECTION_FINANCE_ID,
  SECTION_LOCATION_ID,
  SECTION_TESTIMONIAL_ID,
  TEMPLATE_5_NAME,
  TEMPLATE_CODE_EASY_NAVIGATION_C,
  TEMPLATE_CODE_MODERN_VIEW_3,
  TEMPLATE_MAIN_NAME,
  VALIDATION_REQUIRED
} from './Constants'
import get from 'lodash/get'
import _, {chunk} from 'lodash'
import {browserHistory} from 'react-router'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import DPCarListItem from './DPCarItems/DPCarListItem'
import {DPCarGridItemEasyNavigation} from './DPCarItems/DPCarGridItemEasyNavigation'

export function getMainImageURL(car) {
  //var defaultImgId = car.images && car.images.length > 0 && car.images[0].id;
  //var img = _.find(car.images,{id: car.mainImage || defaultImgId});
  const img = car.mainImage
  return img ? img : CAR_IMAGE_COMING_SOON
}

export function buildMessages(errors, success) {
  if ((!errors || errors.length === 0) && !success)
    return null

  if (success && success !== '') {
    return (
      <div data-testid='alert-success' className='alert alert-success'>
        {success.split(',').map((msg, index) => <div key={index}>{msg}</div>)}
      </div>
    )
  } else {
    return (
      <div data-testid='alert-danger' className='alert alert-danger'>
        {errors.join(', ')}
      </div>
    )
  }
}

export function getSiteColor(generalSetting) {
  const currentTheme = generalSetting[CURRENT_THEME] || DEFAULT_THEME_ID
  return (currentTheme === LIGHT_THEME) ? generalSetting[LIGHT_COLORS] : generalSetting[DARK_COLORS]
}

export const getPrimaryColor = (templateCode, primaryColor, currentTheme) => {
  let color = primaryColor
  if ((templateCode === TEMPLATE_CODE_EASY_NAVIGATION_C || templateCode === TEMPLATE_CODE_MODERN_VIEW_3) && currentTheme === DARK_THEME)
    color = '#FFF'
  return color
}

export function navigationMenuHandler(element, templateName, currentPage = null, templateCode = '') {
  const elementId = element.id
  let el = null
  let menuSelected = null

  const linkToPageDefault = get(element, ['linkToPage'], null)
  const link = get(element, ['link'], null)
  const type = get(element, ['link', 'type'], null)
  const linkToPage = get(element, ['link', 'linkToPage'], '')
  const linkToSection = get(element, ['link', 'linkToSection'], '')
  const linkToUrl = get(element, ['link', 'linkToUrl'], '')

  if (get(currentPage, ['id'], null) === PAGE_HOME_ID) {
    if (link) {
      el = document.getElementById(elementId + templateName)
    }
  }

  let pointTo = getPosition(el)
  let y = getScrollTop()
  let pointFrom = {
    x: 0,
    y: y
  }

  const getPath = () => {
    let path
    if (get(currentPage, ['id'], null) === PAGE_HOME_ID) {
      if (linkToPageDefault === PAGE_HOME_ID) {
        path = `/${PAGE_CATALOG_ID}`
      } else {
        path = `/${PAGE_CATALOG_ID}/${linkToPageDefault}`
      }
    } else {
      path = `/${PAGE_CATALOG_ID}/${elementId}`
    }

    return path
  }

  if (!link) {
    switch (elementId) {
      case PAGE_HOME_ID:
        browserHistory.push(`/${PAGE_CATALOG_ID}`)
        menuSelected = PAGE_HOME_ID
        break
      case PAGE_FINANCE_ID:
        browserHistory.push(getPath())
        menuSelected = PAGE_FINANCE_ID
        break
      case PAGE_INVENTORY_ID:
        browserHistory.push(getPath())
        menuSelected = PAGE_INVENTORY_ID
        break
      case PAGE_ABOUT_US_ID:
        browserHistory.push(getPath())
        menuSelected = PAGE_ABOUT_US_ID
        break
      case SECTION_LOCATION_ID:
        browserHistory.push(getPath())
        menuSelected = SECTION_LOCATION_ID
        break
      case SECTION_CONTACT_US_ID:
        browserHistory.push(getPath())
        menuSelected = SECTION_CONTACT_US_ID
        break
    }
  }

  switch (type) {
    case 'linkToPage':
      pointFrom.y = 0
      pointTo = pointFrom
      if (linkToPage === PAGE_HOME_ID) {
        browserHistory.push(`/${PAGE_CATALOG_ID}`)
        menuSelected = elementId
      }
      if (linkToPage === PAGE_FINANCE_ID) {
        browserHistory.push(`/${PAGE_CATALOG_ID}/${PAGE_FINANCE_ID}`)
        menuSelected = elementId
      }
      if (linkToPage === PAGE_INVENTORY_ID) {
        browserHistory.push(`/${PAGE_CATALOG_ID}/${PAGE_INVENTORY_ID}`)
        menuSelected = elementId
      }
      if (linkToPage === PAGE_ABOUT_US_ID) {
        browserHistory.push(`/${PAGE_CATALOG_ID}/${PAGE_ABOUT_US_ID}`)
        menuSelected = elementId
      }
      break
    case 'linkToSection':
      if (linkToSection === SECTION_FINANCE_ID) {
        el = document.getElementById(SECTION_FINANCE_ID + templateName)
        pointTo = getPosition(el)
        pointTo.y -= 30
        menuSelected = elementId
      }
      if (linkToSection === SECTION_LOCATION_ID) {
        el = document.getElementById(SECTION_LOCATION_ID + templateName)
        pointTo = getPosition(el)
        pointTo.y -= 30
        menuSelected = elementId
      }
      if (linkToSection === SECTION_CONTACT_US_ID) {
        const templateNameForContact = [MODERN_VIEW_2_TEMPLATE_CODE, EASY_NAVIGATION_TEMPLATE_CODE, EASY_NAVIGATION_B_TEMPLATE_CODE].includes(templateCode)
          ? TEMPLATE_MAIN_NAME
          : TEMPLATE_5_NAME

        el = document.getElementById(SECTION_CONTACT_US_ID + templateNameForContact)
        pointTo = getPosition(el)
        pointTo.y -= 50
        menuSelected = elementId
      }
      if (linkToSection === SECTION_TESTIMONIAL_ID) {
        if ([EASY_NAVIGATION_C_TEMPLATE_CODE, MODERN_VIEW_TEMPLATE_CODE, MODERN_VIEW_3_TEMPLATE_CODE].includes(templateCode)) {
          el = document.getElementById('containerTestimonial')
          pointTo = getPosition(el)
          menuSelected = elementId
        } else {
          const templateNameForContact = [MODERN_VIEW_2_TEMPLATE_CODE, EASY_NAVIGATION_TEMPLATE_CODE, EASY_NAVIGATION_B_TEMPLATE_CODE].includes(templateCode)
            ? TEMPLATE_MAIN_NAME
            : TEMPLATE_5_NAME

          el = document.getElementById(SECTION_CONTACT_US_ID + templateNameForContact)
          pointTo = getPosition(el)
          pointTo.y -= 50
          menuSelected = elementId
        }
      }
      break
    case 'linkToUrl': {
      if (validateUrl(linkToUrl)) {
        window.open(linkToUrl, '_blank')
      }
      menuSelected = elementId
      break
    }
  }

  scroll(pointFrom, pointTo, 600)
  let menu = window.document.getElementById('toggle-menu')

  if (menu)
    menu.style = 'display:none;'

  return menuSelected
}

export function scroll(pointFrom, pointTo, duration) {
  if (duration <= 0) return
  let difference = pointTo.y - pointFrom.y
  let perTick = Math.round(difference / duration * 10)
  setTimeout(function () {
    pointFrom.y = pointFrom.y + perTick
    window.scrollTo(pointTo.x, pointFrom.y)
    if (pointTo.y === pointFrom.y) {
      return
    } else {
      let dur = duration - 10
      scroll(pointFrom, pointTo, dur)
    }
  }, 10)
}

export function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
}

export function emailData(dealerId, clientEmail, message, name, phoneNumber) {
  return {
    message: {
      dealerId: dealerId,
      name: name ?? '',
      email: clientEmail.toLowerCase(),
      phoneNumber: phoneNumber ?? '',
      text: message,
      read: false,
      active: true
    },
    email: {
      to: clientEmail.toLowerCase(),
      from: '',
      subject: '',
      html: '',
      text: message,
      name: name
    }
  }
}

export function messageEmailWithSelectedCar(text, car) {
  if (car) {
    return `About <b>${car.year} ${car.makeName} ${car.modelName} - ${car.vin}</b><br> ${text}`
  }
  return text
}

export function getPosition(element) {
  let xPosition = 0
  let yPosition = 0

  while (element) {
    xPosition += (element.offsetLeft + element.clientLeft)
    yPosition += (element.offsetTop + element.clientTop)
    element = element.offsetParent
  }
  return {x: xPosition, y: yPosition}
}

export function getScrollTop() {
  if (typeof window.pageYOffset !== 'undefined') {
    //most browsers except IE before #9
    return window.pageYOffset
  } else {
    let B = document.body //IE 'quirks'
    let D = document.documentElement //IE with doctype
    D = (D.clientHeight) ? D : B
    return D.scrollTop
  }
}

export function scrollTo(pointFrom, pointTo, duration) {
  if (duration <= 0) return
  let difference = pointTo.y - pointFrom.y
  let perTick = Math.round(difference / duration * 10)
  setTimeout(function () {
    pointFrom.y = pointFrom.y + perTick
    window.scrollTo(pointTo.x, pointFrom.y)
    if (pointTo.y === pointFrom.y) {
      return
    } else {
      let dur = duration - 10
      scrollTo(pointFrom, pointTo, dur)
    }
  }, 10)
}


export function getHoverButton(buttonId, primaryColor, secondaryColor, buttonTextColor = '#FFFFFF') {
  const button = window.document.getElementById(buttonId)

  if (button) {
    const elButton = $(button)
    elButton.hover(function () {
      elButton.css('background-color', secondaryColor)
      elButton.css('border', `1px solid ${secondaryColor}`)
      elButton.css('color', buttonTextColor)
      elButton.css('font-weight', '600')
      elButton.css('font-size', '14px')
    }, function () {
      elButton.css('background-color', primaryColor)
      elButton.css('border', `1px solid ${primaryColor}`)
      elButton.css('color', buttonTextColor)
      elButton.css('font-weight', '600')
      elButton.css('font-size', '14px')
    })
  }
}

export function defaultString(str) {
  if (str === null || str === undefined)
    return ''
  return str
}

export function addZeros(num) {
  let number = Number(num)
  // Cast as number
  // If not a number, return 0
  if (isNaN(number) || number === 0) {
    return ''
  }
  // If there is no decimal, or the decimal is less than 2 digits, toFixed
  if (String(number).split('.').length < 2 || String(number).split('.')[1].length <= 2) {
    number = number.toFixed(2)
  }
  // Return the number
  return number
}

export function getAttributesByAttributeIds(attributes, attributeIds) {
  return filter(attributes, function (form) {
    return find(attributeIds, function (attributeId) {
      return form.id === attributeId
    })
  })
}

export function getAttributeJSON(attributes, values) {
  let item = {}
  attributes.forEach((attribute) => {
    if (attribute.id === attribute.name || (attribute.inputType !== InputTypes.SELECT && attribute.inputType !== InputTypes.RADIO_BUTTON)) {
      if (values)
        item[attribute.id] = values[attribute.id] || ''
      else
        item[attribute.id] = ''
    } else {
      if (values)
        item[attribute.id] = values[attribute.id] || []
      else
        item[attribute.id] = []
    }
  })
  return item
}

export function parseObjectForm(sections, values) {
  let objectForm = {}
  sections.forEach((section) => {
    let sectionCopy = JSON.parse(JSON.stringify(section))
    if (section.isArray) {
      sectionCopy.sectionSubs = []
      if (!values) {
        //let initialSectionSub = _.isArray(section.sectionSubs) ? section.sectionSubs[0] : section.sectionSubs;
        if (isArray(section.sectionSubs)) {
          section.sectionSubs.forEach(sectionSub => {
            if (!sectionSub.isHidden)
              sectionCopy.sectionSubs.push(sectionSub)
          })
        } else {
          sectionCopy.sectionSubs.push(section.sectionSubs)
        }
      } else {
        section.sectionSubs.forEach(sectionSub => {
          let attributeId = sectionSub.attributeIds[0]
          let valueFound = values[attributeId]
          if (valueFound)
            sectionCopy.sectionSubs.push(sectionSub)
        })
      }
    }
    objectForm[section.name] = sectionCopy
  })
  return objectForm
}

export function parseValues(attributes, sections, valuesFromDB) {
  let values = {}
  forEach(sections, function (section, index) {
    if (section.isArray) {
      values[section.name] = []
      if (!valuesFromDB) {
        section.sectionSubs.forEach(sectionSub => {
          if (!sectionSub.isHidden) {
            let attributesBySectionSub = getAttributesByAttributeIds(attributes, sectionSub.attributeIds)
            values[section.name].push(getAttributeJSON(attributesBySectionSub))
          }
        })
      } else {
        section.sectionSubs.forEach(sectionSub => {
          let attributesFound = getAttributesByAttributeIds(attributes, sectionSub.attributeIds)
          let attributeRequired = find(attributesFound, function (attribute) {
            return attribute.validation
          })
          let valueFound = valuesFromDB[attributeRequired.id]
          if (valueFound)
            values[section.name].push(getAttributeJSON(attributesFound, valuesFromDB))
        })
      }

    } else {
      let attributesBySection = getAttributesByAttributeIds(attributes, section.attributeIds)
      values[section.name] = getAttributeJSON(attributesBySection, valuesFromDB)
    }
  })
  return values
}

export function setErrors(attributes, section, errors) {
  for (let key in section) {
    if (section[key] === '' || (isArray(section[key]) && section[key].length === 0)) {
      let attributeFound = find(attributes, function (attribute) {
        return attribute.id === key
      })
      if (attributeFound.validation && attributeFound.validation.length > 0) {
        forEach(attributeFound.validation, function (validation) {
          if (validation.type === VALIDATION_REQUIRED) {
            errors[key] = []
            errors[key].push(validation.type)
          }
        })
      }
    }
  }
}

export function validate(errors) {
  let hasError = false
  for (let key in errors) {
    let errorBySection = errors[key]
    for (let sectionKey in errorBySection) {
      if (errorBySection[sectionKey])
        return true
    }
  }
  return hasError
}

export function debounce(f, interval) {
  let timer = null

  return (...args) => {
    clearTimeout(timer)
    return new Promise((resolve) => {
      timer = setTimeout(
        () => resolve(f(...args)),
        interval,
      )
    })
  }
}

export const getCarfaxHTML = (carfaxValue, vin, wrapperStyle = {}) => {
  return carfaxValue
    && carfaxValue !== ''
    && <div
      className={'carfax-content'}
      style={wrapperStyle}
      dangerouslySetInnerHTML={{
        __html: carfaxValue.toString().replace(INSERT_VIN_HERE, vin)
      }}
    />
}

export function getBaseTemplateCode(code) {
  const Templates = {
    [EASY_NAVIGATION_TEMPLATE_CODE]: TEMPLATE_MAIN_NAME,
    [EASY_NAVIGATION_B_TEMPLATE_CODE]: TEMPLATE_MAIN_NAME,
    [EASY_NAVIGATION_C_TEMPLATE_CODE]: TEMPLATE_MAIN_NAME,
    [MODERN_VIEW_TEMPLATE_CODE]: TEMPLATE_5_NAME,
    [MODERN_VIEW_2_TEMPLATE_CODE]: TEMPLATE_5_NAME,
    [MODERN_VIEW_3_TEMPLATE_CODE]: TEMPLATE_5_NAME
  }
  return Templates[code]
}

export const goToLink = (link) => {
  if (link) {
    const {type} = link
    if (type === 'linkToPage') {
      const route = link[type] !== PAGE_HOME_ID ? `/${link[type]}` : ''
      browserHistory.push(`/catalog${route}`)
    }
    if (type === 'linkToUrl') {
      window.open(link[type], '_blank')
    }
  }
}

export const getClassContainerEasyNavigation = (templateCode, classContainerDefault) => {
  if (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE)
    return `${classContainerDefault} ${classContainerDefault}-b`
  if (templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE)
    return `${classContainerDefault} ${classContainerDefault}-c`
  return classContainerDefault
}

export function getBackgroundEasyNavigation(templateCode, backgroundMain) {
  if (backgroundMain)
    return backgroundMain

  if (templateCode === EASY_NAVIGATION_TEMPLATE_CODE)
    return `${pathServer.PATH_IMG}mountains-nature.jpg`
  if (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE)
    return `${pathServer.PATH_IMG}snow-landscape.jpg`
  if (templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE)
    return `${pathServer.PATH_IMG}natural-forest.jpg`
}

export function getContainerClassModernView(templateCode, pageId) {
  if (templateCode === MODERN_VIEW_2_TEMPLATE_CODE)
    return `container-${pageId}-modern-view-2`
  if (templateCode === MODERN_VIEW_3_TEMPLATE_CODE)
    return `container-${pageId}-modern-view-3`
  if (templateCode === MODERN_VIEW_TEMPLATE_CODE)
    return `container-${pageId}-modern-view-1`
  return ''
}

export function getBackgroundModernView(templateCode, backgroundMain) {
  if (backgroundMain)
    return backgroundMain

  if (templateCode === MODERN_VIEW_TEMPLATE_CODE)
    return `${pathServer.PATH_IMG}desert_template5.jpg`
  if (templateCode === MODERN_VIEW_2_TEMPLATE_CODE)
    return `${pathServer.PATH_IMG}cloudy-landscape.jpg`
  if (templateCode === MODERN_VIEW_3_TEMPLATE_CODE)
    return `${pathServer.PATH_IMG}mountains-forest.jpg`
}

export const getServerUrl = () => {
  // return 'http://localhost:9000/'
  if (window.baseHref)
    return window.baseHref

  return ''
}

export const getPrimaryColorTheme = (templateCode, primaryColor, currentTheme) => {
  if (templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE && currentTheme === DARK_THEME)
    return '#FFF'
  return primaryColor
}

export const getCarByGroup = (cars, numberOfCarsByGroup, carImageHeight,
                              primaryColor, siteColor, information = null,
                              inventoryView = GRID_VIEW, carfaxValue = null, isAdminTemplate = false) => {
  const carGroups = chunk(cars, numberOfCarsByGroup)
  return carGroups.map((carGroup, index) => {
    const carsItem = carGroup.map((car, indexCar) => {
      return (inventoryView === LIST_VIEW ?
          <DPCarListItem
            key={car.id}
            car={car}
            indexCar={indexCar}
            information={information}
            siteColor={siteColor}
            carfaxValue={carfaxValue}
          /> :
          <div key={car.id} className={'col' + numberOfCarsByGroup} style={{textAlign: 'center'}}
               data-testid={`car-box-${car.id}`}>
            <DPCarGridItemEasyNavigation
              car={car}
              primaryColor={primaryColor}
              indexCar={indexCar}
              information={information}
              carImageHeight={carImageHeight}
              siteColor={siteColor}
              isAdminTemplate={isAdminTemplate}
              carfaxValue={carfaxValue}
            />
          </div>
      )
    })
    return (
      <div key={index} className='row-layout-car'>
        {carsItem}
      </div>
    )
  })
}

export const getViewportWidth = (element) => {
  if (document.querySelector(element))
    return document.querySelector(element).offsetWidth
  else
    return 999
}

export const compareIds = (reduxData, requestData) => {
  const reduxIds = reduxData?.map(att => att.id)
  const requestIds = requestData?.map(att => att.id)
  return [..._.difference(reduxIds ,requestIds ),..._.difference(requestIds ,reduxIds )].length !== 0
}

export const convertMakesToMap = (makes) => {
  return reduce(makes, (map, obj) => {
    map[obj.id] = obj.name;
    return map;
  }, {})
}

export const convertModelsToMap = (models) => {
  return reduce(models, (map, obj) => {
    map[`${obj.id}-${obj.makeId}`] = obj.name;
    return map;
  }, {});
}

export const carWithMakeAndModel = (car, makesById, modelsById) => {
  return {
    ...car,
    makeName: makesById[car.makeId] ?? '',
    modelName: modelsById[`${car.modelId}-${car.makeId}`] ?? ''
  }
}

export const carsWithMakeAndModel = (cars, makesById, modelsById) => {
  if (!makesById)
    return cars

  return map(cars, (car) => {
    return carWithMakeAndModel(car, makesById, modelsById)
  })
}

export const getButtonTextCapByTemplate = (text, templateCode) => {
  const baseTemplateCode = getBaseTemplateCode(templateCode)
  return baseTemplateCode === TEMPLATE_MAIN_NAME || templateCode === MODERN_VIEW_2_TEMPLATE_CODE ?
    (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase())
    :
    text.toUpperCase()
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const capitalize = (text) => {
  return `${text[0].toUpperCase()}${text.substring(1)}`
}