import React, {useEffect, useState} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader'
import {connect, useDispatch, useSelector} from 'react-redux'
import {
  PAGE_INVENTORY_ID,
  pathServer,
  PAGE_HOME_ID,
  SECTION_CONTACT_US_ID,
  TEMPLATE_5_NAME,
  LIGHT_THEME
} from '../../common/Constants'
import LayoutFooter from '../../common/template/LayoutFooter'
import {
  searchInventoryPublic,
  loadMakeAndModel,
  loadLocationsPublic,
  saveEmailMessage, loadCarByVinPublic, clearCarsByVinPublic, CLEAR_CARS_BY_VIN_DATA
} from '../actions'
import DPBackToTop from '../../common/DPBackToTop'
import {getSiteColor} from '../../common/Util'
import find from 'lodash/find'
import LayoutInventoryCarViewTemplateCode5
  from '../../common/template/LayoutInventoryCar/LayoutInventoryCarViewTemplateCode5'
import {browserHistory} from 'react-router'

const LayoutCarContainer = ({
                              searchInventoryPublic, loadMakeAndModel, loadLocationsPublic,
                              carsInformation, dealerId, dealerName, locations, makes,
                              templateCode, templateContent, params: {carVin}
                            }) => {

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const carByVinData = useSelector(state => state.carByVinData)


  useEffect(() => {
    if (!carsInformation) {
      searchInventoryPublic(dealerId, {})
      loadMakeAndModel()
      loadLocationsPublic()
    }

    if (carByVinData?.error) {
      browserHistory.push(`/catalog/inventory`)
      dispatch(clearCarsByVinPublic())
    } else if (!carByVinData || carVin !== carByVinData.car.vin) {
      setIsLoading(true)
      dispatch(loadCarByVinPublic(carVin, dealerId))
      setTimeout(() => {
        setIsLoading(false)
      },200)
    }
    window.scrollTo(0,0)
  }, [carByVinData, carVin])

  //variables------------------------------------------------
  if (isLoading || !carByVinData || carByVinData?.error || !carsInformation || !makes || !locations)
    return null

  const content = JSON.parse(templateContent)
  const {generalSetting, pages} = content
  const {
    socialMedia, footer, banner,
    site: {logo = pathServer.PATH_IMG + 'logo-template5.png', maxHeight: maxHeightLogo},
    currentTheme
  } = generalSetting
  const backgroundMain = banner.backgroundMain.url || pathServer.PATH_IMG + 'desert_template5.jpg'
  const inventoryPage = find(pages, page => page.id === PAGE_INVENTORY_ID)
  const homePage = find(pages, page => page.id === PAGE_HOME_ID)
  const {menu} = inventoryPage.header
  const sectionContactUs = find(homePage.sections, sec => sec.id === SECTION_CONTACT_US_ID)
  const siteColor = getSiteColor(generalSetting)

  const {car, image: carImages} = carByVinData
  const locationFound = find(locations, location => location.locationId === car.locationId)

  const goBack = () => {
    browserHistory.push(`/catalog/inventory`)
    dispatch({type: CLEAR_CARS_BY_VIN_DATA})
    searchInventoryPublic(dealerId)
  }

  //------------------------------------------------------
  return (
    <div
      id='inventoryCar'
      className={`layout-inventory-container-car-5 container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}
    >
      <LayoutHeader
        menu={menu}
        logo={logo}
        maxHeight={maxHeightLogo}
        menuSelected={'inventory'}
        locations={locations}
        siteColor={siteColor}
        templateCode={templateCode}
        locationId={locationFound && locationFound.locationId}
        selectLocationId={() => {
        }}
        baseTemplateCode={TEMPLATE_5_NAME}
        disableLocationsDropdown
      />
      <div id='bannerSeparator' className='banner-separator'/>
      {
        <LayoutInventoryCarViewTemplateCode5
          carImages={carImages}
          carWithAttributes={car}
          dealerId={dealerId}
          dealerName={dealerName}
          location={locationFound}
          logo={logo}
          sectionContactUs={sectionContactUs}
          siteColor={siteColor}
          currentTheme={currentTheme}
          templateCode={templateCode}
          pages={pages}
          goBack={goBack}
        />
      }
      <LayoutFooter
        backgroundMain={backgroundMain}
        dealerId={dealerId}
        footer={footer}
        socialMedia={socialMedia}
        siteColor={siteColor}
        templateCode={templateCode}
        saveEmailMessage={saveEmailMessage}
        isButtonSticky={true}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    templateContent: state.templateContent,
    locations: state.locationsPublic,
    carsInformation: state.carsInformation,
    makes: state.makes,
    templateCode: state.templateCode,
    models: state.models,
    dealerName: state.dealerName
  }
}

export default connect(
  mapStateToProps,
  {searchInventoryPublic, loadMakeAndModel, loadLocationsPublic, saveEmailMessage}
)(LayoutCarContainer)
