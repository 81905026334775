import React, {Component} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'
import classnames from 'classnames'

const Loading = ({className = ''}) => {
  return (
    <div data-testid='dp-loader'>
      <div className={classnames('backgroundLoader', className)}/>
      <div id='loader'/>
    </div>
  )
}

export default class Loader extends Component {

  componentDidMount() {
    this.renderingDiv = document.createElement('div')
    document.body.append(this.renderingDiv)
    render(<Loading className={this.props.className}/>, this.renderingDiv)
  }

  componentWillUnmount() {
    unmountComponentAtNode(this.renderingDiv)
    document.body.removeChild(this.renderingDiv)
  }

  render() {
    return null
  }

}