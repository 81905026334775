import React from 'react'
import {useSelector} from 'react-redux'
import {pathServer} from '../common/Constants'
import {useScreenSize} from '../common/hooks/useScreenSize'

const PublicPageNotSetup = () => {

	const { height } = useScreenSize()
	const subdomain = useSelector(state => state.subdomain)

	const goToLogin = () => {
		let domains = window.location.host.split(`${subdomain}.`)
		let domainUrl = domains[1]

		if (!domainUrl)
			domainUrl = domains[0]

		window.location.href = `//${domainUrl}/login`
	}

	return (
		<div className="container-general" style={{ height }}>
			<div className="header-page-setup"/>
			<div className="container-setup-not-complete">
				<div className="container-page-not-setup">
					<div className="title">
						<img
							className="dealer-logo-img"
							src={`${pathServer.PATH_IMG}godealergo.png`}
						/>
					</div>
					<div className="image-working">
						<img
							src={`${pathServer.PATH_IMG}page-on-build.png`}
						/>
					</div>
					<div className="under-title">
						<span className="under-title-1">A nice </span>
						<span className="under-title-2">dealer </span>
						<span className="under-title-3">is getting ready here!</span>
					</div>
					<div className="title-bottom">
						<span className="title-bottom-1">Are you the owner? </span>
						<span className="title-bottom-2">Please </span>
						<span className="title-bottom-3" onClick={goToLogin}>log in </span>
						<span className="title-bottom-4">to set up your dealer public page.</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PublicPageNotSetup