import React from 'react'
import isEmpty from 'lodash/isEmpty'
import {getCarfaxHTML} from '../Util'
import {WIDTH_VIEW_TABLET, TEXT_COLOR, carStatus2, WIDTH_VIEW_MOBILE} from '../Constants'

const DPCarItem = ({
                     indexCar,
                     information,
                     attributeCarStatus,
                     carImageHeight,
                     img,
                     trim,
                     car,
                     transmission,
                     miles,
                     viewMore,
                     siteColor,
                     carfaxValue
                   }) => {

  const greaterThan1200 = window.innerWidth > 1200
  const shorterThan768 = window.innerWidth <= WIDTH_VIEW_MOBILE
  const showTrim = greaterThan1200 ? true : isEmpty(transmission)
  const carDescription = ` ${shorterThan768 ? '' : car.year.toString()} ${car.makeName} ${car.modelName} ${trim}`

  const priceComponent = (car.suggestedPrice === 0)
    ? <label className='price'>Coming Soon</label>
    : <label>{`$${car.suggestedPrice}`}</label>


  return (
    <>
      <div
        className={(attributeCarStatus.attributeValue !== carStatus2.SOLD)
          ? 'container-car-image'
          : 'container-car-image-sold'
        }
        onClick={() => viewMore(car.vin)}
        style={carImageHeight
          ? {height: carImageHeight}
          : null
        }
      >
        <img id={'carImage' + indexCar} src={img}/>
      </div>
      <div className='car-item-info'>
        <div className='row-car-info'>
          <div className='car-item-title' style={{color: siteColor[TEXT_COLOR]}}>
            {carDescription}
          </div>
          {
            window.innerWidth >= WIDTH_VIEW_TABLET
            && <div className='car-item-price'>
              {
                (car.suggestedPrice === 0)
                  ? <label className='price'>Coming Soon</label>
                  : <label>{`$${car.suggestedPrice}`}</label>
              }
            </div>
          }
        </div>
        {(shorterThan768 && !information) &&
          <div className='car-item-price'>
            {priceComponent}
          </div>
        }
        <div className='row-car-info-btn'>
          <div className='row-car-info-tags'>
            {
              showTrim && trim &&
              <div className='btn-car-content'>
                <div className='btn-car-info' style={{color: siteColor[TEXT_COLOR]}}>
                  {trim}
                </div>
              </div>
            }
            {
              transmission &&
              <div className='btn-car-content'>
                <div className='btn-car-info' style={{color: siteColor[TEXT_COLOR]}}>
                  {transmission}
                </div>
              </div>
            }
            <div className='btn-car-content'>
              <div className='btn-car-info' style={{color: siteColor[TEXT_COLOR]}}>
                {miles + ' M'}
              </div>
            </div>
          </div>
          {
            window.innerWidth >= WIDTH_VIEW_TABLET
            && getCarfaxHTML(carfaxValue, car.vin, {color: siteColor[TEXT_COLOR]})
          }
        </div>
        {
          (information ? window.innerWidth < WIDTH_VIEW_TABLET : (window.innerWidth < WIDTH_VIEW_TABLET && !shorterThan768))
          && <div className='row-car-info-footer'>
            {getCarfaxHTML(carfaxValue, car.vin, {color: siteColor[TEXT_COLOR]})}
            <div className='car-item-price'>
              {priceComponent}
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default DPCarItem