import React, { useState, useEffect } from 'react'
import { findIndex, chunk } from 'lodash'
import {
  CAR_IMAGE_COMING_SOON,
  CAR_IMAGE_HEIGHT_PERCENT, carStatus2,
  PRIMARY_COLOR,
  WIDTH_VIEW_TABLET
} from '../../../Constants'
import { Carousel, CarouselItem } from 'react-bootstrap'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { getViewportWidth } from '../../../Util'

const PreviewImagesGroup = ({ activeIndex, primaryColor, handleSelect, previewImages }) => (
  <div id="carInvItems">
    {previewImages.map(image => (
      <div
        className="container-car-image-carousel"
        style={image.imageOrder === (activeIndex + 1) ? { borderRadius: 8, border: '2px solid ' + primaryColor } : null}
        key={image.id}
      >
        <img src={image.url} onClick={() => handleSelect(image.id)}/>
      </div>
    ))}
  </div>
)


const CarCarouselTemplateCodeMain = ({ images, siteColor, carStatus }) => {

  const { width } = useScreenSize()
  const [mainImageIndex, setMainImageIndex] = useState(0)
  const [previewImagesGroupIndex, setPreviewImagesGroupIndex] = useState(0)
  const [mainCarouselHeight, setMainCarouselHeight] = useState(0)
  const [previewPicturesQuantity, setPreviewPicturesQuantity] = useState(0)

  const primaryColor = siteColor[PRIMARY_COLOR]

  useEffect(() => {
    setMainCarouselHeight(getViewportWidth('#carCarouselOne') * CAR_IMAGE_HEIGHT_PERCENT)
    setPreviewPicturesQuantity(width > 1182 ? 5 : 4)
  }, [images, width])

  //Executed when clicking the preview images
  const handleSelectImageGroup = (selectedIndex) => {
    const index = findIndex(images, { 'id': selectedIndex })
    setMainImageIndex(index)
  }

  // Prev/Next for preview groups
  const handleSelectGroup = (selectedGroupIndex) => setPreviewImagesGroupIndex(selectedGroupIndex)

  // Prev/Next for main image
  const handleSelectMain = (selectedMainIndex) => {
    setMainImageIndex(selectedMainIndex)
    handleSelectGroup(Math.floor(selectedMainIndex / previewPicturesQuantity))
  }

  // Main image is a carousel 1 by 1 image
  const centerImages = images && images.length > 0 ?
    images.map(image => (
      <CarouselItem key={image.id}>
        <div className="container-car-image" style={{ height: mainCarouselHeight }}>
          {(carStatus) &&
            <div className="banner-sold-car">Vehicle SOLD</div>
          }
          <img className="car-image" src={image.url}/>
          {/*<div className='car-pagination'>*/}
          {/*  <span>{(mainImageIndex + 1) + '/' + images.length}</span>*/}
          {/*</div>*/}
        </div>
      </CarouselItem>
    )) :
    <CarouselItem key={0}>
      <div className="container-car-image" style={{ height: mainCarouselHeight }}>
        {(carStatus) &&
          <div className="banner-sold-car">Vehicle SOLD</div>
        }
        <img className="car-image" src={CAR_IMAGE_COMING_SOON} style={{ width: mainCarouselHeight + 'px' }}/>
      </div>
    </CarouselItem>

  // Preview images is a carousel of groups
  const previewImagesGroups = images && images.length > 0 ? (
    chunk(images, previewPicturesQuantity).map((imagesChunk, chunkIndex) => (
      <CarouselItem key={chunkIndex}>
        <PreviewImagesGroup
          previewImages={imagesChunk}
          handleSelect={handleSelectImageGroup}
          activeIndex={mainImageIndex}
          primaryColor={primaryColor}
        />
      </CarouselItem>
    ))
  ) : []

  const prevIconSmall = centerImages.length > previewPicturesQuantity &&
    <div className="semi-circle-carousel sc-left" style={{ borderColor: primaryColor }}>
      <i className="fa fa-angle-down" style={{ color: primaryColor }}/>
    </div>

  const nextIconSmall = centerImages.length > previewPicturesQuantity &&
    <div className="semi-circle-carousel sc-right" style={{ borderColor: primaryColor }}>
      <i className="fa fa-angle-up" style={{ color: primaryColor }}/>
    </div>

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div className="inv-car-img">
        {/*Carousel for desktop view*/}
        {width > WIDTH_VIEW_TABLET && previewImagesGroups.length > 0 &&
          <Carousel
            id="carCarouselTwoWebView"
            prevIcon={prevIconSmall}
            nextIcon={nextIconSmall}
            activeIndex={previewImagesGroupIndex}
            onSelect={handleSelectGroup}
            style={{ height: mainCarouselHeight }}
          >
            {previewImagesGroups}
          </Carousel>
        }
        {/*Main carousel*/}
        <Carousel
          id="carCarouselOne"
          activeIndex={mainImageIndex}
          slide={false}
          onSelect={handleSelectMain}
          controls={false}
          style={{ height: mainCarouselHeight }}>
          {centerImages}
        </Carousel>
        {/*Carousel for tablet and mobile view*/}
        {width <= WIDTH_VIEW_TABLET && previewImagesGroups.length > 0 &&
          <Carousel
            id="carCarouselTwo"
            prevIcon={prevIconSmall}
            nextIcon={nextIconSmall}
            activeIndex={previewImagesGroupIndex}
            onSelect={handleSelectGroup}
          >
            {previewImagesGroups}
          </Carousel>
        }
      </div>
    </div>
  )

}

export default CarCarouselTemplateCodeMain
