import React, { useState } from 'react'
// @ts-ignore
import DPIcon, { Icons } from '../../../admin/common/DPIcon'
// @ts-ignore
import { WIDTH_VIEW_TABLET, WIDTH_VIEW_MOBILE } from '../../Constants'
import './DPCarousel.scss'

interface DPCarouselProps {
  barSelectedPageColor: string;
  itemList: any[];
  ItemComponent: React.ComponentType<any>;
  numberOfItemsPerPage: number;
  numberOfItemsToShift: number;
  barType?: string;
  direction: string;
  iconArrow?: string;
  width: string;
  height: string;
  customisedArrowPosition?: string;
  arrowColor?: string;
  arrowContainerBackgroundColor?: string;
  arrowContainerBorderColor?: string;
}

export const HORIZONTAL_CAROUSEL = 'horizontal'
export const VERTICAL_CAROUSEL = 'vertical'
export const SEGMENTS_BAR = 'segments'
export const SLIDE_BAR = 'slide'
export const ARROWS_BOTTOM = 'arrowsBottom'
export const ARROWS_SIDES = 'arrowsSide'

const baseControlStyle = {
  display: 'flex',
  alignItems: 'center',
}

const baseStyle = {
  width: '100%',
  flexDirection: 'column' as const,
  display: 'flex',
  position: 'relative' as const,
  flexWrap: 'wrap' as const,
  overflow: 'hidden',
}

const baseStyleItem = {
  display: 'flex',
  position: 'absolute' as const,
  alignItems: 'center',
}

export const SliderSegments = ({
                                 totalPages,
                                 primaryColor,
                                 currentIndex,
                                 shifts
                               }: { totalPages: number, primaryColor: string, currentIndex: number, shifts: number }) => {
  const segments = (totalPages % shifts < shifts && totalPages % shifts > 0) ? (totalPages / shifts | 0) + 1 : (totalPages / shifts | 0)
  const segmentElements = Array.from({ length: segments }, (_, index) => (
    <div key={index} className="segment" style={index === currentIndex / shifts ? {
      backgroundColor: primaryColor
    } : {
      backgroundColor: '#809FB8'
    }}/>
  ))

  return <div className="segments-container">{segmentElements}</div>
}


export const SliderBar = ({
                            currentPage,
                            totalPages,
                            primaryColor,
                            heightCurrentBar,
                            heightTotalBar
                          }: { currentPage: number, totalPages: number, primaryColor: string, heightCurrentBar: number, heightTotalBar: number }) => {

  const widthBar = Math.round(((1 / totalPages) * 100) * 100) / 100
  const marginLeft = currentPage * widthBar

  return (
    <div className="slider-bar-total" style={{ height: heightTotalBar }}>
      <div className="slider-bar-current"
           style={{
             width: widthBar + '%',
             marginLeft: marginLeft + '%',
             backgroundColor: primaryColor,
             height: heightCurrentBar,
             bottom: (heightCurrentBar - heightTotalBar)
           }}/>
    </div>
  )
}

const DPCarousel = (props: DPCarouselProps) => {
  const {
    barSelectedPageColor,
    itemList,
    ItemComponent,
    numberOfItemsPerPage,
    numberOfItemsToShift,
    barType,
    direction,
    iconArrow = Icons.ARROW_NEXT_CAROUSEL,
    width,
    height,
    customisedArrowPosition = ARROWS_SIDES,
    arrowContainerBackgroundColor,
    arrowContainerBorderColor,
    arrowColor = '#809FB8',
  } = props


  const [currentIndex, setCurrentIndex] = useState(0)
  const totalItems = itemList.length

  const styleBaseCarousel = direction === HORIZONTAL_CAROUSEL
    ? baseStyle
    : {
      ...baseStyle,
      alignItems: 'center',
    }

  const genericStyleCarousel = {
    width: width,
    height: 'auto',
  }

  const directionCarousel: React.CSSProperties = {
    width: width,
    height: height,
    display: 'flex',
  }


  const controlStyle = direction === HORIZONTAL_CAROUSEL
    ? {
      ...baseControlStyle,
      width: width,
      height: 'auto',
      justifyContent: 'space-between',
    }
    : {
      ...baseControlStyle,
      flexDirection: 'column' as const,
      width: width,
    }


  const getStyleItem = (margin: string, width: string) => {
    if (direction === HORIZONTAL_CAROUSEL) {
      return {
        ...baseStyleItem,
        marginLeft: margin,
        width: width,
        height: height,
      }
    } else if (direction === VERTICAL_CAROUSEL) {
      return {
        ...baseStyleItem,
        marginTop: margin,
        width: width,
        height: 'auto',
      }
    }
  }


  const handleBack = (currentIndex: number, max: number, shifts: number) => {
    let newCurrentIndex = currentIndex
    newCurrentIndex -= shifts
    if (newCurrentIndex < 0) newCurrentIndex = max - (max % shifts)
    setCurrentIndex(newCurrentIndex)
  }

  const handleNext = (currentIndex: number, max: number, shifts: number) => {
    let newCurrentIndex = currentIndex
    newCurrentIndex += shifts
    if (newCurrentIndex >= max && newCurrentIndex - max > 0) newCurrentIndex = 0
    else if (newCurrentIndex >= max) newCurrentIndex = max - (max % shifts)
    setCurrentIndex(newCurrentIndex)
  }

  const getSelectedBar = (custom?: string) => {
    if (custom === SEGMENTS_BAR) {
      return (
        <SliderSegments
          totalPages={totalItems}
          primaryColor={barSelectedPageColor}
          currentIndex={currentIndex}
          shifts={numberOfItemsToShift}
        />
      )
    }
    if (custom === SLIDE_BAR) {
      return (
        <SliderBar
          currentPage={currentIndex}
          totalPages={totalItems}
          primaryColor={barSelectedPageColor}
          heightCurrentBar={12}
          heightTotalBar={3}
        />
      )
    }
    return null
  }

  const getCarouselItems = () => {
    const width = window.innerWidth < WIDTH_VIEW_MOBILE ? (100 / numberOfItemsPerPage) + '%' : (95 / numberOfItemsPerPage) + '%'

    return (
      <div className="display-elements-container" style={directionCarousel}>
        <div className="elements-container">
          <div className="elements-carousel"
               style={styleBaseCarousel}>
            {itemList.map((item, index) => {
              const margin = window.innerWidth > WIDTH_VIEW_TABLET ? (index - currentIndex) * 100 / numberOfItemsPerPage + (0.4 * (index - currentIndex)) + '%' :
                             window.innerWidth <= WIDTH_VIEW_TABLET && window.innerWidth >= WIDTH_VIEW_MOBILE ? (index - currentIndex) * 100 / numberOfItemsPerPage + (0.8 * (index - currentIndex)) + '%' :
                               (index - currentIndex) * 100 / numberOfItemsPerPage + (5 * (index - currentIndex)) + '%'
              return (
                <div key={index} id={`item-${index}`}
                     className={'item'}
                     style={getStyleItem(margin, width)}>
                  <ItemComponent item={item}/>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const ArrowBack = () => {
    return (
      <div className="control-back"
           style={{ backgroundColor: arrowContainerBackgroundColor, borderColor: arrowContainerBorderColor }}>
        <div className="arrow-back-container"
             style={direction === VERTICAL_CAROUSEL ? { transform: 'rotate(90deg)' } : {}}>
          <DPIcon className="arrow-back" icon={iconArrow} style={{ fill: arrowColor }} iconClasses={'icon-back'}
                  onClick={() => handleBack(currentIndex, totalItems - 1, numberOfItemsToShift)}/>
        </div>
      </div>
    )
  }

  const ArrowNext = () => {
    return (
      <div className="control-next"
           style={{ backgroundColor: arrowContainerBackgroundColor, borderColor: arrowContainerBorderColor }}>
        <div className="arrow-next-container"
             style={direction === VERTICAL_CAROUSEL ? { transform: 'rotate(90deg)' } : {}}>
          <DPIcon className="arrow-next" icon={iconArrow} style={{ fill: arrowColor }} iconClasses={'icon-next'}
                  onClick={() => handleNext(currentIndex, totalItems - 1, numberOfItemsToShift)}/>
        </div>
      </div>
    )
  }

  switch (customisedArrowPosition) {
    case ARROWS_BOTTOM:
      return (
        <div className="generic-carousel-container" style={genericStyleCarousel}>
          {direction === HORIZONTAL_CAROUSEL && getCarouselItems()}
          <div className="control-carousel-container"
               style={controlStyle}>
            <ArrowBack/>
            {direction === HORIZONTAL_CAROUSEL && (
              <div className="control-center">
                <div className="bar-progress">{getSelectedBar(barType)}</div>
              </div>
            )}
            {direction === VERTICAL_CAROUSEL && getCarouselItems()}
            <ArrowNext/>
          </div>
        </div>
      )
    case ARROWS_SIDES:
      return (
        <div className="generic-carousel-container" style={genericStyleCarousel}>
          {direction === HORIZONTAL_CAROUSEL && (
            <div className="control-carousel-container"
                 style={controlStyle}>
              <ArrowBack/>
                {getCarouselItems()}
              <ArrowNext/>
            </div>
          )}
        </div>
      )
    default:
      return null
  }
}

export default DPCarousel
