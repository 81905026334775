import React, {useEffect, useState} from 'react'
import assign from 'lodash/assign'
import {loadDealerMedia} from '../../catalog/Api'
import {CAR_IMAGE_HEIGHT_PERCENT, defaultDealerImages, SUCCESSFUL_CODE, TEMPLATE_5_NAME} from '../Constants'
import VideoPlayerComponent from '../UI/VideoPlayerComponent'
import ReactDOM from "react-dom";

const listStyles = [
  {
    styleContainer: 'carousel-content-0',
    styleImage: 'carousel-content-image-0',
    styleText: 'carousel-content-text-0'
  },
  {
    styleContainer: 'carousel-content-1',
    styleImage: 'carousel-content-image-1',
    styleText: 'carousel-content-text-1'
  },
  {
    styleContainer: 'carousel-content-2',
    styleImage: 'carousel-content-image-2',
    styleText: 'carousel-content-text-2'
  },
  {
    styleContainer: 'carousel-content-3',
    styleImage: 'carousel-content-image-3',
    styleText: 'carousel-content-text-3'
  },
  {
    styleContainer: 'carousel-content-4',
    styleImage: 'carousel-content-image-4',
    styleText: 'carousel-content-text-4'
  }
]

const HEIGHT_VIEW_TABLET_0 = 940
const HEIGHT_VIEW_TABLET_1 = 941

const getHeightCarousel = (widthLayoutContainer, carouselHeight, carouselH) => {
  let carouselHeightImage = 0
  if (widthLayoutContainer === HEIGHT_VIEW_TABLET_0) {
    carouselHeightImage = carouselHeight < carouselH ? carouselH : carouselHeight
  } else if (widthLayoutContainer === HEIGHT_VIEW_TABLET_1) {
    carouselHeightImage = carouselH
  }
  return carouselHeightImage
}

const getClassContent = (quantityMedia) => {
  let classContentNext = 'content-next'
  let classContentBack = 'content-back'
  if (quantityMedia < 3) {
    classContentNext = 'content-next-0'
    classContentBack = 'content-back-0'
  }

  return {classContentNext: classContentNext, classContentBack: classContentBack}
}

const LayoutDealerMedia = ({
                             dealerId,
                             primaryColor,
                             carouselHeight,
                             widthLayoutContainer,
                             buttonTextColor,
                             templateCode
                           }) => {
  const [currentCarousel, setCurrentCarousel] = useState(2)
  const [carouselH, setCarouselH] = useState(null)
  const [dealerMedias, setDealerMedias] = useState([])
  const [mediaSelected, setMediaSelected] = useState(null)
  const [videoUrl, setVideoUrl] = useState(null)
  const [indexVideoSelected, setIndexVideoSelected] = useState(null)


  const quantityMedia = dealerMedias.length
  const {classContentNext, classContentBack} = getClassContent(quantityMedia)

  useEffect(() => {
    const loadDealerMediaData = async () => {
      const response = await loadDealerMedia(dealerId)
      const {responseCode, responseMessage, data = {}} = response
      if (responseCode !== SUCCESSFUL_CODE) {
        console.error(responseMessage)
        return
      }

      if (templateCode === TEMPLATE_5_NAME) {
        let dealerMedias = data?.dealerMedias.length > 0 ? data?.dealerMedias : defaultDealerImages
        setDealerMedias(dealerMedias)
        setMediaSelected(dealerMedias[0])
      } else {
        let dealerMedias = (data?.dealerMedias.length > 0) ? data?.dealerMedias : defaultDealerImages
        if (dealerMedias && dealerMedias.length > 0) {
          if (listStyles.length === dealerMedias.length || dealerMedias.length > listStyles.length) {
            listStyles.forEach((style, index) => {
              dealerMedias[index].styleContainer = style.styleContainer
              dealerMedias[index].styleImage = style.styleImage
              dealerMedias[index].styleText = style.styleText
            })
          } else if (dealerMedias.length < listStyles.length) {
            if (dealerMedias.length === 1) {
              dealerMedias[0].styleContainer = listStyles[2].styleContainer
              dealerMedias[0].styleImage = listStyles[2].styleImage
              dealerMedias[0].styleText = listStyles[2].styleText
            } else {
              dealerMedias.forEach((dealerMedia, index) => {
                dealerMedia.styleContainer = listStyles[index].styleContainer
                dealerMedia.styleImage = listStyles[index].styleImage
                dealerMedia.styleText = listStyles[index].styleText
              })
            }
          }
          setDealerMedias(dealerMedias)
        }
      }
    }

    loadDealerMediaData().then()

    upDownHover('semiUpDPMedia', 'iconUpDPMedia', primaryColor, buttonTextColor)
    upDownHover('semiDownDPMedia', 'iconDownDPMedia', primaryColor, buttonTextColor)

    const carousel = window.document.getElementById('layoutDealerMedia')
    let carouselH = null
    if (carousel) {
      carouselH = carousel.offsetWidth * CAR_IMAGE_HEIGHT_PERCENT
    }
    setCarouselH(carouselH)

    return (() => {
        upDownHover('semiUpDPMedia', 'iconUpDPMedia', primaryColor, buttonTextColor)
        upDownHover('semiDownDPMedia', 'iconDownDPMedia', primaryColor, buttonTextColor)
      }
    )

  }, [])


  const upDownHover = (idSemi, idIcon, primaryColor, buttonTextColor) => {
    let semiUpDown = window.document.getElementById(idSemi)
    let elUpDown = $(semiUpDown)
    let icon
    elUpDown.hover(function () {
      elUpDown.css('background-color', primaryColor)
      icon = window.document.getElementById(idIcon)
      icon.style.color = buttonTextColor
    }, function () {
      elUpDown.css('background-color', primaryColor)
      icon = window.document.getElementById(idIcon)
      icon.style.color = buttonTextColor
    })
  }

  const slideUp = () => {

    const next = window.document.getElementById('nextDP')
    next.style.display = 'none'
    const back = window.document.getElementById('backDP')
    back.style.display = 'none'
    let dealerMediasOld = assign({}, dealerMedias)
    let styleContainerAux = dealerMediasOld[dealerMedias.length - 1].styleContainer
    let styleImageAux = dealerMediasOld[dealerMedias.length - 1].styleImage
    let styleTextAux = dealerMediasOld[dealerMedias.length - 1].styleText

    let i = dealerMedias.length - 1
    while (i > 0) {
      dealerMedias[i].styleContainer = dealerMediasOld[i - 1].styleContainer
      dealerMedias[i].styleImage = dealerMediasOld[i - 1].styleImage
      dealerMedias[i].styleText = dealerMediasOld[i - 1].styleText
      i--
    }

    dealerMedias[0].styleContainer = styleContainerAux
    dealerMedias[0].styleImage = styleImageAux
    dealerMedias[0].styleText = styleTextAux

    if (currentCarousel === dealerMedias.length - 1) {
      setCurrentCarousel(dealerMedias.length)
    } else {
      setCurrentCarousel(currentCarousel - 1)
    }

    setDealerMedias(dealerMedias)

    setTimeout(() => {
      next.style.display = 'flex'
      back.style.display = 'flex'
    }, 400)

  }

  const slideDown = () => {

    const next = window.document.getElementById('nextDP')
    next.style.display = 'none'
    const back = window.document.getElementById('backDP')
    back.style.display = 'none'
    let dealerMediasOld = assign({}, dealerMedias)
    let styleContainerAux = dealerMediasOld[0].styleContainer
    let styleImageAux = dealerMediasOld[0].styleImage
    let styleTextAux = dealerMediasOld[0].styleText

    let i = 0
    while (i < (dealerMedias.length - 1)) {
      dealerMedias[i].styleContainer = dealerMediasOld[i + 1].styleContainer
      dealerMedias[i].styleImage = dealerMediasOld[i + 1].styleImage
      dealerMedias[i].styleText = dealerMediasOld[i + 1].styleText
      i++
    }

    dealerMedias[dealerMedias.length - 1].styleContainer = styleContainerAux
    dealerMedias[dealerMedias.length - 1].styleImage = styleImageAux
    dealerMedias[dealerMedias.length - 1].styleText = styleTextAux

    if (currentCarousel === 0) {
      setCurrentCarousel(dealerMedias.length)

    } else {
      setCurrentCarousel(currentCarousel - 1)

    }

    setDealerMedias(dealerMedias)

    setTimeout(() => {
      next.style.display = 'flex'
      back.style.display = 'flex'
    }, 400)
  }

  const sliderLeft = (
    <div id="nextDP" className={`content-next-back ${classContentBack}`} onClick={slideUp}>
      <div id="semiDownDPMedia" className="semi-circle-back"
           style={{border: '1px solid ' + primaryColor, backgroundColor: primaryColor}}>
        <a>
          <i id="iconDownDPMedia" className="fa fa-chevron-right" style={{color: buttonTextColor}}/>
        </a>
      </div>
    </div>
  )

  const sliderRight = (
    <div id="backDP" className={`content-next-back ${classContentNext}`} onClick={slideDown}>
      <div id="semiUpDPMedia" className="semi-circle-next"
           style={{border: '1px solid ' + primaryColor, backgroundColor: primaryColor}}>
        <a>
          <i id="iconUpDPMedia" className="fa fa-chevron-right" style={{color: buttonTextColor}}/>
        </a>
      </div>
    </div>
  )

  const carouselHeightImage = getHeightCarousel(widthLayoutContainer, carouselHeight, carouselH)

  const onSelectMedia = (media, index) => {
    let indexVideo = null;
    let videoUrlToUpdate = null;
    if (media.mediaType === 'video') {
      indexVideo = index;
      const playerPreview = document.getElementsByClassName('react-player__preview')
      if (playerPreview && playerPreview[index]) {
        const backgroundImage = playerPreview[index].style.backgroundImage
        const split = backgroundImage.split('"')
        videoUrlToUpdate = split[1];
      }
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setMediaSelected(media)
      setVideoUrl(videoUrlToUpdate)
      setIndexVideoSelected(indexVideo)
    })
  }

  return (
    <div className="layout-dealer-carousel"
         style={templateCode === TEMPLATE_5_NAME ? null : (widthLayoutContainer === HEIGHT_VIEW_TABLET_0 || widthLayoutContainer === HEIGHT_VIEW_TABLET_1) ? {height: carouselHeightImage} : carouselHeight ? {height: carouselHeight} : {height: carouselH}}>
      {
        templateCode === TEMPLATE_5_NAME ?
          <>
            <div id="carImageSelected" className="car-image-selected">
              {
                mediaSelected ?
                  (mediaSelected.mediaType === 'image') ?
                    <img src={mediaSelected.url}/> :
                    <VideoPlayerComponent url={mediaSelected.url} autoPlay={false}/>
                  : null
              }
            </div>
            {
              dealerMedias.length > 0 ?
                <div id="carImageGallery" className="car-image-gallery">
                  {
                    dealerMedias.map((dealerMedia, index) => {
                      return (
                        <div key={index}
                             className={templateCode === TEMPLATE_5_NAME ? 'content-image-template-main' : 'content-image'}
                             onClick={() => onSelectMedia(dealerMedia, index)}>
                          <img id={'image' + index}
                               src={dealerMedia.mediaType === 'image' ? dealerMedia.url : videoUrl}/>
                        </div>
                      )
                    })
                  }
                </div> : null
            }
          </> :
          <>
            {
              (quantityMedia >= 3) ?
                <div className="layout-dealer-carousel" style={(widthLayoutContainer === HEIGHT_VIEW_TABLET_0 || widthLayoutContainer === HEIGHT_VIEW_TABLET_1) ? {height:carouselHeightImage} : carouselHeight ? {height:carouselHeight} : {height:carouselH}}>
                  {
                    dealerMedias.map((dealerMedia,index) => {
                      return(
                        ( dealerMedia.styleContainer && dealerMedia.styleContainer !== '') ?
                          <div key={index} className={dealerMedia.styleContainer} style={(widthLayoutContainer === HEIGHT_VIEW_TABLET_0 || widthLayoutContainer === HEIGHT_VIEW_TABLET_1) ? {height:carouselHeightImage} : carouselHeight ? {height:carouselHeight}:{height:carouselH}}>
                            <div id={dealerMedia.styleContainer} className="content-image">
                              {
                                dealerMedia.mediaType === 'image' ?
                                  <img id={'image' + index} src={dealerMedia.url}/> :
                                  <VideoPlayerComponent url={dealerMedia.url} autoPlay={false}/>
                              }
                            </div>
                          </div>: null
                      )
                    })
                  }
                  {sliderLeft}
                  {sliderRight}
                </div> :
                (quantityMedia > 0) ?
                  <div className="layout-dealer-carousel" style={(widthLayoutContainer === HEIGHT_VIEW_TABLET_0 || widthLayoutContainer === HEIGHT_VIEW_TABLET_1) ? {height:carouselHeightImage} : carouselHeight ? {height:carouselHeight} : {height:carouselH}}>
                    {
                      dealerMedias.map((dealerMedia,index) => {
                        const indexContainer = dealerMedia.styleContainer.substr(dealerMedia.styleContainer.length - 1, dealerMedia.styleContainer.length)
                        return(
                          dealerMedia.styleContainer !== '' ?
                            <div key={index} className={dealerMedia.styleContainer+(" dealer-media-only-"+indexContainer)} style={(widthLayoutContainer === HEIGHT_VIEW_TABLET_0 || widthLayoutContainer === HEIGHT_VIEW_TABLET_1) ? {height:carouselHeightImage} : carouselHeight ? {height:carouselHeight} : {height:carouselH}}>
                              <div id={dealerMedia.styleContainer} className="content-image">
                                {
                                  dealerMedia.mediaType === 'image' ?
                                    <img id={'image' + index} src={dealerMedia.url}/> :
                                    <VideoPlayerComponent url={dealerMedia.url} autoPlay={false}/>
                                }
                              </div>
                            </div>: null
                        )
                      })
                    }
                    {
                      dealerMedias.length > 1 && sliderLeft
                    }
                    {
                      dealerMedias.length > 1 && sliderRight
                    }
                  </div> :
                  <div className="layout-dealer-carousel" style={{padding:"0 25%"}}>
                    <div id="layoutDealerMedia"/>
                  </div>
            }
          </>
      }
    </div>
  )
}

export default LayoutDealerMedia
