import React from 'react'
import get from 'lodash/get'
import {
  BOX_BACKGROUND_COLOR,
  BOX_BORDER_COLOR,
  ATTRIBUTE_CODE_CAR_STATUS,
  TEXT_COLOR,
  WIDTH_VIEW_MOBILE,
  carStatus2,
  ATTRIBUTE_CODE_CAR_BODY,
  ATTRIBUTE_CODE_CAR_TITLE,
  ATTRIBUTE_CODE_CAR_DRIVE,
  ATTRIBUTE_CODE_CAR_FUEL,
  ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR,
  ATTRIBUTE_CODE_CAR_INTERIOR_COLOR
} from '../Constants'
import {browserHistory} from 'react-router'
import {getCarfaxHTML, getMainImageURL, getServerUrl} from '../Util'
import {ATTRIBUTE_CODE_CAR_TRIM} from "../../admin/Constants";

const TO_BE_DETERMINED = 'N/A'

const getCarColorText = (car) => {
  const carExteriorColor = get(car, [[ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR], 'attributeValue'], TO_BE_DETERMINED)
  const carInteriorColor = get(car, [[ATTRIBUTE_CODE_CAR_INTERIOR_COLOR], 'attributeValue'], TO_BE_DETERMINED)

  if (carExteriorColor === TO_BE_DETERMINED && carInteriorColor === TO_BE_DETERMINED)
    return TO_BE_DETERMINED

  const exteriorColorText = `${carExteriorColor !== TO_BE_DETERMINED ? "Ext. " : ''}${carExteriorColor}`
  const interiorColorText = `${carInteriorColor !== TO_BE_DETERMINED ? "Int. " : ''}${carInteriorColor}`

  return `${exteriorColorText} / ${interiorColorText}`
}

const MechanicalInfo = ({
                          carVIN,
                          carType,
                          carTitle,
                          carDriveTrain,
                          carFuel,
                          carColor,
                          phone,
                          boxBorderColor,
                          carfaxValue
                        }) =>
  (
    <>
      <div>
        <div className='main-mechanical-info-section'>
          <div className='sub-title'>VIN</div>
          <div className='sub-title-info'>{carVIN}</div>
          <div className='sub-title'>Car Type</div>
          <div className='sub-title-info'>{carType}</div>
          <div className='sub-title'>Title</div>
          <div className='sub-title-info'>{carTitle}</div>
        </div>
        <div className='additional-mechanical-info-section'>
          <div className='sub-title'>DriveTrain</div>
          <div className='sub-title-info'>{carDriveTrain}</div>
          <div className='sub-title'>Fuel</div>
          <div className='sub-title-info'>{carFuel}</div>
          <div className='sub-title'>Color</div>
          <div className='sub-title-info'>{carColor}</div>
        </div>
      </div>
      <div className='options-section' style={{borderColor: boxBorderColor}}>
        <div
          className='apply-option'
          style={{borderColor: boxBorderColor}}
          onClick={() => window.open(`${getServerUrl()}catalog/finance`, '_blank')}
        >
          Apply for financing
        </div>
        <div className='other-option' style={{borderColor: boxBorderColor}}>
          <a href={`tel:${phone}`}>Call</a>
        </div>
        <div className='other-option' style={{borderColor: boxBorderColor}}>
          <a href={`tel:${phone}`}>Text</a>
        </div>
        <div className='other-option' style={{borderColor: boxBorderColor, borderRight: 0}}>
          {getCarfaxHTML(carfaxValue, carVIN)}
        </div>
      </div>
    </>
  )

const DPCarListItem = (props) => {
  const {car, indexCar, information, siteColor, carfaxValue} = props
  const carVIN = get(car, ['vin'], TO_BE_DETERMINED)
  const carType = get(car, [[ATTRIBUTE_CODE_CAR_BODY], 'attributeValue'], TO_BE_DETERMINED)
  const carTitle = get(car, [[ATTRIBUTE_CODE_CAR_TITLE], 'attributeValue'], TO_BE_DETERMINED)
  const carDriveTrain = get(car, [[ATTRIBUTE_CODE_CAR_DRIVE], 'attributeValue'], TO_BE_DETERMINED)
  const carFuel = get(car, [[ATTRIBUTE_CODE_CAR_FUEL], 'attributeValue'], TO_BE_DETERMINED)
  const trim = get(car, [[ATTRIBUTE_CODE_CAR_TRIM], 'attributeValue'], '')
  const carColor = getCarColorText(car)
  const attributeCarStatus = car[ATTRIBUTE_CODE_CAR_STATUS]
  const img = getMainImageURL(car);
  const phone = get(information, ['content1'], '')
  const carDescription = `${car.year.toString()} ${car.makeName} ${car.modelName} ${trim}`
  const {
    [BOX_BORDER_COLOR]: boxBorderColor, [TEXT_COLOR]: textColor,
    [BOX_BACKGROUND_COLOR]: boxBackgroundColor
  } = siteColor

  const goCarDetail = () => {
    browserHistory.push(`/catalog/car/${carVIN}`)
  }

  return (
    <div key={car.id}
         className="list-view-item"
         style={{backgroundColor: boxBackgroundColor, color: textColor, borderColor: boxBorderColor}}>
      <>
        <div className={(attributeCarStatus.attributeValue !== carStatus2.SOLD) ?
          "container-car-image" : "container-car-image-sold"}
             onClick={goCarDetail}
             style={{backgroundColor: boxBackgroundColor, borderColor: boxBorderColor}}>
          <img
            id={'carImage' + indexCar}
            src={img}
            className='custom-image'
          />
        </div>
        <div className='detailed-info-container'>
          <div className='general-info-section'>
            <div className='car-description'>{carDescription}</div>
            <div className='car-price-mileage'>
              {
                (car.suggestedPrice === 0) ?
                  <label className='price-coming-soon'>Coming Soon</label> :
                  <label className='car-price'>{`$${car.suggestedPrice}`}</label>
              }
              <div className='car-mileage'>
                <label>{`${car.mileage.toLocaleString()} Miles`}</label>
              </div>
            </div>
          </div>
          {
            window.innerWidth > WIDTH_VIEW_MOBILE ?
              <MechanicalInfo
                carVIN={carVIN}
                carType={carType}
                carTitle={carTitle}
                carDriveTrain={carDriveTrain}
                carFuel={carFuel}
                carColor={carColor}
                phone={phone}
                boxBorderColor={boxBorderColor}
                carfaxValue={carfaxValue}/> : null
          }
        </div>
      </>
      {
        window.innerWidth <= WIDTH_VIEW_MOBILE ?
          <div className={'container-mechanical-info'}>
            {
              <MechanicalInfo
                carVIN={carVIN}
                carType={carType}
                carTitle={carTitle}
                carDriveTrain={carDriveTrain}
                carFuel={carFuel}
                carColor={carColor}
                phone={phone}
                boxBorderColor={boxBorderColor}
                carfaxValue={carfaxValue}/>
            }
          </div> : null
      }
    </div>
  )
}

export default DPCarListItem