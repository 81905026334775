import React from 'react'
import {BOX_BACKGROUND_COLOR, BUTTON_TEXT_COLOR, PRIMARY_COLOR, SECONDARY_COLOR} from './Constants'
import styled from 'styled-components'
import {ReactSVG} from 'react-svg'
import {Link} from 'react-router'

export const StyledReactSVG = styled(ReactSVG)`
  svg {
    fill: ${props => props.color} !important;
  }
 
  .a {
    fill: ${props => props.color} !important;
    //stroke: ${props => props.color} !important;
  }
`

export const StyledAnchor = styled.a`
  background-color: ${props => props.siteColor[PRIMARY_COLOR]} !important;
  border: 1px solid ${props => props.siteColor[PRIMARY_COLOR]} !important;
  color: ${props => props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
  ${props => props.smallFont ?
  `
  font-weight: 600 !important; 
  font-size: 14px !important;
  `
  : ''}
  
  ${props => !isMobileDevice() ? `&:hover {
    background-color: ${props.siteColor[SECONDARY_COLOR]} !important;
    border: 1px solid ${props.siteColor[SECONDARY_COLOR]} !important;
    color: ${props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
    text-decoration: none;
  }` : ''}
`

export const StyledButton = styled.button`
  background: ${({isOutline, siteColor}) => isOutline ? 'none' : siteColor[PRIMARY_COLOR]} !important;
  border: 1px solid ${props => props.siteColor[PRIMARY_COLOR]} !important;
  color: ${({isOutline, siteColor}) => isOutline ? siteColor[PRIMARY_COLOR] : siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
  font-weight: ${props => props.customWeight ? props.customWeight : 600} !important;
  font-size: ${props => props.adminSizeLetter ? '12px' : '14px'} !important;
  
  &:focus {
    border: 1px solid ${props => props.siteColor[PRIMARY_COLOR]} !important;
  }
  
  ${props => !isMobileDevice() ? `&:hover {
    background-color: ${props.siteColor[SECONDARY_COLOR]} !important;
    border: 1px solid ${props.siteColor[SECONDARY_COLOR]} !important;
    color: ${props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
    font-weight: ${props => props.customWeight ? props.customWeight : 600} !important;
    font-size: ${props => props.adminSizeLetter ? '12px' : '14px'} !important;
  }` : ''}
`

export const StyledInvertedButton = styled.button`
  background-color: ${({siteColor}) => siteColor[BOX_BACKGROUND_COLOR]} !important;
  border: 1px solid ${({siteColor, buttonSpecialStyles}) => siteColor[buttonSpecialStyles.border]} !important;
  color: ${props => props.siteColor[props.buttonSpecialStyles.color] || '#FFFFFF'} !important;
  font-weight: 600 !important;
  font-size: ${props => props.adminSizeLetter ? '12px' : '14px'} !important;
  
  &:focus {
    border: 1px solid ${props => props.siteColor[SECONDARY_COLOR]} !important;
  }
  
  ${props => !isMobileDevice() ? `&:hover {
    background-color: ${props.siteColor[PRIMARY_COLOR]} !important;
    border: 1px solid ${props.siteColor[PRIMARY_COLOR]} !important;
    color: ${props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
    font-weight: 600 !important;
    font-size: ${props => props.adminSizeLetter ? '12px' : '14px'} !important;
  }` : ''}
`

export const StyledLink = styled(Link)`
  background-color: ${props => props.siteColor[PRIMARY_COLOR]} !important;
  border: 1px solid ${props => props.siteColor[PRIMARY_COLOR]} !important;
  color: ${props => props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  
  &:hover {
    background-color: ${props => props.siteColor[SECONDARY_COLOR]} !important;
    border: 1px solid ${props => props.siteColor[SECONDARY_COLOR]} !important;
    color: ${props => props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
`

export const StyledDiv = styled.div`
  background-color: ${props => props.siteColor[PRIMARY_COLOR]} !important;
  border: 1px solid ${props => props.siteColor[PRIMARY_COLOR]} !important;
  color: ${props => props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  
  &:hover {
    background-color: ${props => props.siteColor[SECONDARY_COLOR]} !important;
    border: 1px solid ${props => props.siteColor[SECONDARY_COLOR]} !important;
    color: ${props => props.siteColor[BUTTON_TEXT_COLOR] || '#FFFFFF'} !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
`


export function isMobileDevice() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}