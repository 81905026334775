import React, {Component} from 'react'
import {BUTTON_TEXT_COLOR, PRIMARY_COLOR, SECONDARY_COLOR} from './Constants'
import {assign} from 'lodash'
import DPCustomerTestimonial from './DPCustomerTestimonial'

import {loadTestimonials} from '../catalog/actions'
import {connect} from 'react-redux'

let listStyles = [
  {
    styleContainer: 'carousel-content-0',
    styleImage: 'carousel-content-image-0',
    styleText: 'carousel-content-text-0'
  },
  {
    styleContainer: 'carousel-content-1',
    styleImage: 'carousel-content-image-1',
    styleText: 'carousel-content-text-1'
  },
  {
    styleContainer: 'carousel-content-2',
    styleImage: 'carousel-content-image-2',
    styleText: 'carousel-content-text-2'
  },
  {
    styleContainer: 'carousel-content-3',
    styleImage: 'carousel-content-image-3',
    styleText: 'carousel-content-text-3'
  },
  {
    styleContainer: 'carousel-content-4',
    styleImage: 'carousel-content-image-4',
    styleText: 'carousel-content-text-4'
  },
  {
    styleContainer: 'carousel-content-5',
    styleImage: 'carousel-content-image-5',
    styleText: 'carousel-content-text-5'
  }
]

function getNumbersOfLines(width, from) {
  const browser = window.navigator.userAgent.toLowerCase().indexOf('firefox')
  if (from === 'home') {
    if (width > 1260) {
      return (browser > -1) ? 8 : 7
    } else if (width < 1261 && width > 940) {
      return (browser > -1) ? 7 : 4
    } else if (width > 600 && width < 941) {
      return (browser > -1) ? 6 : 4
    } else if (width < 601) {
      return (browser > -1) ? 7 : 5
    }
  } else if (from === 'aboutUs') {
    if (width > 942) {
      return (browser > -1) ? 3 : 3
    } else if (width > 600 && width <= 942) {
      return (browser > -1) ? 4 : 4
    } else if (width > 400 && width <= 600) {
      return (browser > -1) ? 5 : 5
    } else if (width <= 400) {
      return (browser > -1) ? 4 : 5
    }
  }
}

class LayoutCustomerVerticalTestimonial extends Component {

  constructor() {
    super()
    this.state = {
      currentCarousel: 2,
      displayUpDown: true,
      width: window.innerWidth,
      showPopover: false,
      displayValue: null
    }
  }

  loadTestimonialsInitial = async () =>{
    if (!this.props.testimonials) {
      await this.props.loadTestimonials()
    }

    const {testimonials} = this.props
    testimonials?.forEach((testimonial) => {
      testimonial.styleContainer = ''
      testimonial.styleText = ''
      testimonial.styleImage = ''
      const lengthTestimonials = testimonials.length
      if (listStyles.length === lengthTestimonials || lengthTestimonials > listStyles.length) {
        listStyles.forEach((style, index) => {
          testimonials[index].styleContainer = style.styleContainer
          testimonials[index].styleImage = style.styleImage
          testimonials[index].styleText = style.styleText
        })
      }

      if (lengthTestimonials < listStyles.length && lengthTestimonials > 2) {
        testimonials.forEach((testimonial, index) => {
          testimonial.styleContainer = listStyles[index].styleContainer
          testimonial.styleImage = listStyles[index].styleImage
          testimonial.styleText = listStyles[index].styleText
        })
      }

      if (lengthTestimonials === 2) {
        testimonials.forEach((testimonial, index) => {
          testimonial.styleContainer = listStyles[index + 1].styleContainer
          testimonial.styleImage = listStyles[index + 1].styleImage
          testimonial.styleText = listStyles[index + 1].styleText
        })
      }

      if (lengthTestimonials === 1) {
        testimonials[0].styleContainer = listStyles[2].styleContainer
        testimonials[0].styleImage = listStyles[2].styleImage
        testimonials[0].styleText = listStyles[2].styleText
      }

    })
  }

  async componentDidMount() {
    await this.loadTestimonialsInitial()
    this.setLines()
    const {siteColor} = this.props
    const primaryColor = siteColor[PRIMARY_COLOR]
    const buttonTextColor = siteColor[BUTTON_TEXT_COLOR]
    const secondaryColor = siteColor[SECONDARY_COLOR]
    this.upDownHover('semiUpDP', 'iconUpDP', primaryColor, secondaryColor, buttonTextColor)
    this.upDownHover('semiDownDP', 'iconDownDP', primaryColor, secondaryColor, buttonTextColor)
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.getHeightCarousel()
    }, 1000)

  }

  componentDidUpdate() {
    const {siteColor} = this.props
    const primaryColor = siteColor[PRIMARY_COLOR]
    const buttonTextColor = siteColor[BUTTON_TEXT_COLOR] ?? 'white'
    const secondaryColor = siteColor[SECONDARY_COLOR]

    if (primaryColor) {
      this.upDownHover('semiUpDP', 'iconUpDP', primaryColor, secondaryColor, buttonTextColor)
      this.upDownHover('semiDownDP', 'iconDownDP', primaryColor, secondaryColor, buttonTextColor)
    }

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setLines()
    this.getHeightCarousel()
    this.setState({width: window.innerWidth})
  }

  setLines = () => {
    const {from} = this.props
    const numberOfLines = getNumbersOfLines(window.innerWidth, from)
    $('body').removeClass('stop-scrolling')
    this.setState({numberOfLines: numberOfLines, showPopover: false})
  }

  upDownHover = (idSemi, idIcon, primaryColor, secondaryColor, buttonTextColor) => {
    const semiUpDown = window.document.getElementById(idSemi)
    const elUpDown = $(semiUpDown)
    let icon
    elUpDown.hover(function () {
      elUpDown.css('background-color', primaryColor)
      icon = document.getElementById(idIcon)
      icon.style.color = buttonTextColor
    }, function () {
      elUpDown.css('background-color', primaryColor)
      icon = document.getElementById(idIcon)
      icon.style.color = buttonTextColor
    })
  }

  slideUp = () => {

    const {testimonials} = this.props

    const {currentCarousel} = this.state
    const next = window.document.getElementById('nextT')
    next.style.display = 'none'
    const back = window.document.getElementById('backT')
    back.style.display = 'none'
    const testimonialOld = assign({}, testimonials)
    const styleContainerAux = testimonialOld[testimonials.length - 1].styleContainer
    const styleImageAux = testimonialOld[testimonials.length - 1].styleImage
    const styleTextAux = testimonialOld[testimonials.length - 1].styleText

    let i = testimonials.length - 1
    while (i > 0) {
      testimonials[i].styleContainer = testimonialOld[i - 1].styleContainer
      testimonials[i].styleImage = testimonialOld[i - 1].styleImage
      testimonials[i].styleText = testimonialOld[i - 1].styleText
      i--
    }

    testimonials[0].styleContainer = styleContainerAux
    testimonials[0].styleImage = styleImageAux
    testimonials[0].styleText = styleTextAux

    let newCarouselData = currentCarousel
    if (currentCarousel === testimonials.length - 1) {
      newCarouselData = 0
    } else {
      newCarouselData = currentCarousel + 1
    }

    this.setState({currentCarousel: newCarouselData, displayUpDown: false})

    setTimeout(() => {
      next.style.display = 'flex'
      back.style.display = 'flex'
    }, 400)

  }

  slideDown = () => {

    const {testimonials} = this.props
    const {currentCarousel} = this.state
    const next = window.document.getElementById('nextT')
    next.style.display = 'none'
    const back = window.document.getElementById('backT')
    back.style.display = 'none'
    const testimonialOld = assign({}, testimonials)
    const styleContainerAux = testimonialOld[0].styleContainer
    const styleImageAux = testimonialOld[0].styleImage
    const styleTextAux = testimonialOld[0].styleText

    let i = 0
    while (i < (testimonials.length - 1)) {
      testimonials[i].styleContainer = testimonialOld[i + 1].styleContainer
      testimonials[i].styleImage = testimonialOld[i + 1].styleImage
      testimonials[i].styleText = testimonialOld[i + 1].styleText
      i++
    }

    testimonials[testimonials.length - 1].styleContainer = styleContainerAux
    testimonials[testimonials.length - 1].styleImage = styleImageAux
    testimonials[testimonials.length - 1].styleText = styleTextAux

    let newCarouselData = currentCarousel
    if (currentCarousel === 0) {
      newCarouselData = testimonials.length - 1
    } else {
      newCarouselData = currentCarousel - 1
    }

    this.setState({currentCarousel: newCarouselData})

    setTimeout(() => {
      next.style.display = 'flex'
      back.style.display = 'flex'
    }, 400)

  }

  handlePopover = (displayValue, event) => {
    const {from} = this.props
    const {width} = this.state
    event.preventDefault()
    let positionPopoverX = 50
    const offsetWidth = event.currentTarget.offsetWidth - 100
    let positionPopoverY = 210
    if (from === 'home') {
      if (width > 1250) {
        positionPopoverX = 100
        positionPopoverY = 510
      } else if (width > 1000) {
        positionPopoverX = '20%'
        positionPopoverY = 310
      } else {
        positionPopoverX = '21%'
        positionPopoverY = 310
      }
    }
    $('body').addClass('stop-scrolling')
    this.setState({
      showPopover: true, positionPopoverX: positionPopoverX, positionPopoverY: positionPopoverY,
      offsetWidth: offsetWidth, displayValue: displayValue
    })
  }

  handleRequestClose = () => {
    $('body').removeClass('stop-scrolling')
    this.setState({showPopover: false})
  }

  getHeightCarousel = () => {
    const carouselContainer = document.getElementById('carouselTestimonialsContainer')
    if (carouselContainer && window.innerWidth > 1250) {
      const {testimonials} = this.props
      const testimonialLength = testimonials?.length ?? 0
      let heightCarousel
      switch (testimonialLength) {
        case 1:
          heightCarousel = '502px'
          break
        case 2:
        case 3 :
          heightCarousel = '386px'
          break
        case 4:
          heightCarousel = '471px'
          break
        case 5:
          heightCarousel = '536px'
          break
        default:
          heightCarousel = '502px'
      }
      if (testimonialLength >= 6) {
        heightCarousel = '587px'
      }
      carouselContainer.style.height = heightCarousel
    }
  }

  render() {
    const {showPopover, positionPopoverX, positionPopoverY, offsetWidth, displayValue, width} = this.state
    const {testimonials, from, siteColor, sectionTestimonial} = this.props
    const primaryColor = siteColor[PRIMARY_COLOR]
    const buttonTextColor = siteColor[BUTTON_TEXT_COLOR] ?? 'white'

    if (!testimonials) {
      return null
    }

    let sliderLeft = (
      <div id='nextT' className='content-up-down'>
        <div id='semiUp' className='semi-circle-up'
             style={{border: '1px solid ' + primaryColor, backgroundColor: primaryColor}} onClick={this.slideUp}>
          <a>
            <i id='iconUpArrow' className='fa fa-chevron-up up' style={{color: buttonTextColor}}/>
          </a>
        </div>
      </div>
    )

    let sliderRight = (
      <div id='backT' className='content-up-down'>
        <div id='semiDown' className='semi-circle-down'
             style={{border: '1px solid ' + primaryColor, backgroundColor: primaryColor}} onClick={this.slideDown}>
          <a>
            <i id='iconDownArrow' className='fa fa-chevron-up down' style={{color: buttonTextColor}}/>
          </a>
        </div>
      </div>
    )

    if (from === 'aboutUs') {

      sliderLeft = (
        <div id='nextT' className='content-next-back content-back'>
          <div id='semiDownDP' className='semi-circle-back' onClick={this.slideUp}
               style={{border: '1px solid ' + primaryColor, backgroundColor: primaryColor}}>
            <a>
              <i id='iconDownDP' className='fa fa-chevron-right' style={{color: buttonTextColor}}/>
            </a>
          </div>
        </div>
      )

      sliderRight = (
        <div id='backT' className='content-next-back content-next'>
          <div id='semiUpDP' className='semi-circle-next'  onClick={this.slideDown}
               style={{border: '1px solid ' + primaryColor, backgroundColor: primaryColor}}>
            <a>
              <i id='iconUpDP' className='fa fa-chevron-right' style={{color: buttonTextColor}}/>
            </a>
          </div>
        </div>
      )
    }

    return (
      <DPCustomerTestimonial testimonials={testimonials}
                             handlePopover={this.handlePopover}
                             sliderLeft={sliderLeft}
                             sliderRight={sliderRight}
                             widthLayoutContainer={width}
                             showPopover={showPopover}
                             positionPopoverX={positionPopoverX}
                             positionPopoverY={positionPopoverY}
                             offsetWidth={offsetWidth}
                             displayValue={displayValue}
                             handleRequestClose={this.handleRequestClose}
                             siteColor={siteColor}
                             title={(sectionTestimonial && sectionTestimonial.title) ? sectionTestimonial.title : null}
      />
    )
  }

}

const mapStateToProps = state => ({
  testimonials: state.testimonials,
})

export default connect(
  mapStateToProps,
  {loadTestimonials}
)(LayoutCustomerVerticalTestimonial)