import React, {Component} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader'
import {connect} from 'react-redux'
import {
  loadLocationsPublic, searchInventoryPublic, loadMakeAndModel, selectedLocationId, saveEmailMessage
} from '../actions'
import LayoutInventory from '../../common/template/LayoutInventory'
import LayoutFinance from '../../common/template/LayoutFinance.jsx'
import LayoutFooter from '../../common/template/LayoutFooter'
import {
  LIGHT_THEME, TEMPLATE_5_NAME, PAGE_FINANCE_ID, PAGE_HOME_ID, PAGE_INVENTORY_ID, SECTION_FINANCE_ID,
  CARS_BY_PAGE_DEFAULT, LIGHT_COLORS, DARK_COLORS,
} from '../../common/Constants'
import {getSiteColor, getContainerClassModernView, getBackgroundModernView} from '../../common/Util'
import DPBackToTop from '../../common/DPBackToTop'
import get from 'lodash/get'
import find from 'lodash/find'

class LayoutVehiclesContainer extends Component{

  UNSAFE_componentWillMount(){
    const {dealerId,searchInventoryPublic,loadLocationsPublic,loadMakeAndModel,carsInformation} = this.props;
    if(!carsInformation){
      loadMakeAndModel();
      loadLocationsPublic();
      searchInventoryPublic(dealerId,{from: 0 , size : CARS_BY_PAGE_DEFAULT});
    }
  }

  render(){
    const {
      carsInformation,makes,locations,templateContent,dealerId, templateCode,
      locationId, selectedLocationId, saveEmailMessage,carfaxValue,models,searchInventoryPublic
    } = this.props;

    if(!carsInformation || !locations || !makes)
      return null;

    const content = JSON.parse(templateContent)
    const {generalSetting, pages} = content
    const {socialMedia, footer, site: {logo = '', maxHeight: maxHeightLogo}, banner, currentTheme} = generalSetting
    const backgroundUrl = getBackgroundModernView(templateCode, banner.backgroundMain.url)
    const financePage = pages.find(page => page.id === PAGE_FINANCE_ID)
    const inventoryPage = pages.find(page => page.id === PAGE_INVENTORY_ID)
    const homePage = pages.find(page => page.id === PAGE_HOME_ID)
    const {menu} = inventoryPage.header
    const sectionFinanceInventory = find(inventoryPage.sections, sec => sec.id === SECTION_FINANCE_ID) || {}
    const {showSection: showFinanceSection = true} = sectionFinanceInventory
    const sectionFinance = homePage.sections.find(sec => sec.id === SECTION_FINANCE_ID)
    const siteColor = getSiteColor(generalSetting)

    return (
      <div
        className={`layout-inventory-container ${getContainerClassModernView(templateCode, 'inventory')} container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}
      >
        <LayoutHeader
          menu={menu}
          logo={logo}
          maxHeight={maxHeightLogo}
          menuSelected="inventory"
          locations={locations}
          siteColor={siteColor}
          templateCode={templateCode}
          locationId={locationId}
          selectLocationId={selectedLocationId}
          baseTemplateCode={TEMPLATE_5_NAME}
        />
        <div id="bannerSeparator" className="banner-separator" />
        <LayoutInventory
          siteColor={siteColor}
          makes={makes.map(make => ({id: make.id, name: make.name, value: make.name}))}
          dealerId={dealerId}
          carsInformation={carsInformation}
          searchInventoryPublic={searchInventoryPublic}
          carfaxValue={carfaxValue}
          models={models}
          currentTheme={currentTheme}
          templateCode={templateCode}
          information={get(footer, ['information'], {})}
          locationId={locationId}
        />
        {
          showFinanceSection &&
          <LayoutFinance
            section={sectionFinance}
            siteColor={siteColor}
            templateCode={templateCode}
            currentTheme={currentTheme}
          />
        }
        <LayoutFooter
          backgroundMain={backgroundUrl}
          dealerId={dealerId}
          footer={footer}
          socialMedia={socialMedia}
          siteColor={siteColor}
          templateCode={templateCode}
          saveEmailMessage={saveEmailMessage}
          isButtonSticky={true}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId:state.dealerId,
    locations:state.locationsPublic,
    templateContent:state.templateContent,
    carsInformation: state.carsInformation,
    makes:state.makes,
    templateCode:state.templateCode,
    locationId:state.locationId,
    carfaxValue: state.carfaxValue,
    models: state.models
  }
};

export default connect(
  mapStateToProps,
  {loadLocationsPublic,searchInventoryPublic,loadMakeAndModel, selectedLocationId, saveEmailMessage}
)(LayoutVehiclesContainer)