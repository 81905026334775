import {
  searchInventoryPublicApi,
  loadMakeAndModelApi,
  loadLocationsPublicApi,
  saveMessage, loadPublishedTestimonials, loadCarByVinPublicApi, loadPublicFormByCodeApi, processFinanceFormApi
} from '../Api.js'
import {carsWithMakeAndModel, carWithMakeAndModel} from "../../common/Util";
import {SUCCESSFUL_CODE} from "../../common/Constants";

export const SEARCH_INVENTORY_PUBLIC_REQUEST = 'SEARCH_INVENTORY_PUBLIC_REQUEST'
export const SEARCH_INVENTORY_PUBLIC_SUCCESS = 'SEARCH_INVENTORY_PUBLIC_SUCCESS'
export const SEARCH_INVENTORY_PUBLIC_FAILURE = 'SEARCH_INVENTORY_PUBLIC_FAILURE'

export const SEARCH_INVENTORY_PUBLIC_FILTERS_SUCCESS = 'SEARCH_INVENTORY_PUBLIC_FILTERS_SUCCESS'

export const LOAD_MAKES_REQUEST = 'LOAD_MAKES_REQUEST'
export const LOAD_MAKES_SUCCESS = 'LOAD_MAKES_SUCCESS'
export const LOAD_MAKES_FAILURE = 'LOAD_MAKES_FAILURE'

export const PUBLIC_LOAD_LOCATIONS_REQUEST = 'PUBLIC_LOAD_LOCATIONS_REQUEST'
export const PUBLIC_LOAD_LOCATIONS_SUCCESS = 'PUBLIC_LOAD_LOCATIONS_SUCCESS'
export const PUBLIC_LOAD_LOCATIONS_FAILURE = 'PUBLIC_LOAD_LOCATIONS_FAILURE'

export const SET_PAGE_NUMBER_INVENTORY = 'SET_PAGE_NUMBER_INVENTORY'
export const SET_ITEMS_BY_PAGE_QUANTITY = 'SET_ITEMS_BY_PAGE_QUANTITY'
export const SELECTED_LOCATION_ID = 'SELECTED_LOCATION_ID'
export const TOGGLE_CAR_ADVANCED_SEARCH_MENU = 'TOGGLE_CAR_ADVANCED_SEARCH_MENU'

export const SET_INVENTORY_VIEW = 'SET_INVENTORY_VIEW'

export const LOAD_TESTIMONIALS_SUCCESS = 'LOAD_TESTIMONIALS_SUCCESS'
export const SAVE_MESSAGE_DATA_SUCCESS = 'SAVE_MESSAGE_DATA_SUCCESS'
export const SAVE_MESSAGE_DATA_FAILURE = 'SAVE_MESSAGE_DATA_FAILURE'
export const CLEAR_SAVED_MESSAGE_DATA = 'CLEAR_SAVED_MESSAGE_DATA'
export const GET_CARS_BY_VIN_SUCCESS = 'GET_CARS_BY_VIN_SUCCESS'
export const GET_CARS_BY_VIN_FAILURE = 'GET_CARS_BY_VIN_FAILURE'
export const CLEAR_CARS_BY_VIN_DATA = 'CLEAR_CARS_BY_VIN_DATA'
export const LOAD_FORM_BY_CODE_SUCCESS = 'LOAD_FORM_BY_CODE_SUCCESS'
export const PROCESS_FINANCE_FORM_SUCCESS = 'PROCESS_FINANCE_FORM_SUCCESS'
export const PROCESS_FINANCE_FORM_FAILURE = 'PROCESS_FINANCE_FORM_FAILURE'
export const CLEAR_FINANCE_FORM = 'CLEAR_FINANCE_FORM'

export const GET_COMPLETE_CAR_ATTRIBUTES = 'GET_COMPLETE_CAR_ATTRIBUTES'
export const GET_COMPLETE_CARS_ATTRIBUTES = 'GET_COMPLETE_CARS_ATTRIBUTES'
export const GET_COMPLETE_CARS_MAIN_ATTRIBUTES = 'GET_COMPLETE_CARS_MAIN_ATTRIBUTES'


function handleFetchRejection(dispatch, action, error) {
  dispatch({type: action, error: error})
  if (error.status === 401 || error.status === 400) { //not authorized request
    console.log('handling error')
  }
}

export function searchInventoryPublic(dealerId, body = null) {
  return (dispatch, getState) => {
    dispatch({type: SEARCH_INVENTORY_PUBLIC_REQUEST})

    let filters = body
    if (filters === null) {
      filters = getState().carsInformationFilters
    }

    return searchInventoryPublicApi(dealerId, filters).then((response) => {
      const {responseCode, responseMessage, data: dataInventory} = response
      if (responseCode === SUCCESSFUL_CODE){
        //const partitions = partition(cars,function(car) { return car[ATTRIBUTE_CODE_CAR_STATUS] && car[ATTRIBUTE_CODE_CAR_STATUS].attributeValue === carStatus.SOLD });
        //data.cars = concat(partitions[1],partitions[0]);
        const state = getState();
        const {makesById, modelsById} = state;
        const carsInformation = {...dataInventory};
        carsInformation.cars = carsWithMakeAndModel(carsInformation.cars, makesById, modelsById)
        dispatch({type: SEARCH_INVENTORY_PUBLIC_SUCCESS, payload: carsInformation})
        dispatch({type: SEARCH_INVENTORY_PUBLIC_FILTERS_SUCCESS, payload: filters})
        return dataInventory
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, SEARCH_INVENTORY_PUBLIC_FAILURE, ex)
      return ex
    });
  }
}

export function loadMakeAndModel() {
  return (dispatch) => {

    dispatch({type: LOAD_MAKES_REQUEST})

    return loadMakeAndModelApi().then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        dispatch({type: LOAD_MAKES_SUCCESS, payload: data})
        return data
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, LOAD_MAKES_FAILURE, ex)
      return ex
    });
  }
}

export let selectedLocationId = (locationID) => ({type: SELECTED_LOCATION_ID, payload: locationID})

export function loadLocationsPublic() {
  return (dispatch) => {

    dispatch({type: PUBLIC_LOAD_LOCATIONS_REQUEST})

    return loadLocationsPublicApi().then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        dispatch({type: PUBLIC_LOAD_LOCATIONS_SUCCESS, payload: data.locations})
        return data.locations
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, PUBLIC_LOAD_LOCATIONS_FAILURE, ex)
      return ex
    });
  }

}

export let setPageNumberInventory = (pageNumberInventory) => ({
  type: SET_PAGE_NUMBER_INVENTORY,
  payload: pageNumberInventory
})

export let setItemsByPageQuantity = (itemsByPageQuantity) => ({
  type: SET_ITEMS_BY_PAGE_QUANTITY,
  payload: itemsByPageQuantity
})

export const toggleCarAdvancedSearchMenu = () => ({type: TOGGLE_CAR_ADVANCED_SEARCH_MENU})

export const saveEmailMessage = (data) => {
  return async dispatch => {
    const {responseCode, responseMessage} = await saveMessage(data)
    if (responseCode !== SUCCESSFUL_CODE) {
      console.error(responseMessage)
    }
  }
}

export const setCurrentInventoryView = (view) => ({type: SET_INVENTORY_VIEW, payload: view})

export const loadTestimonials = () => {

  return (dispatch) => {

    dispatch({type: 'LOAD_TESTIMONIALS_REQUEST'})

    return loadPublishedTestimonials().then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        dispatch({type: LOAD_TESTIMONIALS_SUCCESS, payload: data.testimonials})
        return data
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, 'LOAD_TESTIMONIALS_FAILURE', ex)
      return ex
    });
  }
}

export const saveMessages = (data, time) => {
  return dispatch => {
    dispatch({type: 'SAVE_MESSAGE_DATA_REQUEST'})
    return saveMessage(data).then((response) => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        dispatch({type: SAVE_MESSAGE_DATA_SUCCESS, payload: data})

        setTimeout(() => {
          dispatch({type: CLEAR_SAVED_MESSAGE_DATA})
        }, time)
        return
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, SAVE_MESSAGE_DATA_FAILURE, ex)

      setTimeout(() => {
        dispatch({type: CLEAR_SAVED_MESSAGE_DATA})
      }, time)

      return ex
    });
  }
}

export const loadCarByVinPublic = (carVin, dealerId) => {
  return (dispatch, getState) => {
    dispatch({type: 'GET_CARS_BY_VIN_REQUEST'})
    return loadCarByVinPublicApi(carVin, dealerId).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        const state = getState();
        const {makesById, modelsById} = state;
        const carData = {...data}
        carData.car = carWithMakeAndModel(carData.car, makesById, modelsById)
        dispatch({type: GET_CARS_BY_VIN_SUCCESS, payload: carData})
        return
      }
        const ex = {status: responseCode, message: responseMessage}
        handleFetchRejection(dispatch, GET_CARS_BY_VIN_FAILURE, ex)
        return ex
    });
  }
}

export const clearCarsByVinPublic = () => ({type: CLEAR_CARS_BY_VIN_DATA})

export const loadPublicFormByCode = (code, status) => {
  return dispatch => {
    dispatch({type: 'LOAD_FORM_BY_CODE_REQUEST'})
    return loadPublicFormByCodeApi(code, status).then(({responseCode, responseMessage, data}) => {
      if (responseCode === SUCCESSFUL_CODE){
        dispatch({type: LOAD_FORM_BY_CODE_SUCCESS, payload: data})
      } else {
        const ex = {status: responseCode, message: responseMessage}
        handleFetchRejection(dispatch, 'LOAD_FORM_BY_CODE_FAILURE', ex)
        return ex
      }
    })
  }
}

export const processFinanceForm = (data) => {
  return dispatch => {
    dispatch({type: 'PROCESS_FINANCE_FORM_REQUEST'})
    return processFinanceFormApi(data).then(response => {
      const {responseCode, responseMessage, data} = response
      if (responseCode === SUCCESSFUL_CODE){
        dispatch({type: PROCESS_FINANCE_FORM_SUCCESS, payload: data})
        return
      }
      const ex = {status: responseCode, message: responseMessage}
      handleFetchRejection(dispatch, PROCESS_FINANCE_FORM_FAILURE, ex)
      return ex
    })
  }
}

export const clearFinanceForm = () => ({type: CLEAR_FINANCE_FORM})

export const getCompleteCarAttributes = (car, makes, models) => ({
  type: GET_COMPLETE_CAR_ATTRIBUTES,
  payload: {car, makes, models}
})

export const getCompleteCarsAttributes = (cars, makes, models) => ({
  type: GET_COMPLETE_CARS_ATTRIBUTES,
  payload: {cars, makes, models}
})

export const getCompleteCarsMainAttributes = (cars, makes, models) => ({
  type: GET_COMPLETE_CARS_MAIN_ATTRIBUTES,
  payload: {cars, makes, models}
})
