import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

const DPSocialMedia = props => {
  const {socialMedia} = props;

  const onSocialMedia = (url) => {
    window.open(url, "_blank")
  }

  return(
    <>
      {
        (socialMedia && (!isEmpty(socialMedia?.facebook?.trim()) || !isEmpty(socialMedia?.instagram?.trim()) || !isEmpty(socialMedia?.twitter?.trim()))) ?
          <div id="bannerRedSocial" className="banner-red-social" >
            {
              !isEmpty(socialMedia?.facebook?.trim()) ?
                <i className="fa fa-facebook-f" onClick={(event) => onSocialMedia(socialMedia?.facebook)} /> : null

            }
            {
              !isEmpty(socialMedia?.instagram?.trim()) ?
                <i className="fa fa-instagram" onClick={(event) => onSocialMedia(socialMedia?.instagram)} /> : null

            }
            {
              !isEmpty(socialMedia?.twitter?.trim()) ?
                <i className="fa fa-twitter" onClick={(event) => onSocialMedia(socialMedia?.twitter)} /> : null

            }
          </div> : null
      }
    </>
  )
};

DPSocialMedia.propTypes = {
  socialMedia: PropTypes.object
}

export default DPSocialMedia;