import React from 'react'
import {ReactSVG} from 'react-svg'
import {
  BOX_BACKGROUND_COLOR, CONTROL_BORDER_COLOR,
  pathServer,
  SITE_BACKGROUND_COLOR,
  TEMPLATE_5_NAME, TEXT_COLOR
} from '../../Constants'
import DPTextInput from '../../genericComponents/DPTextInput'

export const SimpleCarSearch = ({
                                  isAdmin,
                                  setFilterValues,
                                  filterValues,
                                  simpleSearch,
                                  baseTemplate,
                                  siteColor
                                }) => {

  const isTemplate5 = baseTemplate === TEMPLATE_5_NAME

  const {
    [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [SITE_BACKGROUND_COLOR]: siteBackgroundColor,
    [TEXT_COLOR]: textSiteColor,
    [CONTROL_BORDER_COLOR]: controlBorderColor
  } = siteColor

  const {keyword} = filterValues

  const onTextChange = (event) => {
    if (isAdmin)
      return

    const keyword = event.target.value
    setFilterValues({...filterValues, keyword})
  }

  const handlePressEnter = (evt) => {
    if (isAdmin)
      return

    if (evt.key === 'Enter') {
      simpleSearch(evt.target.value, evt)
    }
  }

  const iconSearch = (
    <ReactSVG
      src={pathServer.PATH_IMG + 'icon/ic_search.svg'}
      beforeInjection={svg => {
        svg.classList.add('icon-search')
        svg.setAttribute('style',
          `fill: ${isTemplate5 ? textSiteColor : ''}; 
                      ${isAdmin ? 'padding: 0' : ''} `
        )
      }}
    />
  )

  return (
    <div className='col-keyboard'>
      <DPTextInput
        inputId='keyboard'
        inputClassName={isTemplate5 ? 'form-control' : 'form-control finance-input'}
        placeholder={isTemplate5 ? 'SEARCH' : 'KEYWORD'}
        onChangeText={(e) => onTextChange(e)}
        onKeyPress={handlePressEnter}
        value={keyword || ''}
        disabled={isAdmin}
        style={{
          backgroundColor: `${isTemplate5 ? boxBackgroundColor : siteBackgroundColor}`,
          border: `1px solid  ${isTemplate5 ? boxBackgroundColor : controlBorderColor}`,
          boxShadow: 'none',
          color: `${isTemplate5 ? '' : textSiteColor}`,
        }}
        leftElement={!isTemplate5 ? iconSearch : null}
        rightElement={isTemplate5 ? iconSearch : null}
        simpleInputClassname={true}
      />
    </div>
  )
}