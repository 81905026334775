import React, {useState} from 'react'
import ReactPlayerModal from './ReactPlayerModal'

const VideoPlayerComponent = ({url, autoPlay}) => {
  const [showModal, setShowModal] = useState(false)

  const onShow = () => {
    setShowModal(true)
  }

  const onClose = () => {
    setShowModal(false)
  }

  return (
    <div className={'video-player-component'}>
      <img src={url} className="current-image" onClick={onShow}/>
      <ReactPlayerModal showModal={showModal} url={url} playing={autoPlay} onClose={onClose}/>
    </div>
  )

}

export default VideoPlayerComponent