import React, {Component} from 'react'
import {
  pathServer,
  WIDTH_VIEW_MOBILE,
  optionSocialNetwork,
  SITE_BACKGROUND_COLOR,
  TEXT_COLOR,
  PRIMARY_COLOR, LIGHT_THEME
} from './Constants'
import {ReactSVG} from 'react-svg'
import isEmpty from 'lodash/isEmpty'
import {BOX_BACKGROUND_COLOR} from './Constants'
import {SliderBar} from './genericComponents/DPCarousel/index'
import {loadTestimonials} from '../catalog/actions'
import {connect} from 'react-redux'

function getNumbersOfLines(width, offsetHeight) {
  //const browser = navigator.userAgent.toLowerCase().indexOf('firefox');
  if (width > WIDTH_VIEW_MOBILE) {
    if (offsetHeight >= 190) {
      return 6
    } else if (offsetHeight < 190 && offsetHeight > 150) {
      return 5
    } else if (offsetHeight <= 150 && offsetHeight > 130) {
      return 4
    } else if (offsetHeight < 130) {
      return 3
    }
  } else {
    if (offsetHeight >= 220) {
      return 4
    } else {
      return 3
    }
  }
}


export class LayoutCustomerHorizontalTestimonials extends Component {

  constructor() {
    super()
    this.handleSlideBack = this.handleSlideBack.bind(this)
    this.handleSlideNext = this.handleSlideNext.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.setLines = this.setLines.bind(this)
    this.state = {
      currentStep: 0,
      currentIndex: 0,

      width: window.innerWidth
    }
  }

   componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillMount() {

    if (!this.props.testimonials) {
       this.props.loadTestimonials()
    }

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate() {
    const {numberOfLines} = this.state
    const {testimonials} = this.props
    testimonials?.forEach((testimonial, index) => {
      let module = document.getElementById("testimonial5Content" + index)
      if (typeof window.$clamp === 'function')
        window.$clamp(module, {clamp: numberOfLines})
    });
  }

  handleResize(e) {
    this.setLines()
  }

  setLines() {
    //const {from} = this.props;
    const colInfo = document.getElementById('colInfo0')
    if (colInfo) {
      const numberOfLines = getNumbersOfLines(window.innerWidth, colInfo.offsetHeight)
      this.setState({numberOfLines: numberOfLines})
    }
  }

  handleSlideBack(currentIndex, max) {
    currentIndex -= 1;
    if (currentIndex < 0)
      currentIndex = max - 1;
    this.setState({currentIndex: currentIndex})
  }

  handleSlideNext(currentIndex, max) {
    currentIndex += 1;
    if (currentIndex >= max)
      currentIndex = 0;
    this.setState({currentIndex: currentIndex})
  }

  render() {
    const {hideTitle, heightCurrentBar, heightTotalBar, title, siteColor, currentTheme, testimonials} = this.props;
    const {currentIndex} = this.state;
    const totalPages = testimonials?.length - 2
    return (
      <div>
        { testimonials &&
          testimonials.length > 0 ?
            <div id="containerTestimonial" className={`container-testimonial container-testimonial-general`}
                 style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}
            >
              {
                !hideTitle ?
                  <div className="separator-testimonial"
                       style={{backgroundColor: siteColor[BOX_BACKGROUND_COLOR]}}/> : null
              }
              {
                !hideTitle ?
                  <div id="testimonialHeader" className="testimonial-header">
                    <h1 style={{color: siteColor[TEXT_COLOR]}}>{title ? title : "WHAT OUR CUSTOMER SAY"}</h1>
                  </div> : null
              }
              <div className="body-customer-section">
                <div className="section-left">
                  {
                    testimonials.length > 1 ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/keyboard_arrow_left.svg"}
                                onClick={() => this.handleSlideBack(currentIndex, totalPages)}
                                beforeInjection={svg => {
                                  svg.classList.add('testimonial-icon');
                                  svg.setAttribute('style', `fill: ${siteColor[PRIMARY_COLOR]}`);
                                }} className="slide-left"/> : null
                  }
                </div>
                <div className="container-testimonial" style={{backgroundColor: siteColor[SITE_BACKGROUND_COLOR]}}>
                  <div className="carousel-testimonial">
                    {
                      testimonials.map((testimonial, index) => {
                        const marginLeft = window.innerWidth <= WIDTH_VIEW_MOBILE ? (index - currentIndex) * 100 + 9 + "%" : (index - currentIndex) * 100 / 3 + 1.7 + "%";
                        const socialNetwork = optionSocialNetwork.find(x => x.code === testimonial.socialNetwork);
                        return (
                          <div key={index} className={"section-testimonial" + (index === currentIndex ? " active" : "")}
                               style={{marginLeft: marginLeft, background: siteColor[BOX_BACKGROUND_COLOR]}}>
                            <div className="row-customer" style={{boxShadow: `0px 4px ${siteColor[BOX_BACKGROUND_COLOR]}`}}>
                              <div className="quotation-marks">
                                “
                              </div>
                              <div id={"testimonial5Content" + index} className="testimonial-content-5"
                                   style={{color: siteColor[TEXT_COLOR]}}>
                                {testimonial.content}
                              </div>
                              <div id={`colInfo${index}`} className="col-info">
                                <div className="col-image">
                                  <div className="box-image">
                                    <div className="content-image">
                                      <img
                                        src={(testimonial.userPicture !== "") ? testimonial.userPicture : `${pathServer.PATH_IMG}ic_account_circle.png`}
                                        onError={(e)=>{e.target.src = `${pathServer.PATH_IMG}ic_account_circle.png`}}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="container-label-name">
                                    <label className="label-name" style={{color: siteColor[TEXT_COLOR]}}>
                                      {testimonial.userName ? testimonial.userName : "User name unavailable"}
                                    </label>
                                  </div>
                                  {
                                    !isEmpty(socialNetwork) ?
                                      <div className="container-label-social-network">
                                        <label className="label-social">
                                          {`Testimonial from ${socialNetwork.value}`}
                                        </label>
                                      </div> : null
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="section-right">
                  {
                    testimonials.length > 1 ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/keyboard_arrow_right.svg"}
                                onClick={() => this.handleSlideNext(currentIndex, totalPages)}
                                beforeInjection={svg => {
                                  svg.classList.add('testimonial-icon');
                                  svg.setAttribute('style', `fill: ${siteColor[PRIMARY_COLOR]}`);
                                }} className="slide-right"/> : null
                  }
                </div>
              </div>
              {
                testimonials.length > 1 ?
                  <div className="container-bar-progress">
                    {
                      window.innerWidth <= WIDTH_VIEW_MOBILE ?
                        <div className="section-left"
                             style={{backgroundColor: siteColor[currentTheme === LIGHT_THEME ? BOX_BACKGROUND_COLOR : SITE_BACKGROUND_COLOR]}}>
                          {
                            testimonials.length > 1 ?
                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                        onClick={() => this.handleSlideBack(currentIndex, testimonials.length)}
                                        beforeInjection={svg => {
                                          svg.classList.add('testimonial-icon');
                                          svg.setAttribute('style', `fill: ${siteColor[PRIMARY_COLOR]}`);
                                        }} className="slide-left"/> : null
                          }
                        </div> : null
                    }
                    <div className="bar-progress">
                      <SliderBar currentPage={currentIndex} totalPages={totalPages}
                                 primaryColor={siteColor[PRIMARY_COLOR]} heightCurrentBar={heightCurrentBar}
                                 heightTotalBar={heightTotalBar}/>
                    </div>
                    {
                      window.innerWidth <= WIDTH_VIEW_MOBILE ?
                        <div className="section-right"
                             style={{backgroundColor: siteColor[currentTheme === LIGHT_THEME ? BOX_BACKGROUND_COLOR : SITE_BACKGROUND_COLOR]}}>
                          {
                            testimonials.length > 1 ?
                              <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                        onClick={() => this.handleSlideNext(currentIndex, testimonials.length)}
                                        beforeInjection={svg => {
                                          svg.classList.add('testimonial-icon');
                                          svg.setAttribute('style', `fill: ${siteColor[PRIMARY_COLOR]}`);
                                        }} className="slide-right"/> : null
                          }
                        </div> : null
                    }
                  </div>
                  : null
              }
            </div> : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  testimonials: state.testimonials
})

export default connect(
  mapStateToProps,
  {loadTestimonials}
)(LayoutCustomerHorizontalTestimonials)
