import React, {useEffect, useState} from 'react'
import {
  BOX_BACKGROUND_COLOR,
  BOX_BORDER_COLOR, carStatus2, MODERN_VIEW_2_TEMPLATE_CODE,
  PRIMARY_COLOR,
  SITE_BACKGROUND_COLOR,
} from '../../Constants'
import Loader from '../../UI/Loader'
import LayoutGetInTouch from '../LayoutGetInTouch'
import {createPDFFile} from '../../../catalog/Utils'
import CarInformationTemplateCode5 from './Template5Components/CarInformationTemplateCode5'
import {useScreenSize} from '../../hooks/useScreenSize'
import CarCarouselTemplateCode5 from './Template5Components/CarCarouselTemplateCode5'
import {get} from "lodash";


const LayoutInventoryCarViewTemplateCode5 = ({
                                               carImages, carWithAttributes, dealerId, dealerName,
                                               location, logo, sectionContactUs, siteColor,
                                               currentTheme,templateCode,pages, goBack = null
                                             }) => {

  const {
    [PRIMARY_COLOR]: primaryColor, [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [BOX_BORDER_COLOR]: boxBorderColor, [SITE_BACKGROUND_COLOR]: siteBackgroundColor
  } = siteColor

  const {width} = useScreenSize()

  const [tabSelected, setTabSelected] = useState('description')
  const [isLoading, setIsLoading] = useState(false)
  const [showGetInTouchOnCarousel, setShowGetInTouchOnCarousel] = useState(true)

  const handleTabChange = (evt) => setTabSelected(evt.target.id)

  const printCarInformation = (evt) => {
    evt.stopPropagation()
    const closeSpinner = () => setIsLoading(false)
    setIsLoading(true)
    createPDFFile(carWithAttributes, primaryColor, location, closeSpinner, carImages, {dealerName, logo})
  }

  useEffect(() => {
    setShowGetInTouchOnCarousel(width >= 753)
  }, [carWithAttributes, carImages, width])

  if (!carWithAttributes)
    return null

  const carStatus = get(carWithAttributes, ['car.status', 'attributeValue'], '')
  const statusSOLD = carStatus === carStatus2.SOLD
  return (
    <div
      id='carDetailsView'
      className='inv-car-view'
      style={{backgroundColor: siteBackgroundColor}}
    >
      {isLoading ? <Loader/> : null}
      <div
        id='invCarContent'
        className='inv-car-box'
        style={{
          backgroundColor: boxBackgroundColor,
          border: `1px solid ${boxBorderColor}`,
        }}
      >
        <div id='invCarImage' className='inv-car-image'>
          <CarCarouselTemplateCode5
            images={carImages}
            siteColor={siteColor}
            carStatus={statusSOLD}
          />
          {
            templateCode === MODERN_VIEW_2_TEMPLATE_CODE ?
              <div className="layout-get-in-touch-modern-view-2">
                {showGetInTouchOnCarousel ?
                  <LayoutGetInTouch
                    title={sectionContactUs?.title}
                    siteColor={siteColor}
                    dealerId={dealerId}
                    templateCode={templateCode}
                    car={carWithAttributes}
                    section={sectionContactUs}
                  />
                  : null}
              </div> :
            showGetInTouchOnCarousel ?
              <LayoutGetInTouch
                title={sectionContactUs?.title}
                siteColor={siteColor}
                dealerId={dealerId}
                templateCode={templateCode}
                car={carWithAttributes}
                section={sectionContactUs}
              />
              : null
          }
        </div>
        <CarInformationTemplateCode5
          car={carWithAttributes}
          tabSelected={tabSelected}
          showGetInTouchOnCarousel={showGetInTouchOnCarousel}
          sectionContactUs={sectionContactUs}
          goBack={goBack}
          printCarInformation={printCarInformation}
          handleTabChange={handleTabChange}
          siteColor={siteColor}
          dealerId={dealerId}
          carStatus={statusSOLD}
          currentTheme={currentTheme}
          templateCode={templateCode}
          pages={pages}
        />
      </div>
    </div>
  )
}

export default LayoutInventoryCarViewTemplateCode5
