import React, {useCallback, useEffect, useState} from 'react'
import {Nav, NavItem, Navbar} from 'react-bootstrap'
import {
  pathServer,
  LOCATION_ALL,
  NAVBAR_BACKGROUND_COLOR,
  NAVBAR_TEXT_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEMPLATE_MAIN_NAME,
  WIDTH_VIEW_MOBILE
} from '../../Constants'
import {browserHistory} from 'react-router'
import Popover from 'material-ui/Popover'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import {getPosition, getScrollTop, navigationMenuHandler, scroll} from '../../Util'
import Backdrop from '../../UI/Backdrop'
import NavigationItems from '../../UI/NavigationItems'
import SideDrawer from '../../genericComponents/SideDrawer'
import {ReactSVG} from 'react-svg'
import find from 'lodash/find'
import map from 'lodash/map'
import './LayoutHeader.scss'

const LayoutHeader = ({
                        menu, logo, maxHeight, menuSelected, changeSelectedMenu, locations, siteColor, currentPage,
                        templateCode, locationId, selectLocationId, baseTemplateCode, isAdminTemplate, disableLocationsDropdown
                      }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [widthPage, setWidthPage] = useState(window.innerWidth)
  const [showPopoverLocation, setShowPopoverLocation] = useState(false)
  const [anchorPopoverElement, setAnchorPopoverElement] = useState(null)
  const {
    [NAVBAR_TEXT_COLOR]: navigationBarTextColor, [NAVBAR_BACKGROUND_COLOR]: navigationBarBackgroundColor,
    [PRIMARY_COLOR]: primaryColor, [SECONDARY_COLOR]: secondaryColor
  } = siteColor

  useEffect(() => {
    const currentLocation = (locationId && locationId !== LOCATION_ALL) ? find(locations, ['locationId', locationId]) : {locationId: LOCATION_ALL}
    if (!locationId)
      selectLocationId(currentLocation.locationId)
    updateHover()
  }, [])

  const handleResize = useCallback(() => {
    setWidthPage(window.innerWidth)
    if (window.innerWidth > WIDTH_VIEW_MOBILE)
      setShowMenu(false)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useEffect(() => {
    updateHover()
  }, [siteColor, widthPage])

  const updateHover = () => {
    const isMobile = widthPage <= WIDTH_VIEW_MOBILE
    const menuTextColor = navigationBarTextColor ?? '#515151'
    const selectedStyles = baseTemplateCode === TEMPLATE_MAIN_NAME
      ? `color: ${primaryColor};${!isMobile && ' border: 1px solid ' + primaryColor + '; border-radius: 20px;'}`
      : `color: ${secondaryColor};`
    const unSelectedStyles = baseTemplateCode === TEMPLATE_MAIN_NAME
      ? `color: ${navigationBarTextColor}; border: 0;`
      : `color: ${menuTextColor};`

    menu.forEach(menuItem => {
      const itemAnchor = window.document.getElementById(`${menuItem.id}`)
      if (itemAnchor) {
        if (menuItem.id === menuSelected)
          itemAnchor.setAttribute('style', selectedStyles)
        else
          itemAnchor.setAttribute('style', unSelectedStyles)
      }
    })

    const menuLocationMobile = document.getElementById('menuLocationMobile5')
    if (menuLocationMobile)
      menuLocationMobile.style.border = '1px ' + menuTextColor + ' solid'

    const menuLocation = document.getElementById('menuLocation')
    if (menuLocation)
      menuLocation.style.border = '1px ' + menuTextColor + ' solid'
  }

  const onSelectItem = selectedItem => {
    if (isAdminTemplate)
      return
    const selectedMenuItem = navigationMenuHandler(selectedItem, baseTemplateCode, currentPage, templateCode)
    setShowMenu(false)
    if (changeSelectedMenu)
      changeSelectedMenu(selectedMenuItem)
  }

  const toggleMenu = () => setShowMenu(showMenu => !showMenu)

  const openLocationsPopover = event => {
    if (isAdminTemplate || disableLocationsDropdown)
      return
    event.preventDefault()
    setShowPopoverLocation(true)
    setAnchorPopoverElement(event.currentTarget)
  }

  const onSelectLocation = locationId => {
    if (showMenu)
      setShowMenu(false)

    selectLocationId(locationId)
    setShowPopoverLocation(false)
  }

  const onClickLogo = event => {
    if (isAdminTemplate)
      return
    const logoId = event.target.id
    const logoElement = document.getElementById(logoId)
    const pointTo = getPosition(logoElement)
    const y = getScrollTop()
    const pointFrom = {x: 0, y}
    scroll(pointFrom, pointTo, 600)
    browserHistory.push('/catalog')
  }

  if (!locationId || !baseTemplateCode)
    return null

  const selectedLocation = find(locations, ['locationId', locationId])
  const locationName = !selectedLocation ? 'All Locations' : (
    isAdminTemplate || !selectedLocation.name ?
      `${selectedLocation.city} ${selectedLocation.state}` :
      (selectedLocation.name.length > 12 ? selectedLocation.name.substring(0, 12) + '.' : selectedLocation.name)
  )
  const maxHeightInt = maxHeight ? parseInt(maxHeight.toString()) : 0

  return (
    <header id="header" className="header-container" style={{backgroundColor: navigationBarBackgroundColor ?? '#FFFFFF'}}>
      <div className={'menu-header'}>
        {/*Logo section*/}
        <div className={'header-left'}>
          <img
            id="logoHeader"
            data-testid='logo'
            src={logo}
            alt="logo"
            onClick={onClickLogo}
            style={{maxHeight: maxHeightInt}}
          />
        </div>

        {/*Right section - location and tabs - desktop view*/}
        {
          (widthPage > WIDTH_VIEW_MOBILE || isAdminTemplate) &&
          <div className={`header-right ${baseTemplateCode === TEMPLATE_MAIN_NAME ? '' : 'header-right-5'}`}>
            <Navbar bsSize="small" fluid={true} collapseOnSelect>
              <Nav navbar activeKey={true}>
                  <NavItem
                    disabled={isAdminTemplate}
                    onClick={openLocationsPopover}
                    id={'menuLocation'}
                    className={`menu-location ${baseTemplateCode === TEMPLATE_MAIN_NAME ? 'menu-location-main' : ''}`}
                    data-testid='location-dropdown-link'
                  >
                    <span
                      data-testid={'location-name'}
                      style={{color: navigationBarTextColor}}>
                      {locationName}
                    </span>
                    <i className="fa fa-chevron-down"/>
                  </NavItem>
                <NavigationItems
                  items={menu}
                  clicked={isAdminTemplate ? () => {} : onSelectItem}
                  className="menu-header-li"
                  navigationBarText={navigationBarTextColor}
                />
              </Nav>
            </Navbar>
          </div>
        }

        {/*Right section - location and tabs - mobile view*/}
        {
          (widthPage <= WIDTH_VIEW_MOBILE || !isAdminTemplate) &&
          <div className={'header-toggle-button'} data-testId='toggle-button'>
            {
              !showMenu &&
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_dehaze_24px.svg'}
                beforeInjection={svg => svg.classList.add('burger-button')}
                className="toggle-button"
                id="toggle-button"
                onClick={toggleMenu}
              />
            }
            <Backdrop show={showMenu} clicked={toggleMenu}/>
            <SideDrawer
              clicked={toggleMenu}
              open={showMenu}
              backgroundColor={navigationBarBackgroundColor ?? '#FFFFFF'}
            >
              <li className="nav-item">
                <div
                  onClick={openLocationsPopover}
                  id="menuLocationMobile5"
                  className="location-item"
                >
                  <span style={{color: navigationBarTextColor}}>{locationName}</span>
                  <i className="fa fa-chevron-down"/>
                </div>
              </li>
              <NavigationItems
                items={menu}
                clicked={onSelectItem}
                className="nav-item"
                isMobile={true}
                navigationBarText={navigationBarTextColor}
              />
            </SideDrawer>
          </div>
        }
      </div>

      {/*Locations popover*/}
      <Popover
        open={showPopoverLocation}
        anchorEl={anchorPopoverElement}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        targetOrigin={{horizontal: 'left', vertical: 'top'}}
        className='popover-public-locations'
        onRequestClose={() => setShowPopoverLocation(false)}
        style={{
          backgroundColor: navigationBarBackgroundColor,
          color: navigationBarTextColor
        }}
      >
        <Menu data-testid={'popover-locations'}>
          <MenuItem
            onClick={() => onSelectLocation(LOCATION_ALL)}
            primaryText="All Locations"
            style={{color: navigationBarTextColor}}
          />
          {
            map(locations, ({locationId, name}) => (
              <MenuItem
                key={locationId}
                onClick={() => onSelectLocation(locationId)}
                primaryText={name}
                style={{color: navigationBarTextColor}}
                data-testid={'location-' + name}
              />
            ))
          }
        </Menu>
      </Popover>
    </header>
  )
}

export default LayoutHeader