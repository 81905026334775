import React, {Component} from 'react'
import LayoutHeader from '../../common/template/LayoutHeader'
import {
  TEMPLATE_5_NAME, PAGE_FINANCE_ID, SECTION_CONTACT_US_ID, PAGE_HOME_ID, SECTION_TESTIMONIAL_ID,
  MODERN_VIEW_TEMPLATE_CODE, MODERN_VIEW_3_TEMPLATE_CODE, MODERN_VIEW_2_TEMPLATE_CODE, SITE_BACKGROUND_COLOR,
  LIGHT_THEME
} from '../../common/Constants'
import {connect} from 'react-redux'
import {
  loadLocationsPublic, selectedLocationId, saveEmailMessage
} from '../actions'
import LayoutFinanceViewForm from '../../common/LayoutFinanceViewForm'
import LayoutFooter from '../../common/template/LayoutFooter'
import LayoutCustomerHorizontalTestimonials from '../../common/LayoutCustomerHorizontalTestimonials'
import LayoutGetInTouch from '../../common/template/LayoutGetInTouch'
import get from 'lodash/get'
import find from 'lodash/find'
import LayoutCustomerVerticalTestimonial from '../../common/LayoutCustomerVerticalTestimonial'
import {getSiteColor, getBackgroundModernView} from '../../common/Util'
import DPBackToTop, {handleToTop} from '../../common/DPBackToTop'

class LayoutFinanceContainer extends Component {

  componentDidMount() {
    this.props.loadLocationsPublic()
  }

  render() {
    const {
      locations, templateContent, dealerId, templateCode, locationId, selectedLocationId, saveEmailMessage
    } = this.props

    if (!locations)
      return null

    const content = JSON.parse(templateContent)
    // getting data from template
    const {generalSetting, pages} = content
    const {socialMedia, footer, site: {logo = '', maxHeight: maxHeightLogo}, banner, currentTheme} = generalSetting
    const backgroundMainUrl = banner.backgroundMain.url
    // getting pages
    const homePage = find(pages, page => page.id === PAGE_HOME_ID)
    const financePage = find(pages, page => page.id === PAGE_FINANCE_ID)
    // getting sections from home Page
    const sectionContactUs = find(homePage.sections, sec => sec.id === SECTION_CONTACT_US_ID)
    const testimonialSectionHome = find(homePage.sections, sec => sec.id === SECTION_TESTIMONIAL_ID)
    // getting sections from finance Page
    const {menu} = financePage.header
    const contactSection = find(financePage.sections, sec => sec.id === SECTION_CONTACT_US_ID) || {}
    const testimonialSection = find(financePage.sections, sec => sec.id === SECTION_TESTIMONIAL_ID) || {}
    const showContactSection = contactSection.showSection || true
    const showTestimonialSection = testimonialSection.showSection || true

    const hideTestimonial = !!testimonialSectionHome.hideSection
    const hideContact = !!sectionContactUs.hideSection

    const siteColor = getSiteColor(generalSetting)
    const {[SITE_BACKGROUND_COLOR]: siteBackgroundColor} = siteColor

    //todo: fix these 2 lines
    sectionContactUs.showSection = showContactSection
    testimonialSectionHome.showSection = showTestimonialSection

    const getInTouchComponent = (
      <LayoutGetInTouch
        title={sectionContactUs?.title}
        siteColor={siteColor}
        dealerId={dealerId}
        templateCode={templateCode}
        section={sectionContactUs}
      />
    )

    return (
      <div className={`layout-finance-container container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}>
        <LayoutHeader
          menu={menu}
          logo={logo}
          maxHeight={maxHeightLogo}
          menuSelected='finance'
          locations={locations}
          siteColor={siteColor}
          templateCode={templateCode}
          locationId={locationId}
          selectLocationId={selectedLocationId}
          baseTemplateCode={TEMPLATE_5_NAME}
        />
        <div id="bannerSeparator" className="banner-separator" />
        <div className="finance-form-5" style={{backgroundColor: siteBackgroundColor}}>
          {
            (templateCode === MODERN_VIEW_TEMPLATE_CODE || templateCode === MODERN_VIEW_3_TEMPLATE_CODE) &&
            <LayoutFinanceViewForm
              formView={financePage.formView}
              borderColor="#e3e3e3"
              onClickUp={handleToTop}
              siteColor={siteColor}
              templateCode={templateCode}
            />
          }
          {
            (templateCode === MODERN_VIEW_2_TEMPLATE_CODE) &&
            <div className="finance-form-modern-view-2">
              <LayoutFinanceViewForm
                formView={financePage.formView}
                borderColor="#e3e3e3"
                onClickUp={handleToTop}
                siteColor={siteColor}
                templateCode={templateCode}
              />
            </div>
          }
        </div>
        {
          (templateCode === MODERN_VIEW_TEMPLATE_CODE || templateCode === MODERN_VIEW_3_TEMPLATE_CODE) &&
          <>
            {
              !hideContact &&
              getInTouchComponent
            }
            {
              !hideTestimonial &&
              <LayoutCustomerHorizontalTestimonials
                hideTitle={false}
                heightCurrentBar={12} heightTotalBar={3} from={'home'}
                siteColor={siteColor}
                title={testimonialSection.title ?? 'CHECK OUR NEW INVENTORY'}
              />
            }
          </>
        }
        {
          (templateCode === MODERN_VIEW_2_TEMPLATE_CODE) &&
          <>
            {(showContactSection || showTestimonialSection) &&
              <div id='contactMain' className='lay-get-in-touch' style={{backgroundColor: siteBackgroundColor}}>
                {/*todo: this has no condition before refactor, maybe it was wrong*/}
                <div className='layout-contact-testimonial'>
                  <div className={`layout-contact-${!hideContact? 'full' : 'hide'}`}>
                    {getInTouchComponent}
                  </div>
                  <div className={`layout-testimonials-${!hideTestimonial? 'full' : 'hide'}`}>
                    {
                      !hideTestimonial ?
                        testimonialSection && get(testimonialSection, ['showSection'], true) &&
                        <LayoutCustomerVerticalTestimonial
                          from={'home'}
                          sectionTestimonial={testimonialSection}
                          siteColor={siteColor}
                        /> :null
                    }
                  </div>
                </div>


              </div>
            }
          </>
        }
        <LayoutFooter
          dealerId={dealerId}
          footer={footer}
          backgroundMain={getBackgroundModernView(templateCode, backgroundMainUrl)}
          socialMedia={socialMedia}
          siteColor={siteColor}
          templateCode={templateCode}
          saveEmailMessage={saveEmailMessage}
          isButtonSticky={true}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    locations: state.locationsPublic,
    templateContent: state.templateContent,
    templateCode: state.templateCode,
    locationId: state.locationId
  }
}

export default connect(
  mapStateToProps,
  {loadLocationsPublic, selectedLocationId, saveEmailMessage}
)(LayoutFinanceContainer)