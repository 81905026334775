import React, {useState} from 'react'
import {
  BOX_BACKGROUND_COLOR,
  BOX_BORDER_COLOR, BUTTON_TEXT_COLOR, CHECKBOX_AND_EXPAND_COLOR, CONTROL_BACKGROUND_COLOR,
  CONTROL_BORDER_COLOR, EmptyFunc,
  optionBody,
  optionColor,
  optionCondition,
  optionCylinder,
  optionTitle,
  PRIMARY_COLOR, SECONDARY_COLOR,
  SITE_BACKGROUND_COLOR,
  TEMPLATE_5_NAME,
  TEXT_COLOR
} from '../../Constants'
import {DPSelectDropdown} from '../../genericComponents/DPSelectDropdown'
import DPButton, {BUTTON_TYPE} from '../../genericComponents/DPButton'
import {getBaseTemplateCode} from '../../Util'
import {difference, filter} from 'lodash'
import {DPRangeNumberInput} from '../../DPRangeNumberInput'
import DPTextInput from '../../genericComponents/DPTextInput'

const styleSelect = {
  container: 'col-filter-select',
  select: 'filter-select'
}
const styleInput = {
  container: 'col-filter-select',
  label: 'label-filter',
  filter: 'filter-number',
  input: 'input-filter'
}
const styleYearMilesPrice = {...styleInput, container: 'row-filter-ymp'}
const stylePrice = {...styleInput, container: 'row-filter-ymp', input: 'input-filter input-filter-price'}

export const AdvancedSearchInventory = ({
                                          isAdmin,
                                          templateCode,
                                          siteColor,
                                          filterValues,
                                          setFilterValues,
                                          search,
                                          showFiltersTemplate5,
                                          hideFilters,
                                          makes,
                                          models,
                                          cleanFilter
                                        }) => {
  const baseTemplate = getBaseTemplateCode(templateCode)
  const isTemplate5 = (baseTemplate === TEMPLATE_5_NAME)
  const [modelsByMakeIds, setModelsByMakeIds] = useState([])

  const {
    [PRIMARY_COLOR]: primaryColor, [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [SITE_BACKGROUND_COLOR]: siteBackgroundColor, [CONTROL_BORDER_COLOR]: controlBorderColor,
    [TEXT_COLOR]: textSiteColor, [BOX_BORDER_COLOR]: boxBorderColor
  } = siteColor

  const {
    yearFrom, yearTo, mileageFrom, mileageTo, priceFrom, priceTo, trim
  } = filterValues

  const styleYearMilesPriceDisplay = {...styleInput, container: 'row-filter-display'}

  const colorsFilters = {
    primaryColor: primaryColor,
    backgroundColor: siteBackgroundColor,
    borderColor: controlBorderColor,
    textColor: textSiteColor
  }

  const iconPrice = <label className={isTemplate5 ? 'label-price' : 'prices'}>$</label>
  const iconMiles = <label className={isTemplate5 ? 'label-miles' : 'k-miles'}>K</label>

  const idYear = {idFrom: 'yearFrom', idTo: 'yearTo'}
  const valueYear = {valueFrom: yearFrom, valueTo: yearTo}
  const idPrice = {idFrom: 'priceFrom', idTo: 'priceTo'}
  const valuePrice = {valueFrom: priceFrom, valueTo: priceTo}
  const idMileage = {idFrom: 'mileageFrom', idTo: 'mileageTo'}
  const valueMileage = {valueFrom: mileageFrom, valueTo: mileageTo}

  const onChangeNumber = (evt, modelValue) => {
    if (isAdmin)
      return
    const id = evt.target.id
    setFilterValues({...filterValues, [id]: modelValue})
  }

  const handleInputChange = (evt) => {
    const id = evt.target.id
    const value = evt.target.value
    setFilterValues({...filterValues, [id]: value})
  }

  const cleanAllFilters = () => {
    cleanFilter()
  }

  const handleFiltersCheck = (type, values) => {
    if (type === 'selectedMakes') {
      let modelsByMakeIdMem = {}
      const addedValue = difference(values, filterValues.selectedMakes)[0]
      const deleteValue = difference(filterValues.selectedMakes, values)
      if (addedValue) {
        const modelsFoundToAdd = filter(models, {makeId: addedValue})
        const makeNameToAdd = filter(makes, {id: addedValue})[0].name
        const modelsDataGroup = modelsFoundToAdd.map(({id, name}) => {
          return {id, name}
        })
        modelsByMakeIdMem = {...modelsByMakeIds, [makeNameToAdd]: modelsDataGroup}
      } else {
        modelsByMakeIdMem = {...modelsByMakeIds}
        deleteValue.forEach(makeId => {
          const makeNameToDelete = filter(makes, {id: makeId})[0].name
          delete modelsByMakeIdMem[makeNameToDelete]
          if (filterValues.selectedModels) {
            const modelsByMakeIdToDelete = filter(models, {makeId: makeId}).map(({id}) => id)
            const modelsFilteredToAdd = filter( filterValues.selectedModels, modelId => !modelsByMakeIdToDelete.includes(modelId))
            setFilterValues(prevState => ({
              ...prevState,
              selectedModels: modelsFilteredToAdd.map((modelId) => modelId)
            }))
          }
        })
      }
      setModelsByMakeIds(modelsByMakeIdMem)
    }
    setFilterValues(prevState => ({
      ...prevState,
      [type]: values
    }))
  }

  return (
    isTemplate5 ?
      (
        showFiltersTemplate5 && !isAdmin ?
          <div
            className="filter-box"
            style={{
              backgroundColor: boxBackgroundColor,
              border: `1px solid ${boxBorderColor}`
            }}
          >
            <div className='row-filter'>
              <DPSelectDropdown
                id='selectedMakes'
                options={makes}
                name='MAKE'
                placeholder='MAKE'
                onSelect={values => handleFiltersCheck('selectedMakes', values)}
                selectedValue={filterValues?.selectedMakes}
                siteColor={{...siteColor,[CHECKBOX_AND_EXPAND_COLOR]: '#CACACA'}}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPSelectDropdown
                id='selectedModels'
                options={modelsByMakeIds}
                name='MODEL'
                placeholder='MODEL'
                hasGroups={true}
                onSelect={values => handleFiltersCheck('selectedModels', values)}
                selectedValue={filterValues?.selectedModels}
                siteColor={{...siteColor,[CHECKBOX_AND_EXPAND_COLOR]: '#CACACA'}}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPSelectDropdown
                id='body'
                options={optionBody}
                name='BODY TYPE'
                placeholder='BODY TYPE'
                onSelect={values => handleFiltersCheck('body', values)}
                selectedValue={filterValues?.body}
                siteColor={{...siteColor,[CHECKBOX_AND_EXPAND_COLOR]: '#CACACA'}}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPRangeNumberInput
                id={{idFrom: idYear.idFrom, idTo: idYear.idTo}}
                value={{valueFrom: valueYear.valueFrom, valueTo: valueYear.valueTo}}
                displayValue='Year'
                integer={4}
                classNames={styleYearMilesPriceDisplay}
                onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
                siteColor={siteColor}
                disabled={isAdmin}
              />
            </div>
            <div className='row-filter-color-condition'>
              <DPSelectDropdown
                id='extCondition'
                options={optionCondition}
                name='EXT. CONDITION'
                placeholder='EXT. CONDITION'
                onSelect={values => handleFiltersCheck('extCondition', values)}
                selectedValue={filterValues?.extCondition}
                siteColor={{...siteColor,[CHECKBOX_AND_EXPAND_COLOR]: '#CACACA'}}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPSelectDropdown
                id='exteriorColor'
                options={optionColor}
                name='EXT. COLOR'
                placeholder='EXT. COLOR'
                onSelect={values => handleFiltersCheck('exteriorColor', values)}
                selectedValue={filterValues?.exteriorColor}
                siteColor={{...siteColor,[CHECKBOX_AND_EXPAND_COLOR]: '#CACACA'}}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPSelectDropdown
                id='interiorColor'
                options={optionColor}
                name='INT. COLOR'
                placeholder='INT. COLOR'
                onSelect={values => handleFiltersCheck('interiorColor', values)}
                selectedValue={filterValues?.interiorColor}
                siteColor={{...siteColor,[CHECKBOX_AND_EXPAND_COLOR]: '#CACACA'}}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPRangeNumberInput
                id={{idFrom: idMileage.idFrom, idTo: idMileage.idTo}}
                value={{valueFrom: valueMileage.valueFrom, valueTo: valueMileage.valueTo}}
                displayValue='Miles'
                integer={8}
                icon={iconMiles}
                iconPosition={'right'}
                classNames={styleYearMilesPriceDisplay}
                onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
                siteColor={siteColor}
                disabled={isAdmin}
              />
            </div>
            <div className='row-filter-color-condition'>
              <DPSelectDropdown
                id='cylinder'
                options={optionCylinder}
                name='CYLINDERS'
                placeholder='CYLINDERS'
                onSelect={values => handleFiltersCheck('cylinder', values)}
                selectedValue={filterValues?.cylinder}
                siteColor={siteColor}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPSelectDropdown
                id='title'
                options={optionTitle}
                name='TITLE'
                placeholder='TITLE'
                onSelect={values => handleFiltersCheck('title', values)}
                selectedValue={filterValues?.title}
                siteColor={siteColor}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPTextInput
                inputId='trim'
                placeholder='TRIM'
                onChangeText={handleInputChange}
                value={trim}
                inputClassName='form-control'
                inputContainerClassName='filter-input'
                containerClassName='col-filter-select'
                inputStyle={{
                  backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                  color: siteColor[TEXT_COLOR],
                  border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                }}
                simpleInputClassname={true}
                disabled={isAdmin}
              />
              <DPRangeNumberInput
                id={{idFrom: idPrice.idFrom, idTo: idPrice.idTo}}
                value={{valueFrom: valuePrice.valueFrom, valueTo: valuePrice.valueTo}}
                displayValue='Price'
                integer={8}
                icon={iconPrice}
                iconPosition={'left'}
                classNames={styleYearMilesPriceDisplay}
                onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
                siteColor={siteColor}
                disabled={isAdmin}
              />
            </div>
            <div className='row-filter-condition-cyl-mobile'>
              <DPSelectDropdown
                id='extCondition'
                options={optionCondition}
                name='EXT. CONDITION'
                placeholder='EXT. CONDITION'
                onSelect={values => handleFiltersCheck('extCondition', values)}
                selectedValue={filterValues?.extCondition}
                siteColor={siteColor}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPSelectDropdown
                id='cylinder'
                options={optionCylinder}
                name='CYLINDERS'
                placeholder='CYLINDERS'
                onSelect={values => handleFiltersCheck('cylinder', values)}
                selectedValue={filterValues?.cylinder}
                siteColor={siteColor}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
            </div>
            <div className='row-filter-color-mobile'>
              <DPSelectDropdown
                id='exteriorColor'
                options={optionColor}
                name='EXT. COLOR'
                placeholder='EXT. COLOR'
                onSelect={values => handleFiltersCheck('exteriorColor', values)}
                selectedValue={filterValues?.exteriorColor}
                siteColor={siteColor}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPSelectDropdown
                id='interiorColor'
                options={optionColor}
                name='INT. COLOR'
                placeholder='INT. COLOR'
                onSelect={values => handleFiltersCheck('interiorColor', values)}
                selectedValue={filterValues?.interiorColor}
                siteColor={siteColor}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
            </div>
            <div className='row-filter-title-trim-mobile'>
              <DPSelectDropdown
                id='title'
                options={optionTitle}
                name='TITLE'
                placeholder='TITLE'
                onSelect={values => handleFiltersCheck('title', values)}
                selectedValue={filterValues?.title}
                siteColor={siteColor}
                multiple={true}
                selectClassName={styleSelect.container}
                contentClassName={styleSelect.select}
                selectDeployedClassName='advancedSearch-select-deployed'
                isDisabled={isAdmin}
              />
              <DPTextInput
                inputId='trim'
                placeholder='TRIM'
                onChangeText={handleInputChange}
                value={trim}
                inputClassName='form-control'
                inputContainerClassName='filter-input'
                containerClassName='col-filter-select'
                inputStyle={{
                  backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                  color: siteColor[TEXT_COLOR],
                  border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                }}
                simpleInputClassname={true}
                disabled={isAdmin}
              />
            </div>
            <div className='row-filter-year-miles-price'>
              <DPRangeNumberInput
                id={{idFrom: idYear.idFrom, idTo: idYear.idTo}}
                value={{valueFrom: valueYear.valueFrom, valueTo: valueYear.valueTo}}
                displayValue='Year'
                integer={4}
                classNames={styleYearMilesPrice}
                onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
                siteColor={siteColor}
                disabled={isAdmin}
              />
              <DPRangeNumberInput
                id={{idFrom: idMileage.idFrom, idTo: idMileage.idTo}}
                value={{valueFrom: valueMileage.valueFrom, valueTo: valueMileage.valueTo}}
                displayValue='Miles'
                integer={8}
                icon={iconMiles}
                iconPosition='right'
                classNames={styleYearMilesPrice}
                onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
                siteColor={siteColor}
                disabled={isAdmin}
              />
              <DPRangeNumberInput
                id={{idFrom: idPrice.idFrom, idTo: idPrice.idTo}}
                value={{valueFrom: valuePrice.valueFrom, valueTo: valuePrice.valueTo}}
                displayValue='Price'
                integer={8}
                icon={iconPrice}
                iconPosition='left'
                classNames={styleYearMilesPrice}
                onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
                siteColor={siteColor}
                disabled={isAdmin}
              />
            </div>
            <div className='row-filter-button'>
              <div className='btn-clear'>
                <DPButton
                  id='btnFilter'
                  buttonClassName='btn btn-clear-inventory'
                  onClick={cleanAllFilters}
                  label='Clear filters'
                  textColor={siteColor[TEXT_COLOR]}
                  backgroundColor={siteColor[BOX_BACKGROUND_COLOR]}
                  backgroundHoverColor={siteColor[PRIMARY_COLOR]}
                  type={BUTTON_TYPE.NO_BORDER}
                  isDisabled={isAdmin}
                  hoverTextColor={siteColor[BUTTON_TEXT_COLOR]}
                />
              </div>
              <div className='btn-search'>
                <DPButton
                  id='btnSearch'
                  buttonClassName='btn btn-search-inventory'
                  onClick={search}
                  label='Apply filters'
                  textColor={siteColor[BUTTON_TEXT_COLOR]}
                  backgroundColor={siteColor[PRIMARY_COLOR]}
                  backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                  isDisabled={isAdmin}
                />
              </div>
            </div>
          </div>
          : null
      ) : (
        !hideFilters ?
          <div className='car-search-advance'>
            <div className='col-menu-advanced'>
              <label>Advanced Search</label>
            </div>
            <DPSelectDropdown
              id='selectedMakes'
              options={makes}
              name='MAKE'
              placeholder='Make'
              onSelect={values => handleFiltersCheck('selectedMakes', values)}
              selectedValue={filterValues?.selectedMakes}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              isPopover={false}
              selectClassName='car-row-filter'
              selectDeployedClassName='car-box-collapse'
              contentClassName='car-box-dp'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPSelectDropdown
              id='selectedModels'
              options={modelsByMakeIds}
              name='MODEL'
              placeholder='Model'
              hasGroups={true}
              isPopover={false}
              onSelect={values => handleFiltersCheck('selectedModels', values)}
              selectedValue={filterValues?.selectedModels}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              selectClassName='car-row-filter'
              contentClassName='car-box-dp'
              selectDeployedClassName='car-box-collapse'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPSelectDropdown
              id='body'
              options={optionBody}
              name='BODY TYPE'
              placeholder='Body Type'
              onSelect={values => handleFiltersCheck('body', values)}
              selectedValue={filterValues?.body}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              isPopover={false}
              selectClassName='car-row-filter'
              contentClassName='car-box-dp'
              selectDeployedClassName='car-box-collapse'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPRangeNumberInput
              id={{idFrom: idYear.idFrom, idTo: idYear.idTo}}
              value={{valueFrom: valueYear.valueFrom, valueTo: valueYear.valueTo}}
              integer={4}
              classNames={{
                container: 'car-row-filter',
                input: 'car-input-number-from',
                labelFrom: 'car-label-number-from',
                labelTo: 'car-label-number-to'
              }}
              displayValueFrom='Year'
              displayValueTo='To'
              onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
              siteColor={siteColor}
              disabled={isAdmin}
            />
            <DPRangeNumberInput
              id={{idFrom: idMileage.idFrom, idTo: idMileage.idTo}}
              value={{valueFrom: valueMileage.valueFrom, valueTo: valueMileage.valueTo}}
              integer={8}
              classNames={{
                container: 'car-row-filter',
                input: 'car-input-number-from',
                labelFrom: 'car-label-number-from',
                labelTo: 'car-label-number-to'
              }}
              displayValueFrom='Miles'
              displayValueTo='To'
              icon={iconMiles}
              iconPosition={'right'}
              onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
              siteColor={siteColor}
              disabled={isAdmin}
            />
            <DPRangeNumberInput
              id={{idFrom: idPrice.idFrom, idTo: idPrice.idTo}}
              value={{valueFrom: valuePrice.valueFrom, valueTo: valuePrice.valueTo}}
              integer={8}
              classNames={{
                container: 'car-row-filter',
                input: 'car-input-number-from',
                labelFrom: 'car-label-number-from',
                labelTo: 'car-label-number-to'
              }}
              displayValueFrom='Price'
              displayValueTo='To'
              icon={iconPrice}
              iconPosition={'left'}
              onChangeNumber={(evt, value) => onChangeNumber(evt, value)}
              siteColor={siteColor}
              disabled={isAdmin}
            />
            <DPSelectDropdown
              id='extCondition'
              options={optionCondition}
              name='EXT. CONDITION'
              placeholder='Exterior Condition'
              onSelect={values => handleFiltersCheck('extCondition', values)}
              selectedValue={filterValues?.extCondition}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              isPopover={false}
              selectClassName='car-row-filter'
              contentClassName='car-box-dp'
              selectDeployedClassName='car-box-collapse'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPSelectDropdown
              id='exteriorColor'
              options={optionColor}
              name='EXT. COLOR'
              placeholder='Ext.Color'
              onSelect={values => handleFiltersCheck('exteriorColor', values)}
              selectedValue={filterValues?.exteriorColor}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              isPopover={false}
              selectClassName='car-row-filter'
              contentClassName='car-box-dp'
              selectDeployedClassName='car-box-collapse'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPSelectDropdown
              id='interiorColor'
              options={optionColor}
              name='INT. COLOR'
              placeholder='Int.Color'
              onSelect={values => handleFiltersCheck('interiorColor', values)}
              selectedValue={filterValues?.interiorColor}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              isPopover={false}
              selectClassName='car-row-filter'
              contentClassName='car-box-dp'
              selectDeployedClassName='car-box-collapse'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPSelectDropdown
              id='cylinder'
              options={optionCylinder}
              name='CYLINDERS'
              placeholder='Cylinders'
              onSelect={values => handleFiltersCheck('cylinder', values)}
              selectedValue={filterValues?.cylinder}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              isPopover={false}
              selectClassName='car-row-filter'
              contentClassName='car-box-dp'
              selectDeployedClassName='car-box-collapse'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPSelectDropdown
              id='title'
              options={optionTitle}
              name='TITLE'
              placeholder='Title'
              onSelect={values => handleFiltersCheck('title', values)}
              selectedValue={filterValues?.title}
              siteColor={siteColor}
              sameCheckboxColor={PRIMARY_COLOR}
              multiple={true}
              isPopover={false}
              selectClassName='car-row-filter'
              contentClassName='car-box-dp'
              selectDeployedClassName='car-box-collapse'
              optionsClassName='car-box-detail'
              isDisabled={isAdmin}
            />
            <DPTextInput
              inputId='trim'
              placeholder='Trim'
              onChangeText={isAdmin ? EmptyFunc : handleInputChange}
              value={isAdmin ? 'SE' : trim}
              inputClassName='input-advance-search'
              inputContainerClassName='filter-input'
              containerClassName='car-row-filter-condition'
              inputStyle={{
                backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                color: siteColor[TEXT_COLOR],
                border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
              }}
              simpleInputClassname={true}
              disabled={isAdmin}
            />

            <div className='car-row-clean-filters'>
              <div className='btn-clear'>
                <DPButton
                  id='cleanFilters'
                  buttonClassName='btn btn-clean-filter'
                  onClick={cleanAllFilters}
                  label='Clear filters'
                  textColor={siteColor[TEXT_COLOR]}
                  backgroundColor={siteColor[BOX_BACKGROUND_COLOR]}
                  backgroundHoverColor={siteColor[PRIMARY_COLOR]}
                  isDisabled={isAdmin}
                  hoverTextColor={siteColor[BUTTON_TEXT_COLOR]}
                />
              </div>
            </div>
            <div className='car-row-apply-filters'>
              <div className='btn-search'>
                <DPButton
                  id='applyFilters'
                  buttonClassName='btn btn-search-advance'
                  onClick={search}
                  label='Apply filters'
                  textColor={siteColor[BUTTON_TEXT_COLOR]}
                  backgroundColor={siteColor[PRIMARY_COLOR]}
                  backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                  isDisabled={isAdmin}
                />
              </div>
            </div>
          </div> : null
      )
  )
}
