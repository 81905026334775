import React from 'react'
import {pathServer, BUTTON_TEXT_COLOR, PRIMARY_COLOR, TEMPLATE_5_NAME, SECONDARY_COLOR} from '../../Constants'
import PropTypes from 'prop-types'
import DPTextInput from '../../genericComponents/DPTextInput'
import DPButton from '../../genericComponents/DPButton'

const CarQuickSearch = ({
                          filterValues, setShowFilters, siteColor, search, placeholder, baseTemplateCode,
                          onChangeInputFilters, isAdminTemplate
                        }) => {

  const rightElement = (
    <>
      <div className={`car-search-icon ${isAdminTemplate ? 'car-search-icon-preview' : ''}`}>
        <img src={pathServer.PATH_IMG + 'search-icon.png'} alt=''/>
      </div>
      {baseTemplateCode === TEMPLATE_5_NAME &&
        <div className='container-button'>
          <DPButton
            id='btnFilter'
            buttonClassName='button-input'
            onClick={isAdminTemplate ? () => {
            } : setShowFilters}
            textColor={siteColor[BUTTON_TEXT_COLOR]}
            backgroundColor={siteColor[PRIMARY_COLOR]}
            backgroundHoverColor={siteColor[SECONDARY_COLOR]}
            label='Filters'
          />
        </div>}
    </>
  )

  const searchBody = (
    <DPTextInput
      inputId='keyword'
      containerClassName='car-search-input'
      inputContainerClassName='input-container'
      inputClassName='form-control-search'
      placeholder={placeholder}
      onChangeText={onChangeInputFilters}
      onKeyPress={(event) => event.key === 'Enter' ? search(false) : null}
      value={filterValues?.keyword || ''}
      disabled={isAdminTemplate}
      testInputId='keyword-input'
      rightElement={rightElement}
    />
  )

  return (
    baseTemplateCode === TEMPLATE_5_NAME ?
      <div className='container-car-search-input'>
        {searchBody}
      </div> :
      searchBody
  )
}

CarQuickSearch.propTypes = {
  filterValues: PropTypes.object,
  setShowFilters: PropTypes.func,
  siteColor: PropTypes.object,
  search: PropTypes.func,
  onChangeInputFilters: PropTypes.func,
  placeholder: PropTypes.string,
  baseTemplateCode: PropTypes.string
}

CarQuickSearch.defaultProps = {
  filterValues: {},
  setShowFilters: () => {
  },
  onChangeInputFilters: () => {
  },
  siteColor: {},
  search: () => {
  },
  placeholder: '',
  baseTemplateCode: ''
}

export default CarQuickSearch
