import React from 'react'
import {Route, IndexRoute} from 'react-router'

import LayoutContainerTemplate5 from './template5/LayoutHomeContainer.jsx'
import LayoutContainerTemplateMain from './templateMain/LayoutHomeContainer.jsx'

import LayoutFinanceContainerMain from './templateMain/LayoutFinanceContainer.jsx'
import LayoutInventoryContainerMain from './templateMain/LayoutVehiclesContainer.jsx'
import LayoutInventoryContainerCarMain from './templateMain/LayoutCarContainer.jsx'
import LayoutAboutUs from './templateMain/LayoutAboutUsContainer.jsx'

import LayoutFinanceContainer5 from './template5/LayoutFinanceContainer.jsx'
import LayoutInventoryContainer5 from './template5/LayoutVehiclesContainer.jsx'
import LayoutInventoryContainerCar5 from './template5/LayoutCarContainer.jsx'
import LayoutAboutUs5 from './template5/LayoutAboutUsContainer.jsx'
import PublicPageNotSetup from './PublicPageNotSetup.jsx'
import {
  MODERN_VIEW_2_TEMPLATE_CODE,
  MODERN_VIEW_3_TEMPLATE_CODE,
  MODERN_VIEW_TEMPLATE_CODE,
  PAGE_ABOUT_US_ID,
  PAGE_FINANCE_ID
} from '../common/Constants'
import isEmpty from 'lodash/isEmpty'

const LIST_FONT_FAMILY = [
  {id: "lato", displayValue: "Lato, sans-serif"},
  {id: "montserrat", displayValue: "Montserrat, sans-serif"},
  {id: "openSans", displayValue: "Open Sans, sans-serif"},
  {id: "roboto", displayValue: "Roboto, sans-serif"},
  {id: "raleway", displayValue: "Raleway, sans-serif"},
  {id: "robotoSlab", displayValue: "Roboto Slab, sans-serif"}
];

function setFontFamily(templateContent) {
  const content = JSON.parse(templateContent);
  const generalSetting = content.generalSetting;
  const fontId = generalSetting.typography && generalSetting.typography.title !== "" ? generalSetting.typography.title : "openSans"
  const fontSelected = LIST_FONT_FAMILY.find(x => x.id === fontId);
  let fontFamily = "";
  if(isEmpty(fontSelected)){
    fontFamily = "'Open Sans', sans-serif"
  } else{
    fontFamily = fontSelected.displayValue
  }

  const body = document.getElementsByTagName("body")[0];
  body.style.fontFamily = fontFamily
}

let Routes;
if (!window.initialState || !window.initialState.setupComplete) {
  Routes = (
    <Route path="/catalog" component={PublicPageNotSetup}/>
  )
} else {
  setFontFamily(window.initialState.templateContent);
  const content = JSON.parse(window.initialState.templateContent)
  const pages = content.pages
  const redirectFinance = pages.find(x => x.id === PAGE_FINANCE_ID)?.isActive ?? true
  const redirectAboutUs = pages.find(x => x.id === PAGE_ABOUT_US_ID)?.isActive ?? true
  switch (window.initialState.templateCode) {
    case MODERN_VIEW_TEMPLATE_CODE:
    case MODERN_VIEW_2_TEMPLATE_CODE:
    case MODERN_VIEW_3_TEMPLATE_CODE:
      require('./template5/template5.scss');
      require('./templateMain/templateMain.scss');
      Routes = (
        <Route path="/catalog">
          <Route path="finance" component={redirectFinance ? LayoutFinanceContainer5 : LayoutContainerTemplate5 }/>
          <Route path="inventory" component={LayoutInventoryContainer5}/>
          <Route path="car/:carVin" component={LayoutInventoryContainerCar5}/>
          <Route path="aboutUs" component={redirectAboutUs ? LayoutAboutUs5 : LayoutContainerTemplate5}/>
          <IndexRoute component={LayoutContainerTemplate5}/>
        </Route>);
      break;

    default:
      require('./templateMain/templateMain.scss');
      require('./template5/template5.scss');
      Routes = (
        <Route path="/catalog">
          <Route path="finance" component={redirectFinance ? LayoutFinanceContainerMain : LayoutContainerTemplateMain }/>
          <Route path="inventory" component={LayoutInventoryContainerMain}/>
          <Route path="car/:carVin" component={LayoutInventoryContainerCarMain}/>
          <Route path="aboutUs" component={redirectAboutUs ? LayoutAboutUs : LayoutContainerTemplateMain}/>
          <IndexRoute component={LayoutContainerTemplateMain}/>
        </Route>
      );
      break;
  }
}

export default Routes