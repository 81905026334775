import React, {useState} from 'react'
import {
  BOX_BACKGROUND_COLOR,
  CONTROL_BACKGROUND_COLOR,
  CONTROL_BORDER_COLOR,
  PRIMARY_COLOR,
  TEXT_COLOR,
  TEMPLATE_5_NAME,
  DARK_THEME,
  BUTTON_TEXT_COLOR,
  SECONDARY_COLOR,
  SECTION_FINANCE_ID,
  PAGE_INVENTORY_ID
} from '../../Constants'
import DynamicNumber from 'react-dynamic-number'
import {StyledButton} from '../../StyledComponentUtil'
import GetAFinance from './TemplateMainComponents/GetAFinance'
import DPButton from '../../genericComponents/DPButton'
import find from 'lodash/find'

const CarPriceCalculator = ({
                              templateCode,
                              siteColor,
                              car,
                              price,
                              currentTheme = '',
                              onChangePrice,
                              pages
                            }) => {
  const isTemplate5 = (templateCode === TEMPLATE_5_NAME)
  const [calculationValues, setCalculationValues] = useState({
    interestRate: 3.99,
    monthsInLoan: 72,
    downPaymentAmount: 0,
    amountTradeIn: 0,
    monthlyPayment: 0,
  })
  const inventoryPage = pages.find(page => page.id === PAGE_INVENTORY_ID)
  const {showSection: showFinanceSection = true} = find(inventoryPage.sections, sec => sec.id === SECTION_FINANCE_ID) || {}

  const {interestRate, monthsInLoan, downPaymentAmount, amountTradeIn, monthlyPayment} = calculationValues

  const monthlyPaymentsRound = monthlyPayment ? (Math.round(monthlyPayment * 1000) / 1000) : monthlyPayment

  const handleChange = (type, event) => {
    setCalculationValues({...calculationValues, [type]: event.target.value})
  }

  const onBlurNumber = (type, event) => {
    const value = event.target.value
    const valueNumber = Number(value.replace(/,/g, ''))
    setCalculationValues({...calculationValues, [type]: valueNumber})
  }

  const calculateCost = () => {
    if (interestRate && monthsInLoan) {
      const interestDecimal = interestRate / 100
      const monthlyInterest = interestDecimal / 12
      const x = Math.pow((1 + monthlyInterest), monthsInLoan)
      const monthlyPayment = (price - downPaymentAmount - amountTradeIn) * ((monthlyInterest * x) / (x - 1))
      setCalculationValues({...calculationValues, monthlyPayment: monthlyPayment})
    }
  }

  //ToDo: Remember using DPTextInputs instead of inputs and DynamicNumber
  return (
    <>
      <div
        className={isTemplate5 ? 'calculate-body' : 'inv-car-calculator'}
        style={!isTemplate5 ? {
          backgroundColor: siteColor[BOX_BACKGROUND_COLOR],
          borderTop: `1px solid ${currentTheme === DARK_THEME ? '#E2E2E2' : '#00000029'}`
        } : null}
      >
        {
          isTemplate5 ?
            (<>
              <div className='row-calculate'>
                <div className='col-value-left'>
                  <label className='label-display' style={{color: siteColor[TEXT_COLOR]}}>Price of vehicle ($)</label>
                  <span className='icon-symbol' style={{color: siteColor[TEXT_COLOR]}}>$</span>
                  <DynamicNumber
                    id='price'
                    className={'form-control input-symbol'}
                    value={price}
                    positive={true}
                    negative={false}
                    thousand={true}
                    readOnly={false}
                    separator={'.'}
                    integer={8}
                    fraction={4}
                    onChange={(evt) => onChangePrice(evt, evt.target.value)}
                    style={{
                      backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                      color: siteColor[TEXT_COLOR]
                    }}
                  />
                </div>
                <div className='col-value-right'>
                  <label className='label-display' style={{color: siteColor[TEXT_COLOR]}}>Interest Rate (%)</label>
                  <DynamicNumber
                    id='interestRate'
                    className={'form-control'}
                    value={interestRate}
                    positive={true}
                    negative={false}
                    thousand={true}
                    onChange={(event) => handleChange('interestRate', event)}
                    onBlur={(event) => onBlurNumber('interestRate', event)}
                    separator={'.'}
                    integer={4}
                    fraction={4}
                    style={{
                      backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                      color: siteColor[TEXT_COLOR]
                    }}
                  />
                </div>
              </div>
              <div className='row-calculate'>
                <div className='col-value-left'>
                  <label className='label-display' style={{color: siteColor[TEXT_COLOR]}}>Loan Term (Months)</label>
                  <DynamicNumber
                    id='monthsInLoan'
                    className={'form-control'}
                    value={monthsInLoan}
                    positive={true}
                    negative={false}
                    thousand={true}
                    onChange={(event) => handleChange('monthsInLoan', event)}
                    onBlur={(event) => onBlurNumber('monthsInLoan', event)}
                    separator={'.'}
                    integer={4}
                    fraction={0}
                    style={{
                      backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                      color: siteColor[TEXT_COLOR]
                    }}
                  />
                </div>
                <div className='col-value-right'>
                  <label className='label-display' style={{color: siteColor[TEXT_COLOR]}}>Down Payment ($)</label>
                  <span className='icon-symbol' style={{color: siteColor[TEXT_COLOR]}}>$</span>
                  <DynamicNumber
                    id='downPaymentAmount'
                    className={'form-control input-symbol'}
                    value={downPaymentAmount}
                    positive={true}
                    negative={false}
                    thousand={true}
                    onChange={(event) => handleChange('downPaymentAmount', event)}
                    onBlur={(event) => onBlurNumber('downPaymentAmount', event)}
                    separator={'.'}
                    integer={8}
                    fraction={0}
                    style={{
                      backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                      color: `${siteColor[TEXT_COLOR]} !important`
                    }}
                  />
                </div>
              </div>
              <div className='row-calculate'>
                <div className='col-value-left'>
                  <label className='label-display' style={{color: siteColor[TEXT_COLOR]}}>Trade-In Price ($)</label>
                  <span className='icon-symbol' style={{color: siteColor[TEXT_COLOR]}}>$</span>
                  <DynamicNumber
                    id='amountTradeIn'
                    className={'form-control input-symbol'}
                    value={amountTradeIn}
                    positive={true}
                    negative={false}
                    thousand={true}
                    onChange={(event) => handleChange('amountTradeIn', event)}
                    onBlur={(event) => onBlurNumber('amountTradeIn', event)}
                    separator={'.'}
                    integer={8}
                    fraction={0}
                    style={{
                      backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                      color: siteColor[TEXT_COLOR]
                    }}
                  />
                </div>
                <div className='col-value-right'>
                  <StyledButton
                    className='btn-calculate'
                    siteColor={siteColor}
                    onClick={calculateCost}
                  >
                    Calculate
                  </StyledButton>
                </div>
              </div>
              <div className='row-monthly-payment'>
                <div className='col-value-left'>
                  <label className='label-display' style={{color: siteColor[TEXT_COLOR]}}>Your Monthly Payment</label>
                  <div
                    className='box-monthly-payment'
                    style={{
                      backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                      border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                      color: siteColor[TEXT_COLOR]
                    }}
                  >
                    {monthlyPaymentsRound}
                  </div>
                </div>
              </div>
            </>) :
            (<>
              <div className={`container-right-calculate ${showFinanceSection ? '': 'no-finance' }`}>
                <div className='first-row'>
                  <div className='container-price-vehicle'>
                    <label style={{color: siteColor[TEXT_COLOR]}}>Price of Vehicle ($)</label>
                    <input
                      type='number' placeholder=' $'
                      value={price}
                      contentEditable={true}
                      disabled={false}
                      style={{
                        backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                        borderColor: siteColor[CONTROL_BORDER_COLOR],
                        color: siteColor[TEXT_COLOR]
                      }}
                      onChange={(evt) => onChangePrice(evt, evt.target.value)}
                    />
                  </div>
                  <div className='container-interest-rate'>
                    <label style={{color: siteColor[TEXT_COLOR]}}>Interest Rate (%)</label>
                    <input
                      type='number'
                      step={0.01}
                      value={interestRate}
                      onChange={(event) => handleChange('interestRate', event)}
                      onBlur={(event) => onBlurNumber('interestRate', event)}
                      style={{
                        backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                        borderColor: siteColor[CONTROL_BORDER_COLOR],
                        color: siteColor[TEXT_COLOR]
                      }}
                    />
                  </div>
                  <div className='container-months'>
                    <label style={{color: siteColor[TEXT_COLOR]}}>Loan Term (Months)</label>
                    <input
                      type='number'
                      value={monthsInLoan}
                      onChange={(event) => handleChange('monthsInLoan', event)}
                      onBlur={(event) => onBlurNumber('monthsInLoan', event)}
                      style={{
                        backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                        borderColor: siteColor[CONTROL_BORDER_COLOR],
                        color: siteColor[TEXT_COLOR]
                      }}
                    />
                  </div>
                </div>
                <div className='second-row'>
                  <div className='container-payment-amount'>
                    <label style={{color: siteColor[TEXT_COLOR]}}>Down Payment ($)</label>
                    <input
                      type='number'
                      placeholder=' $'
                      value={downPaymentAmount}
                      onChange={(event) => handleChange('downPaymentAmount', event)}
                      onBlur={(event) => onBlurNumber('downPaymentAmount', event)}
                      style={{
                        backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                        borderColor: siteColor[CONTROL_BORDER_COLOR],
                        color: siteColor[TEXT_COLOR]
                      }}
                    />
                  </div>
                  <div className='container-amount-for-trade'>
                    <label style={{color: siteColor[TEXT_COLOR]}}>Trade-in Price ($)</label>
                    <input
                      type='number'
                      placeholder=' $'
                      value={amountTradeIn}
                      onChange={(event) => handleChange('amountTradeIn', event)}
                      onBlur={(event) => onBlurNumber('amountTradeIn', event)}
                      style={{
                        backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                        borderColor: siteColor[CONTROL_BORDER_COLOR],
                        color: siteColor[TEXT_COLOR]
                      }}
                    />
                  </div>
                </div>
                <div className='third-row'>
                  <div className='container-button-calculate'>
                    <DPButton
                      buttonClassName='btn-calculate'
                      textColor={siteColor[BUTTON_TEXT_COLOR]}
                      backgroundColor={siteColor[PRIMARY_COLOR]}
                      backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                      size='DP-MEDIUM'
                      onClick={calculateCost}
                      label={'Calculate'}
                    />
                  </div>
                </div>
                <div className='fourth-row'>
                  <div className='container-monthly-payment'>
                    <label style={{color: siteColor[TEXT_COLOR]}}>Your monthly payment</label>
                    <input
                      style={{
                        backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                        borderColor: siteColor[PRIMARY_COLOR],
                        color: siteColor[TEXT_COLOR]
                      }}
                      type='number'
                      value={monthlyPaymentsRound}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {
                showFinanceSection ?
                  <GetAFinance siteColor={siteColor}/>
                  : null
              }

            </>)
        }
      </div>
    </>
  )
}

export default CarPriceCalculator
