import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {LOCATION_ALL, CURRENT_LOCATION, BOX_BORDER_COLOR} from './Constants.js'
import find from 'lodash/find'
import PropTypes from 'prop-types'

let map;
let marker;

export default class GoogleMaps extends Component {

  constructor() {
    super()
  }

  componentWillReceiveProps(nextProps) {
    this.renderMap(nextProps)
  }

  shouldComponentUpdate() {
    return false
  }

  renderMap(props){
    map = new google.maps.Map(this.refs.map, {
      center:{lat :props.lat, lng:props.lng},
      zoom:16,
      draggable: false,
      scrollwheel:  false
    });

    const {locations, locationId} = props
    if (locationId === LOCATION_ALL || !locationId) {
      locations.forEach(location => {
        let showMarker = false
        if (location.locationId === CURRENT_LOCATION)
          showMarker = true
        this.geoCoder(location, locationId, showMarker)
      })
    } else {
      const locationFound = find(locations, location => (location.locationId ?? location.temporalId) === locationId)
      if (locationFound) {
        this.geoCoder(locationFound, locationId, true)
      }
    }
  }

  componentDidMount() {
    this.renderMap(this.props)
  }

  geoCoder(location, locationId) {
    const that = this
    const address = location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zip
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({'address': address}, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        const latitude = results[0].geometry.location.lat()
        const longitude = results[0].geometry.location.lng()
        if (locationId && locationId !== LOCATION_ALL) {
          map.setCenter(new google.maps.LatLng(latitude, longitude))
        }
        marker = new google.maps.Marker({
          position: that.mapCenterLatLng(latitude, longitude),
          map: map
        })
        that.createMarker(map, marker, location.name, address, latitude, longitude)
      }
    })
  }

  createMarker(map, marker, name, address, lat, lng) {
    const that = this
    let infowindow = new google.maps.InfoWindow({
      content: 'div'
    })
    google.maps.event.addListener(marker, 'click', function () {
      const div = document.createElement('div')
      ReactDOM.render(that._renderInfoWindow(name, address, lat, lng), div)
      infowindow.setContent(div)
      infowindow.open(map, marker)
    })
  }

  mapCenterLatLng(lat, lng) {
    return new google.maps.LatLng(lat, lng)
  }

  onGetDirections = (lat, lng, e) => {
    e.preventDefault()
    if (this.props.isViewOnly)
      return
    window.open('https://maps.google.com/maps?q=' + lat + ',' + lng)
  }

  _renderInfoWindow(name, address, lat, lng) {
    return (
      <div id="gmapToolTip">
        <h4 id="locationgeo1" className="text-center">{name}</h4>
        <div id="locationgeo2" className="text-center">{address}</div>
        <div id="locationgeo3" className="text-center">
          <div id="locationgeo4" onClick={this.onGetDirections.bind(this, lat, lng)} className="btn btn-default">
            GET DIRECTIONS
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {siteColor} = this.props
    return (
      <div
        id="map"
        ref="map"
        style={ siteColor ?
          {
            borderTop: `1px solid ${siteColor[BOX_BORDER_COLOR]}`,
            borderBottom: `1px solid ${siteColor[BOX_BORDER_COLOR]}`,
            borderRight: `1px solid ${siteColor[BOX_BORDER_COLOR]}`
          }
          : {}
      }
      />
    )
  }

}

GoogleMaps.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number
}

GoogleMaps.defaultProps = {
  lat: 40.2256805,
  lng: -111.64971980000001
}
