import React from "react";
// @ts-ignore
import classnames from 'classnames'
// @ts-ignore
import DPButton from '../DPButton'
// @ts-ignore
import {capitalize} from "../../Util";
import './DPDaySelector.scss'
// @ts-ignore
import { reduce, keyBy } from 'lodash'

interface Day {
  id: string
  fullName: string
  displayName: string
  abbreviation: string
  order: number
}

const daysOfWeek: Day[] = [
  {id: 'sunday' , fullName: 'Sunday', displayName: 'SU', abbreviation: 'Sun', order: 0 },
  {id: 'monday' , fullName: 'Monday', displayName: 'MO', abbreviation: 'Mon', order: 1 },
  {id: 'tuesday' , fullName: 'Tuesday', displayName: 'TU', abbreviation: 'Tue', order: 2 },
  {id: 'wednesday' , fullName: 'Wednesday', displayName: 'WE', abbreviation: 'Wed', order: 3 },
  {id: 'thursday' , fullName: 'Thursday', displayName: 'TH', abbreviation: 'Thu', order: 4 },
  {id: 'friday' , fullName: 'Friday', displayName: 'FR', abbreviation: 'Fri', order: 5 },
  {id: 'saturday' , fullName: 'Saturday', displayName: 'SA', abbreviation: 'Sat', order: 6 },
]

interface DaySelectorProp {
  daysSelected: string[]
  daysDisabled?: string[]
  onClick: any
}

const daysById= keyBy(daysOfWeek, 'id')

const areConsecutive = (day1: Day, day2: Day): boolean => day1.order + 1 === day2.order
// ['monday', 'tuesday', 'wednesday', 'sunday'] => [['monday', 'tuesday', 'wednesday'], ['sunday']]
// @ts-ignore
const getGroupedConsecutiveDays = (dayIds: string[], daysById: {[id: string] : Day}): string[][] => {
  return reduce(dayIds, (groupedDays: string[][], dayId: string) => {
    const lastGroup = groupedDays[groupedDays.length - 1]
    if (!lastGroup)
      return [[dayId]]

    const lastDayOfLastGroup = daysById[lastGroup[lastGroup.length - 1]]
    const currentDay = daysById[dayId]
    if (areConsecutive(lastDayOfLastGroup, currentDay))
      return [...groupedDays.slice(0, groupedDays.length - 1), [...lastGroup, dayId]]
    else
      return [...groupedDays, [dayId]]
  }, [])
}
export const getDaysDisplay = (selectedDayIds: string[]): string => {

  const groupedConsecutiveDays = getGroupedConsecutiveDays(selectedDayIds, daysById)

  if(groupedConsecutiveDays.length === 1 && groupedConsecutiveDays[0].length === 1)
    return daysById[groupedConsecutiveDays[0][0]].fullName

  return groupedConsecutiveDays.map((group) => {
    if (group.length === 1)
      return daysById[group[0]].abbreviation
    return `${daysById[group[0]].abbreviation}-${daysById[group[group.length - 1]].abbreviation}`
  }).join(', ')
}

export const sortSelectedDays = ( selectedDays: string[]) => selectedDays.sort((a, b) => daysById[a].order - daysById[b].order )

const DPDaySelector = (props: DaySelectorProp) => {
  const { daysSelected = [], daysDisabled = [], onClick } = props

  const updateDayToList = (dayId: string) => {
    const foundDay = daysSelected.find(day => day === dayId)
    return !foundDay
      ? [...daysSelected, dayId]
      : daysSelected.filter(day => day !== dayId)
  }

  return(
    <div className="container-days-of-week">
      {
        daysOfWeek.map((day) => {
          const dayFound = daysSelected.find( dayItem => dayItem === day.id )
          const dayDisabled = daysDisabled.find( dayDisabled => dayDisabled.includes(day.id) )
          return(
            <DPButton
              key={day.id}
              width='160px'
              buttonClassName={classnames(["day-of-week", dayFound ? "day-selected" : "", dayDisabled ? "day-disabled" : ""])}
              onClick={() => onClick(sortSelectedDays(updateDayToList(day.id)))}
              label={day.displayName}
              isDisabled={dayDisabled}
            />
          )
        })
      }
    </div>
  )
}

export default DPDaySelector