import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {PRIMARY_COLOR, SITE_BACKGROUND_COLOR} from './Constants'

const hoverContentStyle = {
  position: 'absolute',
  float: 'left',
  width: '100%',
  top: 0,
  height: '100%',
  backgroundColor: '#D10C0C',
  opacity: '0.68',
  borderRadius: '7px',
  padding: '0',
  alignItems: 'center',
  justifyContent: 'center',
}

export const HoverOverlayCar = (props) => {
  const [showHoverOverlay, setShowHoverOverlay] = useState(false)

  return (
    <div
      onMouseEnter={props.showHoverContent
        ? () => setShowHoverOverlay(true)
        : null
      }
      onMouseLeave={props.showHoverContent
        ? () => setShowHoverOverlay(false)
        : null
      }
      style={{
        ...props.containerStyle,
        backgroundColor: props.siteColor[SITE_BACKGROUND_COLOR],
      }}
      data-testid={`car-hover-${props.indexCar}`}
    >
      <props.WrappedComponent {...props}/>
      {
        showHoverOverlay
          ? <div
            className={props.hoveredContentClassName}
            style={{
              ...hoverContentStyle,
              backgroundColor: props.siteColor[PRIMARY_COLOR],
              display: 'flex'
            }}
          >
            <props.WrapperHoverComponent
              indexCar={props.indexCar}
              siteColor={props.siteColor}
              viewMore={() => {
                if (props.isAdminTemplate)
                  return
                props.viewMore(props.car.vin)
              }}
              car={props.car}
            />
          </div>
          : null
      }
    </div>
  )
}

HoverOverlayCar.propTypes = {
  showHoverContent: PropTypes.bool,
  containerStyle: PropTypes.object,
  siteColor: PropTypes.object,
  indexCar: PropTypes.string,
  viewMore: PropTypes.func,
  car: PropTypes.object,
}
