import React, {useEffect, useState} from 'react'
import {ReactSVG} from 'react-svg'
import './DPSelectDropdown.scss'
import {
  CONTROL_BACKGROUND_COLOR,
  BOX_BORDER_COLOR,
  pathServer,
  PRIMARY_COLOR,
  TEXT_COLOR,
  CONTROL_BORDER_COLOR,
  CHECKBOX_AND_EXPAND_COLOR
} from '../../Constants'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash'

const defaultColors = {
  [CONTROL_BORDER_COLOR]: '#CACACA',
  [BOX_BORDER_COLOR]: '#707070',
  [PRIMARY_COLOR]: '#515151',
  [TEXT_COLOR]: '#515151',
  [CONTROL_BACKGROUND_COLOR]: '#ffffff',
  [CHECKBOX_AND_EXPAND_COLOR]: '#CACACA'
}

const MultiSelectOption = ({selected, handleSelect, value, siteColor, children, sameCheckboxColor}) => {

  const handleSelectOption = (event) => {
    event.preventDefault()
    handleSelect(value)
  }

  const checkBoxIcon = selected ? (
    <ReactSVG
      style={{fill: siteColor[sameCheckboxColor || PRIMARY_COLOR], height: '24px', width: '24px'}}
      src={pathServer.PATH_IMG + 'icon/ic_check_box.svg'}
    />
  ) : (
    <ReactSVG
      style={{
        fill: siteColor[sameCheckboxColor || CHECKBOX_AND_EXPAND_COLOR],
        height: '24px',
        width: '24px'
      }}
      src={pathServer.PATH_IMG + 'icon/ic_uncheck_box.svg'}
    />
  )

  return (
    <div className='dp-multiselect-option'>
      <div className='dp-option' onClick={handleSelectOption}/>
      <div>
        {checkBoxIcon}
      </div>
      <span>{children}</span>
    </div>
  )
}

const SelectOption = ({displayValue, handleSelect, value}) => {
  const handleSelectOption = (event) => {
    event.preventDefault()
    handleSelect(value)
  }

  return (
    <div className='dp-option' onClick={handleSelectOption}>
      {displayValue}
    </div>
  )
}

export const DPSelectDropdown = ({
                                   id,
                                   options,
                                   name,
                                   placeholder,
                                   onSelect,
                                   selectedValue,
                                   siteColor = defaultColors,
                                   label,
                                   labelClassName,
                                   isDisabled = false,
                                   multiple = false,
                                   hasGroups = false,
                                   isPopover = true,
                                   sameCheckboxColor = null,
                                   selectClassName = '',
                                   selectDeployedClassName = '',
                                   contentClassName = '',
                                   optionsClassName = '',
                                   styles = null,
                                   isRequired = false
                                 }) => {
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(isDisabled)


  const [selectTestId, menuTestId, buttonTestId, clearFiltersTestId, listTestId] = [
    `dp-select--${id}`,
    `dp-select--${id}-menu`,
    `dp-select--${id}-button`,
    `dp-select--${id}-clear-filters`,
    `dp-select--${id}-list`
  ]

  const joinedOptions = hasGroups ? Object.values(options).flat() : options

  const themeStyles = {
    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
    borderColor: siteColor[CONTROL_BORDER_COLOR],
    color: disabled ? '#999999' : siteColor[TEXT_COLOR]
  }

  useEffect(() => {
    if (isEmpty(options)) {
      setDisabled(true)
      setOpen(false)
      onSelect(multiple ? [] : '')
    } else if (!isDisabled) {
      setDisabled(false)
    }
  }, [options])

  function getDisplayValue() {
    if (multiple && selectedValue.length === 1) {
      const option = joinedOptions.find((option) => selectedValue.includes(option.id))
      if (option && option.name) return (<span>{option.name}</span>)
    }
    if (multiple && selectedValue[0]) return (
      <div className='dp-multiselect-display-val'>
        <span>{`${name} (${selectedValue.length})`}</span>
      </div>
    )
    if (!multiple && selectedValue) {
      const option = joinedOptions.find((option) => selectedValue === option.id)
      if (option && option.name) return (<span>{option.name}</span>)
    }
    return placeholder
  }

  function handleSelect(option) {
    if (!multiple) {
      onSelect(option)
      setOpen(false)
      return
    }
    onSelect(selectedValue.includes(option) ?
      selectedValue.filter(value => value !== option) : [...selectedValue, option])
  }

  function getOptionsList(options) {
    return multiple ?
    options.map(option => (
        <MultiSelectOption
          key={option.id}
          siteColor={siteColor}
          handleSelect={handleSelect}
          selected={selectedValue.indexOf(option.id) > -1}
          value={option.id}
          sameCheckboxColor={sameCheckboxColor}
        >
          {option.name}
        </MultiSelectOption>
    ))
    :
    options.map(option=>(
      <SelectOption displayValue={option.name} value={option.id} handleSelect={handleSelect}/>
    ))
  }


  const selectIcon = open ? (
    <ReactSVG
      className='dp-select-arrow'
      style={{fill: siteColor[CHECKBOX_AND_EXPAND_COLOR], cursor: 'pointer'}}
      src={pathServer.PATH_IMG + 'icon/ic_expand_less.svg'}
    />
  ) : (
    <ReactSVG
      className='dp-select-arrow'
      style={{fill: siteColor[CHECKBOX_AND_EXPAND_COLOR], cursor: 'pointer'}}
      src={pathServer.PATH_IMG + 'icon/ic_expand_more.svg'}
    />
  )
  const optionsList = hasGroups ? Object.keys(options).map(groupName => {
    const items = getOptionsList(options[groupName])
    return [<span key={groupName} className='dp-select-subheader'>{groupName}</span>, items]
  }) : getOptionsList(options)

  return (
    <div id={id} className={'dp-select-container ' + selectClassName} style={styles} data-testid={selectTestId}>
      {label &&
        <label style={{color: siteColor[TEXT_COLOR]}} className={labelClassName}>
          {label}
          {isRequired &&
            <span className='label-error'>*</span>
          }
        </label>
      }
      <div className={`dp-select-element ${contentClassName} ${open && selectDeployedClassName}`} role='button'
           tabIndex={0}
           onClick={() => (!disabled && setOpen(!open))} style={themeStyles} data-testid={buttonTestId}>
        {getDisplayValue()}
        {selectIcon}
      </div>
      {
        open && (
          <>
            <div className={`dp-select-paper ${isPopover ? 'p-abs' : 'p-rel'} ${optionsClassName}`} style={themeStyles}
                 data-testid={menuTestId}>
              <div className={'dp-select-clear-button'}>
                {multiple && <span data-testid={clearFiltersTestId} onClick={() => onSelect([])}>Clear filters</span>}
              </div>
              <div className={'dp-select-options-list'} data-testid={listTestId}>
                {optionsList}
              </div>
            </div>
            {isPopover && <div className='dp-select-overlay' onClick={() => setOpen(false)}/>}
          </>
        )
      }
    </div>
  )
}

DPSelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  //if hasGroups is false options is an array of objects, else is an object with keys as group names and grouped options as arrays of objects
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.shape({
      [PropTypes.string]: PropTypes.arrayOf(PropTypes.object)
    }),
  ]).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  siteColor: PropTypes.object,
  isDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  hasGroups: PropTypes.bool,
  isPopover: PropTypes.bool,
  isRequired: PropTypes.bool,
  sameCheckboxColor: PropTypes.string,
  selectClassName: PropTypes.string,
  selectDeployedClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  styles: PropTypes.object,
}
