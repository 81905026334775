import React, {useState} from 'react'
import {
  BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_5,
  EASY_NAVIGATION_B_TEMPLATE_CODE,
  MILES,
  MODERN_VIEW_2_TEMPLATE_CODE,
  MODERN_VIEW_3_TEMPLATE_CODE,
  MODERN_VIEW_TEMPLATE_CODE,
  EASY_NAVIGATION_TEMPLATE_CODE,
  EASY_NAVIGATION_C_TEMPLATE_CODE,
  BUTTON_TEXT_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  BOX_BACKGROUND_COLOR,
  LIGHT_THEME,
  LIGHT_COLORS, DARK_COLORS,
  WIDTH_VIEW_MOBILE
} from '../../Constants'
import {browserHistory} from 'react-router'
import PropTypes from 'prop-types'
import CarQuickSearch from './CarQuickSearch.jsx'
import CarSearch from './CarSearch'
import DPSocialMedia from '../../DPSocialMedia'
import {cloneDeep, isEmpty} from 'lodash'
import {difference, filter} from 'lodash'
import {getBaseTemplateCode} from '../../Util'
import './LayoutBanner.scss'
import DPButton from '../../genericComponents/DPButton'

const initialFilterValues = {
  selectedMakes: [],
  selectedModels: [],
  selectedBodies: [],
  selectedConditions: [],
  yearFrom: '',
  yearTo: '',
  milesFrom: '',
  milesTo: '',
  priceTo: '',
  priceFrom: '',
  keyword: ''
}

const optionsBannerClasses = {
  [EASY_NAVIGATION_TEMPLATE_CODE]: {
    classNameBannerTitle: 'banner-title',
    btnApplyContainerClassname: 'btn-apply-content',
    btnApplyClassname: 'btn-apply',
  },
  [EASY_NAVIGATION_B_TEMPLATE_CODE]: {
    classNameBannerTitle: 'banner-title-setting-easy-navigation',
    btnApplyContainerClassname: 'btn-apply-content-settings',
    btnApplyClassname: 'btn-apply-settings',
    carSearchContainerClasName: 'car-search-container'
  },
  [EASY_NAVIGATION_C_TEMPLATE_CODE]: {
    classNameBannerTitle: 'banner-title',
    btnApplyContainerClassname: 'btn-apply-content',
    btnApplyClassname: 'btn-apply',
  },
  [MODERN_VIEW_TEMPLATE_CODE]: {
    classNameBannerTitle: 'banner-title-setting',
    btnApplyContainerClassname: 'btn-apply-content-settings',
    btnApplyClassname: 'btn-apply-settings',
    carSearchContainerClasName: 'car-search-container'
  },
  [MODERN_VIEW_2_TEMPLATE_CODE]: {
    classNameBannerTitle: 'banner-title-setting-modern-view',
    btnApplyContainerClassname: 'btn-apply-content-settings-modern-view',
    btnApplyClassname: 'btn-apply-settings',
    carSearchContainerClasName: 'car-search-container'
  },
  [MODERN_VIEW_3_TEMPLATE_CODE]: {
    classNameBannerTitle: 'banner-title-setting-modern-view-3',
    btnApplyContainerClassname: 'btn-apply-content',
    btnApplyClassname: 'btn-apply',
  }
}

const LayoutBanner = ({
                        slogan, backgroundMain, maxHeight, templateCode, siteColor,
                        searchInventoryPublic, dealerId, makes, models, socialMedia,
                        isShowOptionsClickable, toggleCarAdvancedSearchMenu, isAdminTemplate,
                        currentTheme
                      }) => {
  const {[BOX_BACKGROUND_COLOR]: boxBackgroundColor,} = siteColor
  const baseTemplateCode = getBaseTemplateCode(templateCode)
  const {
    classNameBannerTitle, btnApplyContainerClassname, btnApplyClassname,
    carSearchContainerClasName
  } = optionsBannerClasses[templateCode]
  const advancedSearchOpen = (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE ||
    templateCode === MODERN_VIEW_TEMPLATE_CODE ||
    templateCode === MODERN_VIEW_2_TEMPLATE_CODE ||
    templateCode === MODERN_VIEW_3_TEMPLATE_CODE) // Here, consider the model view 3 because of the style of css banner
  const carSearchContainerClasNameFiltered = (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE && isAdminTemplate ?
    'car-search-container-settings-easy-navigation' : carSearchContainerClasName)
  const [showCarSearch, setShowCarSearch] = useState(templateCode === MODERN_VIEW_3_TEMPLATE_CODE)
  const [filterValues, setFilterValues] = useState(cloneDeep(initialFilterValues))
  const [loading, setLoading] = useState(false)
  const [modelsByMakeIds, setModelsByMakeIds] = useState([])

  const onChangeInputFilters = (event, value) => {
    event.preventDefault()
    const key = event.target.id
    const newValue = event.target.id === 'keyword' ? event.target.value : value
    setFilterValues(prevState => ({
      ...prevState,
      [key]: newValue
    }))
  }

  const isValidValue = (value) => {
    return value && value !== '' && value !== 0
  }

  const handleFiltersCheck = (type, values) => {
    if (type === 'selectedMakes') {
      let modelsByMakeIdMem = {}
      const addedValue = difference(values, filterValues.selectedMakes)[0]
      const deleteValue = difference(filterValues.selectedMakes, values)
      if (addedValue) {
        const modelsFoundToAdd = filter(models, {makeId: addedValue})
        const makeNameToAdd = filter(makes, {id: addedValue})[0].name
        const modelsDataGroup = modelsFoundToAdd.map(({id, name}) => {
          return {id, name}
        })
        modelsByMakeIdMem = {...modelsByMakeIds, [makeNameToAdd]: modelsDataGroup}
      } else {
        modelsByMakeIdMem = {...modelsByMakeIds}
        deleteValue.forEach(makeId => {
          const makeNameToDelete = filter(makes, {id: makeId})[0].name
          delete modelsByMakeIdMem[makeNameToDelete]
          if (filterValues.selectedModels) {
            const modelsByMakeIdToDelete = filter(models, {makeId: makeId}).map(({id}) => id)
            const modelsFilteredToAdd = filter(filterValues.selectedModels, modelId => !modelsByMakeIdToDelete.includes(modelId))
            setFilterValues(prevState => ({
              ...prevState,
              selectedModels: modelsFilteredToAdd.map((modelId) => modelId)
            }))
          }
        })
      }
      setModelsByMakeIds(modelsByMakeIdMem)
    }
    setFilterValues(prevState => ({
      ...prevState,
      [type]: values
    }))
  }

  const search = (isAdvancedSearch = true) => {
    let filter = {}
    const {
      selectedMakes, selectedModels, selectedConditions, yearFrom, yearTo, priceFrom, priceTo,
      milesFrom, milesTo, selectedBodies, keyword
    } = filterValues

    if (isAdvancedSearch) {
      if (selectedMakes.length > 0)
        filter.makes = selectedMakes

      if (selectedModels.length > 0)
        filter.models = selectedModels

      if (selectedConditions.length > 0)
        filter.extCondition = selectedConditions

      if (isValidValue(yearFrom))
        filter.rangeYearFrom = yearFrom.toString()

      if (isValidValue(yearTo)) {
        if (isValidValue(yearFrom)) {
          if (yearTo >= yearFrom)
            filter.rangeYearTo = yearTo.toString()
        } else
          filter.rangeYearTo = yearTo.toString()
      }

      if (isValidValue(priceFrom))
        filter.rangePriceFrom = priceFrom

      if (isValidValue(priceTo)) {
        if (isValidValue(priceFrom)) {
          if (priceTo >= priceFrom)
            filter.rangePriceTo = priceTo
        } else
          filter.rangePriceTo = priceTo
      }

      if (isValidValue(milesFrom))
        filter.rangeMileageFrom = milesFrom * MILES

      if (isValidValue(milesTo)) {
        if (isValidValue(milesFrom)) {
          if (milesTo >= milesFrom)
            filter.rangeMileageTo = milesTo * MILES
        } else {
          filter.rangeMileageTo = milesTo * MILES
        }
      }

      if (selectedBodies.length > 0)
        filter.body = selectedBodies
    } else {
      setFilterValues(prevState => ({
        ...cloneDeep(initialFilterValues),
        keyword: prevState.keyword
      }))
      setModelsByMakeIds([])
    }

    if (!!keyword) {
      filter.keyword = keyword
    }
    setLoading(true)
    searchInventoryPublic(dealerId, filter).then(response => {
      setLoading(false)
    }).catch(error => {
      setLoading(false)
    })
  }

  const goToFinance = () => {
    if (isAdminTemplate)
      return
    browserHistory.push(`/catalog/finance`)
  }

  const toggleTemplateMainAdvancedSearch = () => {
    setShowCarSearch(prevState => !prevState)
    toggleCarAdvancedSearchMenu()
  }

  return (
    <div
      id='bannerContainer'
      className={`banner-container${advancedSearchOpen ? '-5' : ''}`}
      style={ [MODERN_VIEW_3_TEMPLATE_CODE, MODERN_VIEW_2_TEMPLATE_CODE].includes(templateCode) ?
        {'paddingTop': 0} : {} }
      data-testid='search-form'
    >
      {
        advancedSearchOpen ?
          <>
            <div id='bannerSeparator' className='banner-separator'/>
            <img
              id='background'
              className='background'
              style={{
                maxHeight: maxHeight >= BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_5 ?
                  maxHeight : BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_5
              }}
              src={backgroundMain}
            />
            <div
              id='bannerTitle'
              className={isAdminTemplate && !isEmpty(classNameBannerTitle) ? classNameBannerTitle : 'banner-title'}>
              <div>
                <h1 id='titleBanner5'>{slogan}</h1>
              </div>
              {
                [MODERN_VIEW_TEMPLATE_CODE, MODERN_VIEW_2_TEMPLATE_CODE].includes(templateCode) && (
                  <div className={btnApplyContainerClassname}>
                    <DPButton
                      buttonClassName={`${btnApplyClassname}`}
                      onClick={goToFinance}
                      label={templateCode === MODERN_VIEW_2_TEMPLATE_CODE ? 'Apply now' :'APPLY NOW'}
                      textColor={siteColor[BUTTON_TEXT_COLOR]}
                      backgroundColor={siteColor[PRIMARY_COLOR]}
                      backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                      isAdmin={isAdminTemplate}
                    />
                  </div>
                )
              }
            </div>
            {
              showCarSearch && ( window.innerWidth > WIDTH_VIEW_MOBILE) ?
                <div className='car-search-container'
                     style={
                       (templateCode === MODERN_VIEW_3_TEMPLATE_CODE && showCarSearch) &&
                         {display: 'block'}
                     }
                >
                  <CarQuickSearch
                    setShowFilters={() => setShowCarSearch(false)}
                    siteColor={siteColor}
                    dealerId={dealerId}
                    search={search}
                    filterValues={filterValues}
                    placeholder={'What car are you looking for?'}
                    baseTemplateCode={baseTemplateCode}
                    onChangeInputFilters={onChangeInputFilters}
                    isAdminTemplate={isAdminTemplate}
                  />
                </div>
                :
                <CarSearch
                  siteColor={siteColor}
                  templateCode={templateCode}
                  baseTemplateCode={baseTemplateCode}
                  onClose={templateCode === MODERN_VIEW_3_TEMPLATE_CODE ? () => setShowCarSearch(true)
                    : null}
                  searchInventoryPublic={searchInventoryPublic}
                  dealerId={dealerId}
                  makes={makes}
                  models={models}
                  filterValues={filterValues}
                  onChangeInputFilters={onChangeInputFilters}
                  search={search}
                  handleFiltersCheck={handleFiltersCheck}
                  loading={loading}
                  modelsByMakeIds={modelsByMakeIds}
                  isAdminTemplate={isAdminTemplate}
                  carSearchContainerClasName={carSearchContainerClasNameFiltered}
                  currentTheme={currentTheme}
                />
            }
          </> :
          <>
            <div className='car-search'>
              {
                showCarSearch ?
                  <CarSearch
                    siteColor={siteColor}
                    searchInventoryPublic={searchInventoryPublic}
                    makes={makes}
                    models={models}
                    dealerId={dealerId}
                    onChangeInputFilters={onChangeInputFilters}
                    filterValues={filterValues}
                    search={search}
                    handleFiltersCheck={handleFiltersCheck}
                    loading={loading}
                    modelsByMakeIds={modelsByMakeIds}
                    templateCode={templateCode}
                    baseTemplateCode={baseTemplateCode}
                    isAdminTemplate={isAdminTemplate}
                    carSearchContainerClasName={carSearchContainerClasNameFiltered}
                    currentTheme={currentTheme}
                  />
                  :
                  <CarQuickSearch
                    filterValues={filterValues}
                    search={search}
                    placeholder={'Find your dream car ...'}
                    baseTemplateCode={baseTemplateCode}
                    onChangeInputFilters={onChangeInputFilters}
                    isAdminTemplate={isAdminTemplate}
                  />
              }
              <div className='show-options-wrapper'>
                <DPButton
                  buttonClassName='btn-show-options'
                  size='DP-SMALL'
                  textColor={siteColor[BUTTON_TEXT_COLOR]}
                  backgroundColor={siteColor[PRIMARY_COLOR]}
                  backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                  onClick={isShowOptionsClickable ? toggleTemplateMainAdvancedSearch : () => {
                  }}
                  label={showCarSearch ? 'Hide options' : 'Show options'}
                  isDisabled={isAdminTemplate}
                />
              </div>
            </div>
            <div className='banner-content-img'>
              <div id='bannerTitle' className='banner-title'>
                <h1
                  style={showCarSearch && window.innerWidth < 520 ? {display: 'none'} : null}
                >
                  {slogan}
                </h1>
              </div>
              <DPSocialMedia
                socialMedia={socialMedia}
              />
            </div>
          </>
      }
    </div>
  )
}

LayoutBanner.propTypes = {
  slogan: PropTypes.string,
  backgroundMain: PropTypes.string,
  maxHeight: PropTypes.string,
  socialMedia: PropTypes.object,
  siteColor: PropTypes.object,
  templateCode: PropTypes.string,
  dealerId: PropTypes.string,
  makes: PropTypes.arrayOf(PropTypes.string),
  models: PropTypes.arrayOf(PropTypes.string),
  baseTemplateCode: PropTypes.string,
  isShowOptionsClickable: PropTypes.bool,
  searchInventoryPublic: PropTypes.func,
  toggleCarAdvancedSearchMenu: PropTypes.func,
  isAdminTemplate: PropTypes.bool,
  classNameBannerTitle: PropTypes.string,
  btnApplyClassname: PropTypes.string,
  btnApplyContainerClassname: PropTypes.string,
  carSearchContainerClasName: PropTypes.string,
}

LayoutBanner.defaultProps = {
  slogan: '',
  backgroundMain: '',
  maxHeight: '',
  socialMedia: {},
  siteColor: {},
  templateCode: '',
  dealerId: '',
  makes: [],
  models: [],
  baseTemplateCode: '',
  isShowOptionsClickable: false,
  isAdminTemplate: false,
  classNameBannerTitle: '',
  btnApplyClassname: '',
  btnApplyContainerClassname: '',
  carSearchContainerClasName: '',
  searchInventoryPublic: () => {
  },
  toggleCarAdvancedSearchMenu: () => {
  }
}

export default LayoutBanner