import React from 'react'
import {pathServer} from '../../Constants'
import {ReactSVG} from 'react-svg'
import './SideDrawer.scss'

const SideDrawer = props => {
  const {backgroundColor, clicked, children, open} = props
  return (
    <div id='side-drawer'
         className={open ? 'side-drawer side-drawer-open' : 'side-drawer side-drawer-close'}
         style={backgroundColor ? {backgroundColor: backgroundColor} : {}}
         data-testid='dp-sidedrawer'
    >
      <ReactSVG
        src={pathServer.PATH_IMG + 'icon/ic_cancel.svg'}
        beforeInjection={svg => svg.classList.add('exit-button')}
        className='toggle-button' onClick={() => clicked()}
      />
      <div className='side-drawer-body'>
        {children}
      </div>
    </div>
  )
}

export default SideDrawer