import React from "react";
import DPSelectDropDown from "../../../common/DPSelectionDropDown";
// @ts-ignore
import classnames from 'classnames'
// @ts-ignore
import DPCheckbox from '../../../../admin/common/DPCheckbox'
// @ts-ignore
import {DPInput} from '../../../../admin/common/DPInput'
// @ts-ignore
import DPIcon from '../../../../admin/common/DPIcon'
import "./OpeningHour.scss"
// @ts-ignore
import {optionHours, OptionOpeningHours24, OptionOpeningHours} from "../../../../admin/Constants"
import DPDaySelector from "../../../../common/genericComponents/DPDaySelector";
// @ts-ignore
import { keyBy } from 'lodash'
import {HourOperation} from "../../../../common/models/Location";

const openingOptions = [
  { id: 'specificHours', displayValue: 'Specific hours'},
  { id: 'appointmentOnly', displayValue: 'Appointment only'},
  { id: 'closed', displayValue: 'Closed'},
]

export const openingOptionsById = keyBy(openingOptions, "id")

interface OpeningHourProp  {
  hourOperation: HourOperation
  selectHour: any
  index: number
  selectOption: any
  deleteOpeningHour: any
  daysDisabled?: string[]
}

const OpeningHour = (props: OpeningHourProp) => {
  const { hourOperation, selectHour, index , selectOption, deleteOpeningHour, daysDisabled} = props
  const { days, optionSelected = 'specificHours',
          format24 = false, from, to} = hourOperation || {}

  const hoursOptions = format24 ? OptionOpeningHours24 : OptionOpeningHours

  const updateDays = (newDaysSelected: string[]) => {
    selectOption(newDaysSelected, "days", index)
  }

  return(
    <div className="opening-hours-container">
      <div className="left-container">
        <DPDaySelector
          daysSelected={days}
          onClick={updateDays}
          daysDisabled={daysDisabled}
        />
        <DPSelectDropDown
          options={openingOptions}
          value={optionSelected}
          onClick={(id: string) => selectOption(id, "optionSelected", index)}
          heightOfSelect={40}
        />
      </div>
      {
        optionSelected === 'specificHours' ?
          <>
            <DPCheckbox
              label="24 hours format"
              className="check-box-hours"
              checked={format24}
              onCheck={ (evt: any) => selectOption(evt.target.checked,"format24", index)}
            />
            <div className="container-hours">
              <DPSelectDropDown
                options={hoursOptions}
                value={from}
                onClick={(id: string) => selectHour(id, 'from', index)}
                heightOfSelect={40}
                className="from-input"
              />
              <span>to</span>
              <DPSelectDropDown
                options={hoursOptions}
                value={to}
                onClick={(id: string) => selectHour(id, 'to', index)}
                heightOfSelect={40}
              />
            </div>
          </> : null
      }
      <DPIcon
        icon={"Trash"}
        className="icon-class"
        style={{width: 'unset', height: 'unset'}}
        onClick={() => deleteOpeningHour(index)}
      />
    </div>
  )
}

export  default OpeningHour