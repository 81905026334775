import React from 'react'
import {browserHistory} from 'react-router'
import {HoverOverlayCar} from '../HoverOverlayCar'
import {PRIMARY_COLOR, ATTRIBUTE_CODE_CAR_STATUS, carStatus2, optionTrans} from '../Constants'
import DPCarItem from './DPCarItem'
import {getMainImageURL} from '../Util'

const WrapperHoverComponent = (props) => (
  <div className='row-details'>
    <div className='btn-content-details'>
      <a
        id={'btnDetails' + props.indexCar}
        className='btn btn-details'
        onClick={() => {
          if (props.isAdminTemplate)
            return
          props.viewMore(props.car.vin)
        }}
        style={{color: props.siteColor[PRIMARY_COLOR]}}
        data-testid='dp-details-button'
      >
        DETAILS
      </a>
    </div>
  </div>
)

export const DPCarGridItemEasyNavigation = ({
                                              car,
                                              indexCar,
                                              information,
                                              carImageHeight,
                                              siteColor,
                                              carfaxValue,
                                              isAdminTemplate
                                            }) => {

  if (!car)
    return null

  const containerStyle = {
    borderRadius: '7px',
    boxShadow: '0 3px 6px #00000029',
    float: 'left',
    width: '100%',
    backgroundColor: '#fff',
    position: 'relative'
  }

  const trans = car['car.transmission'] ? car['car.transmission'].attributeValue : ''
  const trimCar = (car['car.trim'] ? car['car.trim'].attributeValue : '') === '-' ? '' : (car['car.trim'] ? car['car.trim'].attributeValue : '')
  const transmission = typeof optionTrans[trans] === 'undefined' ? null : optionTrans[trans]
  const viewMore = (vin, isAdminTemplate) => {
    if (isAdminTemplate)
      return
    browserHistory.push(`/catalog/car/${vin}`)
  }

  return (
    <HoverOverlayCar
      WrappedComponent={DPCarItem}
      WrapperHoverComponent={WrapperHoverComponent}
      indexCar={indexCar}
      information={information}
      attributeCarStatus={car[ATTRIBUTE_CODE_CAR_STATUS]}
      carImageHeight={carImageHeight}
      img={getMainImageURL(car)}
      trim={trimCar}
      car={car}
      transmission={transmission}
      miles={Math.round(car.mileage / 1000)}
      viewMore={viewMore}
      siteColor={siteColor}
      showHoverContent={car[ATTRIBUTE_CODE_CAR_STATUS].attributeValue !== carStatus2.SOLD}
      containerStyle={containerStyle}
      hoveredContentClassName={'car-item-details'}
      carfaxValue={carfaxValue}
      isAdminTemplate={isAdminTemplate}
    />
  )
}
