import React from 'react'
import {useEffect, useState} from 'react'
import {chunk, slice} from 'lodash'
import {getBaseTemplateCode, getButtonTextCapByTemplate, getCarByGroup, goToLink} from '../Util'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import {StyledButton, StyledLink, StyledReactSVG} from '../StyledComponentUtil'
import {
  BOX_BACKGROUND_COLOR,
  BOX_BORDER_COLOR,
  CAR_IMAGE_HEIGHT_PERCENT,
  CAROUSEL_INTERVAL,
  EASY_NAVIGATION_B_TEMPLATE_CODE,
  LOCATION_ALL,
  pathServer,
  PRIMARY_COLOR,
  REDUCE_IMAGE_PERCENTAGE,
  SITE_BACKGROUND_COLOR,
  MODERN_VIEW_2_TEMPLATE_CODE,
  TEMPLATE_CODE_MODERN_VIEW_3,
  TEXT_COLOR,
  WIDTH_VIEW_MOBILE,
  TEMPLATE_MAIN_NAME,
  TEMPLATE_CODE_EASY_NAVIGATION_B,
  GRID_VIEW,
  SECONDARY_COLOR,
  BUTTON_TEXT_COLOR
} from '../Constants'
import {LayoutCarItemCarousel} from './LayoutCarItemCarousel'
import {Carousel, CarouselItem} from 'react-bootstrap'
import {SliderBar} from '../genericComponents/DPCarousel/index'
import {ReactSVG} from 'react-svg'
import DPButton from '../genericComponents/DPButton'
import styled from 'styled-components'
import ReactDOM from 'react-dom'

const LayoutCar = ({
                     carsInformation = [],
                     locationId,
                     sectionInventory,
                     templateCode,
                     siteColor,
                     isAdminTemplate
                   }) => {
  const typeTemplate = getBaseTemplateCode(templateCode)
  const isTemplateMain = typeTemplate === TEMPLATE_MAIN_NAME
  const [numberOfCarsByGroup, setNumberOfCarsByGroup] = useState(isTemplateMain ? 4 : 8)
  const [quantityCarsToShow, setQuantityCarsToShow] = useState(16)
  const [carImageHeight, setCarImageHeight] = useState(null)
  const [index, setIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [previousIndex, setPreviousIndex] = useState(null)
  const [nextIndex, setNextIndex] = useState(1)
  const primaryColor = siteColor[PRIMARY_COLOR]
  const templateMain = (isTemplateMain)
  let totalPages

  const CustomCarousel = styled(Carousel)`
    .row-carousel {
      justify-content: center;
      }

    .carousel-control {
      pointer-events: none;
      cursor: default;
    }
  `

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
  }, [])


  useEffect( () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setIndex(0)
      setCurrentIndex(0)
      setPreviousIndex(carsToShow.length > 1 ? carsToShow.length - 1 : 0)
      setNextIndex(carsToShow.length > 1 ? 1 : 0)
    })
 }, [locationId])

  const getCarouselItems = (cars, carsByCarouselItem, carImageHeight,
                            siteColor, isAdminTemplate) => {
    const carGroups = chunk(cars, carsByCarouselItem)
    return carGroups.map((carGroup, index) => {
      const carsItem = carGroup.map((car, indexCar) => (

        <div key={car.vin} className={'col-car'}>
          <LayoutCarItemCarousel
            car={car}
            indexCar={indexCar}
            carImageHeight={carImageHeight}
            index={indexCar}
            siteColor={siteColor}
            isAdminTemplate={isAdminTemplate}
          />
        </div>
      ))
      return (
        <CarouselItem key={index}>
          <div className='row-carousel'>
            {carsItem}
          </div>
        </CarouselItem>)
    })
  }


  const getPaddingTop = (widthPage, templateCode) => (
    (widthPage <= WIDTH_VIEW_MOBILE && templateCode === TEMPLATE_CODE_MODERN_VIEW_3) ? '60px' : null
  )

  const getViewportWidth = (element) => (
    document.querySelector(element) ?
      document.querySelector(element).offsetWidth :
      (isTemplateMain ? 1100 : 1186)
  )

  const prevIcon = (
    <div id='scLef' className='sc-left' style={{borderColor: siteColor[TEXT_COLOR]}}>
      <StyledReactSVG
        src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
        className='wrapper'
        color={siteColor[TEXT_COLOR]}
      />
    </div>
  )

  const nextIcon = (
    <div id='scRight' className='sc-right' style={{borderColor: siteColor[TEXT_COLOR]}}>
      <StyledReactSVG
        src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'}
        className='wrapper'
        color={siteColor[TEXT_COLOR]}
      />
    </div>
  )

  const handleResize = () => {
    if (isAdminTemplate)
      return
    const width = isTemplateMain ?
      getViewportWidth('#layCar') :
      getViewportWidth('#carCarousel')
    if (isTemplateMain) {
      if (width > 1080) {
        setCarInventoryList(4, 12)
      } else if (width > 820 && width <= 1080) {
        setCarInventoryList(3, 12)
      } else if (width <= 820) {
        setCarInventoryList(2, 8)
      }
    } else {
      if (width > 1182) {
        setCarInventoryList(8, 16)
      } else if (width > WIDTH_VIEW_MOBILE && width <= 1182) {
        setCarInventoryList(6, 12)
      } else {
        setCarInventoryList(1, 8)
      }
    }
  }

  const setCarInventoryList = (numberOfCarsByGroup, quantityCarsToShow) => {
    if (isAdminTemplate)
      return
    setNumberOfCarsByGroup(numberOfCarsByGroup)
    setQuantityCarsToShow(quantityCarsToShow)
    modifyCarImageHeight()
  }

  const getStyles = () => {
    const isMobileView = window.innerWidth < 520
    const reduceLayCarMargin = !!(isMobileView)
    const style = {}
    if (templateCode === EASY_NAVIGATION_B_TEMPLATE_CODE)
      style.backgroundColor = siteColor[BOX_BACKGROUND_COLOR]
    if (reduceLayCarMargin)
      style.marginTop = '90px'
    return style
  }

  const modifyCarImageHeight = () => {
    if (isAdminTemplate)
      return
    const width = getViewportWidth('#layCar')
    const car = document.getElementById('car0')
    if (car) {
      const imageHeightPercentage = width > 600 ?
        CAR_IMAGE_HEIGHT_PERCENT :
        CAR_IMAGE_HEIGHT_PERCENT - REDUCE_IMAGE_PERCENTAGE
      const carImageHeight = car.offsetWidth * imageHeightPercentage
      setCarImageHeight(carImageHeight)
    }
  }

  const handlePagination = (nextIndex, lastIndex) => {
    if (isAdminTemplate)
      return
    const newPreviousIndex = (nextIndex === 0) ? lastIndex : nextIndex - 1
    const newCurrentIndex = nextIndex
    const newNextIndex = (nextIndex === lastIndex) ? 0 : nextIndex + 1
    setIndex(newCurrentIndex)
    setCurrentIndex(newCurrentIndex)
    setPreviousIndex(newPreviousIndex)
    setNextIndex(newNextIndex)
  }

  const carsFiltered = (locationId && locationId !== LOCATION_ALL) ?
    filter(carsInformation, ['locationId', locationId]) :
    carsInformation

  const showButton = sectionInventory.showButton ?? false

  const carsToShow = (!isTemplateMain) ?
    (slice(carsFiltered, 0, quantityCarsToShow)) : carsFiltered

  const carsCarousel5 = (isAdminTemplate ? Array.from({length: numberOfCarsByGroup}, (v, i) => carsToShow[i]) : carsToShow)

  const carouselItems = !isTemplateMain ? getCarouselItems(
      carsCarousel5, numberOfCarsByGroup, carImageHeight, siteColor, isAdminTemplate)
    :
    getCarByGroup(carsFiltered.length >= quantityCarsToShow ?
        slice(carsFiltered, 0, quantityCarsToShow) :
        carsFiltered, numberOfCarsByGroup, carImageHeight, primaryColor, siteColor, null,
      GRID_VIEW, null, isAdminTemplate)

  if (!isTemplateMain && !isAdminTemplate) {
    if (previousIndex === null) {
      setPreviousIndex(carsToShow.length - 1)
    }
    totalPages = (carsToShow.length < numberOfCarsByGroup) ? 1 : (Math.ceil(carsToShow.length / numberOfCarsByGroup))
  }

  const btnClassName = `btn ${(templateCode === MODERN_VIEW_2_TEMPLATE_CODE) ? "btn-inventory-mv2" : ""}`

  return (
    templateMain ?
      <div id='layCar' className={
        (!isAdminTemplate || (isAdminTemplate && templateCode !== TEMPLATE_CODE_EASY_NAVIGATION_B)) ?
          'layout-car' : 'layout-car-easy-navigation-b'}
           style={getStyles()}>
        <div id='layoutCarContent' className={
          (!isAdminTemplate || (isAdminTemplate && templateCode !== TEMPLATE_CODE_EASY_NAVIGATION_B)) ?
            'layout-car-content' : 'layout-car-content-easy-navigation-b'}
             style={{
               backgroundColor: siteColor[BOX_BACKGROUND_COLOR],
               border: `1px solid ${siteColor[BOX_BORDER_COLOR]}`,
               zIndex: `${isAdminTemplate ? 1000 : 0}`
             }}>
          <div className='layout-car-header'>
            <h2 style={{color: siteColor[TEXT_COLOR]}}>{sectionInventory.title}</h2>
          </div>
          <div className='layout-car-body'>
            <div>
              {carouselItems}
            </div>
            {
              showButton &&
              <div className='row-car-more' data-testid={'dp-home-inventory-button'}>
                <div className='text-center'>
                  <DPButton
                    buttonClassName='btn btn-inv-more'
                    textColor={siteColor[BUTTON_TEXT_COLOR]}
                    backgroundColor={siteColor[PRIMARY_COLOR]}
                    backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                    size='DP-MEDIUM'
                    onClick={() => {
                      if (isAdminTemplate) return
                      goToLink(sectionInventory.link)
                    }}
                    label={getButtonTextCapByTemplate(!isEmpty(sectionInventory.buttonText) ? sectionInventory.buttonText : 'SHOW MORE', templateCode) }
                    isAdmin={isAdminTemplate}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div> :
      <div id='carCarousel' className='car-carousel-template5'
           style={
             {
               paddingTop: getPaddingTop(window.innerWidth, templateCode),
               backgroundColor: siteColor[SITE_BACKGROUND_COLOR]
             }
           }
      >
        <div className='inventory-title'>
          <label style={{color: siteColor[TEXT_COLOR]}}>
            {sectionInventory.title.toUpperCase()}
          </label>
        </div>
        {numberOfCarsByGroup !== 1 ? (
          carsToShow.length < 4 ? (
            <CustomCarousel
              id='carCarouselBodyTemplate5'
              interval={CAROUSEL_INTERVAL}
              prevIcon={prevIcon}
              nextIcon={nextIcon}
              onSelect={(selectedIndex) => (setIndex(selectedIndex))}
              className="custom-carousel"
            >
              {carouselItems}
            </CustomCarousel>
          ) : (
            <Carousel
              id='carCarouselBodyTemplate5'
              interval={CAROUSEL_INTERVAL}
              prevIcon={prevIcon}
              nextIcon={nextIcon}
              onSelect={(selectedIndex) => (setIndex(selectedIndex))}
              className="custom-carousel"
            >
              {carouselItems}
            </Carousel>
            )
          )
          :
          <div className='carCarouselMobile'>
            {
              carsToShow.map((car, index) => {
                const isActive = (index === currentIndex) || (index === previousIndex) || (index === nextIndex)
                let style
                if (index === currentIndex) {
                  style = 'car-item-active'
                } else if (index === previousIndex) {
                  style = 'car-item-previous'
                } else if (index === nextIndex) {
                  style = 'car-item-next'
                }
                if (isActive) {
                  return (
                    <LayoutCarItemCarousel
                      key={car.id}
                      indexCar={car.id}
                      car={car}
                      style={style}
                      index={index}
                      siteColor={siteColor}
                    />
                  )
                }
              })
            }
          </div>
        }
        <div className='pagination-carousel'>
          {carsToShow.length > 0 ?
            <SliderBar
              currentPage={index}
              totalPages={totalPages}
              primaryColor={primaryColor}
              heightCurrentBar={6}
              heightTotalBar={2}
            />
            : ""
          }

          {
            (numberOfCarsByGroup === 1 && carsToShow.length > 0) ?
              <div>
                <ReactSVG
                  src={
                    window.innerWidth > WIDTH_VIEW_MOBILE ?
                      `${pathServer.PATH_IMG}icon/ic_arrow_back.svg` :
                      `${pathServer.PATH_IMG}icon/ic_expand_less.svg`
                  }
                  className='wrapper-previous'
                  onClick={() => {
                    if (isAdminTemplate)
                      return
                    handlePagination(previousIndex, carsToShow.length - 1)
                  }}
                  beforeInjection={svg => {
                    svg.classList.add('icon-arrow')
                    svg.setAttribute('style', `fill: ${primaryColor}`)
                  }}/>
                <ReactSVG
                  src={
                    window.innerWidth > WIDTH_VIEW_MOBILE ?
                      `${pathServer.PATH_IMG}icon/ic_arrow_next.svg` :
                      `${pathServer.PATH_IMG}icon/ic_expand_more.svg`
                  }
                  className='wrapper-next'
                  onClick={() => {
                    if (isAdminTemplate)
                      return
                    handlePagination(nextIndex, carsToShow.length - 1)
                  }}
                  beforeInjection={svg => {
                    svg.classList.add('icon-arrow')
                    svg.setAttribute('style', `fill: ${primaryColor}`)
                  }}/>
              </div> : null
          }
        </div>
        {
          showButton &&
          <div className='inventoryButton'>
            <DPButton
              id='btnGoToInventory'
              buttonClassName= {btnClassName}
              onClick={() => goToLink(sectionInventory.link)}
              label={!isEmpty(sectionInventory.buttonText) ? sectionInventory.buttonText : 'GO TO INVENTORY'}
              isAdmin={isAdminTemplate}
              backgroundColor={`${siteColor[PRIMARY_COLOR]} !important`}
              backgroundHoverColor={`${siteColor[SECONDARY_COLOR]} !important`}
              textColor={siteColor[BUTTON_TEXT_COLOR]}
              borderStyle='none'
            />
          </div>
        }
      </div>
  )
}

export default LayoutCar