import {ATTRIBUTE_CODE_CAR_STATUS, carStatus} from '../Constants'
import React from 'react'
import {getCarfaxHTML, getMainImageURL} from '../Util'
import {BOX_BACKGROUND_COLOR, SITE_BACKGROUND_COLOR, TEXT_COLOR, WIDTH_VIEW_MOBILE} from '../Constants'

const DPCarGridItemModernView = ({
                                   styleContainer,
                                   indexCar,
                                   carImageHeight,
                                   car,
                                   viewMore,
                                   indexDesc,
                                   siteColor = {},
                                   carfaxValue,
                                   isAdminTemplate = false
                                 }) => {

  const img = getMainImageURL(car)
  const miles = Math.round(car.mileage / 1000)
  const trans = car['car.transmission'] ? car['car.transmission'].attributeValue : ''
  const title = car['car.title'] ? car['car.title'].attributeValue : ''
  const trim = car['car.trim']?.attributeValue ?? ''
  const isStyleCarItemActive = (styleContainer === 'car-item-active')
  const widthTotal = (window.innerWidth <= WIDTH_VIEW_MOBILE && styleContainer) ?
    (window.innerWidth - 20) : null
  const heightCar = (window.innerWidth <= WIDTH_VIEW_MOBILE && styleContainer) ?
    (isStyleCarItemActive ? (widthTotal - 60) * 0.65 : (widthTotal * 0.7 * 0.65)) : null
  const attributeCarStatus = car[ATTRIBUTE_CODE_CAR_STATUS]
  const isCarComingSoon = car.suggestedPrice === 0

  return (
    <div key={indexCar.toString()}
         className={'car-item ' + (styleContainer ? styleContainer : '')}
         style={{
           backgroundColor: siteColor[SITE_BACKGROUND_COLOR]
         }}
    >
      <div
        id={'car' + indexCar.toString()}
        className="car-item-box"
        style={{
          backgroundColor: siteColor[BOX_BACKGROUND_COLOR]
        }}
      >
        <div className={(attributeCarStatus.attributeValue !== carStatus.SOLD) ? 'car-image' : 'car-image-sold'}
             style={(window.innerWidth <= WIDTH_VIEW_MOBILE && styleContainer) ?
               {height: heightCar}
               : carImageHeight ? {height: carImageHeight} : null}
             onClick={() => {
               if (isAdminTemplate)
                 return
               viewMore(car.vin)
             }}>
          <img id={'carImage' + indexCar} src={img}/>
        </div>
        <div className="car-item-details" style={{backgroundColor: siteColor[BOX_BACKGROUND_COLOR]}}>
          <div className="row-details">
            <label className="label-title" style={{color: siteColor[TEXT_COLOR]}}>
              {`${car.year} ${car.makeId.toString().toUpperCase()} ${car.modelId.toString().toUpperCase()} ${trim}`}
            </label>
          </div>
          <div className="row-details" style={{flexWrap: 'wrap', paddingBottom: '15px'}}>
            {
              trans ?
                <div className="btn-car-content">
                  <label className="btn-car-info" style={{color: siteColor[TEXT_COLOR]}}>
                    {trans.toUpperCase()}
                  </label>
                </div> : null
            }
            {
              title ?
                <div className="btn-car-content">
                  <label className="btn-car-info" style={{color: siteColor[TEXT_COLOR]}}>
                    {title.toUpperCase()}
                  </label>
                </div> : null
            }
            <div className="btn-car-content">
              <label className="btn-car-info" style={{color: siteColor[TEXT_COLOR]}}>
                {miles + ' Mil.'}
              </label>
            </div>
          </div>

          {
            window.innerWidth > WIDTH_VIEW_MOBILE
            && <div className="row-details">
              <label
                id={'carDescriptionPublic' + indexDesc}
                className="car-description-inventory"
                onClick={() => viewMore(car.vin)}
                style={{color: siteColor[TEXT_COLOR]}}
              >
                {car.description}
              </label>
            </div>
          }

          <div className="row-details row-item-price">
            {getCarfaxHTML(carfaxValue, car.vin, {color: siteColor[TEXT_COLOR]})}
            <div className="col-item-price">
              <label className={isCarComingSoon ? 'car-item-price-soon' : 'car-item-price'}
                     style={{color: siteColor[TEXT_COLOR]}}
              >
                {isCarComingSoon ? carStatus.COMING_SOON : `$${car.suggestedPrice}`}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DPCarGridItemModernView
