import React from 'react'
import {
  DARK_THEME,
  LIGHT_THEME,
  TEMPLATE_5_NAME,
  // @ts-ignore
} from '../../Constants.js'
// @ts-ignore
import {getBaseTemplateCode, getPrimaryColor, hexToRgb} from '../../Util.js'
import {ReactSVG} from "react-svg";
// @ts-ignore
import classnames from 'classnames'
import {Page, SiteColor} from "../../Interfaces";
require('./LayoutAboutUsGoal.scss')

interface LayoutAboutUsGoal {
  page: Page
  siteColor: SiteColor
  currentTheme: DARK_THEME | LIGHT_THEME
  templateCode: string
}

const LayoutAboutUsGoal = ( props: LayoutAboutUsGoal) => {
  const {
    page,
    siteColor,
    currentTheme,
    templateCode,
  } = props

  const {values, headerText} = page;
  const {
    primary : primaryColor,
    text: textColor,
    // siteBackground // temporary commented because custom color
  } = siteColor

  const baseTemplate = getBaseTemplateCode(templateCode)

  const getPrimaryBackgroundColor = (primaryColor: string) => {
    const rgbColor = hexToRgb(primaryColor)
    return rgbColor ? `rgb(${rgbColor.r},${rgbColor.g}, ${rgbColor.b}, 0.2)` : primaryColor
  }

  const primaryBackgroundColor = getPrimaryBackgroundColor(primaryColor)
  const templateClassName =  baseTemplate === TEMPLATE_5_NAME ? 'box-template-5' : ''
  const customBackgroundColor = currentTheme === LIGHT_THEME ? "#F5F6FA" : "#0B1524" //temporal until the colors in the setting style are defined
  const customBackgroundColorForAllTemplates = baseTemplate === TEMPLATE_5_NAME
      ? siteColor.boxBackground
      : customBackgroundColor

  return(
    <div className="about-us-goal">
      <div
        className={classnames(['box', templateClassName])}
        style={{backgroundColor: customBackgroundColorForAllTemplates }}
      >
        {
          <h3
            className='general-title'
            style={{color: textColor}}
          >
            {headerText.title}
          </h3>
        }
        <span className="value-description" style={{color: textColor}}>{headerText.description}</span>
        <div className="values-container">
          {
            values.map((value) => {
              const {displayValue, showValue, icon, id} = value
              if(showValue){
                return (
                  <div key={id} className="box-item" style={{backgroundColor: primaryBackgroundColor}}>
                    <ReactSVG
                      src={icon} className="wrapper" id={id}
                      beforeInjection={svg => {
                        svg.classList.add('icon-style');
                        svg.setAttribute('style', `fill: ${getPrimaryColor(templateCode, primaryColor, currentTheme)}`);
                      }}
                    />
                    <span className="value-text" style={{color: primaryColor}}>{displayValue}</span>
                  </div>
                )
              }
            })
          }
        </div>
      </div>
    </div>
  )
}

export default LayoutAboutUsGoal
