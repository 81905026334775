import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import {
  BOX_BACKGROUND_COLOR,
  BOX_BORDER_COLOR,
  cover,
  pathServer,
  TEXT_COLOR
} from './Constants'
import classnames from 'classnames'
import './DPPagination.scss'
import {StyledReactSVG} from './StyledComponentUtil'
import DPButton from './genericComponents/DPButton'

export const DPPagination = ({
                               className,
                               currentPage,
                               totalPages,
                               optionsQuantityByPage = [],
                               selectedItemsByPage,
                               handleChangeItemsByPage,
                               handlePageChange,
                               siteColor,
                               showItemPerPage = true,
                               showPaginationArrow = true,
                               disabled = false
                             }) => {
  const [top, setTop] = useState(0)
  const [offsetWidth, setOffsetWidth] = useState(68)
  const [showOptions, setShowOptions] = useState(false)
  const {
    [BOX_BACKGROUND_COLOR]: boxBackground,
    [BOX_BORDER_COLOR]: boxBorder,
    [TEXT_COLOR]: textColor
  } = siteColor

  const getPopoverStyles = () => {
    return {
      zIndex: '2',
      left: 0,
      top,
      backgroundColor: boxBackground,
      transition: '0.8s',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      position: 'absolute',
      width: offsetWidth,
      borderRadius: '4px'
    }
  }

  const handleRequestOpen = (e) => {
    if (disabled)
      return
    const el = e.target.closest('.pagination-left')
    if (el) {
      setTop(el.offsetHeight + 1)
      setOffsetWidth(el.offsetWidth)
    }
    setShowOptions(true)
  }

  const handleRequestClose = () => {
    if (disabled)
      return
    setShowOptions(false)
  }

  return (
    <div className={classnames('dp-pagination-container', className)}>
      {
        showItemPerPage ?
          <div className='pagination-left'>
            <DPButton
              buttonClassName='btn-page-group'
              onClick={handleRequestOpen}
              textColor={textColor}
              backgroundColor={`${boxBackground} !important`}
              borderStyle={`1px solid ${boxBorder} !important`}
              isDisabled={disabled}
              label={
                <>
                  <StyledReactSVG
                    src={pathServer.PATH_IMG + 'icon/ic_format_list_bulleted.svg'}
                    className='icon-container'
                    beforeInjection={svg => {
                      svg.classList.add('icon-menu-list')
                    }}
                    color={textColor}
                  />
                  <span
                    className='label-page-selected'
                    style={{color: textColor}}
                  >
                    {selectedItemsByPage}
                </span>
                  <ReactSVG
                    src={pathServer.PATH_IMG + 'icon/ic_unfold.svg'}
                    className='icon-container'
                    beforeInjection={
                      svg => {
                        svg.classList.add('icon-unfold')
                        svg.setAttribute('style', `fill: ${textColor}`)
                      }
                    }
                  />
                </>
              }
            />
          </div> : null
      }
      {
        showPaginationArrow && <div className='pagination-right'>
          <DPButton
            buttonClassName='btn-back'
            onClick={
              () => handlePageChange(currentPage === 1 ? 1 : currentPage - 1, totalPages)
            }
            isDisabled={currentPage === 1 || disabled}
            backgroundColor={`${boxBackground} !important`}
            borderStyle={`1px solid ${boxBorder} !important`}
            textColor={textColor}
            label={
              <StyledReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
                beforeInjection={svg => {
                  svg.classList.add('icon-arrow')
                }}
                color={textColor}
              />
            }
          />
          <DPButton
            buttonClassName='btn-center'
            borderStyle={`1px solid ${boxBorder} !important`}
            backgroundColor={`${boxBackground} !important`}
            textColor={textColor}
            label={currentPage + ' OF ' + totalPages}
          />
          <DPButton
            buttonClassName='btn-next'
            onClick={
              () => handlePageChange(currentPage + 1, totalPages)
            }
            isDisabled={currentPage === totalPages || disabled}
            backgroundColor={`${boxBackground} !important`}
            borderStyle={`1px solid ${boxBorder} !important`}
            textColor={textColor}
            label={
              <StyledReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'}
                beforeInjection={svg => {
                  svg.classList.add('icon-arrow')
                }}
                color={textColor}
              />
            }
          />
        </div>
      }
      {
        !disabled && showOptions ?
          <div style={getPopoverStyles()}>
            <div style={cover} onClick={handleRequestClose}/>
            <div className='popover-pagination-box'>
              {
                optionsQuantityByPage.map(option => {
                  return (
                    <div key={option} className='items-by-page'>
                      <DPButton
                        id={option}
                        buttonClassName='items-by-page-value'
                        onClick={() => {
                          setShowOptions(false)
                          handleChangeItemsByPage(option)
                        }}
                        backgroundColor={`${boxBackground} !important`}
                        label={option}
                        textColor={textColor}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div> : null
      }
    </div>
  )
}

DPPagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  optionsQuantityByPage: PropTypes.array,
  selectedItemsByPage: PropTypes.number,
  handleChangeItemsByPage: PropTypes.func,
  handlePageChange: PropTypes.func,
  siteColor: PropTypes.object,
  showItemPerPage: PropTypes.bool,
  showPaginationArrow: PropTypes.bool,
  disabled: PropTypes.bool
}
