import React, {useState, useEffect} from 'react'
import {
  carStatus2,
  BOX_BACKGROUND_COLOR,
  CONTROL_BACKGROUND_COLOR,
  pathServer,
  PRIMARY_COLOR,
  TEMPLATE_MAIN_NAME,
  TEXT_COLOR,
  WIDTH_VIEW_MOBILE, DARK_THEME, PAGE_HOME_ID, SECTION_FINANCE_ID, SECTION_CONTACT_US_ID
} from '../../Constants'
import Loader from '../../UI/Loader'
import {ReactSVG} from 'react-svg'
import CarCarouselTemplateCodeMain from './TemplateMainComponents/CarCarouselTemplateCodeMain'
import {isEmpty, filter, get} from 'lodash'
import {
  getPrimaryColorTheme,
} from '../../Util'
import CarDescriptionAndTable from './CarDescriptionAndTable'
import CarPriceCalculator from './CarPriceCalculator'
import {createPDFFile} from '../../../catalog/Utils'
import GetInTouchTemplateCodeMain from './TemplateMainComponents/GetInTouchTemplateCodeMain'
import {useScreenSize} from '../../hooks/useScreenSize'
import DPCarousel from '../../DPCarousel'
import LayoutCarImagesDetail from "../LayoutCarImagesDetail";

const PRICE_RANK = 5000

const getCarsQuantityForSuggestedCarousel = (pageWidth) => {
  if (pageWidth < 500) return 2
  if (pageWidth < 700) return 2
  if (pageWidth < 1100) return 3
  return 4
}

export const CarInfoLabels = ({ width, car }) => {
  const trans = car['car.transmission'] ? car['car.transmission'].attributeValue : ''
  const miles = Math.round(car.mileage / 1000)
  const title = car['car.title'] ? car['car.title'].attributeValue : ''
  const exteriorColor = car['car.exteriorColor'] ? car['car.exteriorColor'].attributeValue : null
  const interiorColor = car['car.interiorColor'] ? car['car.interiorColor'].attributeValue : null
  const cylinder = car['car.cylinder'] ? car['car.cylinder'].attributeValue : ''
  const liters = car['car.liter'] ? car['car.liter'].attributeValue : ''
  const bodyType = car['car.body'] ? car['car.body'].attributeValue : ''

  return (
    <div className="row-info-details">
      <div className="row-info-details-line">
        {miles !== 0 &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_M.svg'}
                className="wrapper-set"
                beforeInjection={svg => svg.classList.add('image-style-width')}
              />
              <div className="label-car-info" title={miles?.toString()}><span>{`${miles}K`}</span></div>
            </div>
          </div>
        }
        {!isEmpty(title) &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_description.svg'}
                className="wrapper"
                beforeInjection={svg => svg.classList.add('image-style-height')}
              />
              <div className="label-car-info" title={title}><span>{title}</span></div>
            </div>
          </div>
        }
        {!isEmpty(cylinder) &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_settings_input_component.svg'}
                className="wrapper"
                beforeInjection={svg => svg.classList.add('image-style-width')}
              />
              <div className="label-car-info" title={cylinder}><span>{`${cylinder} Cylinders`}</span>
              </div>
            </div>
          </div>
        }
        {!isEmpty(liters) &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_engine.svg'}
                className="wrapper"
                beforeInjection={svg => svg.classList.add('image-style-width')}
              />
              <div className="label-car-info" title={liters}><span>{`${liters} Liters`}</span></div>
            </div>
          </div>
        }
        {!isEmpty(bodyType) &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_time_to_leave.svg'}
                className="wrapper"
                beforeInjection={svg => svg.classList.add('image-style-width')}
              />
              <div className="label-car-info" title={bodyType}><span>{bodyType}</span></div>
            </div>
          </div>
        }
        {!isEmpty(trans) &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_settings.svg'}
                className="wrapper"
                beforeInjection={svg => svg.classList.add('image-style-width')}
              />
              <div className="label-car-info" title={trans}><span>{trans}</span></div>
            </div>
          </div>
        }
        {!isEmpty(exteriorColor) &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <div
                className="dot-color"
                style={exteriorColor ? {
                  backgroundColor: exteriorColor,
                  border: (exteriorColor === 'White') ? '1px solid gray' : 'transparent'
                } : null}
              />
              <label className="label-color" title={'Exterior Color'}>Exterior Color</label>
            </div>
          </div>
        }
        {!isEmpty(interiorColor) &&
          <div className={width > WIDTH_VIEW_MOBILE ? 'col-50' : 'col-49'}>
            <div className="box-info">
              <div
                className="dot-color"
                style={interiorColor ? {
                  backgroundColor: interiorColor,
                  border: (interiorColor === 'White') ? '1px solid gray' : 'transparent'
                } : null}
              />
              <label className="label-color" title={'Interior Color'}>Interior Color</label>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export const CarInfoDetails = ({textColor, carWithAttributes ,statusSOLD}) => {
  return (
    <div className="row-info">
      <div className="info-car-details">
        <div className="car-title" style={{color: textColor}}>
          {`${carWithAttributes.year} ${carWithAttributes.makeName} ${carWithAttributes.modelName}`}
        </div>
        {statusSOLD && <div className="sold-tag">SOLD</div>}
      </div>
      <div className="row-info-price">
        <label className={statusSOLD ? 'price-banner' : 'price'}>
          {(carWithAttributes.suggestedPrice === 0) ? '' : `$${carWithAttributes.suggestedPrice}`}
        </label>
      </div>
    </div>
  )
}

const LayoutInventoryCarViewTemplateCodeMain = ({
                                                  currentTheme,
                                                  dealerId,
                                                  logo,
                                                  siteColor,
                                                  templateCode,
                                                  allCarsWithAttributes,
                                                  location,
                                                  dealerName,
                                                  carWithAttributes,
                                                  carImages,
                                                  pages,
                                                  goBack = null
                                                }) => {

  const {
    [PRIMARY_COLOR]: primaryColor,
    [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [TEXT_COLOR]: textColor,
    [CONTROL_BACKGROUND_COLOR]: controlBackgroundColor
  } = siteColor

  const {width} = useScreenSize()

  const [tabSelected, setTabSelected] = useState('information')
  const [isLoading, setIsLoading] = useState(false)
  const [carsQuantityInSuggestionCarousel, setCarsQuantityInSuggestionCarousel] = useState(getCarsQuantityForSuggestedCarousel(width))
  const [errors, setErrors] = useState([])
  const [price, setPrice] = useState(carWithAttributes.suggestedPrice)

  const handleTabChange = (evt) => setTabSelected(evt.target.id)

  const homePage = pages.find(page => page.id === PAGE_HOME_ID)
  const sectionContactUs = homePage.sections.find(sec => sec.id === SECTION_CONTACT_US_ID)
  const hideContactSection = !!sectionContactUs.hideSection

  const onChangePrice = (event, value) => {
    event.preventDefault()
    const newValue = value
    setPrice(newValue)
  }

  const printCarInformation = (evt) => {
    evt.stopPropagation()
    const closeSpinner = () => setIsLoading(false)
    setIsLoading(true)
    createPDFFile(carWithAttributes, primaryColor, location, closeSpinner, carImages, {dealerName, logo})
  }

  useEffect(() => {
    setCarsQuantityInSuggestionCarousel(getCarsQuantityForSuggestedCarousel(width))
  }, [width])

  if (!carWithAttributes)
    return null

  const vin = carWithAttributes.vin.toString()
  const carPrice = carWithAttributes.suggestedPrice
  const carStatus = get(carWithAttributes, ['car.status', 'attributeValue'], '')
  const filteredCarsWithAttributesForCarousel = allCarsWithAttributes && filter(allCarsWithAttributes,
    carWithAttributes => carWithAttributes.suggestedPrice >= (carPrice - PRICE_RANK) && carWithAttributes.suggestedPrice <= (carPrice + PRICE_RANK) &&
      carWithAttributes.vin !== vin
  )

  const tabSelectedStyles = {
    border: '1px solid' + getPrimaryColorTheme(templateCode, primaryColor, currentTheme),
    color: getPrimaryColorTheme(templateCode, primaryColor, currentTheme),
    backgroundColor: boxBackgroundColor
  }
  const tabUnselectedStyles = {
    border: '1px solid #7F7F7F',
    color: '#7F7F7F',
    backgroundColor: boxBackgroundColor
  }

  const statusSOLD = carStatus === carStatus2.SOLD
  const trim = get(carWithAttributes, ['car.trim', 'attributeValue'], '')
  return (
    <div className="inv-car-view">
      {isLoading ? <Loader/> : null}
      <div id="invCarContent" className="inv-car-content">
        <div className="inv-car-box" style={{ backgroundColor: boxBackgroundColor }}>
          {/* Header bar */}
          <div className="inv-car-header">
            <div className="left">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
                onClick={goBack}
                beforeInjection={svg => svg.classList.add('image-style')}
              />
            </div>
            <div className="right">
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_print.svg'}
                className="wrapper"
                beforeInjection={svg => svg.classList.add('image-style')} onClick={printCarInformation}
              />
            </div>
          </div>

          {/* Car information body */}
          <div className="inv-car-body">
            {
              !hideContactSection ?
                <div className="row-inv-car" style={{display: 'flex'}}>
                  {
                    <CarCarouselTemplateCodeMain
                        images={carImages}
                        siteColor={siteColor}
                        carStatus={statusSOLD}
                    />
                  }
                  <div className="inv-car-info">
                    <React.Fragment>
                      <CarInfoDetails
                        textColor={textColor}
                        carWithAttributes={carWithAttributes}
                        statusSOLD={statusSOLD}
                      />
                      <CarInfoLabels
                        width={width}
                        car={carWithAttributes}
                      />
                    </React.Fragment>

                    <div className="inv-car-foot">
                      {
                        <GetInTouchTemplateCodeMain
                          siteColor={siteColor}
                          car={carWithAttributes}
                          dealerId={dealerId}
                          errors={errors}
                          setErrors={setErrors}
                          section={sectionContactUs}
                        />
                      }
                    </div>
                  </div>
                </div>
                :
                <LayoutCarImagesDetail
                textColor={textColor}
                carWithAttributes={carWithAttributes}
                statusSOLD={statusSOLD}
                width={width}
                galleryImages={carImages}
              />
            }

            <div className="row-inv-car row-inv-car-top">
              <div className="car-detail-extra">
                <div
                  id="information"
                  style={(tabSelected === 'information') ? tabSelectedStyles : tabUnselectedStyles}
                  className="btn-car-extra"
                  onClick={handleTabChange}
                >
                  INFORMATION
                </div>
              </div>
              <div className="car-detail-extra">
                <div id="calculator"
                     style={(tabSelected === 'calculator') ? tabSelectedStyles : tabUnselectedStyles}
                     className="btn-car-extra"
                     onClick={handleTabChange}
                >
                  CALCULATOR
                </div>
              </div>
            </div>
          </div>

          {tabSelected === 'information' &&
            <CarDescriptionAndTable
              templateCode={TEMPLATE_MAIN_NAME}
              siteColor={siteColor}
              car={carWithAttributes}
              currentTheme={currentTheme}
            />
          }
          {tabSelected === 'calculator' &&
            <CarPriceCalculator
              templateCode={TEMPLATE_MAIN_NAME}
              siteColor={siteColor}
              price={price}
              car={carWithAttributes}
              currentTheme={currentTheme}
              onChangePrice={onChangePrice}
              pages={pages}
            />
          }
        </div>
      </div>
      {
        (filteredCarsWithAttributesForCarousel && filteredCarsWithAttributesForCarousel.length > 0) ?

          <DPCarousel
            cars={filteredCarsWithAttributesForCarousel}
            // cars={allCarsWithAttributes}
            groupCar={carsQuantityInSuggestionCarousel}
            displayArrows={allCarsWithAttributes.length > carsQuantityInSuggestionCarousel}
            primaryColor={primaryColor}
            title={width <= WIDTH_VIEW_MOBILE ? 'More vehicles' : null}
            siteColor={siteColor}
            isAdmin={false}
          />

        : null
      }
    </div>
  )
}

export default LayoutInventoryCarViewTemplateCodeMain
