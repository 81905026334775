import React from 'react'
import PropTypes from 'prop-types'
import {ReactSVG} from 'react-svg'
import {EmptyFunc, pathServer} from '../../common/Constants'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import classnames from 'classnames'

require('./DPIcon.scss')

export const Icons = {
  EDIT: 'Edit',
  ACTIVE_USER: 'ActiveUser',
  INACTIVE_USER: 'InActiveUser',
  TRASH: 'Trash',
  INACTIVE_BADGE: 'InActiveBadge',
  CLOSE_ICON: 'CloseIcon',
  SAVE_ICON: 'Save',
  ARROW_BACK: 'ArrowBack',
  ARROW_NEXT: 'ArrowNext',
  PAPER: 'Paper',
  PENCIL: 'Pencil',
  VEHICLE: 'Vehicle',
  KB_ARROW_DOWN_ROUNDED: 'KeyboardArrowDownRounded',
  NAVIGATE_NEXT: 'NavigateNext',
  PLAY_VIDEO: 'PlayVideo',
  HAMBURGER_ICON: 'HamburgerIcon',
  CHECK: 'Check',
  PAUSE_VIDEO: "PauseVideo",
  SOUND_ACTIVE: "SoundActive",
  MUTED_VIDEO: "MutedVideo",
  FULL_SCREEN_VIDEO: "FullScreenVideo",
  CANCEL: "Cancel",
  EXCLAMATION: "Exclamation",
  CHECK_CIRCLE: "CheckCircle",
  CLOSE_CIRCLE_RED: "closeCircleRed",
  ARROW_NEXT_CAROUSEL: "arrowNextCarousel",
  TRIANGLE_DOWN: "TriangleDown"
}

const IconsProps = {
  [Icons.EDIT]: {
    path: 'icon/ic_edit-pencil.svg',
    className: 'edit-icon'
  },
  [Icons.ACTIVE_USER]: {
    path: 'icon/ic_user_active.svg',
    className: 'active-user-icon'
  },
  [Icons.INACTIVE_USER]: {
    path: 'icon/ic_user_inactive.svg',
    className: 'inactive-user-icon'
  },
  [Icons.TRASH]: {
    path: 'icon/ic_delete.svg',
    className: 'delete-icon'
  },
  [Icons.INACTIVE_BADGE]: {
    path: 'icon/ic_inactive.svg',
    className: 'inactive-badge'
  },
  [Icons.CLOSE_ICON]:{
    path: 'icon/ic_close.svg',
    className: 'close-icon'
  },
  [Icons.SAVE_ICON]:{
    path: 'icon/ic_save.svg',
    className: 'save-icon'
  },
  [Icons.ARROW_BACK]:{
    path: 'icon/ic_arrow_back.svg',
    className: 'arrow-back-icon'
  },
  [Icons.ARROW_NEXT]:{
    path: 'icon/ic_arrow_next.svg',
    className: 'arrow-next-icon'
  },
  [Icons.PAPER]:{
    path: 'icon/ic_description.svg',
    className: 'paper-icon'
  },
  [Icons.PENCIL]:{
    path: 'icon/ic_edit_pencil.svg',
    className: 'paper-icon'
  },
  [Icons.VEHICLE]:{
    path: 'icon/ic_directions_car.svg',
    className: 'paper-icon'
  },
  [Icons.KB_ARROW_DOWN_ROUNDED]: {
    path: 'icon/keyboard-arrow-down-rounded.svg',
    className: 'key-arrow-down-rounded-icon'
  },
  [Icons.NAVIGATE_NEXT]: {
    path: 'icon/navigate_next.svg',
    className: 'key-arrow-down-rounded-icon'
  },
  [Icons.PLAY_VIDEO]:{
    path:'icon/ic_play_video.svg',
    className: 'play-video'
  },
  [Icons.HAMBURGER_ICON]: {
    path: 'icon/ic_dehaze_24px.svg',
    className: 'hamburger-icon'
  },
  [Icons.CHECK]: {
    path: 'icon/ic_check_normal.svg',
    className: 'check-normal'
  },
  [Icons.PAUSE_VIDEO]:{
    path:'icon/ic_pause_video.svg',
    className: 'pause-video'
  },
  [Icons.SOUND_ACTIVE]:{
    path:'icon/ic_sound_active.svg',
    className: 'sound-active-video'
  },
  [Icons.MUTED_VIDEO]:{
    path:'icon/ic_sound_mute.svg',
    className: 'muted-video'
  },
  [Icons.FULL_SCREEN_VIDEO]:{
    path:'icon/ic_expand_video.svg',
    className: 'full-screen-video'
  },
  [Icons.CANCEL]:{
    path:'icon/ic_cancel.svg',
    className: 'cancel-icon'
  },
  [Icons.EXCLAMATION]:{
    path:'icon/ic_baseline_error.svg',
    className: 'exclamation-icon'
  },
  [Icons.CHECK_CIRCLE]:{
    path:'icon/ic_check_circle2.svg',
    className: 'check-circle-icon'
  },
  [Icons.CLOSE_CIRCLE_RED]:{
    path:'icon/ic_close_circle_red.svg',
    className: 'close-circle-red'
  },
  [Icons.TRIANGLE_DOWN]:{
    path: 'icon/ic_triangle_down.svg',
    className: 'triangle-down'
  },
  [Icons.ARROW_NEXT_CAROUSEL]:{
    path: 'icon/ic_arrow_next.svg',
    className: 'arrow-next-carousel-icon'
  },
}

const DPIcon = ({id, onClick, style, className, iconClasses, icon, onMouseLeave, onMouseEnter}) => {

  const iconProps = IconsProps[icon]

  return (
    <ReactSVG
      style={{...iconProps.style, ...style, padding: '0 2px'}}
      id={id}
      className={classnames('dp-icon', iconProps.className, className)}
      src={`${pathServer.PATH_IMG}${iconProps.path}`}
      beforeInjection={
        svg =>
          !isEmpty(iconClasses) ?
            isArray(iconClasses) ?
              iconClasses.forEach(cls => svg.classList.add(cls))
              : svg.classList.add(iconClasses)
            : null
      }
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    />
  )
}

DPIcon.defaultProps = {
  onClick: EmptyFunc,
  iconClasses: '',
  onMouseLeave: EmptyFunc,
  onMouseEnter: EmptyFunc,
}


DPIcon.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  iconClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
export default DPIcon
