import React, {useEffect, useState} from 'react'
import {cloneDeep, filter, get, map, slice} from 'lodash'
import {ReactSVG} from 'react-svg'
import {unstable_batchedUpdates} from 'react-dom'
import {
  pathServer,
  GRID_VIEW,
  LIST_VIEW,
  CAR_IMAGE_HEIGHT_PERCENT,
  PRIMARY_COLOR,
  SITE_BACKGROUND_COLOR,
  BOX_BACKGROUND_COLOR,
  TEXT_COLOR,
  BOX_BORDER_COLOR,
  CARS_BY_PAGE,
  TEMPLATE_5_NAME,
  WIDTH_VIEW_MOBILE,
  LOCATION_ALL,
  MILES, CARS_BY_PAGE_DEFAULT
} from '../../Constants'
import {LayoutCarItemCarousel} from '../LayoutCarItemCarousel'
import DPCarListItem from '../../DPCarItems/DPCarListItem'
import {debounce, getBaseTemplateCode, getCarByGroup} from '../../Util'
import Loader from '../../UI/Loader'
import {AdvancedSearchInventory} from './AdvancedSearchInventory'
import {DPPagination} from '../../DPPagination'
import {InventoryViewMode} from './InventoryViewMode'
import {SimpleCarSearch} from './SimpleCarSearch'
import find from 'lodash/find'
import {useScreenSize} from '../../hooks/useScreenSize'
import DPCarousel from '../../DPCarousel'
import DPButton from '../../genericComponents/DPButton'
import {useDispatch, useSelector} from "react-redux";
import {setCurrentInventoryView} from "../../../catalog/actions";

const initialFilterValues = {
  keyword: null,
  selectedMakes: [],
  selectedModels: [],
  body: [],
  yearFrom: '',
  yearTo: '',
  mileageFrom: '',
  mileageTo: '',
  priceFrom: '',
  priceTo: '',
  extCondition: [],
  exteriorColor: [],
  interiorColor: [],
  cylinder: [],
  title: [],
  trim: '',
}

const LayoutInventory = ({
                           carsInformation,
                           locationId,
                           information,
                           makes,
                           models,
                           siteColor,
                           carfaxValue,
                           dealerId,
                           searchInventoryPublic,
                           currentTheme = null,
                           templateCode = null,
                           isAdmin = false
                         }) => {

  const inventoryView = useSelector(state => state.currentInventoryView)
  const dispatch = useDispatch()
  const baseTemplate = getBaseTemplateCode(templateCode)
  const isTemplate5 = baseTemplate === TEMPLATE_5_NAME
  const [selectedItemsByPage, setSelectedItemsByPage] = useState(CARS_BY_PAGE_DEFAULT)
  const [filterValues, setFilterValues] = useState(cloneDeep(initialFilterValues))
  const [currentPage, setCurrentPage] = useState(1)
  const [isPaginationOnTop, setIsPaginationOnTop] = useState(isTemplate5)
  const [hideFilters, setHideFilters] = useState(window.innerWidth <= WIDTH_VIEW_MOBILE)
  const [showCarousel, setShowCarousel] = useState(true)
  const [showFiltersTemplate5, setShowFiltersTemplate5] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [carImageHeight, setCarImageHeight] = useState(null)
  const [quantityCarsByRow, setQuantityCarsByRow] = useState(4)
  const [totalPages, setTotalPages] = useState(Math.ceil(carsInformation.quantityCars / selectedItemsByPage))
  const [modelsByMakeIds, setModelsByMakeIds] = useState([])
  const {width, height} = useScreenSize()

  const {
    [PRIMARY_COLOR]: primaryColor,
    [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [SITE_BACKGROUND_COLOR]: siteBackgroundColor,
    [TEXT_COLOR]: textSiteColor,
    [BOX_BORDER_COLOR]: boxBorderColor
  } = siteColor

  const cars = carsInformation && !isAdmin ? cloneDeep(carsInformation.cars) : cloneDeep(carsInformation)
  const filteredCarsByLocation = (locationId !== LOCATION_ALL && !isAdmin) ? filter(cars, ['locationId', locationId]) : cars
  const filteredCars = !isAdmin ? filteredCarsByLocation : cars
  const carElementsForTemplate5 = filteredCars.length >= selectedItemsByPage ?
    slice(filteredCars, 0, selectedItemsByPage) : filteredCars
  const numberOfCarsByGroup = inventoryView === LIST_VIEW ? 1 : quantityCarsByRow
  const carElementsForTemplateMain =
    getCarByGroup(filteredCars.length >= selectedItemsByPage ?
      slice(filteredCars, 0, selectedItemsByPage) :
      filteredCars, numberOfCarsByGroup, carImageHeight, primaryColor, siteColor, information, inventoryView, carfaxValue, isAdmin)
  const displayArrows = (filteredCars.length > quantityCarsByRow)

  useEffect(() => {
    if (isAdmin)
      return
    const {options: optionsFilters} = carsInformation
    const keyword = get(optionsFilters, 'keyword', '')
    const selectedMakes = get(optionsFilters, 'makes', [])
    const selectedModels = get(optionsFilters, 'models', [])
    const body = get(optionsFilters, 'body', [])
    const yearFrom = get(optionsFilters, 'rangeYearFrom', '')
    const yearTo = get(optionsFilters, 'rangeYearTo', '')
    const extCondition = get(optionsFilters, 'extCondition', [])
    const exteriorColor = get(optionsFilters, 'exteriorColor', [])
    const interiorColor = get(optionsFilters, 'interiorColor', [])
    const firstValueMileageFrom = get(optionsFilters, 'rangeMileageFrom', '')
    const mileageFrom = firstValueMileageFrom === '' ? '' : (firstValueMileageFrom / 1000)
    const firstValueMileageTo = get(optionsFilters, 'rangeMileageTo', '')
    const mileageTo = firstValueMileageTo === '' ? '' : (firstValueMileageTo / 1000)
    const cylinder = get(optionsFilters, 'cylinder', [])
    const title = get(optionsFilters, 'title', [])
    const trim = get(optionsFilters, 'trim', '')
    const priceFrom = get(optionsFilters, 'rangePriceFrom', '')
    const priceTo = get(optionsFilters, 'rangePriceTo', '')
    const modelsByMakeIds = !(selectedMakes.length > 0) ? [] :
      (map(selectedMakes, (makeId) => {
        const makeFound = find(makes, function (make) {
          return make.id === makeId
        })
        const modelsFound = filter(models, ['makeId', makeId])
        return {
          makeId: makeId,
          name: makeFound.name,
          options: modelsFound
        }
      }))
    const fromItems = get(optionsFilters, 'from', 0)
    const newSelectedItemsByPage = get(optionsFilters, 'size', selectedItemsByPage)
    const currentPage = (fromItems / newSelectedItemsByPage) + 1
    unstable_batchedUpdates(() => {
      setCurrentPage(currentPage)
      setSelectedItemsByPage(newSelectedItemsByPage)
      setFilterValues({
        ...filterValues, body, yearFrom, yearTo,
        extCondition, exteriorColor, interiorColor,
        mileageFrom, mileageTo, cylinder, title,
        trim, priceFrom, priceTo, selectedModels,
        keyword, selectedMakes
      })
      setModelsByMakeIds(modelsByMakeIds)
    })
  }, [carsInformation])

  useEffect(() => {
    handleResize()
  }, [width, height])

  useEffect(() => {
    if (isAdmin)
      return
    setTotalPages(filteredCarsByLocation ? Math.ceil(carsInformation.quantityCars / selectedItemsByPage) : 1)
  }, [selectedItemsByPage, filteredCarsByLocation])

  const setInitialFilterValues = () => {
    unstable_batchedUpdates(() => {
      setFilterValues(prevState => ({
        ...cloneDeep(initialFilterValues),
        keyword: prevState.keyword
      }))
      setIsLoading(false)
    })
  }

  const handlePageChange = (pageToGo, totalPages) => {
    if (isAdmin)
      return
    let query = {size: selectedItemsByPage}
    if (pageToGo > totalPages) {
      query.from = 0
    } else if (pageToGo === 0) {
      query.from = selectedItemsByPage * (totalPages - 1)
    } else {
      query.from = selectedItemsByPage * (pageToGo - 1)
    }
    advanceSearchFilter(query)
    window.scrollTo(0, 0)
  }

  const handleChangeItemsByPage = (newSelectedItemsByPage) => {
    if (isAdmin)
      return
    const query = {from: 0, size: parseInt(newSelectedItemsByPage)}
    advanceSearchFilter(query)
    window.scrollTo(0, 0)
  }

  const handleResize = () => {
    if (isAdmin)
      return
    const width = getViewportWidth(isTemplate5 ? '#layoutInventory' : '#layoutCarBg')
    let isPaginationOnTopByResize = isPaginationOnTop
    let newQuantityCarsByRow = 4
    let hideFiltersNew = false
    let showCarouselNew = showCarousel
    if (width > 1285) {
    } else if (width > WIDTH_VIEW_MOBILE && width <= 1285) {
      if (width <= 1007)
        isPaginationOnTopByResize = true
      newQuantityCarsByRow = 3
    } else if (width > 665 && width <= 768) {
      newQuantityCarsByRow = 2
      isPaginationOnTopByResize = false
    } else if (width <= 665) {
      newQuantityCarsByRow = 2
      isPaginationOnTopByResize = false
      if (window.innerWidth <= WIDTH_VIEW_MOBILE) {
        showCarouselNew = false
        hideFiltersNew = true
      }
    }
    unstable_batchedUpdates(() => {
      setShowCarousel(showCarouselNew)
      setQuantityCarsByRow(newQuantityCarsByRow)
      setIsPaginationOnTop(isPaginationOnTopByResize)
      setHideFilters(hideFiltersNew)
    })
    defineNewCarImageHeight()
  }

  const handleShowFiltersMobile = () => {
    if (isAdmin)
      return
    setHideFilters(!hideFilters)
  }

  const defineNewCarImageHeight = () => {
    const car = document.getElementById('car0')
    if (car) {
      const carImageHeight = car.offsetWidth * CAR_IMAGE_HEIGHT_PERCENT
      setCarImageHeight(carImageHeight)
    }
  }

  const getViewportWidth = (element) => {
    if (isAdmin)
      return
    if (document.querySelector(element))
      return document.querySelector(element).offsetWidth
    else
      return (isTemplate5 ? 1300 : 1100)
  }

  const simpleSearch = (query, event) => {
    event.preventDefault()
    if (isAdmin)
      return
    if (event) {
      setCurrentPage(1)
      setInitialFilterValues()
      debounce(searchInventoryPublic(dealerId, {keyword: query, from: 0, size: selectedItemsByPage}, 500))
    }
  }

  const advanceSearchFilter = (newQuery) => {
    if (isAdmin)
      return

    const {
      selectedMakes, selectedModels, body, yearFrom, yearTo,
      extCondition, mileageFrom, mileageTo, priceFrom, priceTo,
      exteriorColor, interiorColor, cylinder, title, trim,
      keyword
    } = filterValues

    let filter = {}
    let currentPageNew = currentPage

    if (selectedMakes.length > 0)
      filter.makes = selectedMakes

    if (selectedModels.length > 0)
      filter.models = selectedModels

    if (yearFrom && yearFrom !== '' && yearFrom !== 0)
      filter.rangeYearFrom = yearFrom.toString()

    if (yearTo && yearTo !== '' && yearTo !== 0) {
      if (yearFrom && yearFrom !== '' && yearFrom !== 0) {
        if (yearTo >= yearFrom)
          filter.rangeYearTo = yearTo.toString()
      } else
        filter.rangeYearTo = yearTo.toString()
    }

    if (priceFrom && priceFrom !== '' && priceFrom !== 0)
      filter.rangePriceFrom = priceFrom

    if (priceTo && priceTo !== '' && priceTo !== 0) {
      if (priceFrom && priceFrom !== '' && priceFrom !== 0) {
        if (priceTo >= priceFrom) filter.rangePriceTo = priceTo
      } else
        filter.rangePriceTo = priceTo
    }

    if (mileageFrom && mileageFrom !== '' && mileageFrom !== 0)
      filter.rangeMileageFrom = mileageFrom * MILES

    if (mileageTo && mileageTo !== '' && mileageTo !== 0) {
      if (mileageFrom && mileageFrom !== '' && mileageFrom !== 0) {
        if (mileageTo >= mileageFrom)
          filter.rangeMileageTo = mileageTo * MILES
      } else {
        filter.rangeMileageTo = mileageTo * MILES
      }
    }

    if (body.length > 0)
      filter.body = body

    if (title.length > 0)
      filter.title = title

    if (exteriorColor.length > 0)
      filter.exteriorColor = exteriorColor

    if (interiorColor.length > 0)
      filter.interiorColor = interiorColor

    if (cylinder.length > 0)
      filter.cylinder = cylinder

    if (trim !== '')
      filter.trim = trim

    if (extCondition.length > 0)
      filter.extCondition = extCondition

    if (newQuery) {
      filter.from = newQuery.from
      filter.size = newQuery.size
    } else {
      filter.from = 0
      filter.size = selectedItemsByPage
      currentPageNew = 1
    }

    unstable_batchedUpdates(() => {
      setCurrentPage(currentPageNew)
      setIsLoading(true)
    })

    if (!!keyword) {
      filter.keyword = keyword
    }

    searchInventoryPublic(dealerId, filter).then(response => {
      setIsLoading(false)
      window.scrollTo(0, 0)
    }).catch(error => {
      setIsLoading(false)
    })
  }

  const handleShowFiltersTemplate5 = () => {
    if (isAdmin)
      return
    setShowFiltersTemplate5(!showFiltersTemplate5)
  }

  const toggleInventoryView = (inventoryView) => {
    if (isAdmin)
      return
    dispatch(setCurrentInventoryView(inventoryView))
  }


  const cleanFilter = () => {
    if (isAdmin)
      return

    setInitialFilterValues()
    unstable_batchedUpdates(() => {
      setIsLoading(true)
      setModelsByMakeIds([])
    })

    if (window.innerWidth <= WIDTH_VIEW_MOBILE) {
      setHideFilters(!hideFilters)
    }

    searchInventoryPublic(dealerId, {}).then(response => {
      setIsLoading(false)
      window.scrollTo(0, 0)
    }).catch(error => {
    })
  }

  const renderPaginationAndViewModeForTemplate5 = (selectedItemsByPage, currentPage, totalPages, inventoryView, siteColor) => {
    return (
      <div className='col-inv-pagination'>
        <div className='layout-car-pagination'
             style={{
               borderBottom: `1px solid ${boxBackgroundColor}`,
               borderTop: `1px solid ${boxBackgroundColor}`,
             }}
        >
          <DPPagination
            className='catalog-inventory-pagination'
            optionsQuantityByPage={CARS_BY_PAGE}
            selectedItemsByPage={isAdmin ? CARS_BY_PAGE_DEFAULT : selectedItemsByPage}
            handleChangeItemsByPage={handleChangeItemsByPage}
            currentPage={isAdmin ? 2 : currentPage}
            totalPages={isAdmin ? 5 : totalPages}
            handlePageChange={handlePageChange}
            showItemPerPage={true}
            siteColor={siteColor}
            disabled={isAdmin}
          />
          <InventoryViewMode
            primaryColor={primaryColor}
            handleInventoryView={toggleInventoryView}
            inventoryView={inventoryView}
          />
        </div>
      </div>
    )
  }

  return (
    isTemplate5 ?
      (
        /*Template 5*/
        <div id='layoutInventory' className='layout-inventory-5'>
          {isLoading ? <Loader/> : null}
          <div className={'layout-inventory-body' + (inventoryView === LIST_VIEW ? ' layout-inventory-body-vl' : '')}
               style={{backgroundColor: siteBackgroundColor}}>
            <div className='row-inventory'>
              <div className='col-inv-filter'>
                {/*Keyword simple search*/}
                <SimpleCarSearch
                  isAdmin={isAdmin}
                  setFilterValues={setFilterValues}
                  filterValues={filterValues}
                  simpleSearch={simpleSearch}
                  baseTemplate={baseTemplate}
                  siteColor={siteColor}
                />
                {/*Show/Hide filters*/}
                <div className='btn-filter'>
                  <ReactSVG
                    src={pathServer.PATH_IMG + 'icon/ic_filter.svg'}
                    onClick={handleShowFiltersTemplate5}
                    beforeInjection={svg => {
                      svg.classList.add('icon-filter')
                      svg.setAttribute('style',
                        showFiltersTemplate5 ? `fill: ${primaryColor}` : `fill:${textSiteColor}`)
                    }}
                  />
                </div>
              </div>
              {
                (isPaginationOnTop && cars.length > 0) ?
                  renderPaginationAndViewModeForTemplate5(selectedItemsByPage, currentPage, totalPages, inventoryView, siteColor)
                  : null
              }
            </div>
            {/*Advanced search fields*/}
            <AdvancedSearchInventory
              modelsByMakeIds={modelsByMakeIds}
              setModelsByMakeIds={setModelsByMakeIds}
              makes={makes}
              models={models}
              currentTheme={currentTheme}
              siteColor={siteColor}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              showFiltersTemplate5={showFiltersTemplate5}
              search={advanceSearchFilter}
              templateCode={templateCode}
              hideFilters={hideFilters}
              isAdmin={isAdmin}
              cleanFilter={cleanFilter}
            />
            {/*Cars section*/}
            <div className='row-inventory'>
              {
                cars.length > 0 ?
                  carElementsForTemplate5.map((car, index) => {
                    return inventoryView === GRID_VIEW ?
                      //here
                      (
                        <LayoutCarItemCarousel
                          key={car.id}
                          car={car}
                          primaryColor={primaryColor}
                          indexCar={index}
                          carImageHeight={carImageHeight}
                          index={index}
                          siteColor={siteColor}
                          carfaxValue={carfaxValue}
                        />
                      ) : (
                        <DPCarListItem
                          key={index}
                          car={car}
                          indexCar={index}
                          information={information}
                          siteColor={siteColor}
                          carfaxValue={carfaxValue}
                        />
                      )
                  }) : null
              }
            </div>
            {
              (!isPaginationOnTop) ?
                renderPaginationAndViewModeForTemplate5(selectedItemsByPage, currentPage, totalPages, inventoryView, siteColor)
                : null
            }
          </div>
        </div>
      ) : (
        /*Template Main*/
        <div className='layout-car'>
          {isLoading ? <Loader/> : null}
          <div id='layoutCarBg' className='layout-car-bg'>
            <div id='layoutCar' className='layout-car-content inventory'
                 style={{backgroundColor: boxBackgroundColor, borderColor: boxBorderColor}}
            >
              <div className='layout-car-filter'>
                <div className='car-search-keyboard'>
                  {/*Title and view mode*/}
                  <div className='col-menu'>
                    <label style={{color: textSiteColor}}>Quick Search</label>
                    <InventoryViewMode
                      primaryColor={primaryColor}
                      handleInventoryView={toggleInventoryView}
                      inventoryView={inventoryView}
                    />
                  </div>
                  {/*Keyword simple search*/}
                  <SimpleCarSearch
                    isAdmin={isAdmin}
                    setFilterValues={setFilterValues}
                    filterValues={filterValues}
                    simpleSearch={simpleSearch}
                    baseTemplate={baseTemplate}
                    siteColor={siteColor}
                  />
                  {/*Show/Hide filters for tablet and mobile*/}
                  {
                    <div className='btn-filter'>
                      <DPButton
                        buttonClassName='button-filter'
                        onClick={handleShowFiltersMobile}
                        label={hideFilters ? 'FILTERS' : 'HIDE FILTERS'}
                        backgroundColor='#ffffff'
                        textColor={primaryColor}
                        borderStyle={`1px solid ${primaryColor} !important`}
                        isDisabled={isAdmin}
                      />
                    </div>
                  }
                </div>
                {/*Advanced search fields*/}
                <AdvancedSearchInventory
                  modelsByMakeIds={modelsByMakeIds}
                  setModelsByMakeIds={setModelsByMakeIds}
                  makes={makes}
                  models={models}
                  currentTheme={currentTheme}
                  siteColor={siteColor}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  showFiltersTemplate5={showFiltersTemplate5}
                  search={advanceSearchFilter}
                  templateCode={templateCode}
                  hideFilters={hideFilters}
                  isAdmin={isAdmin}
                  cleanFilter={cleanFilter}
                />
              </div>
              {/*Cars section*/}
              <div className={`layout-car-body ${inventoryView === LIST_VIEW ? 'layout-car-body-list' : ''}`}>
                {carElementsForTemplateMain.length > 0 ?
                  carElementsForTemplateMain :
                  <div className='message-not-found' style={{backgroundColor: boxBackgroundColor}}>
                    <h3>No cars matched your</h3>
                  </div>
                }
              </div>
              {/*Pagination*/}
              {!!cars.length &&
                <div className='layout-car-pagination'
                     style={{
                       borderBottom: `1px solid ${boxBackgroundColor}`,
                       borderTop: `1px solid ${boxBackgroundColor}`
                     }}
                >
                  <DPPagination
                    className='catalog-inventory-pagination'
                    optionsQuantityByPage={CARS_BY_PAGE}
                    selectedItemsByPage={isAdmin ? CARS_BY_PAGE_DEFAULT : selectedItemsByPage}
                    handleChangeItemsByPage={handleChangeItemsByPage}
                    currentPage={currentPage}
                    totalPages={isAdmin ? 10 : totalPages}
                    handlePageChange={handlePageChange}
                    showItemPerPage={true}
                    siteColor={siteColor}
                    disabled={isAdmin}
                  />
                </div>
              }
              {/*Carousel with same cars in the page*/}
              {(showCarousel && carElementsForTemplateMain.length !== 0) ?

                <DPCarousel
                  cars={filteredCars}
                  groupCar={quantityCarsByRow}
                  displayArrows={displayArrows}
                  primaryColor={primaryColor}
                  siteColor={siteColor}
                  makes={makes}
                  models={models}
                  isAdmin={isAdmin}
                /> : null

              }
            </div>
          </div>
        </div>
      )
  )
}

export default LayoutInventory
