import React, {useEffect, useState} from 'react'
// @ts-ignore
import {CarInfoDetails, CarInfoLabels} from '../LayoutInventoryCar/LayoutInventoryCarViewTemplateCodeMain'
// @ts-ignore
import { getViewportWidth } from '../../Util'
// @ts-ignore
import { CAR_IMAGE_HEIGHT_PERCENT, CAR_IMAGE_COMING_SOON } from '../../Constants'
import  {unstable_batchedUpdates} from "react-dom";

require('./LayoutCarImagesDetail.scss')

interface LayoutCarImagesProps {
    textColor : string
    carWithAttributes : object
    statusSOLD : boolean
    width : number
    galleryImages: Array<object>
}


const LayoutCarImagesDetail = ( props: LayoutCarImagesProps) => {

    const {textColor, width, carWithAttributes, statusSOLD, galleryImages} = props
    const [imageSelected, setImageSelected] = useState({})
    const [gallery, setGallery] = useState<object[]>([])
    const [mainImageHeight, setMainImageHeight] = useState(0)

    useEffect(
        // @ts-ignore
        async() => {
            unstable_batchedUpdates( () => {
                setGallery(galleryImages);
                // @ts-ignore
                setImageSelected(galleryImages[0])
                setMainImageHeight(getViewportWidth('#carImageSelected') * 0.7)
            })
        }, [])

    const onSelectMedia = (image: object) => {
            setImageSelected(image);
    }

    return(
        <div className="container-images-details">
            <div className="container-image-selected">
                <div id="carImageSelected" className="car-image-selected">
                    { gallery.length > 0 ?
                        <img // @ts-ignore
                            src={imageSelected.url} style={{height: mainImageHeight}}/> :
                        <img src={CAR_IMAGE_COMING_SOON} style={{height: mainImageHeight}}/>
                    }
                </div>
            </div>
            <div className="container-details-gallery">
                <CarInfoDetails
                    textColor={textColor}
                    carWithAttributes={carWithAttributes}
                    statusSOLD={statusSOLD}
                />
                <CarInfoLabels
                    width={width}
                    car={carWithAttributes}
                />
                {
                    gallery.length > 0 ?
                        <div id="carImageGallery" className="car-image-gallery">
                            {
                                galleryImages.map((image, index) => {
                                    return (
                                        // @ts-ignore
                                        <div key={image.id} className="content-image"
                                            onClick={() => onSelectMedia(image)}
                                        >
                                            <img id={'image' + index}
                                                // @ts-ignore
                                                 src={image.url}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div> : null
                }
            </div>
        </div>

    )
}

export default  LayoutCarImagesDetail