import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import isBoolean from 'lodash/isBoolean'
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
const PHONE_NUMBER_REGEX = /\([0-9]{3}\)[0-9]{3}-[0-9]{4}/
const NUMBER_REGEX = /^(?:\(([0-9]{3})\)|([0-9]{3}))[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

export function checkValidEmail(value) {
  return EMAIL_REGEX.test(value.toLowerCase())
}

export function checkValidPhoneNumber(value) {
  return PHONE_NUMBER_REGEX.test(value)
}

export function checkValidVIN(value) {
  return (value && (value.length > 5 && value.length < 18))
}

export function checkValidPhone(value) {
  return NUMBER_REGEX.test(value)
}

export function checkValidZipCode(value) {
  return (value.length === 5)
}

export function checkRequired(value) {
  if (isArray(value)) {
    return reduce(value, function (acc, n) {
      return acc || (n && n.length > 0)
    })
  } else if (isBoolean(value)) {
    return true
  } else {
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      return parseFloat(value) >= 0
    } else {
      return value !== undefined && value !== null && value.length > 0
    }
  }
}