import React from 'react'
import {
  BOX_BACKGROUND_COLOR, BUTTON_TEXT_COLOR,
  CONTROL_BACKGROUND_COLOR,
  CONTROL_BORDER_COLOR, MODERN_VIEW_2_TEMPLATE_CODE, PRIMARY_COLOR, SECONDARY_COLOR,
  TEXT_COLOR,
  WIDTH_VIEW_MOBILE
} from '../../Constants'
import DPTextInput from '../../genericComponents/DPTextInput'
import DPButton from '../../genericComponents/DPButton'
import classNames from 'classnames'
import {getButtonTextCapByTemplate} from '../../Util'
import {useSelector} from 'react-redux'
import InputElement from 'react-input-mask'


const GetInTouch = ({
                      title, getInTouchForm, readOnly, onBlurPhoneField, sendMessage, inputChange, isCompact, siteColor, sampleErrors
                    }) => {

  const templateCode = useSelector(state=>state.templateCode)

  const sampleError = sampleErrors

  const nameClassName  = sampleError.hasOwnProperty('name') ? 'sample-error-input' : 'form-control'
  const phoneNumberClassName  = sampleError.hasOwnProperty('phoneNumber') ? 'sample-error-input' : 'form-control'
  const emailClassName  = sampleError.hasOwnProperty('email') ? 'sample-error-input' : 'form-control'

  const {
    [TEXT_COLOR]: textColor, [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [CONTROL_BACKGROUND_COLOR]: controlBackgroundColor, [CONTROL_BORDER_COLOR]: controlBorderColor
  } = siteColor
  const {email = '', name = '', text = '', phoneNumber} = getInTouchForm

  const inputStyles = {
    backgroundColor: controlBackgroundColor,
    border: isCompact ? `1px solid ${controlBorderColor}` : undefined,
    color: textColor
  }

  const buttonClassNames = classNames(
    {'readOnly': readOnly},
    {'btn btn-send': isCompact},
    {'btn btn-inv-more': !isCompact},
    'btn-center'
  )

  const sendButton = (
    <DPButton
      id='btnSend'
      buttonClassName={buttonClassNames}
      onClick={sendMessage}
      backgroundColor={`${siteColor[PRIMARY_COLOR]} !important`}
      backgroundHoverColor={`${siteColor[SECONDARY_COLOR]} !important`}
      textColor={`${siteColor[BUTTON_TEXT_COLOR]} `}
      label={getButtonTextCapByTemplate('send', templateCode)}
      isDisabled={readOnly}
      isAdmin={readOnly}
    />
  )

  const onInputChange = (prop, event) => {
    event.preventDefault()
    if (readOnly)
      return
    inputChange(prop, event?.target?.value)
  }

  const formClassName =  templateCode === MODERN_VIEW_2_TEMPLATE_CODE
    ? "form-get-in-touch mv2"
    : 'form-get-in-touch'

  if (isCompact) {
    return (
      <div className='get-in-touch-box'
           style={{borderColor: controlBackgroundColor, backgroundColor: boxBackgroundColor}}>
        <div className='get-in-touch-tile'>
          <h1 style={{color: textColor}}>{title ? title : null}</h1>
        </div>
        <div className='get-in-touch-form'>
          <form>
            <div className='form-first-row'>
              <div className='input-text form-first-row-element'>
                {
                  window.innerWidth <= WIDTH_VIEW_MOBILE ?
                    <span className='title-input-get-in' style={{color: textColor}}>Name</span> : null
                }
                <DPTextInput
                  inputClassName={nameClassName}
                  errorMessage={sampleError.name}
                  simpleInputClassname={true}
                  type='text'
                  inputId='name'
                  value={name}
                  onChange={(e) => onInputChange('name', e)}
                  inputName='clientName'
                  placeholder='Name *'
                  disabled={readOnly}
                  inputStyle={inputStyles}
                />
              </div>
              <div className='input-text form-first-row-second-element'>
                {
                  window.innerWidth <= WIDTH_VIEW_MOBILE ?
                    <span className='title-input-get-in' style={{color: textColor}}>Phone number</span> : null
                }
                <DPTextInput
                  inputClassName={phoneNumberClassName}
                  errorMessage={sampleError.phoneNumber}
                  mask='(999)999-9999'
                  simpleInputClassname={true}
                  type='tel'
                  inputId='phoneNumber'
                  value={phoneNumber}
                  onChange={(e) => onInputChange('phoneNumber', e)}
                  inputName='phoneNumber'
                  placeholder={'Phone Number *'}
                  disabled={readOnly}
                  inputStyle={inputStyles}
                  onBlur={(e) => onBlurPhoneField(e)}
                />
              </div>
            </div>
            <div className='input-text'>
              {
                window.innerWidth <= WIDTH_VIEW_MOBILE ?
                  <span className='title-input-get-in' style={{color: textColor}}>Email</span> : null
              }

              <DPTextInput
                inputClassName={emailClassName}
                errorMessage={sampleError.email}
                simpleInputClassname={true}
                type='text'
                inputId='email'
                value={email}
                onChange={(e) => onInputChange('email', e)}
                inputName='email'
                placeholder={'Email *'}
                disabled={readOnly}
                inputStyle={inputStyles}
              />

            </div>
            <div className='input-area'>
              {
                window.innerWidth <= WIDTH_VIEW_MOBILE ?
                  <span className='title-input-get-in' style={{color: textColor}}>Message</span> : null
              }


              <textarea
                className='form-control'
                id='message'
                value={text}
                onChange={(e) => onInputChange('text', e)}
                name='message'
                placeholder='Ask us anything'
                disabled={readOnly}
                style={inputStyles}/>
            </div>
            <div className='btn-row'>
              <div className='btn-send-content'>
                {sendButton}
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  } else {
    return (
      <div id='containerGetInTouch' className='container-get-in-touch'>
        <div className='container-form-get-in-touch' style={{backgroundColor: boxBackgroundColor}}>
          <div className='contain-form-getintouch'>
            <div style={{float: 'left'}}>
              <div>
                <h1 className='title-get-in-touch' style={{color: textColor}}>{title}</h1>
              </div>
            </div>
          </div>
          <div className={formClassName}>
            <form>
              <div className='form-flex-container'>
                <div className='form-first-row'>
                  <div className='form-first-row-element'>
                    <div className='container-label'>
                      <label style={{color: textColor}} htmlFor='name'>Name *</label>
                    </div>
                    <div className='container-input'>


                      <DPTextInput
                        inputClassName={nameClassName}
                        errorMessage={sampleErrors.name}
                        type='text'
                        inputId='name'
                        value={name}
                        onChange={(e) => onInputChange('name', e)}
                        inputName='clientName'
                        placeholder='Type here'
                        inputStyle={inputStyles}
                        disabled={readOnly}
                      />

                    </div>
                  </div>
                  <div className='form-first-row-second-element'>
                    <div className='container-label'>
                      <label style={{color: textColor}} htmlFor='phoneNumber'>Phone number *</label>
                    </div>
                    <div className='container-input'>

                      <DPTextInput
                        inputClassName={phoneNumberClassName}
                        errorMessage={sampleErrors.phoneNumber}
                        mask='(999)999-9999'
                        type='tel'
                        inputId='phoneNumber'
                        value={phoneNumber}
                        onChangeText={(e) => onInputChange('phoneNumber', e)}
                        inputName='phoneNumber'
                        placeholder='Type here'
                        inputStyle={inputStyles}
                        onBlur={(e) => onBlurPhoneField(e)}
                        disabled={readOnly}
                      />

                    </div>
                  </div>
                </div>
                <div>
                  <div className='container-label'>
                    <label style={{color: textColor}} htmlFor='email'>Email Address *</label>
                  </div>
                  <div className='container-input'>


                    <DPTextInput
                      inputClassName={emailClassName}
                      errorMessage={sampleErrors.email}
                      type='text'
                      inputId='email'
                      value={email}
                      onChange={(e) => onInputChange('email', e)}
                      inputName='email'
                      placeholder='Type here'
                      inputStyle={inputStyles}
                      disabled={readOnly}
                    />

                  </div>
                </div>
                <div>
                  <div className='container-label'>
                    <label style={{color: textColor}} htmlFor='message'>Message</label>
                  </div>
                  <div className='container-input'>


                    <DPTextInput
                      type='text'
                      inputId='message'
                      inputName='message'
                      value={text}
                      onChange={(e) => onInputChange('text', e)}
                      placeholder='Type here'
                      inputStyle={inputStyles}
                      disabled={readOnly}
                    />

                  </div>
                </div>
              </div>
              <div className='container-row-button'>
                <div className='container-button-get-in-touch'>
                  {sendButton}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default GetInTouch