import React, {useEffect, useState} from 'react'
import {
  PAGE_ABOUT_US_ID,
  SECTION_TESTIMONIAL_ID,
  WIDTH_VIEW_MOBILE,
  LIGHT_THEME,
  LIGHT_COLORS,
  DARK_COLORS,
  DARK_THEME,
  BOX_BACKGROUND_COLOR,
  SITE_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEMPLATE_MAIN_NAME,
  pathServer,
  CONTROL_BACKGROUND_COLOR,
  TEXT_COLOR,
  WIDTH_VIEW_TABLET,
  PAGE_HOME_ID,
  defaultDealerImages,
  SUCCESSFUL_CODE
} from '../../common/Constants'
import LayoutHeader from '../../common/template/LayoutHeader'
import LayoutFooter from '../../common/template/LayoutFooter'
import LayoutAboutUsGoal from '../../common/template/LayoutAboutUsGoal'
import {connect} from 'react-redux'
import {
  loadLocationsPublic,
  loadMakeAndModel,
  searchInventoryPublic,
  selectedLocationId,
  saveEmailMessage,
} from '../actions'
import {getBackgroundEasyNavigation, getClassContainerEasyNavigation} from '../../common/Util'
import {useScreenSize} from '../../common/hooks/useScreenSize'
import DPCarousel, {
  ARROWS_BOTTOM,
  HORIZONTAL_CAROUSEL,
  SEGMENTS_BAR,
} from '../../common/genericComponents/DPCarousel'
import {loadDealerMedia, loadPublishedTestimonials } from "../Api";

const LayoutFinanceContainer = ({
                                  dealerId,
                                  searchInventoryPublic,
                                  loadLocationsPublic,
                                  loadMakeAndModel,
                                  carsInformation,
                                  selectedLocationId,
                                  saveEmailMessage,
                                  templateContent,
                                  templateCode,
                                  locations,
                                  locationId
                                }) => {
  const [groupCar, setGroupCar] = useState(4)
  const [carouselHeight, setCarouselHeight] = useState(null)
  const [widthLayoutContainer, setWidthLayoutContainer] = useState(null)
  const [dealerMedias, setDealerMedias] = useState([])
  const [testimonials, setTestimonials] = useState([])

  const {width, height} = useScreenSize()

  const content = JSON.parse(templateContent)
  const generalSetting = content.generalSetting
  const pageAboutUs = content.pages.find(page => page.id === PAGE_ABOUT_US_ID)
  const pageHome = content.pages.find(page => page.id === PAGE_HOME_ID)
  const testimonialSection = pageHome.sections.find( sec => sec.id === SECTION_TESTIMONIAL_ID)
  const hideTestimonial = !!testimonialSection.hideSection

  const {header, showDealerHours, sections} = pageAboutUs

  const {socialMedia, footer, currentTheme, banner, site} = generalSetting
  const maxHeightLogo = site.maxHeight
  const maxHeight = banner.backgroundMain.maxHeight
  const backgroundMain = getBackgroundEasyNavigation(templateCode, banner.backgroundMain.url)
  const logo = site.logo ?? ''
  const classTemplateContainer = getClassContainerEasyNavigation(templateCode, 'layout-about-container')
  const siteColor = (currentTheme === LIGHT_THEME) ? generalSetting[LIGHT_COLORS] : generalSetting[DARK_COLORS]
  const {
    [PRIMARY_COLOR]: primaryColor, [SECONDARY_COLOR]: secondaryColor, [SITE_BACKGROUND_COLOR]: backgroundColor,
    [BOX_BACKGROUND_COLOR]: boxBackgroundColor, [BUTTON_TEXT_COLOR]: buttonTextColor = 'white',
    [CONTROL_BACKGROUND_COLOR]: controlBackgroundColor, [TEXT_COLOR]: textColor
  } = siteColor

  let cars = carsInformation ? carsInformation.cars : []

  if (locationId && locationId !== '-1' && cars) {
    cars = cars.filter(car => car.locationId === locationId)
  }

  let hoursOfOperation = []
  let locationAddress = ''
  if (locations) {
    const locationFound = locations.find(location => location.locationId === locationId)
    const location = locationFound ? locationFound : locations[0]
    if (location && location.info) {
      const locationInfo = JSON.parse(location.info)
      const {name, city, state, address} = location
      hoursOfOperation = locationInfo.hoursOfOperation
      locationAddress = `${name}: ${address}, ${city}. ${state}.`
    }
  }

  let displayArrows = false
  if (cars.length > groupCar)
    displayArrows = true

  let showTestimonialSection = false
  if (sections) {
    const sectionTestimonial = sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID)
    showTestimonialSection = !!sectionTestimonial?.showSection
  }

  useEffect(async () => {
    if (!carsInformation) {
      loadMakeAndModel()
      loadLocationsPublic()
      searchInventoryPublic(dealerId, {})
    }
    const response = await loadDealerMedia(dealerId)
    const {responseCode, data = {}} = response
    if (responseCode !== SUCCESSFUL_CODE) {
      return
    }
    let dealerMedias = data?.dealerMedias.length > 0 ? data?.dealerMedias : defaultDealerImages
    setDealerMedias(dealerMedias)

    if (!testimonials.length) {
      const responseTestimonials = await loadPublishedTestimonials()
      const {responseCode, responseMessage, data} = responseTestimonials
      if (responseCode === SUCCESSFUL_CODE){
        setTestimonials(data.testimonials)
      }else{
        console.error(responseMessage)
      }
    }
  }, [])

  useEffect(async () => {
    handleResize()

  }, [width, height])

  const handleResize = (e) => {
    let width = getViewportWidth('#carCarousel')

    let dealerCarousel = document.getElementById('carousel-content-2')
    if (!dealerCarousel) {
      dealerCarousel = document.getElementById('carousel-content-1')
      if (!dealerCarousel) {
        dealerCarousel = document.getElementById('carousel-content-0')
      }
    }
    if (dealerCarousel) {
      setCarouselHeight(carouselHeight)
    }

    if (width > 1045) {
      setGroupCar(4)
      setCarouselHeight(carouselHeight)
      setWidthLayoutContainer(window.innerWidth)
    } else if (width > 693 && width <= 1045) {
      setGroupCar(3)
      setCarouselHeight(carouselHeight)
      setWidthLayoutContainer(window.innerWidth)
    } else if (width <= 693) {
      setGroupCar(2)
      setCarouselHeight(carouselHeight)
      setWidthLayoutContainer(window.innerWidth)
    }

    const backgroundMain = document.getElementById('aboutBackgroundMain')
    const goal = document.getElementById('goal')
    if (backgroundMain && goal && window.innerWidth <= WIDTH_VIEW_MOBILE) {
      backgroundMain.style.height = `${goal.offsetHeight + 75}px`
    }

  }

  const getViewportWidth = (element) => {
    if (document.querySelector(element))
      return document.querySelector(element).offsetWidth
    else
      return 1100
  }

  const ItemDealerMedia = ({item}) => {
    return(
      <img className="dealer-image-item" src={item.url || pathServer.PATH_IMG +`carImageComingSoon.jpg` } /*style={{transition: "0.8s"}}*//>
    )
  }

    const ItemTestimonial = ({item}) => {
    return (
        <div className={"section-testimonial"} style={{backgroundColor: customBackgroundContrast}}>
        <div className="row-customer">
          <div className="testimonial-content-5 item-a" style={{color: textColor}}>
            <div className="text-container">
            {item.content}
            </div>
          </div>
          <div className="col-image item-b">
            <div className="box-image">
              <div className="content-image">
                <img
                  src={(item.userPicture !== "") ? item.userPicture : `${pathServer.PATH_IMG}ic_account_circle.png`}
                  onError={(e)=>{e.target.src = `${pathServer.PATH_IMG}ic_account_circle.png`}}
                />
              </div>
            </div>
          </div>
          <div className="item-c">
            <div className="container-label-name">
              <label className="label-name" style={{color: textColor}}>
                {item.userName ? item.userName : "User name unavailable"}
              </label>
              {
                (item.socialNetwork) ?
                <div className="container-label-social-network">
                  <label className="label-social" style={{color: "#809fbb"}}>
                    {`From ${item.socialNetwork? item.socialNetwork : "From Facebook"}`}
                  </label>
                </div>
                : null
              }
            </div>

          </div>
        </div>
      </div>
    )
  }

 const numberOfItemsPerPage = window.innerWidth <= WIDTH_VIEW_TABLET && window.innerWidth >= WIDTH_VIEW_MOBILE ? 3 : window.innerWidth < WIDTH_VIEW_MOBILE ? 1 : 4
 const numberOfItemsToShift = window.innerWidth <= WIDTH_VIEW_TABLET && window.innerWidth >= WIDTH_VIEW_MOBILE ? 3 : window.innerWidth < WIDTH_VIEW_MOBILE ? 1 : 4
 const imagesCarouselHeight = window.innerWidth <= WIDTH_VIEW_TABLET && window.innerWidth >= WIDTH_VIEW_MOBILE ? "22vw" : window.innerWidth < WIDTH_VIEW_MOBILE ? "65vw" : "17vw"
 const testimonialCarouselHeight = window.innerWidth <= WIDTH_VIEW_TABLET && window.innerWidth >= WIDTH_VIEW_MOBILE ? "30vw" : window.innerWidth < WIDTH_VIEW_MOBILE ? "72vw" : "21vw"
 const customBackground = currentTheme === LIGHT_THEME ? "#FFFFFF" : "#162335" //temporal until the colors in the setting style are defined
 const customBackgroundContrast = currentTheme === LIGHT_THEME ? "#F5F6FA" : "#0B1524" //temporal until the colors in the setting style are defined

  return (
    <div
      id='layoutAboutContainer'
      className={`${classTemplateContainer} ${currentTheme === DARK_THEME ? 'layout-about-container-dark' : ''} container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}
    >
      <LayoutHeader
        menu={header.menu}
        logo={logo}
        maxHeight={maxHeightLogo}
        menuSelected={'aboutUs'}
        locations={locations}
        siteColor={siteColor}
        templateCode={templateCode}
        locationId={locationId}
        selectLocationId={selectedLocationId}
        baseTemplateCode={TEMPLATE_MAIN_NAME}
      />
      <div id='aboutBackgroundMain' className='header-content about-background'>
        <img
          className='background'
          src={backgroundMain}
        />
        <LayoutAboutUsGoal
          currentTheme={currentTheme}
          siteColor={siteColor}
          templateCode={templateCode}
          page={pageAboutUs}
        />
        {/*<DPSocialMedia socialMedia={socialMedia}/>*/}
      </div>
      <div id='separatorHeader' className='separator-header'/>
      <div>
        <div className='layout-about'>
          {/*<LayoutAboutUsGoalOld
            currentTheme={currentTheme}
            siteColor={siteColor}
            hoursOfOperation={hoursOfOperation}
            locationAddress={locationAddress}
            templateCode={templateCode}
            page={pageAboutUs}
            showDealerHours={showDealerHours}
          />*/}

          {/*{
            widthLayoutContainer > 992 ?
              <div className='about-testimonial'>
                {
                  showTestimonialSection ?
                    <LayoutCustomerVerticalTestimonial
                      from={'aboutUs'}
                      widthLayoutContainer={widthLayoutContainer}
                      siteColor={siteColor}
                    /> : null
                }
                <LayoutDealerMedia
                  primaryColor={primaryColor}
                  dealerId={dealerId}
                  carouselHeight={carouselHeight}
                  templateCode={TEMPLATE_MAIN_NAME}
                  widthLayoutContainer={widthLayoutContainer}
                  buttonTextColor={buttonTextColor}/>
              </div> : null
          }*/}
        </div>
        {
          cars && cars.length !== 0 &&
          <div className='' style={{backgroundColor: boxBackgroundColor}}>
            <div className='content-separator'>
              <div className='car-separator1' style={{backgroundColor: primaryColor}}/>
              <div className='content-separator2' style={{backgroundColor: boxBackgroundColor}}>
                <div className='car-separator2' style={{backgroundColor: secondaryColor}}/>
              </div>
            </div>
            <div className="carousel-dealer-images-container" style={{backgroundColor: customBackground}}>
              <DPCarousel
                barSelectedPageColor={primaryColor}
                direction={HORIZONTAL_CAROUSEL}
                itemList={dealerMedias}
                ItemComponent={({item}) => <ItemDealerMedia item={item}/>}
                numberOfItemsPerPage={numberOfItemsPerPage}
                numberOfItemsToShift={numberOfItemsToShift}
                barType={SEGMENTS_BAR}
                width={"100%"}
                height={imagesCarouselHeight}
                customisedArrowPosition={ARROWS_BOTTOM}
                arrowContainerBackgroundColor={customBackgroundContrast}
              />
            </div>
            {
              showTestimonialSection &&
              <div id="testimonialHeader" className="testimonial-header" style={{backgroundColor: customBackground}}>
                <h1 style={{color: siteColor[TEXT_COLOR]}}>{"What people say about us"}</h1>
                <p style={{color: "#809FB8"}}>{"See why they recommend us"}</p>
              </div>
            }
            {
              showTestimonialSection &&
              <div className={"testimonials-container"} style={{backgroundColor: customBackground}}>
              <DPCarousel
                barSelectedPageColor={primaryColor}
                direction={HORIZONTAL_CAROUSEL}
                itemList={testimonials}
                ItemComponent={({item}) => <ItemTestimonial item={item}/>}
                numberOfItemsPerPage={numberOfItemsPerPage}
                numberOfItemsToShift={numberOfItemsToShift}
                barType={SEGMENTS_BAR}
                width={"100%"}
                height={testimonialCarouselHeight}
                customisedArrowPosition={ARROWS_BOTTOM}
                arrowContainerBackgroundColor={customBackgroundContrast}
                />
              </div>
            }
          </div>
        }
      </div>
      <LayoutFooter
        backgroundMain={backgroundMain}
        dealerId={dealerId}
        footer={footer}
        socialMedia={socialMedia}
        siteColor={siteColor}
        templateCode={templateCode}
        saveEmailMessage={saveEmailMessage}
        currentTheme={currentTheme}
        isButtonSticky={true}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    locations: state.locationsPublic,
    carsInformation: state.carsInformation,
    locationId: state.locationId,
    templateContent: state.templateContent,
    templateCode: state.templateCode,
  }
}

export default connect(mapStateToProps,
  {
    loadLocationsPublic,
    searchInventoryPublic,
    loadMakeAndModel,
    selectedLocationId,
    saveEmailMessage,
  })
(LayoutFinanceContainer)
