import React, {memo, useState} from 'react'
import {
  EASY_NAVIGATION_C_TEMPLATE_CODE,
  LIGHT_THEME,
  TEMPLATE_5_NAME,
  TEMPLATE_MAIN_NAME,
  pathServer,
  SITE_BACKGROUND_COLOR,
  TEXT_COLOR,
  BOX_BACKGROUND_COLOR,
  BOX_BORDER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  LOCATION_ALL
} from '../Constants'
import {ReactSVG} from 'react-svg'
import map from 'lodash/map'
import GoogleMaps from '../GoogleMaps'
import {getDaysDisplay} from '../genericComponents/DPDaySelector'
import {openingOptionsById} from '../../admin/newComponents/setting/locationSetting/OpeningHour'

const DEFAULT_LAT = 40.2256805
const DEFAULT_LNG = -111.64971980000001
const HOUR_CLOSED = 'Closed'

// Prevent re-render when GoogleMaps props does not change
const MemoGoogleMaps = memo(GoogleMaps)

export const getHourOperations = (hoursOfOperation, textColor, baseTemplateCode) => {
  return(
    map(hoursOfOperation, ({days, displayValue, from, format24, optionSelected, to}, index) => (
      <span key={index} style={{color: from === HOUR_CLOSED && baseTemplateCode === TEMPLATE_5_NAME ? undefined : textColor}}>
          <>
            {
              displayValue ?
                (from === HOUR_CLOSED )
                  ? <>{`${displayValue} `}<span style={{color: "#D10C0C"}}> {from}</span></>
                  : <>{`${displayValue} ${from} - ${to}`}</>
                :
                <div>
                  <span>{`${getDaysDisplay(days)}: `}</span>
                  <span style={ optionSelected === 'closed' ? {color: "#D10C0C"} : {}}>
                    {`${optionSelected === 'specificHours' ? `${from} - ${to}` : openingOptionsById[optionSelected].displayValue}`}
                  </span>
                </div>
            }
          </>
        </span>
    ))

  )
}

const LayoutLocation = ({
                          sectionLocation, siteColor, locations, selectedLocationId, templateCode,
                          currentTheme, baseTemplateCode, isAdminTemplate
}) => {

  const [displayedLocationId, setDisplayedLocation] = useState(selectedLocationId)

  const displayLocation = (locationId) => setDisplayedLocation(locationId === displayedLocationId ? LOCATION_ALL:locationId)

  const hideLocationSection = !!sectionLocation.hideSection
  const {
    [SITE_BACKGROUND_COLOR]: siteBackgroundColor, [TEXT_COLOR]: textColor, [BOX_BACKGROUND_COLOR]: boxBackgroundColor,
    [BOX_BORDER_COLOR]: boxBorderColor, [PRIMARY_COLOR]: primaryColor, [SECONDARY_COLOR]: secondaryColor
  } = siteColor
  const {title} = sectionLocation
  const iconColor = baseTemplateCode === TEMPLATE_MAIN_NAME && templateCode === EASY_NAVIGATION_C_TEMPLATE_CODE && currentTheme !== LIGHT_THEME ?
      textColor : primaryColor

  //todo: fix css, it is adding styles depending on the id, where all should have the same styles
  return (
    !hideLocationSection ?
    <div
      id={baseTemplateCode === TEMPLATE_MAIN_NAME ? ( isAdminTemplate ? 'locationMainEasyNavigation' : 'locationMain') : 'location5'}
      className='layout-location'
      style={{backgroundColor: siteBackgroundColor}}
    >
      <div id={'layout-location-title'} className="layout-location-title">
        <h2 style={{color: textColor}} data-testid='locations-section-header'>
          {baseTemplateCode === TEMPLATE_MAIN_NAME ? title : title?.toUpperCase()}
        </h2>
      </div>
      <div className='layout-location-content'>
        <div className="layout-location-left" style={{backgroundColor: boxBackgroundColor, border: `1px solid ${boxBorderColor}`}}>
          {
            map(locations, ({info, locationId, name, address, city, country, state, zip}) => {
              const locationInfo = info ? JSON.parse(info) : {}
              const {phoneNumber, hoursOfOperation} = locationInfo
              return (
                <div key={locationId} className="container-location">
                  {/*Location name*/}
                  <div className="location-header" onClick={isAdminTemplate ? () => {} : ()=> {
                    displayLocation(locationId);
                    document.getElementById("layout-location-title").scrollIntoView();
                  }}>
                    <div className="title-location" style={{color: textColor}}>
                      {name}.
                    </div>
                    <div className={isAdminTemplate ? 'icon-message-location' : 'icon-message'}style={{backgroundColor: primaryColor}}>
                      <i className="fa fa-envelope" style={{color: '#FFF'}}/>
                    </div>
                  </div>
                  {/*Location body, only show when is selected locationId*/}
                  <div className="location-description" style={{display: locationId === displayedLocationId ? 'block' : 'none'}}>
                    {/*Location Position*/}
                    <div className="container-description">
                      <div className="icon-position">
                        <ReactSVG
                          src={pathServer.PATH_IMG + "icon/ic_location.svg"}
                          className="icon-location-content"
                          beforeInjection={ svg => {
                            svg.classList.add("icon-location")
                            svg.setAttribute('style', `fill: ${iconColor}`)
                          }}
                        />
                      </div>
                      <div className="description">
                        <span style={{color: textColor}}>{address}</span>
                        <span style={{color: textColor}}>{city}, {state} {zip}</span>
                        <span style={{color: textColor}}>{country}</span>
                      </div>
                    </div>
                    {/*Location Telephone*/}
                    <div className="container-description">
                      <div className="icon-telephone">
                        <ReactSVG
                          src={pathServer.PATH_IMG + "icon/ic_phone.svg"}
                          beforeInjection={ svg => {
                            svg.classList.add('icon-location')
                            svg.setAttribute('style', `fill: ${iconColor}`)
                          }}
                        />
                      </div>
                      <div className="description">
                        <span style={{color: textColor}}>{phoneNumber ? phoneNumber : '-'}</span>
                      </div>
                    </div>
                    {/*Location Hours of Operation*/}
                    {
                      hoursOfOperation && hoursOfOperation.length &&
                      <div className="container-description">
                        <div className="icon-calendar">
                          <ReactSVG
                            src={pathServer.PATH_IMG + "icon/ic_calendar.svg"}
                            className="icon-location-content"
                            beforeInjection={ svg => {
                              svg.classList.add('icon-location')
                              svg.setAttribute('style', `fill: ${iconColor}`)
                            }}
                          />
                        </div>
                        <div className="description">
                          {
                            getHourOperations(hoursOfOperation, textColor, baseTemplateCode)
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        <MemoGoogleMaps
          lat={DEFAULT_LAT}
          lng={DEFAULT_LNG}
          locations={locations}
          locationId={displayedLocationId}
          siteColor={siteColor}
          isViewOnly={isAdminTemplate}
        />
      </div>
    </div>
    :null
  )
}

export default LayoutLocation
