import {CONTROL_BACKGROUND_COLOR, CONTROL_BORDER_COLOR, TEXT_COLOR} from './Constants'
import DPTextInput from './genericComponents/DPTextInput'
import PropType from 'prop-types'

export const DPRangeNumberInput = ({
                                     classNames,
                                     mask,
                                     integer,
                                     float,
                                     siteColor,
                                     displayValue,
                                     displayValueFrom,
                                     displayValueTo,
                                     id,
                                     value,
                                     onChangeNumber,
                                     icon,
                                     iconPosition,
                                     disabled,
                                     positive,
                                     negative,
                                     thousand
                                   }) => {

  const {container, label, filter, input, labelFrom, labelTo} = classNames

  const dynamicNumberStyle = {
    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
    border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
    color: siteColor[TEXT_COLOR]
  }

  const numberData = {regex: '\\d+', length: integer}

  if (thousand) {
    numberData.regex = numberData.regex + '(,\\d+)*'
    numberData.length = numberData.length * (1 + 1 / 4)
  }

  if (float) {
    numberData.length = float + 1
    numberData.regex = numberData.regex + '(\\.\\d*)?'
  }

  if (negative & positive) {
    numberData.regex = '-?' + numberData.regex
  } else if (negative & !positive) {
    numberData.regex = '-' + numberData.regex
    numberData.length = numberData.length + 1
  }

  numberData.regex = '^' + numberData.regex + '$'

  const thousandSeparator = (val) => {
    if (!val)
      return val
    const newVal = val.replaceAll(',', '')
    const dot = newVal.slice(-1) === '.' ? '.' : ''
    return parseFloat(newVal).toLocaleString('en') + dot
  }

  return (
    <div className={container}>
      {displayValue &&
        <div className={label}>
          <label
            style={{color: siteColor[TEXT_COLOR]}}
          >
            {displayValue}
          </label>
        </div>
      }
      <div className={filter}>
        {displayValueFrom &&
          <div className={labelFrom}>
            <label
              style={{color: siteColor[TEXT_COLOR]}}
            >
              {displayValueFrom}
            </label>
          </div>
        }
        <div className={input}>
          <DPTextInput
            inputId={id.idFrom}
            inputClassName='form-control'
            simpleInputClassname={true}
            value={thousand ? thousandSeparator(value.valueFrom) : value.valueFrom}
            onChangeNumber={onChangeNumber}
            style={dynamicNumberStyle}
            data-testid={`dp-input-${id.idFrom}`}
            rightElement={iconPosition === 'right' ? icon : null}
            leftElement={iconPosition === 'left' ? icon : null}
            mask={mask}
            maxLength={numberData.length.toString()}
            regex={!mask && numberData.regex}
            disabled={disabled}
          />
        </div>
        <div className={'label-to ' + labelTo}>
          <label
            style={{color: siteColor[TEXT_COLOR]}}
          >
            {displayValueTo || 'to'}
          </label>
        </div>
        <div className={input}>
          <DPTextInput
            inputId={id.idTo}
            inputClassName='form-control'
            simpleInputClassname={true}
            value={thousand ? thousandSeparator(value.valueTo) : value.valueTo}
            onChangeNumber={onChangeNumber}
            style={dynamicNumberStyle}
            data-testid={`dp-input-${id.idTo}`}
            rightElement={iconPosition === 'right' ? icon : null}
            leftElement={iconPosition === 'left' ? icon : null}
            mask={mask}
            maxLength={numberData.length.toString()}
            regex={!mask && numberData.regex}
            disabled={disabled}
          />
        </div>
      </div>

    </div>
  )
}

DPRangeNumberInput.defaultProps = {
  positive: true,
  negative: false,
  thousand: false,
  disabled: false,
  mask: null,
  classNames: {container: '', label: '', filter: '', input: '', labelFrom: '', labelTo: ''},
  displayValueFrom: null,
  displayValueTo: null,
  displayValue: null,
  icon: null,
  integer: 10,
  float: null,
  id: {idFrom: 'defaultFrom', idTo: 'defaultTo'},
  value: {valueFrom: '', valueTo: ''},
  onChangeNumber: null,
  siteColor: {},
}

DPRangeNumberInput.propTypes = {
  id: PropType.object,
  value: PropType.object,
  displayValue: PropType.string,
  displayValueFrom: PropType.string,
  displayValueTo: PropType.string,
  siteColor: PropType.object,
  integer: PropType.number,
  float: PropType.number,
  icon: PropType.any,
  iconPosition: PropType.string,
  onChangeNumber: PropType.func,
  classNames: PropType.object,
  mask: PropType.string,
  disabled: PropType.bool,
  positive: PropType.bool,
  negative: PropType.bool,
  thousand: PropType.bool
}