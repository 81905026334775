import React, {Component, useEffect, useState} from 'react'
import chunk from 'lodash/chunk'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import {Carousel, CarouselItem} from 'react-bootstrap'
import {
  PRIMARY_COLOR,
  ATTRIBUTE_CODE_CAR_STATUS,
  BOX_BACKGROUND_COLOR,
  CONTROL_BORDER_COLOR,
  carStatus2, optionTrans,
  CONTROL_BACKGROUND_COLOR,
} from '../Constants'
import {browserHistory} from 'react-router'
import {HoverOverlayCar} from '../HoverOverlayCar'
import LayoutCarItemCarousel from '../LayoutCarItemCarousel'
//CAROUSEL DEPRECATED - DON'T USE IT
const CAROUSEL_INTERVAL = 300000

const WrapperHoverComponent = ({car, indexCar, siteColor, viewMore}) => {
  const miles = Math.round(car.mileage / 1000)

  const trans = car['car.transmission'] ? car['car.transmission'].attributeValue : ''
  const trimCar = (car['car.trim'] ? car['car.trim'].attributeValue : '') === '-' ? ''
    : (car['car.trim'] ? car['car.trim'].attributeValue : '')
  const transmission = typeof optionTrans[trans] === 'undefined' ? null : optionTrans[trans]

  return (<React.Fragment>
    <div className='row-details'>
      <h5>{`${car.year} ${car.makeName} ${car.modelName}`}</h5>
    </div>
    <div className='row-details'>
      {
        trimCar ?
          <div className='btn-car-content'>
            <div className='btn-car-info'>
              {trimCar}
            </div>
          </div> : null

      }
      {
        transmission ?
          <div className='btn-car-content'>
            <div className='btn-car-info'>
              {transmission}
            </div>
          </div> : null
      }
      <div className='btn-car-content'>
        <div className='btn-car-info'>
          {miles + ' M'}
        </div>
      </div>
    </div>
    <div className='row-details' style={{padding: 0}}>
      <div className='btn-content-details'>
        <a
          id={'btnDetails' + indexCar}
          className='btn btn-details'
          style={{color: siteColor[PRIMARY_COLOR]}}
          onClick={viewMore}
        >
          DETAILS
        </a>
      </div>
    </div>
  </React.Fragment>)
}

function setOrderToCars(cars) {
  let carList = [...cars]
  let carsWithOrder = carList.map(car => {
    const status = car['car.status'].attributeValue
    switch (status) {
      case carStatus2.FRONT_LINE_READY:
        car.order = 1
        return car
      case carStatus2.COMING_SOON :
        car.order = 2
        return car
      case carStatus2.SOLD :
        car.order = 3
        return car
      default :
        car.order = 4
        return car
    }
  })

  return sortBy(carsWithOrder, el => el.order)
}

function getCarouselItems(cars, makes, models, carsByCarouselItem = 4, siteColor = {}, isAdmin = false) {
  const containerStyle = {
    float: 'left',
    width: '100%',
    boxShadow: `0 3px 6px #00000029`,
    border: '0',
    borderRadius: '11px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
    borderBottom: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
  }

  const viewMore = (vin) => {
    if (isAdmin)
      return
    browserHistory.push(`/catalog/car/${vin}`)
  }

  const carsOrdered = setOrderToCars(cars)
  let carGroups = chunk(carsOrdered, carsByCarouselItem)
  let indexCar = 0
  let carouselItems = carGroups.map(function (carGroup, index) {
    let carsItem = []
    carGroup.forEach(function (car) {
      const carItem = (
        <div key={car.id} className={'col-car' + carsByCarouselItem}>
          {isAdmin
            ? <div className='car-item'>
              <HoverOverlayCar
                WrappedComponent={LayoutCarItemCarousel}
                WrapperHoverComponent={WrapperHoverComponent}
                car={car}
                indexCar={indexCar.toString()}
                viewMore={viewMore}
                siteColor={siteColor}
                showHoverContent={car[ATTRIBUTE_CODE_CAR_STATUS].attributeValue !== carStatus2.SOLD}
                containerStyle={containerStyle}
                hoveredContentClassName={'car-item-details'}
              />
            </div>
            :
            <HoverOverlayCar
              WrappedComponent={LayoutCarItemCarousel}
              WrapperHoverComponent={WrapperHoverComponent}
              car={car}
              indexCar={indexCar.toString()}
              viewMore={viewMore}
              siteColor={siteColor}
              showHoverContent={car[ATTRIBUTE_CODE_CAR_STATUS].attributeValue !== carStatus2.SOLD}
              containerStyle={containerStyle}
              hoveredContentClassName={'car-item-details'}
            />
          }
        </div>
      )
      carsItem.push(carItem)
      indexCar += 1
    })
    return (<CarouselItem key={index}>
      <div className='row-carousel'>{carsItem}</div>
    </CarouselItem>)
  })

  return carouselItems
}

const DPCarousel = ({
                      cars,
                      makes,
                      models,
                      groupCar,
                      primaryColor,
                      displayArrows,
                      siteColor,
                      title,
                      isAdmin
                    }) => {

  useEffect(() => {
    leftRightHover('scLef', 'iconLeft', primaryColor)
    leftRightHover('scRight', 'iconRight', primaryColor)

  }, [])

  const leftRightHover = (idSemi, idIcon, primaryColor) => {
    const semiLeftRight = window.document.getElementById(idSemi)
    const elLeftRight = $(semiLeftRight)
    let icon
    elLeftRight.hover(function () {
      elLeftRight.css('background-color', primaryColor)
      icon = window.document.getElementById(idIcon)
      icon.style.color = 'white'
    }, function () {
      elLeftRight.css('background-color', 'white')
      icon = window.document.getElementById(idIcon)
      icon.style.color = primaryColor
    })
  }


  const prevIcon =
    displayArrows
      ? (
        <div id='scLef' className='semi-circle-carousel sc-left' style={{borderColor: primaryColor}}>
          <i id='iconLeft' className='fa fa-angle-left' style={{color: primaryColor}}/>
        </div>
      )
      : null

  const nextIcon =
    displayArrows
      ? (
        <div id='scRight' className='semi-circle-carousel sc-right' style={{borderColor: primaryColor}}>
          <i id='iconRight' className='fa fa-angle-right' style={{color: primaryColor}}/>
        </div>
      )
      : null

  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const carsCarousel = (isAdmin ? Array.from({length: 4}, (v, i) => cars[i]) : cars)
  const carouselItems = getCarouselItems(carsCarousel, makes, models, groupCar, siteColor, isAdmin)

  return (

    <div id='carCarousel' className='car-carousel'
         style={siteColor[BOX_BACKGROUND_COLOR] ? {backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR]} : {}}
    >
      {
        title && <span className='title-carousel'>More vehicles</span>
      }
      <Carousel id="carCarouselBody"
                className="carousel-fade"
                interval={CAROUSEL_INTERVAL}
                prevIcon={prevIcon}
                nextIcon={nextIcon}
                onSelect={handleSelect}
      >
        {carouselItems}
      </Carousel>
    </div>
  )

}

export default DPCarousel