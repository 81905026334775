/**
 * Created by Jhonny Apolinario on 20/10/2016
 */

import React, {useState, useEffect} from 'react'
import {
  loadMakeAndModel,
  loadLocationsPublic,
  searchInventoryPublic,
  selectedLocationId,
  saveEmailMessage,
  getCompleteCarsMainAttributes
} from '../actions'
import {connect, useDispatch, useSelector} from 'react-redux'
import {
  TEMPLATE_5_NAME,
  PAGE_HOME_ID,
  SECTION_CONTACT_US_ID,
  SECTION_FINANCE_ID,
  SECTION_INVENTORY_ID,
  SECTION_LOCATION_ID,
  MODERN_VIEW_2_TEMPLATE_CODE,
  MODERN_VIEW_3_TEMPLATE_CODE,
  MODERN_VIEW_TEMPLATE_CODE,
  CURRENT_THEME,
  LIGHT_THEME,
  PRIMARY_COLOR,
  SECTION_TESTIMONIAL_ID,
  SITE_BACKGROUND_COLOR
} from '../../common/Constants'
import LayoutHeader from '../../common/template/LayoutHeader'
import LayoutLocation from '../../common/template/LayoutLocation.jsx'
import LayoutFinance from '../../common/template/LayoutFinance'
import LayoutBanner from '../../common/template/LayoutBanner'
import LayoutCustomerHorizontalTestimonials from '../../common/LayoutCustomerHorizontalTestimonials.jsx'
import LayoutFooter from '../../common/template/LayoutFooter'
import LayoutGetInTouch from '../../common/template/LayoutGetInTouch'
import get from 'lodash/get'
import LayoutCustomerVerticalTestimonial from '../../common/LayoutCustomerVerticalTestimonial'
import {
  getSiteColor,
  getContainerClassModernView,
  getBackgroundModernView, compareIds
} from '../../common/Util'
import DPBackToTop from '../../common/DPBackToTop'
import LayoutCar from '../../common/template/LayoutCar'

function LayoutHomeContainer({
                               carsInformation,
                               makes,
                               locations,
                               templateContent,
                               dealerId,
                               templateCode,
                               locationId,
                               selectedLocationId,
                               saveEmailMessage,
                               searchInventoryPublic,
                               models,
                               loadMakeAndModel,
                               loadLocationsPublic
                             }) {

  const [selectedPage, setSelectedPage] = useState('home')

  useEffect(() => {
    if (!carsInformation) {
      loadMakeAndModel()
      loadLocationsPublic()
      searchInventoryPublic(dealerId, {})
    }
  }, [])

  if (!carsInformation || !makes || !locations) return null

  const content = JSON.parse(templateContent)
  const {generalSetting, pages} = content
  const {
    socialMedia,
    footer,
    site: {logo = '', maxHeight: maxHeightLogo},
    banner,
    header,
    currentTheme
  } = generalSetting
  const backgroundUrl = getBackgroundModernView(templateCode, banner.backgroundMain.url)
  const page = pages.find(page => page.id === PAGE_HOME_ID)
  const sectionFinance = page.sections.find(sec => sec.id === SECTION_FINANCE_ID)
  const sectionContactUs = page.sections.find(sec => sec.id === SECTION_CONTACT_US_ID)
  const sectionInventory = page.sections.find(sec => sec.id === SECTION_INVENTORY_ID)
  const sectionLocation = page.sections.find(sec => sec.id === SECTION_LOCATION_ID)
  const sectionTestimonial = page.sections.find(sec => sec.id === SECTION_TESTIMONIAL_ID)
  const hideFinanceSection = !!sectionFinance.hideSection
  const hideContactSection = !!sectionContactUs.hideSection
  const hideTestimonialSection = !!sectionTestimonial.hideSection
  const siteColor = getSiteColor(generalSetting)

  const {[PRIMARY_COLOR]: primaryColor, [SITE_BACKGROUND_COLOR]: siteBackgroundColor} = siteColor
  const {menu} = header
  const {maxHeight} = banner.backgroundMain
  const classTemplateContainer = getContainerClassModernView(templateCode, 'home')

  const getInTouchComponent = (
    <LayoutGetInTouch
      title={sectionContactUs?.title}
      siteColor={siteColor}
      dealerId={dealerId}
      templateCode={templateCode}
      section={sectionContactUs}
    />
  )
  return (
    <div
      className={`layout-container-5 ${classTemplateContainer} container-${currentTheme === LIGHT_THEME ? 'light' : 'dark'}`}>
      <LayoutHeader
        menu={menu}
        logo={logo}
        maxHeight={maxHeightLogo}
        menuSelected={selectedPage}
        changeSelectedMenu={setSelectedPage}
        locations={locations}
        siteColor={siteColor}
        currentPage={page}
        templateCode={templateCode}
        locationId={locationId}
        selectLocationId={selectedLocationId}
        baseTemplateCode={TEMPLATE_5_NAME}
      />
      <LayoutBanner
        slogan={generalSetting.banner.slogan}
        backgroundMain={backgroundUrl}
        maxHeight={maxHeight}
        templateCode={templateCode}
        siteColor={siteColor}
        searchInventoryPublic={searchInventoryPublic}
        dealerId={dealerId}
        makes={makes}
        models={models}
      />
      {/* Uncomment and set to false isButtonSticky prop from footer if you want to use the regular back to top button */}
      {/*<DPBackToTop*/}
      {/*  siteColor={siteColor}*/}
      {/*  templateCode={templateCode}*/}
      {/*/>*/}
      <LayoutCar
        sectionInventory={sectionInventory}
        templateCode={templateCode}
        siteColor={siteColor}
        carsInformation={carsInformation.cars}
        locationId={locationId}
      />
      {!hideFinanceSection &&
        <LayoutFinance
          section={sectionFinance}
          siteColor={siteColor}
          templateCode={templateCode}
          currentTheme={currentTheme}
        />
      }
      <LayoutLocation
        sectionLocation={sectionLocation}
        siteColor={siteColor}
        locations={locations}
        selectedLocationId={locationId}
        key={locationId}
        templateCode={templateCode}
        baseTemplateCode={TEMPLATE_5_NAME}
      />
      {
        (templateCode === MODERN_VIEW_TEMPLATE_CODE || templateCode === MODERN_VIEW_3_TEMPLATE_CODE) &&
        <>
          {!hideTestimonialSection ?
            <LayoutCustomerHorizontalTestimonials
              primaryColor={primaryColor}
              hideTitle={false}
              heightCurrentBar={7}
              heightTotalBar={3} from={'home'}
              siteColor={siteColor}
              title={(sectionTestimonial && sectionTestimonial.title) ? sectionTestimonial.title : 'CHECK OUR NEW INVENTORY'}
              currentTheme={generalSetting[CURRENT_THEME] || LIGHT_THEME}
            /> : null
          }
          {getInTouchComponent}
        </>
      }
      {
        (templateCode === MODERN_VIEW_2_TEMPLATE_CODE) &&
        <div
          id='contactMain'
          className='lay-get-in-touch'
          style={{backgroundColor: siteBackgroundColor}}
        >
          <div className='layout-contact-testimonial'>
            <div className={`layout-contact-${!hideContactSection? 'full' : 'hide'}`}>
              {getInTouchComponent}
            </div>
            <div className={`layout-testimonials-${!hideTestimonialSection? 'full' : 'hide'}`}>
              {
                !hideTestimonialSection ?
                  sectionTestimonial && get(sectionTestimonial, ['showSection'], true) &&
                  <LayoutCustomerVerticalTestimonial
                    from={'home'}
                    sectionTestimonial={sectionTestimonial}
                    siteColor={siteColor}
                  />:null
              }
            </div>
          </div>


        </div>
      }
      <LayoutFooter
        backgroundMain={backgroundUrl}
        dealerId={dealerId}
        footer={footer}
        socialMedia={socialMedia}
        siteColor={siteColor}
        templateCode={templateCode}
        saveEmailMessage={saveEmailMessage}
        isButtonSticky={true}
      />
      {/*<DPChat/>*/}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    dealerId: state.dealerId,
    carsInformation: state.carsInformation,
    makes: state.makes,
    models: state.models,
    locations: state.locationsPublic,
    templateContent: state.templateContent,
    locationId: state.locationId,
    templateCode: state.templateCode
  }
}


export default connect(
  mapStateToProps,
  {searchInventoryPublic, loadMakeAndModel, loadLocationsPublic, selectedLocationId, saveEmailMessage}
)(LayoutHomeContainer)
