import React, {useState, useEffect} from 'react'
import {findIndex, chunk} from 'lodash'
import {
  BOX_BACKGROUND_COLOR,
  CAR_IMAGE_COMING_SOON,
  CAR_IMAGE_HEIGHT_PERCENT,
  pathServer,
  PRIMARY_COLOR
} from '../../../Constants'
import {Carousel, CarouselItem} from 'react-bootstrap'
import {useScreenSize} from '../../../hooks/useScreenSize'
import {getViewportWidth} from '../../../Util'
import {ReactSVG} from 'react-svg'

const PreviewImagesGroup = ({imagesChunk, handleSelectImageGroup, previewPicturesQuantity}) => (
  imagesChunk.map((image, imageIndex) => (
    <div
      id={'colCarImage' + imageIndex}
      key={image.id}
      className={'col-car-image' + previewPicturesQuantity}
    >
      <img className='car-image' src={image.url} onClick={() => handleSelectImageGroup(image.id)}/>
    </div>
  ))
)

const CarCarouselTemplateCode5 = ({images, siteColor,carStatus}) => {

  const {
    [PRIMARY_COLOR]: primaryColor, [BOX_BACKGROUND_COLOR]: boxBackgroundColor
  } = siteColor

  const {width} = useScreenSize()
  const [mainImageIndex, setMainImageIndex] = useState(0)
  const [previewImagesGroupIndex, setPreviewImagesGroupIndex] = useState(0)
  const [mainCarouselHeight, setMainCarouselHeight] = useState(0)
  const [previewPicturesQuantity, setPreviewPicturesQuantity] = useState(0)

  useEffect(() => {
    setPreviewPicturesQuantity(width <= 753 ? 5 : 4)

    const mainImageHeight = getViewportWidth('#carImageMainContent') * (CAR_IMAGE_HEIGHT_PERCENT - 0.10)
    setMainCarouselHeight(mainImageHeight)
  }, [width])

  //Executed when clicking the preview images
  const handleSelectImageGroup = (selectedIndex) => {
    const index = findIndex(images, {'id': selectedIndex})
    setMainImageIndex(index)
  }

  // Prev/Next for preview groups
  const handleSelectGroup = (selectedGroupIndex) => setPreviewImagesGroupIndex(selectedGroupIndex)

  // Prev/Next for main image
  const handleSelectMain = (selectedMainIndex) => {
    setMainImageIndex(selectedMainIndex)
    handleSelectGroup(Math.floor(selectedMainIndex / previewPicturesQuantity))
  }

  // Main image is a carousel 1 by 1 image
  const centerImages = images && images.length > 0 ?
    images.map(image => (
      <CarouselItem key={image.id}>
        <div id='carImageMainContent' className='item-center' style={{height: mainCarouselHeight}}>
          {(carStatus) &&
            <div className='banner-sold-car'>Vehicle SOLD</div>
          }
          <img id='carImageMain' className='car-image-main' src={image.url}/>
          <div className='car-pagination'>
            <span>{(mainImageIndex + 1) + '/' + images.length}</span>
          </div>
        </div>
      </CarouselItem>
    )) :
    <CarouselItem key={0}>
      <div id='carImageMainContent' className='item-center' style={{height: mainCarouselHeight}}>
        {(carStatus) &&
          <div className='banner-sold-car'>Vehicle SOLD</div>
        }
        <img id='carImageMain' className='car-image-main' src={CAR_IMAGE_COMING_SOON} />
      </div>
    </CarouselItem>

  const previewImagesGroups = (images !== null && images.length > 0) ? (
    chunk(images, previewPicturesQuantity).map((imagesChunk, chunkIndex) => (
      <CarouselItem key={chunkIndex}>
        <div className='row-carousel'>
          <PreviewImagesGroup
            imagesChunk={imagesChunk}
            handleSelectImageGroup={handleSelectImageGroup}
            previewPicturesQuantity={previewPicturesQuantity}
          />
        </div>
      </CarouselItem>
    ))
  ) : []

  const prevIcon = (
    <ReactSVG
      src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
      beforeInjection={svg => {
        svg.classList.add('icon-arrow')
        svg.setAttribute('style', `fill: ${primaryColor}`)
      }}
      className='arrow-left'
      style={{backgroundColor: boxBackgroundColor}}
    />
  )

  const nextIcon = (
    <ReactSVG
      src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'}
      beforeInjection={svg => {
        svg.classList.add('icon-arrow')
        svg.setAttribute('style', `fill: ${primaryColor}`)
      }}
      className='arrow-right'
      style={{backgroundColor: boxBackgroundColor}}
    />
  )

  return (
    <>
      {/*Main image of the Carousel*/}
      <Carousel
        id='carImageMainContent'
        className='item-center car-image-main'
        activeIndex={mainImageIndex}
        slide={false}
        onSelect={handleSelectMain}
        style={{height: mainCarouselHeight, padding: '0 0 0 0'}}
        controls={false}
        indicators={false}
      >
        {centerImages}
      </Carousel>

      {/*Carousel controls*/}
      {
        images.length > 0 ?
          <Carousel
            id='carCarouselOne'
            activeIndex={previewImagesGroupIndex}
            slide={false}
            prevIcon={prevIcon}
            nextIcon={nextIcon}
            onSelect={handleSelectGroup}
          >
            {previewImagesGroups}
          </Carousel> : null
      }
    </>
  )
}

export default CarCarouselTemplateCode5