export const pathServer = {
  PATH_IMG: '/assets/images/',
  PATH_VIDEO: '/assets/videos/'
}

export const imageExpand = pathServer.PATH_IMG + 'ic_expand_more.png'
export const imageSquare = pathServer.PATH_IMG + 'ic_check_icon.png'
export const imageList = pathServer.PATH_IMG + 'ic_list.png'
export const imageParagraph = pathServer.PATH_IMG + 'ic_format_textdirection_l_to_r.png'
export const imageDate = pathServer.PATH_IMG + 'ic_today.png'
export const imageCheckBoxList = pathServer.PATH_IMG + 'ic_radio_button_checked.png'
export const imagePasswordHidden = pathServer.PATH_IMG + 'ic_password_hidden.png'
export const imageTextArea = pathServer.PATH_IMG + 'ic_textarea.png'
export const imageLabel = pathServer.PATH_IMG + 'ic_label.png'
export const imageDynamicNumber = pathServer.PATH_IMG + 'ic_dynamic_number.png'
export const imageSingleLine = pathServer.PATH_IMG + 'ic_text_fields.png'

export const carStatus = {
  SOLD: 'Sold',
  ARCHIVED: 'Archived',
  COMING_SOON: 'Coming Soon'
}

export const ATTRIBUTE_CODE_CAR_STATUS = 'car.status'
export const ATTRIBUTE_CODE_CAR_BODY = 'car.body'
export const ATTRIBUTE_CODE_CAR_TITLE = 'car.title'
export const ATTRIBUTE_CODE_CAR_DRIVE = 'car.drive'
export const ATTRIBUTE_CODE_CAR_FUEL = 'car.fuel'
export const ATTRIBUTE_CODE_CAR_INTERIOR_COLOR = 'car.interiorColor'
export const ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR = 'car.exteriorColor'


export const CAR_IMAGE_COMING_SOON = pathServer.PATH_IMG + 'carImageComingSoon.jpg'

export const CURRENT_THEME = 'currentTheme'
export const LIGHT_THEME = 'light'
export const DARK_THEME = 'dark'

export const LIGHT_COLORS = 'color'
export const DARK_COLORS = 'darkColor'

export const PRIMARY_COLOR = 'primary'
export const SECONDARY_COLOR = 'secondary'
export const BUTTON_TEXT_COLOR = 'buttonText'
export const TEXT_COLOR = 'text'
export const SITE_BACKGROUND_COLOR = 'siteBackground'
export const NAVBAR_BACKGROUND_COLOR = 'navigationBarBackground'
export const NAVBAR_TEXT_COLOR = 'navigationBarText'
export const BOX_BACKGROUND_COLOR = 'boxBackground'
export const CONTROL_BACKGROUND_COLOR = 'controlBackground'
export const FOOTER_TEXT_COLOR = 'footerText'
export const CONTROL_BORDER_COLOR = 'controlBorder'
export const BOX_BORDER_COLOR = 'boxBorder'
export const CHECKBOX_AND_EXPAND_COLOR = 'checkboxAndExpand'

export const defaultDealerImages = [
  {id: 'defaultImage3', url: `${pathServer.PATH_IMG}/DealerDefaultImages/dealer-image-3.jpg`, mediaType: 'image'},
  {id: 'defaultImage1', url: (`${pathServer.PATH_IMG}/DealerDefaultImages/dealer-image-1.jpg`), mediaType: 'image'},
  {id: 'defaultImage2', url: `${pathServer.PATH_IMG}/DealerDefaultImages/dealer-image-2.jpg`, mediaType: 'image'},
  {id: 'defaultImage4', url: `${pathServer.PATH_IMG}/DealerDefaultImages/dealer-image-4.jpg`, mediaType: 'image'},
  {id: 'defaultImage5', url: `${pathServer.PATH_IMG}/DealerDefaultImages/dealer-image-5.jpg`, mediaType: 'image'},
]

export const EMOJI_REGEX = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g

export const LOCATION_ALL = '-1'
export const CURRENT_LOCATION = '1'

export const WIDTH_VIEW_MOBILE = 768
export const WIDTH_VIEW_TABLET = 1024
export const WIDTH_VIEW_WEB = 1140
export const WIDTH_VIEW_1020 = 1020

export const MILES = 1000
export const CAR_IMAGE_HEIGHT_PERCENT = 0.75
export const REDUCE_IMAGE_PERCENTAGE = 0.20

export const TIME_FIVE_SECONDS = 5000

export const TEMPLATE_5_NAME = '5'
export const TEMPLATE_MAIN_NAME = 'Main'

export const EASY_NAVIGATION_TEMPLATE_CODE = 'easyNavigation'
export const EASY_NAVIGATION_B_TEMPLATE_CODE = 'easyNavigationB'
export const EASY_NAVIGATION_C_TEMPLATE_CODE = 'easyNavigationC'

export const BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_5 = 650

export const MODERN_VIEW_TEMPLATE_CODE = 'modernView'
export const MODERN_VIEW_2_TEMPLATE_CODE = 'modernView2'
export const MODERN_VIEW_3_TEMPLATE_CODE = 'modernView3'

export const PAGE_HOME_ID = 'home'
export const PAGE_FINANCE_ID = 'finance'
export const PAGE_INVENTORY_ID = 'inventory'
export const PAGE_ABOUT_US_ID = 'aboutUs'
export const PAGE_GENERAL_SETTING = 'generalSetting'
export const PAGE_CATALOG_ID = 'catalog'

export const SECTION_FINANCE_ID = 'finance'
export const SECTION_FINANCE_STEPS_TITLE = 'stepsTitle'
export const SECTION_INVENTORY_ID = 'inventory'
export const SECTION_CONTACT_US_ID = 'contact'
export const SECTION_LOCATION_ID = 'location'
export const SECTION_TESTIMONIAL_ID = 'testimonial'
export const SECTION_FINANCE_STEPS_DESCRIPTION = 'stepsDescription'

export const formEmail = {
  ACCOUNT_CONFIRMATION_SUBJECT_MESSAGE: 'Account Confirmation',
  CLIENT_TEXT_MESSAGE: 'Your email has been received. ',
  ADMIN_TEXT_MESSAGE: 'There is a new message',
  ADMIN_EMAIL: 'roggerclaudioa@gmail.com',
  ADMIN_NAME: 'Rafael Maldonado',
  ADMIN_PHONE_NUMBER: '-',
  CLIENT_HTML_MESSAGE: 'Your email has been received.  </br><blockquote><div>',
  ADMIN_HTML_MESSAGE: 'New message created. </br><blockquote><div>',
  DEALER_EMAIL: 'support@leivamotors.com',
  CLIENT_TEXT_APPLICANT: 'Your applicant has been received. ',
  ADMIN_TEXT_APPLICANT: 'There is a new Finance Request registered.',
  CLIENT_HTML_APPLICANT: '',
  ADMIN_HTML_APPLICANT: 'There is a new Finance Request registered. </br><blockquote><div>',
  SUBSCRIPTOR_NAME: 'SUBSCRIPTOR',
  SUBSCRIPTION_TEXT_MESSAGE: 'Benefits subscription',
}

export const optionSocialNetwork = [
  {code: 'facebook', value: 'Facebook'},
  {code: 'instagram', value: 'Instagram'}
]

export const FIRST_DAY_OF_WEEK = 0

export const optionBody = [
  {id: 'Sedan', name: 'Sedan', value: 'Sedan', selected: false},
  {id: 'SUV', name: 'SUV', value: 'SUV', selected: false},
  {id: 'Coupe', name: 'Coupe', value: 'Coupe', selected: false},
  {id: 'Hatchback', name: 'Hatchback', value: 'Hatchback', selected: false},
  {id: 'Wagon', name: 'Wagon', value: 'Wagon', selected: false},
  {id: 'Truck', name: 'Truck', value: 'Truck', selected: false},
  {id: 'Minivan', name: 'Minivan', value: 'Minivan', selected: false},
  {id: 'Van', name: 'Van', value: 'Van', selected: false},
  {id: 'Limousine', name: 'Limousine', value: 'Limousine', selected: false},
  {id: 'Roadster', name: 'Roadster', value: 'Roadster', selected: false},
  {id: 'Compact', name: 'Compact', value: 'Compact', selected: false},
  {id: 'Convertible', name: 'Convertible', value: 'Convertible', selected: false},
  {id: 'Crossover', name: 'Crossover', value: 'Crossover', selected: false}
]

export const optionCondition = [
  {id: 'Excellent', name: 'Excellent', value: 'Excellent', selected: false},
  {id: 'Very Good', name: 'Very Good', value: 'Very Good', selected: false},
  {id: 'Good', name: 'Good', value: 'Good', selected: false},
  {id: 'Fair', name: 'Fair', value: 'Fair', selected: false},
  {id: 'Poor', name: 'Poor', value: 'Poor', selected: false}
]

export const optionCylinder = [
  {id: '3', name: '3', value: '3', selected: false},
  {id: '4', name: '4', value: '4', selected: false},
  {id: '5', name: '5', value: '5', selected: false},
  {id: '6', name: '6', value: '6', selected: false},
  {id: '8', name: '8', value: '8', selected: false},
  {id: '10', name: '10', value: '10', selected: false},
  {id: '12', name: '12', value: '12', selected: false}
]

export const optionColor = [
  {id: 'Black', name: 'Black', value: 'Black', selected: false},
  {id: 'Blue', name: 'Blue', value: 'Blue', selected: false},
  {id: 'Brown', name: 'Brown', value: 'Brown', selected: false},
  {id: 'Gold', name: 'Gold', value: 'Gold', selected: false},
  {id: 'Green', name: 'Green', value: 'Green', selected: false},
  {id: 'Grey', name: 'Grey', value: 'Gray', selected: false},
  {id: 'Orange', name: 'Orange', value: 'Orange', selected: false},
  {id: 'Pink', name: 'Pink', value: 'Pink', selected: false},
  {id: 'Purple', name: 'Purple', value: 'Purple', selected: false},
  {id: 'Red', name: 'Red', value: 'Red', selected: false},
  {id: 'Silver', name: 'Silver', value: 'Silver', selected: false},
  {id: 'White', name: 'White', value: 'White', selected: false},
  {id: 'Yellow', name: 'Yellow', value: 'Yellow', selected: false},
  {id: 'Tan', name: 'Tan', value: 'Tan', selected: false},
  {id: 'Maroon', name: 'Maroon', value: 'Maroon', selected: false},
]

export const optionTitle = [
  {id: 'Clean', name: 'Clean', value: 'Clean', selected: false},
  {id: 'Dismantled', name: 'Dismantled', value: 'Dismantled', selected: false},
  {id: 'Rebuilt/Reconstructed', name: 'Rebuilt/Reconstructed', value: 'Rebuilt/Reconstructed', selected: false},
  {id: 'Salvage', name: 'Salvage', value: 'Salvage', selected: false}
]

export const GRID_VIEW = 'gridView'
export const LIST_VIEW = 'listView'

export const BACKGROUND_MAIN_DEFAULT_HEIGHT_TEMPLATE_MAIN = 900
export const DEFAULT_THEME_ID = 'light'

export const ATTRIBUTE_APPLICANT_TYPE = 'customer.applicantType'
export const ATTRIBUTE_APPLICANT_RELATION = 'customer.relation'
export const ATTRIBUTE_RESIDENCE_YEAR = 'customer.year'
export const ATTRIBUTE_RESIDENCE_MONTH = 'customer.month'
export const ATTRIBUTE_EMPLOYER_YEAR = 'customer.employerYear'
export const ATTRIBUTE_EMPLOYER_MONTH = 'customer.employerMonth'
export const ATTRIBUTE_RESIDENCE_STATE = 'customer.state'
export const ATTRIBUTE_HOUSING_STATUS = 'customer.housingStatus'
export const ATTRIBUTE_EMPLOYMENT_STATUS = 'customer.employmentStatus'
export const ATTRIBUTE_EMPLOYER_STATE = 'customer.employerState'
export const ATTRIBUTE_SSN = 'customer.ssn'

export const ATTRIBUTE_APPLICANT_TYPE_CODE = 'joinCredit'

export const FORM_APPLICANT_CODE_PUBLIC = 'formPublicFinance'

export const optionState = [
  {id: 'AL', name: 'AL', displayValue: 'Alabama'},
  {id: 'AK', name: 'AK', displayValue: 'Alaska'},
  {id: 'AZ', name: 'AZ', displayValue: 'Arizona'},
  {id: 'AR', name: 'AR', displayValue: 'Arkansas'},
  {id: 'CA', name: 'CA', displayValue: 'California'},
  {id: 'CO', name: 'CO', displayValue: 'Colorado'},
  {id: 'CT', name: 'CT', displayValue: 'Connecticut'},
  {id: 'DE', name: 'DE', displayValue: 'Delaware'},
  {id: 'DC', name: 'DC', displayValue: 'District Of Columbia'},
  {id: 'FL', name: 'FL', displayValue: 'Florida'},
  {id: 'GA', name: 'GA', displayValue: 'Georgia'},
  {id: 'HI', name: 'HI', displayValue: 'Hawaii'},
  {id: 'ID', name: 'ID', displayValue: 'Idaho'},
  {id: 'IL', name: 'IL', displayValue: 'Illinois'},
  {id: 'IN', name: 'IN', displayValue: 'Indiana'},
  {id: 'IA', name: 'IA', displayValue: 'Iowa'},
  {id: 'KS', name: 'KS', displayValue: 'Kansas'},
  {id: 'KY', name: 'KY', displayValue: 'Kentucky'},
  {id: 'LA', name: 'LA', displayValue: 'Louisiana'},
  {id: 'ME', name: 'ME', displayValue: 'Maine'},
  {id: 'MD', name: 'MD', displayValue: 'Maryland'},
  {id: 'MA', name: 'MA', displayValue: 'Massachusetts'},
  {id: 'MI', name: 'MI', displayValue: 'Michigan'},
  {id: 'MN', name: 'MN', displayValue: 'Minnesota'},
  {id: 'MS', name: 'MS', displayValue: 'Mississippi'},
  {id: 'MO', name: 'MO', displayValue: 'Missouri'},
  {id: 'MT', name: 'MT', displayValue: 'Montana'},
  {id: 'NE', name: 'NE', displayValue: 'Nebraska'},
  {id: 'NV', name: 'NV', displayValue: 'Nevada'},
  {id: 'NH', name: 'NH', displayValue: 'New Hampshire'},
  {id: 'NJ', name: 'NJ', displayValue: 'New Jersey'},
  {id: 'NM', name: 'NM', displayValue: 'New Mexico'},
  {id: 'NY', name: 'NY', displayValue: 'New York'},
  {id: 'NC', name: 'NC', displayValue: 'North Carolina'},
  {id: 'ND', name: 'ND', displayValue: 'North Dakota'},
  {id: 'OH', name: 'OH', displayValue: 'Ohio'},
  {id: 'OK', name: 'OK', displayValue: 'Oklahoma'},
  {id: 'OR', name: 'OR', displayValue: 'Oregon'},
  {id: 'PA', name: 'PA', displayValue: 'Pennsylvania'},
  {id: 'RI', name: 'RI', displayValue: 'Rhode Island'},
  {id: 'SC', name: 'SC', displayValue: 'South Carolina'},
  {id: 'SD', name: 'SD', displayValue: 'South Dakota'},
  {id: 'TN', name: 'TN', displayValue: 'Tennessee'},
  {id: 'TX', name: 'TX', displayValue: 'Texas'},
  {id: 'UT', name: 'UT', displayValue: 'Utah'},
  {id: 'VT', name: 'VT', displayValue: 'Vermont'},
  {id: 'VA', name: 'VA', displayValue: 'Virginia'},
  {id: 'WA', name: 'WA', displayValue: 'Washington'},
  {id: 'WV', name: 'WV', displayValue: 'West Virginia'},
  {id: 'WI', name: 'WI', displayValue: 'Wisconsin'},
  {id: 'WY', name: 'WY', displayValue: 'Wyoming'}
]

export const ATTRIBUTE_RESIDENCE_YEAR_PREVIOUS = 'customer.yearPrevious'
export const ATTRIBUTE_RESIDENCE_MONTH_PREVIOUS = 'customer.monthPrevious'
export const ATTRIBUTE_EMPLOYER_YEAR_PREVIOUS = 'customer.employerYearPrevious'
export const ATTRIBUTE_EMPLOYER_MONTH_PREVIOUS = 'customer.employerMonthPrevious'
export const ATTRIBUTE_EMPLOYER_YEAR_SECOND = 'customer.employerYearSecond'
export const ATTRIBUTE_EMPLOYER_MONTH_SECOND = 'customer.employerMonthSecond'
export const ATTRIBUTE_EMPLOYER_YEAR_THIRD = 'customer.employerYearThird'
export const ATTRIBUTE_EMPLOYER_MONTH_THIRD = 'customer.employerMonthThird'

export const INSERT_VIN_HERE = 'INSERT_VIN_HERE'
export const VALIDATION_REQUIRED = 'required'
export const VALIDATION_NOT_REQUIRED = 'notRequired'
export const VALIDATION_VIN = 'vin'
export const VALIDATION_EMAIL = 'email'
export const VALIDATION_PHONE = 'phone'
export const VALIDATION_PHONE_NUMBER = 'phoneNumber'


export const EXT_PDF = 'pdf'
export const EXT_WORD = 'docx'
export const EXT_IMG = 'jpg'
export const EXT_OTHER_IMG = 'jpeg'
export const EXT_PNG = 'png'
export const EXT_CSV = 'csv'
export const EXT_XLSX = 'xlsx'

export const CAROUSEL_INTERVAL = 300000

export const TEMPLATE_CODE_EASY_NAVIGATION = 'easyNavigation'
export const TEMPLATE_CODE_EASY_NAVIGATION_B = 'easyNavigationB'
export const TEMPLATE_CODE_EASY_NAVIGATION_C = 'easyNavigationC'
export const TEMPLATE_CODE_MODERN_VIEW = 'modernView'
export const TEMPLATE_CODE_MODERN_VIEW_2 = 'modernView2'
export const TEMPLATE_CODE_MODERN_VIEW_3 = 'modernView3'

export const VIEW_MOBILE = 'mobile'
export const VIEW_TABLET = 'tablet'
export const VIEW_WEB = 'web'

export const RESPONSE_CODE_SUCCESS = '00'

export const EmptyFunc = () => {
}

export const carStatus2 = {
  SOLD: 'Sold',
  ARCHIVED: 'Archived',
  COMING_SOON: 'Coming Soon',
  FRONT_LINE_READY: 'Front Line Ready'
}

export const CARS_BY_PAGE = [8, 12, 16, 24, 32, 40, 48, 56, 62]

export const InputTypes = {
  TEXT: 'TEXT',
  LABEL: 'LABEL',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  PASSWORD: 'PASSWORD',
  TEXT_HIDDEN: 'HIDDEN',
  TEXT_AREA: 'TEXTAREA',
  TEXT_AUTOCOMPLETE: 'textAutocomplete',
  DYNAMIC_NUMBER: 'DYNAMIC_NUMBER',
  NUMBER: 'NUMBER',
  DATE_PICKER: 'DATE_PICKER',
  PHONE_MASK: 'PHONE_MASK',
  IMAGE: 'IMAGE',
  RADIO_BUTTON: 'RADIO_BUTTON',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  STATE: 'STATE',
  PARAGRAPH: 'PARAGRAPH',
  DEFAULT_IMAGE_URL: 'https://dummyimage.com/240X180/e4e6ed/7b7b80.jpg&text=No+Image+Found',
  PERCENT: 'PERCENT',
  PRICE: 'PRICE'
}

export const cover = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
}

export const optionTrans = {
  'Automatic': 'Auto',
  'Manual': 'Manual',
  'AutomaticManual': 'A/M',
  'Semi-Automatic': 'Semi-A'
}

export const CARS_BY_PAGE_DEFAULT = 32 //it was 12 by default

export const MENU_ITEM_SETTING_ID = 'setting'
export const MENU_ITEM_CUSTOMER_ID = 'customer'
export const MENU_ITEM_DASHBOARD_ID = 'dashboard'
export const MENU_ITEM_REVIEW_ID = 'reviews'

export const TAB_LOCATION_ID = 'location'
export const TAB_DASHBOARD_ID = 'dashboard'
export const TAB_REVIEW_ID = 'review'
export const TAB_REFERRAL_ID = 'referrals'
export const TAB_REWARDS_ID = 'rewards'

export const ORDER_NUMBER_DEFAULT = 1000

export const TYPE_FEATURE = 'feature'
export const TYPE_STATUS = 'status'

export const cellphoneMask = "(999) 999-9999";


export const TAB_MENU_FINANCE = 'finance'
export const TAB_MENU_VEHICLES = 'inventory'
export const TAB_MENU_ABOUT_US = 'aboutUs'
export const TAB_MENU_LOCATION = 'location'
export const TAB_MENU_CONTACT = 'contact'

export const SUCCESSFUL_CODE = 200

export const colors = {
  errorLabel: '#AB3438',
}