/**
 * Created by Jhonny Apolinario on 22/08/2016.
 */
import React, {Component} from 'react'
import Autosuggest from 'react-autosuggest'
import {defaultString} from '../Util'

export default class InputAutoComplete extends Component {
    constructor(props) {
        super();
        this.onChange = this.onChange.bind(this);
        this.onSuggestionsUpdateRequested = this.onSuggestionsUpdateRequested.bind(this);
        this.getValue  = this.getValue.bind(this);

        this.state = {
            value: defaultString(props.value),
            suggestions: this.getSuggestions('')
        };

    }

    onChange(event, { newValue }) {
        this.setState({value: newValue},()=>{
            this.props.onChange();
        });
    };

    getValue(){
      return this.state.value;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({value:nextProps.value});
    }

    onSuggestionsUpdateRequested({ value }){
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.props.options.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    }

    getSuggestionValue(suggestion) { // when suggestion is selected, this function tells
        return suggestion.name;                 // what should be the value of the input
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.name}</span>
        );
    }

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            value,
            onChange: this.onChange,
            name : this.props.name,
            placeholder : this.props.placeholder
        };


        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsUpdateRequested={this.onSuggestionsUpdateRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                name = {this.props.name}
                inputProps={inputProps} />
        );
    }
}