import React from 'react'
import {isEmpty} from 'lodash'
import InputElement from 'react-input-mask'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './DPTextInput.scss'

const DPTextInput = ({
                       containerClassName,
                       inputContainerClassName,
                       inputClassName,
                       labelClassName,
                       containerStyle,
                       inputContainerStyle,
                       inputStyle,
                       labelStyle,
                       leftElement,
                       rightElement,
                       mask,
                       testInputId,
                       isRequired,
                       id,
                       disabled,
                       inputName,
                       maxLength,
                       onBlur,
                       onKeyPressEnter,
                       onChangeText,
                       value,
                       labelText,
                       placeholder,
                       errorMessage,
                       type,
                       inputId,
                       onChangeNumber,
                        simpleInputClassname,
                       regex,
                       ...rest
                     }) => {

  const dpTextInputContainerClass = classnames(
    containerClassName,
    'dp-text-input-container'
  )

  const labelClass = classnames(
    labelClassName,
    'dp-text-input-label'
  )

  const textInputContainerClass = classnames(
    inputContainerClassName,
    'input-container',
    {
      'dp-text-input-error': !isEmpty(errorMessage),
      'disabled': disabled,
    }
  )

  const inputClass =
    classnames(
      inputClassName,
      !simpleInputClassname && 'dp-text-input',
      {
        'disabled': disabled,
      }
    )

  const onChange = (event) => {
    event.preventDefault()
    const currentValue = event.target.value
    if (regex && (currentValue.match(regex) || currentValue === '') || onChangeNumber && mask) {
      onChangeNumber(event, currentValue)
    } else if (!regex) {
      onChangeText(event, currentValue, inputName)
    }

  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onKeyPressEnter()
    }
  }

  return (
    <div
      className={dpTextInputContainerClass}
      style={{...containerStyle}}
      id={id}
    >
      {
        !isEmpty(labelText) &&
        <label
          className={labelClass}
          style={{...labelStyle}}
        >
          {labelText}
          {
            isRequired &&
            <span className='label-error'>*</span>
          }
        </label>
      }
      <div
        className={textInputContainerClass}
        style={{...inputContainerStyle}}
      >
        {
          leftElement
        }
        {
          mask
            ? <InputElement
              className={inputClass}
              style={{...inputStyle}}
              type={type}
              mask={mask}
              formatChars={
                {
                  '9': '[0-9]',
                  'm': '[0-1]',
                  'd': '[0-3]'
                }
              }
              onChange={onChange}
              value={value}
              disabled={disabled}
              data-testid={`dp-text-input-mask-${testInputId}`}
              name={inputName}
              placeholder={placeholder}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              id={inputId}
              {...rest}
            /> :
            <input
              className={inputClass}
              style={{...inputStyle}}
              disabled={disabled}
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              data-testid={`dp-text-input-${testInputId}`}
              name={inputName}
              maxLength={maxLength}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              id={inputId}
              {...rest}
            />
        }
        {
          rightElement
        }
      </div>
      {
        !isEmpty(errorMessage) && <p className='dp-text-input-error-message'>{errorMessage}</p>
      }
    </div>
  )
}


DPTextInput.defaultProps = {
  /* Component container classname */
  containerClassName: '',
  /* Component container styles */
  containerStyle: {},
  /* Component container id */
  id: '',
  /* <input> container classname */
  inputContainerClassName: '',
  /* <input> container styles */
  inputContainerStyle: {},
  /* <input> classname */
  inputClassName: '',
  /* <label> classname  */
  labelClassName: '',
  /* <input> styles */
  inputStyle: {},
  /* <label> styles */
  labelStyle: {},
  /* mask to use in <InputElement>  */
  mask: '',
  /* testId for inputs */
  testInputId: '',
  /* Is a required field */
  isRequired: false,
  /* Disabled input */
  disabled: false,
  onChangeText: () => {
  },
  onKeyPressEnter: () => {
  },
  onBlur: () => {
  },
  /* Input value */
  value: '',
  labelText: '',
  placeholder: '',
  errorMessage: '',
  /* Will be rendered to the left of the input  */
  leftElement: null,
  /* Will be rendered to the right of the input */
  rightElement: null,
  /* Max input length */
  maxLength: null,
  /* input type */
  type: 'text',
  inputId: '',
  onChangeNumber: null,
  simpleInputClassname: false,
  regex: null
}

DPTextInput.propTypes = {
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  inputContainerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  mask: PropTypes.string,
  testInputId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChangeText: PropTypes.func,
  onKeyPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  leftElement: PropTypes.element,
  rightElement: PropTypes.element,
  maxLength: PropTypes.string,
  type: PropTypes.string,
  inputId: PropTypes.string,
  onChangeNumber: PropTypes.func,
  simpleInputClassname: PropTypes.bool,
  regex: PropTypes.string
}

export default DPTextInput
