/**
 * Created by javierl on 3/23/16
 */

import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import {
  SEARCH_INVENTORY_PUBLIC_SUCCESS,
  LOAD_MAKES_SUCCESS,
  SELECTED_LOCATION_ID,
  PUBLIC_LOAD_LOCATIONS_SUCCESS,
  SET_PAGE_NUMBER_INVENTORY,
  SET_ITEMS_BY_PAGE_QUANTITY,
  TOGGLE_CAR_ADVANCED_SEARCH_MENU,
  SET_INVENTORY_VIEW,
  LOAD_TESTIMONIALS_SUCCESS,
  SAVE_MESSAGE_DATA_SUCCESS,
  SAVE_MESSAGE_DATA_FAILURE,
  CLEAR_SAVED_MESSAGE_DATA,
  GET_CARS_BY_VIN_SUCCESS,
  GET_CARS_BY_VIN_FAILURE,
  CLEAR_CARS_BY_VIN_DATA,
  LOAD_FORM_BY_CODE_SUCCESS,
  PROCESS_FINANCE_FORM_SUCCESS,
  PROCESS_FINANCE_FORM_FAILURE,
  CLEAR_FINANCE_FORM,
  SEARCH_INVENTORY_PUBLIC_FILTERS_SUCCESS,
  PUBLIC_LOAD_LOCATIONS_FAILURE,
} from '../actions'

import isArray from 'lodash/isArray'
import {GRID_VIEW} from "../../common/Constants";
import {convertMakesToMap, convertModelsToMap} from '../../common/Util'

const ITEMS_BY_PAGE_QUANTITY_DEFAULT = 16

let email = (state = null) => {
  return state
}

let dealerId = (state = null) => {
  return state
}

let firstName = (state = null) => {
  return state
}

let urlImage = (state = null) => {
  return state
}

let templateContent = (state = null) => {
  return state
}

let subdomain = (state = null) => {
  return state
}

const lastName = (state = null) => state

const carsInformation = (state = null, action) => {
  let {payload, type} = action
  switch (type) {
    case SEARCH_INVENTORY_PUBLIC_SUCCESS:
      if (payload)
        return payload
      break
    default:
      return state
  }
}

const carsInformationFilters = (state = null, action) => {
  let {payload, type} = action
  switch (type) {
    case SEARCH_INVENTORY_PUBLIC_FILTERS_SUCCESS:
      if (payload) {
        return payload
      }
      break
    default:
      return state
  }
}


const makes = (state = null, action) => {
  let {payload, type} = action
  switch (type) {
    case LOAD_MAKES_SUCCESS:
      if (payload)
        return payload.make
      break
    default:
      return state
  }
}

const models = (state = null, action) => {
  let {payload, type} = action
  switch (type) {
    case LOAD_MAKES_SUCCESS:
      if (payload)
        return payload.model
      break
    default:
      return state
  }
}

const locationId = (state = null, action) => {
  let {payload, type} = action
  switch (type) {
    case SELECTED_LOCATION_ID:
      if (payload)
        return payload
      break
    case PUBLIC_LOAD_LOCATIONS_SUCCESS:
      if (isArray(payload) && payload.length === 1)
        return payload[0].locationId
      else
        return state
    default:
      return state
  }
}

const locationsPublic = (state = null, action) => {
  let {payload, type, error} = action
  switch (type) {
    case PUBLIC_LOAD_LOCATIONS_SUCCESS:
      if (payload)
        return payload
      break
    case PUBLIC_LOAD_LOCATIONS_FAILURE:
      return {...error, error: true}
    default:
      return state
  }
}

let templateId = (state = null, action) => {
  let {payload, type} = action
  switch (type) {
    // case SET_TEMPLATE_ID:
    //   return payload;
    default:
      return state
  }
}

const pageNumberInventory = (state = 1, action) => {
  let {payload, type} = action
  switch (type) {
    case SET_PAGE_NUMBER_INVENTORY:
      return payload
    // case SET_CAR_FILTERS:
    //   return 1;
    // case SET_CAR_FILTERS_AND_CURRENT_PAGE:
    //  return payload.currentPage;
    default:
      return state
  }
}

const itemsByPageQuantity = (state = ITEMS_BY_PAGE_QUANTITY_DEFAULT, action) => {
  let {payload, type} = action
  switch (type) {
    case SET_ITEMS_BY_PAGE_QUANTITY:
      return payload
    default:
      return state
  }
}

let dealerName = (state = null) => {
  return state
}

let templateName = (state = null) => {
  return state
}

const isCarAdvancedSearchMenuOpen = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CAR_ADVANCED_SEARCH_MENU:
      return !state
    default:
      return state
  }
}

const templateCode = (state = null) => {
  return state
}

const carfaxValue = (state = null) => {
  return state
}


const testimonialsData = (state = null, action) => {
  const {payload, type} = action
  switch (type) {
    case LOAD_TESTIMONIALS_SUCCESS:
      return payload
    default:
      return state
  }
}

const messageConfirmation = (state = null, action) => {
  const {payload, type, error} = action
  switch (type) {
    case SAVE_MESSAGE_DATA_SUCCESS:
      return payload
    case SAVE_MESSAGE_DATA_FAILURE:
      return {...error, error: true}
    case CLEAR_SAVED_MESSAGE_DATA:
      return null
    default:
      return state
  }
}

const carByVinData = (state = null, action) => {
  const {payload, type, error} = action
  switch (type) {
    case GET_CARS_BY_VIN_SUCCESS:
      return payload
    case GET_CARS_BY_VIN_FAILURE:
      return {...error, error: true}
    case CLEAR_CARS_BY_VIN_DATA:
      return null
    default:
      return state
  }
}

const loadFormByCodeData = (state = null, action) => {
  const {payload, type} = action
  switch (type) {
    case LOAD_FORM_BY_CODE_SUCCESS:
      return payload
    default:
      return state
  }
}

const processFinanceFormConfirmation = (state = null, action) => {
  const {payload, type, error} = action
  switch (type) {
    case PROCESS_FINANCE_FORM_SUCCESS:
      return payload
    case PROCESS_FINANCE_FORM_FAILURE:
      return {...error, error: true}
    case CLEAR_FINANCE_FORM:
      return null
    default:
      return state
  }
}

const makesById = (state = null, action) => {
  const {payload, type} = action
  switch (type) {
    case LOAD_MAKES_SUCCESS:
      if (payload)
        return convertMakesToMap(payload.make)
      break
    default:
      return state
  }
}

const modelsById = (state = null, action) => {
  const {payload, type} = action
  switch (type) {
    case LOAD_MAKES_SUCCESS:
      if (payload)
        return convertModelsToMap(payload.model)
      break
    default:
      return state
  }
}

const currentInventoryView = (state = GRID_VIEW, action) => {
  const {payload, type} = action;
  switch (type) {
    case SET_INVENTORY_VIEW:
      return payload;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  email,
  dealerId,
  firstName,
  lastName,
  urlImage,
  carsInformation,
  carsInformationFilters,
  makes,
  models,
  locationId,
  locationsPublic,
  routing: routerReducer,
  templateId,
  pageNumberInventory,
  itemsByPageQuantity,
  dealerName,
  templateContent,
  templateName,
  subdomain,
  isCarAdvancedSearchMenuOpen,
  templateCode,
  carfaxValue,
  currentInventoryView,
  testimonials: testimonialsData,
  messageConfirmation,
  carByVinData,
  loadFormByCodeData,
  processFinanceFormConfirmation,
  makesById,
  modelsById
})

export default rootReducer
