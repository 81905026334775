import React, {useState} from 'react'
import {ReactSVG} from 'react-svg'
import CarDescriptionAndTable from '../CarDescriptionAndTable'
import CarPriceCalculator from '../CarPriceCalculator'
import {
  DARK_THEME,
  pathServer,
  PRIMARY_COLOR,
  TEMPLATE_5_NAME,
  TEXT_COLOR
} from '../../../Constants'
import LayoutGetInTouch from '../../LayoutGetInTouch'
import {get} from "lodash";

const CarInformationTemplateCode5 = ({
                                       goBack,
                                       car,
                                       tabSelected,
                                       printCarInformation,
                                       showGetInTouchOnCarousel,
                                       sectionContactUs,
                                       handleTabChange,
                                       siteColor,
                                       dealerId,
                                       carStatus,
                                       currentTheme,
                                       templateCode,
                                       pages
                                     }) => {
  const transmission = car['car.transmission'] ? car['car.transmission'].attributeValue : ''
  const miles = Math.round(car.mileage / 1000)
  const title = car['car.title'] ? car['car.title'].attributeValue : ''
  const price = car.suggestedPrice
  const trim = get(car, ['car.trim', 'attributeValue'], '')

  const [rate, setRate] = useState(price)
  const onChangePrice = (event, value) => {
    event.preventDefault()
    const newValue = value
    setRate(newValue)
  }

  return (
    <div className='inv-car-info'>
      <div className='car-details-header'>
        <div className='car-details-row'>
          <div className='info-car-details'>
            <div className='left'>
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/ic_arrow_back.svg'}
                onClick={goBack}
                beforeInjection={svg => {
                  svg.classList.add('image-style')
                  svg.setAttribute('style', `fill: ${siteColor[TEXT_COLOR]}`)
                }}
              />
            </div>
            <h3
              className='label-make-model'
              style={{color: siteColor[TEXT_COLOR]}}
            >
              {`${car.year} ${car.makeName} ${car.modelName} ${trim}`}
            </h3>
            {carStatus && <div className='sold-tag'>SOLD</div>}
          </div>
          <label
            className={carStatus ? 'price-banner' : 'label-suggested-price'}
          >
            {price !== 0 ? '$' + price : ''}
          </label>
        </div>
        <div className='car-details-row-1'>
          {car.year ?
            <label
              className={`label-value-info ${currentTheme === DARK_THEME ? 'opaque-label' : ''}`}
              style={{
                color: siteColor[TEXT_COLOR],
                border: `1px solid ${siteColor[TEXT_COLOR]}`,
              }}
            >
              {car.year}
            </label> :
            null
          }
          {transmission
            ? <label
              className={`label-value-info ${currentTheme === DARK_THEME ? 'opaque-label' : ''}`}
              style={{
                color: siteColor[TEXT_COLOR],
                border: `1px solid ${siteColor[TEXT_COLOR]}`,
              }}
            >
              {transmission.toUpperCase()}
            </label>
            : null
          }
          {title
            ? <label
              className={`label-value-info ${currentTheme === DARK_THEME ? 'opaque-label' : ''}`}
              style={{
                color: siteColor[TEXT_COLOR],
                border: `1px solid ${siteColor[TEXT_COLOR]}`,
              }}
            >
              {title.toUpperCase()}
            </label>
            : null
          }
          {miles
            ? <label
              className={`label-value-info ${currentTheme === DARK_THEME ? 'opaque-label' : ''}`}
              style={{
                color: siteColor[TEXT_COLOR],
                border: `1px solid ${siteColor[TEXT_COLOR]}`,
              }}
            >
              {miles + 'K'}
            </label>
            : null
          }
        </div>
      </div>
      <div className='car-details-body'>
        <div>
          <button
            id='description'
            onClick={handleTabChange}
            className={tabSelected === 'description' ? 'btn-car-tab btn-tab-active' : 'btn-car-tab'}
            style={tabSelected === 'description' ? {color: siteColor[TEXT_COLOR]} : null}
          >
            Information
          </button>
          <button
            id='calculator'
            onClick={handleTabChange}
            className={tabSelected === 'calculator' ? 'btn-car-tab btn-tab-active' : 'btn-car-tab'}
            style={tabSelected === 'calculator' ? {color: siteColor[TEXT_COLOR]} : null}
          >
            Calculator
          </button>
          <div className='btn-print-car'>
            <ReactSVG
              src={pathServer.PATH_IMG + 'icon/ic_print.svg'}
              beforeInjection={svg => {
                svg.classList.add('icon-arrow')
                svg.setAttribute('style', `fill: ${siteColor[PRIMARY_COLOR]}`)
              }} className='print-option' onClick={printCarInformation}
            />
          </div>
        </div>
        {
          (tabSelected === 'description') ?
            <CarDescriptionAndTable
              templateCode={TEMPLATE_5_NAME}
              siteColor={siteColor}
              car={car}
            /> : null
        }
        {
          (tabSelected === 'calculator') ?
            <CarPriceCalculator
              templateCode={TEMPLATE_5_NAME}
              siteColor={siteColor}
              price={rate}
              car={car}
              onChangePrice={onChangePrice}
              pages={pages}
            />
            : null
        }
      </div>
      {
        !showGetInTouchOnCarousel ?
          <LayoutGetInTouch
            title={sectionContactUs?.title}
            siteColor={siteColor}
            dealerId={dealerId}
            templateCode={templateCode}
            section={sectionContactUs}
          />
          : null
      }
    </div>
  )
}

export default CarInformationTemplateCode5