import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import {
  BOX_BORDER_COLOR,
  BUTTON_TEXT_COLOR,
  CONTROL_BACKGROUND_COLOR,
  CONTROL_BORDER_COLOR,
  pathServer,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SUCCESSFUL_CODE,
  TEXT_COLOR,
  TIME_FIVE_SECONDS
} from '../../../Constants'
import { StyledDiv } from '../../../StyledComponentUtil'
import { buildMessages, emailData, messageEmailWithSelectedCar } from '../../../Util'
import { saveMessage } from '../../../../catalog/Api'
import { checkValidEmail, checkValidPhone } from '../../../ValidationUtil'
import DPButton from '../../../genericComponents/DPButton'
import DPTextInput from '../../../genericComponents/DPTextInput'

const GetInTouchTemplateCodeMain = ({
                                      siteColor,
                                      car,
                                      dealerId,
                                      errors,
                                      setErrors,
                                      section
                                    }) => {

  const [showViewGetItTouch, setShowViewGetItTouch] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [text, setText] = useState('')
  const [message, setMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [sampleErrors, setSampleErrors] = useState({})

  const showGetItTouch = () => setShowViewGetItTouch(!showViewGetItTouch)

  const hideContactSection = !!section.hideSection

  const _inputChange = (type, event) => {
    event.preventDefault()
    setSampleErrors({})
    setErrors([])
    const value = event.target.value
    if (type === 'name') {
      setName(value)
    } else if (type === 'email') {
      setEmail(value)
    } else if (type === 'text') {
      setText(value)
    } else if (type === 'phone'){
      setPhone(value)
    }
  }

  const _handleClick = (e) => {
    e.preventDefault()
    const message = messageEmailWithSelectedCar(text, car)
    const data = emailData(dealerId, email, message, name, phone)

    setMessage('')
    setErrors([])
    setSampleErrors({})
    if (_validate()) {
      saveMessage(data).then(({responseCode}) => {
        if (responseCode !== SUCCESSFUL_CODE) {
          setTimeout(function () {
            setErrors(['Error: Your message could not be sent.'])
          }, TIME_FIVE_SECONDS)
          return
        }

        setName('')
        setEmail('')
        setText('')
        setPhone('')
        setErrors([])
        setMessage('Your message has been send successfully, NOTE: See your email for a copy of your message.')
        setTimeout(function () {
          setMessage('')
        }, TIME_FIVE_SECONDS)
      })
    }
  }

  const _validate = () => {
    let sampleErrorsJson = {}
    let errors = []
    if (!name || !(name.trim())) {
      errors.push('Incorrect name')
      sampleErrorsJson = ({ ...sampleErrorsJson, name: 'Please enter required information' })
    }

    /*if (!text || !(text.trim()))
      errors.push('Incorrect text')*/

    if (!email || !(email.trim()) || !checkValidEmail(email)) {
      errors.push('Incorrect email')
      sampleErrorsJson = ({ ...sampleErrorsJson, email: 'Please enter required information' })
    }

    if(!phone || !(phone.trim()) || !checkValidPhone(phone)) {
      errors.push('Incorrect phone number')
      sampleErrorsJson = ({ ...sampleErrorsJson, phoneNumber: 'Please enter required information' })
    }

    if (errors.length > 0) {
      setErrors(errors)
      setSampleErrors(sampleErrorsJson)
      return false
    }
    return true
  }

  const onBlurPhoneField = () => {

    const matches = phone.match(/\d+/g)

    if (matches) {
      const newPhoneNumber = matches.join('')
      const formattedNewPhoneNumber = `(${newPhoneNumber.substring(0, 3)})${newPhoneNumber.substring(3, 6)}-${newPhoneNumber.substring(6, 10)}`

      setPhone(formattedNewPhoneNumber)

    }
  }

  const primaryColor = siteColor[PRIMARY_COLOR]
  const nameClassName  = sampleErrors.hasOwnProperty('name') ? 'sample-error-input' : ''
  const phoneNumberClassName  = sampleErrors.hasOwnProperty('phoneNumber') ? 'sample-error-input' : ''
  const emailClassName  = sampleErrors.hasOwnProperty('email') ? 'sample-error-input' : ''

  return (
    !hideContactSection ?
    <>
      {buildMessages(errors, message)}
      <div className="row-info-send-message">
        <div className="get-in-touch-header">
          <div className="container-plus" onClick={showGetItTouch}>
            {
              !showViewGetItTouch ?
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/plus_circle_solid.svg'}
                  className="container-icon"
                  beforeInjection={svg => {
                    svg.classList.add('icon-search')
                    svg.setAttribute('style', `fill: ${primaryColor}`)
                  }}
                /> :
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/minus_circle_solid.svg'}
                  className="container-icon"
                  beforeInjection={svg => {
                    svg.classList.add('icon-search')
                    svg.setAttribute('style', `fill: ${primaryColor}`)
                  }}
                />
            }
          </div>
          <label
            className="get-it-touch"
            style={{ color: siteColor[TEXT_COLOR] }}
          >
            Get in touch with us
          </label>
        </div>
        <div className="first-row-send-message">
          <div className="container-name">
            <label>Name *</label>
            <DPTextInput
              inputClassName={nameClassName}
              errorMessage={sampleErrors.name}
              type="text"
              id="name"
              name="clientName"
              value={name}
              onChange={(event) => _inputChange('name', event)}
              placeholder="Type here"
              style={{
                backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                borderColor: siteColor[CONTROL_BORDER_COLOR],
                color: siteColor[TEXT_COLOR]
              }}
            />
          </div>
          <div className="container-email">
            <label>Email Address *</label>
            <DPTextInput
              inputClassName={emailClassName}
              errorMessage={sampleErrors.email}
              type="text"
              id="email"
              value={email}
              onChange={(event) => _inputChange('email', event)}
              name="email"
              placeholder="Type here"
              style={{
                backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                borderColor: siteColor[CONTROL_BORDER_COLOR],
                color: siteColor[TEXT_COLOR]
              }}
            />
          </div>
        </div>
        <div className="second-row-send-message">
          <div className="container-phone">
            <label>Phone number *</label>
            <DPTextInput
              inputClassName={phoneNumberClassName}
              errorMessage={sampleErrors.phoneNumber}
              mask='(999)999-9999'
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(event) => _inputChange('phone', event)}
              placeholder="Type here"
              onBlur={onBlurPhoneField}
              style={{
                backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                borderColor: siteColor[CONTROL_BORDER_COLOR],
                color: siteColor[TEXT_COLOR]
              }}
            />
          </div>
          <div className="container-message">
            <label>Message</label>
            <DPTextInput
              type="text"
              id="message"
              name="message"
              value={text}
              onChange={(event) => _inputChange('text', event)}
              placeholder="Type here"
              style={{
                backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                borderColor: siteColor[CONTROL_BORDER_COLOR],
                color: siteColor[TEXT_COLOR]
              }}
            />
          </div>
        </div>
        <div className="third-row-send-message">
          <DPButton
            buttonClassName="car-contact"
            textColor={siteColor[BUTTON_TEXT_COLOR]}
            backgroundColor={siteColor[PRIMARY_COLOR]}
            backgroundHoverColor={siteColor[SECONDARY_COLOR]}
            size="DP-MEDIUM"
            onClick={_handleClick}
            label={'Send message'}
          />
        </div>
        {
          showViewGetItTouch ?
            <div className="container-send-message-mobile">
              <div className="row-name">
                <label>Name *</label>
                <DPTextInput
                  inputClassName={nameClassName}
                  errorMessage={sampleErrors.name}
                  type="text"
                  id="name"
                  name="clientName"
                  value={name}
                  onChange={(event) => _inputChange('name', event)}
                  placeholder="Type here"
                  style={{
                    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                    borderColor: siteColor[CONTROL_BORDER_COLOR],
                    color: siteColor[TEXT_COLOR]
                  }}
                />
              </div>
              <div className="row-email">
                <label>Email Address *</label>
                <DPTextInput
                  inputClassName={emailClassName}
                  errorMessage={sampleErrors.email}
                  type="text"
                  id="email"
                  value={email}
                  onChange={(event) => _inputChange('email', event)}
                  name="email"
                  placeholder="Type here"
                  style={{
                    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                    borderColor: siteColor[CONTROL_BORDER_COLOR],
                    color: siteColor[TEXT_COLOR]
                  }}
                />
              </div>
              <div className="row-phone">
                <label>Phone Number *</label>
                <DPTextInput
                  inputClassName={phoneNumberClassName}
                  errorMessage={sampleErrors.phoneNumber}
                  mask='(999)999-9999'
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(event) => _inputChange('phone', event)}
                  placeholder="Type here"
                  onBlur={onBlurPhoneField}
                  style={{
                    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                    borderColor: siteColor[CONTROL_BORDER_COLOR],
                    color: siteColor[TEXT_COLOR]
                  }}
                />
              </div>
              <div className="row-message">
                <label>Message</label>
                <DPTextInput
                  type="text"
                  id="message"
                  name="message"
                  value={text}
                  onChange={(event) => _inputChange('text', event)}
                  placeholder="Type here"
                  style={{
                    backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                    borderColor: siteColor[CONTROL_BORDER_COLOR],
                    color: siteColor[TEXT_COLOR]
                  }}
                />
              </div>
              <div className="row-btn-send">
                <DPButton
                  buttonClassName='car-contact'
                  textColor={siteColor[BUTTON_TEXT_COLOR]}
                  backgroundColor={siteColor[PRIMARY_COLOR]}
                  backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                  size='DP-MEDIUM'
                  onClick={_handleClick}
                  label={'Send message'}
                />
              </div>
            </div> : null
        }
      </div>
    </>
    :null
  )

}

export default GetInTouchTemplateCodeMain