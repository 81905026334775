/**
 * Created by johnny on 09/01/16
 */

import React from 'react'
import {Modal} from 'react-bootstrap'
import {
  BOX_BACKGROUND_COLOR,
  CONTROL_BACKGROUND_COLOR,
  CONTROL_BORDER_COLOR, cover,
  pathServer,
  TEXT_COLOR
} from './Constants'
import {ReactSVG} from 'react-svg'
import DynamicNumber from 'react-dynamic-number'

export const PopoverBox = ({
                             id,
                             positionPopoverX,
                             positionPopoverY,
                             borderRadius,
                             handleRequestClose,
                             offsetWidth,
                             displayValue
                           }) => {

  const popover = {
    zIndex: '300',
    left: positionPopoverX,
    top: positionPopoverY,
    backgroundColor: '#fdfdfd',
    transition: '0.8s',
    boxShadow: '0px 6px 10px rgba(0,0,0,0.16)',
    position: 'absolute',
    width: offsetWidth,
    borderRadius: borderRadius
  }

  return (
    <div style={popover}>
      <div style={cover} onClick={handleRequestClose}/>
      <div className='popover-box-template'>
        <div className='popover-header-template' onClick={handleRequestClose}>
          <label>{displayValue}</label>
        </div>
      </div>
    </div>
  )
}

export const ModalBox = ({show, handleRequestClose, displayValue}) => {

  return (
    <div className='container-modal-box'>
      <Modal show={show} className='modal-box' backdropClassName='backdrop-car'>
        <Modal.Body>
          <div className='modal-box-header'>
            <div className='player-close' onClick={handleRequestClose}>
              <div className='horizontal-line'/>
              <div className='vertical-line'/>
            </div>
          </div>
          <div className='modal-box-body'>
            <label>{displayValue}</label>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export const Slider = ({contentId, semiId, iconId, semiClass, iconClass, primaryColor, buttonTextColor}) => {
  return (
    <div
      id={contentId}
      className='content-up-down'
    >
      <div
        id={semiId}
        className={semiClass}
        style={{border: `1px solid ${primaryColor}`, backgroundColor: primaryColor}}
      >
        <a>
          <i
            id={iconId}
            className={`fa fa-chevron-up ${iconClass}`}
            style={{color: buttonTextColor}}
          />
        </a>
      </div>
    </div>
  )
}

export const InputNumberFilter = ({id, value, displayValue, integer, icon, onChangeNumber, style, siteColor = {}}) => {
  return (
    <div className={style.container}>
      <div className={style.label}>
        <label style={{color: siteColor[TEXT_COLOR]}}>{displayValue}</label>
      </div>
      <div className={style.filter}>
        <div className={style.input}>
          <DynamicNumber id={id.idFrom} className='form-control' value={value.valueFrom} positive={true}
                         negative={false} thousand={false} onChange={onChangeNumber} integer={integer} fraction={0}
                         style={siteColor
                           ? {
                             backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                             border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                             color: siteColor[TEXT_COLOR]
                           }
                           : null
                         }
          />
          {icon}
        </div>
        <div className='label-to'>
          <label style={{color: siteColor[TEXT_COLOR]}}>to</label>
        </div>
        <div className={style.input}>
          <DynamicNumber id={id.idTo} className='form-control' value={value.valueTo} positive={true} negative={false}
                         thousand={false} onChange={onChangeNumber} integer={integer} fraction={0}
                         style={siteColor
                           ? {
                             backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                             border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                             color: siteColor[TEXT_COLOR]
                           }
                           : null
                         }
          />
          {icon}
        </div>
      </div>
    </div>
  )
}

export const SelectFilter = ({displayValue, id, handlePopover, style, show, values = [], siteColor = {}}) => {
  return (
    <div id={id} className={style.container}>
      <div
        className={style.select}
        onClick={(event) => !!handlePopover ? handlePopover(id, displayValue, event) : null}
        style={{
          backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
          border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
        }}
      >
        <label style={{color: siteColor[TEXT_COLOR]}}>{displayValue}</label>
        <ReactSVG src={pathServer.PATH_IMG + 'icon/ic_expand_less.svg'}
                  className='icon-expand-more' beforeInjection={svg => svg.classList.add('icon-expand')}
        />
        {values.length > 0 ?
          <label className='number-selected'>{'(' + values.length + ')'}</label> : null
        }
      </div>
    </div>
  )
}

export const InputTextFilter = ({id, value, name, handleInputChange, style, styleInput, siteColor}) => {
  return (
    <div className={style ? style : 'col-filter-select'}>
      <div className='filter-input'>
        <input id={id} className={styleInput ? styleInput : 'form-control'} placeholder={name} value={value}
               onChange={handleInputChange}
               style={siteColor
                 ? {
                   backgroundColor: siteColor[CONTROL_BACKGROUND_COLOR],
                   color: siteColor[TEXT_COLOR],
                   border: `1px solid ${siteColor[CONTROL_BORDER_COLOR]}`,
                 }
                 : null
               }
        />
      </div>
    </div>
  )
}

export const InputNumberCelFilter = ({id, value, displayValue, integer, icon, onChangeNumber, style}) => {
  return (
    <div className={style.container}>
      <div className={style.label}>
        <label>{displayValue}</label>
      </div>
      <div className={style.input}>
        <DynamicNumber id={id.idFrom} className='form-control' value={value.valueFrom} positive={true} negative={false}
                       thousand={false}
                       onChange={onChangeNumber} integer={integer} fraction={0}/>
        {icon}
      </div>
      <div className='label-to'>
        <label>to</label>
      </div>
      <div className={style.input}>
        <DynamicNumber id={id.idTo} className='form-control' value={value.valueTo} positive={true} negative={false}
                       thousand={false}
                       onChange={onChangeNumber} integer={integer} fraction={0}/>
        {icon}
      </div>
    </div>
  )
}

export const elementCheck = (source = 'icon/ic_checkbox_selected.svg', fillColor) => (
  <ReactSVG src={`${pathServer.PATH_IMG}${source}`}
            beforeInjection={svg => {
              svg.classList.add('icon-check')
              fillColor && svg.setAttribute('style', `fill: ${fillColor}`)
            }}
  />
)

export const elementUnCheck = (source = 'icon/ic_checkbox_normal.svg', fillColor) => (
  <ReactSVG src={`${pathServer.PATH_IMG}${source}`}
            beforeInjection={svg => {
              svg.classList.add('icon-uncheck')
              fillColor && svg.setAttribute('style', `fill: ${fillColor}`)
            }}
  />
)

export const Wrapper = ({wrapper, children}) => wrapper(children)

export const TableRow = ({
                           classRow,
                           classFeature,
                           classValue,
                           value,
                           displayValue,
                           customStyle,
                           siteColor,
                           isBackgroundContrasted
                         }) => {

  const getBackGroundColor = () => isBackgroundContrasted ? '#454545' : siteColor[CONTROL_BACKGROUND_COLOR]

  return (
    <div className={classRow}>
      <div className={classFeature}
           style={siteColor ? {backgroundColor: getBackGroundColor()} : {}}>
        <span style={customStyle}>{displayValue}</span>
      </div>
      <div className={classValue}
           style={siteColor ? {backgroundColor: getBackGroundColor()} : {}}>
        <span style={customStyle}>{value}</span>
      </div>
    </div>
  )
}