import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'material-ui/Checkbox'
import {elementCheck, elementUnCheck} from '../../common/ComponentUtils'
import {EmptyFunc} from '../../common/Constants'

const DPCheckbox = React.forwardRef(({children, ...props}, ref) => (
    <Checkbox {...props} ref={ref} data-test={props.testId}/>
));

DPCheckbox.displayName = 'DPCheckbox';
DPCheckbox.defaultProps = {
  onCheck: EmptyFunc,
  checkedIcon: elementCheck(),
  uncheckedIcon: elementUnCheck(),
  disabled: false,
  className : "checkbox",
  required: true,
  iconStyle: {borderColor: "#777", marginRight: 2},
  name: "name",
  testid: ''
};
DPCheckbox.propTypes = {
  onCheck: PropTypes.func.isRequired,
  checkedIcon: PropTypes.object,
  uncheckedIcon: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  iconStyle: PropTypes.object,
  name: PropTypes.string,
  testid: PropTypes.string
};
export default DPCheckbox;