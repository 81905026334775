import React, {useState} from 'react'
import {unstable_batchedUpdates} from 'react-dom'
import {ReactSVG} from 'react-svg'
import {
  BOX_BACKGROUND_COLOR, pathServer, PRIMARY_COLOR, SECONDARY_COLOR, TEMPLATE_MAIN_NAME, TEXT_COLOR, formEmail,
  FOOTER_TEXT_COLOR, CONTROL_BACKGROUND_COLOR, DARK_THEME
} from '../../Constants'
import {buildMessages, emailData, validateUrl, getBaseTemplateCode} from '../../Util'
import isEmpty from 'lodash/isEmpty'
import {checkValidEmail} from '../../ValidationUtil'
import {Wrapper} from '../../ComponentUtils'
import DPBackToTop from '../../DPBackToTop'
import DPTextInput from '../../genericComponents/DPTextInput'
import DPButton from '../../../admin/common/DPButton'
import './LayoutFooter.scss'

const LayoutFooter = ({
                        currentTheme,
                        backgroundMain,
                        templateCode,
                        dealerId,
                        footer,
                        saveEmailMessage,
                        siteColor,
                        socialMedia,
                        isAdmin,
                        isButtonSticky
                      }) => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [message, setMessage] = useState(null)

  const baseTemplateCode = getBaseTemplateCode(templateCode)
  const {subscription, column1, column2} = footer
  const {lineText, showSection: showSectionSubscription, header: headerSubscription} = subscription
  const {
    [PRIMARY_COLOR]: primaryColor, [BOX_BACKGROUND_COLOR]: boxBackgroundColor, [SECONDARY_COLOR]: secondaryColor,
    [TEXT_COLOR]: textColor, [FOOTER_TEXT_COLOR]: footerTextColor, [CONTROL_BACKGROUND_COLOR]: controlBackgroundColor
  } = siteColor

  const inputColorOnDarkTheme = currentTheme === DARK_THEME ? '#454545' : ''

  const handleClick = (e) => {
    e.preventDefault()

    const data = emailData(dealerId, email, formEmail.SUBSCRIPTION_TEXT_MESSAGE)
    unstable_batchedUpdates(() => {
      setMessage('')
      setErrors([])
    })
    if (validate() && saveEmailMessage) {
      saveEmailMessage(data).then(response => {
        unstable_batchedUpdates(() => {
          setEmail('')
          setErrors([])
          setMessage(baseTemplateCode === TEMPLATE_MAIN_NAME ? 'Thanks for Subscribing!' : 'Your message has been send successfully, NOTE: See your email for a copy of your message.')
        })
        setTimeout(function () {
          setMessage('')
        }, 3000)
      }).catch(error => {
        setTimeout(function () {
          setMessage('Error: Your message could not be sent.')
        }, 3000)
      })
    }
  }

  const inputChange = (event) => {
    event.preventDefault()
    setEmail(event.target.value)
    if (errors.length > 0)
      setErrors([])
  }

  const onSocialMedia = (url) => {
    const updatedURL = url.includes('http://') || url.includes('https://') ? url : `https://${url}`

    if (validateUrl(updatedURL)) {
      window.open(updatedURL, '_blank')
    }
  }

  const renderLinkItem = (item, siteColor) => {
    const colorSite = baseTemplateCode === TEMPLATE_MAIN_NAME ? TEXT_COLOR : FOOTER_TEXT_COLOR
    const {type, value} = item.link
    if (type === 'text') {
      return <span style={{color: siteColor[colorSite]}}>{item.value}</span>
    } else if (type === 'link') {
      return <a onClick={() => window.open(value, '_blank')}
                style={{color: siteColor[colorSite], cursor: 'pointer'}}>{item.value}</a>
    } else if (type === 'phone') {
      return <a href={`tel:${value}`} style={{color: siteColor[colorSite]}}>{item.value}</a>
    } else if (type === 'email') {
      return <a href={`mailto:${value}`} style={{color: siteColor[colorSite]}}>{item.value}</a>
    }
  }

  const validate = () => {
    if (!email || !(email.trim()) || !checkValidEmail(email)) {
      setErrors(['Incorrect email'])
      return false
    }
    return true
  }

  return (
    <Wrapper wrapper={
      children => baseTemplateCode === TEMPLATE_MAIN_NAME ?
        <div id='layFooter' className='layout-footer'>
          <div className={'container-footer-background' + (isAdmin ? ' admin' : '')}
               style={{backgroundColor: boxBackgroundColor}}>
            {isButtonSticky &&
              <>
                <div id='footerLimiter' className='footer-limiter' />
                <DPBackToTop
                  siteColor={siteColor}
                  templateCode={templateCode}
                  isArrowColorInverted
                />
              </>}
            {children}
          </div>
        </div> :
        <div id='footerModern' className='footer-modern'>
          <div className={'container-footer-background' + (isAdmin ? ' admin' : '')}>
            {isButtonSticky &&
              <>
                <div id="footerLimiter" className="footer-limiter"/>
                <DPBackToTop
                  siteColor={siteColor}
                  templateCode={templateCode}
                  isArrowColorInverted
                />
              </>}
            {children}
          </div>
        </div>
    }
    >
      {/*Background*/}
      <img id='footerBackground' className='footer-background' src={backgroundMain} alt=''/>
      {/*Content*/}
      {
        baseTemplateCode === TEMPLATE_MAIN_NAME ?
          <div className='container-title-easy-nav'>
            <div className='footer-right' id='footer-component' data-testid='information-columns'>
              {
                column1 &&
                <div className='title-one'>
                  <div
                    className='title'
                    style={{color: textColor}}
                  >
                    {column1?.title}
                  </div>
                  {
                    column1?.items.map((item, index) => {
                      return (
                        <div key={`column1-${index}`}>
                          {
                            renderLinkItem(item, siteColor)
                          }
                        </div>
                      )
                    })
                  }
                </div>
              }
              {
                column2 &&
                <div className='title-two'>
                  <div
                    className='title'
                    style={{color: textColor}}
                  >
                    {column2?.title}
                  </div>
                  {
                    column2?.items.map((item, index) => {
                      return (
                        <div key={`column2-${index}`}>
                          {
                            renderLinkItem(item, siteColor)
                          }
                        </div>
                      )
                    })
                  }
                </div>
              }
            </div>
            {
              showSectionSubscription ?
                <div className='footer-left'>
                  <div
                    className='title'
                    style={{color: textColor}}
                  >
                    {headerSubscription}
                  </div>
                  <div
                    className='line-description'
                    style={{color: textColor}}
                  >
                    {lineText}
                  </div>
                  <div className='content-input'>
                    <div className='content-alert-danger'>
                      {buildMessages(errors, message)}
                    </div>
                    <DPTextInput
                      className='form-control'
                      placeholder='Type your email'
                      onChange={inputChange}
                      value={email}
                      disabled={isAdmin}
                      inputStyle={{
                        backgroundColor: inputColorOnDarkTheme,
                        border: `1px solid ${inputColorOnDarkTheme}`,
                        color: textColor,
                        placeholder: textColor
                      }}
                    />
                    <DPButton
                      data-testid='send-button'
                      className={`btn-arrow-next ${primaryColor === "#ffffff"? 'black-arrow' : 'white-arrow'}`}
                      style={{
                        backgroundColor: primaryColor,
                        border: 'none'
                      }}
                      onClick={saveEmailMessage && handleClick}
                    >
                      <ReactSVG
                        src={pathServer.PATH_IMG + 'icon/ic_arrow_next.svg'}
                        className='content-icon-arrow'
                        beforeInjection={svg => svg.classList.add('icon-arrow')}
                      />
                    </DPButton>
                  </div>
                </div> : null
            }
          </div> :
          <div className='container-tittle-modern'>
            <div className='container-footer-left'>
              {
                column1 &&
                <div className='title-footer-left-one'>
                  <label style={{color: footerTextColor}}>{column1?.title}</label>
                  {
                    column1?.items.map((item, index) => {
                      return (
                        <div key={`column1-${index}`}>
                          {
                            renderLinkItem(item, siteColor)
                          }
                        </div>
                      )
                    })
                  }
                </div>
              }
              {
                column2 &&
                <div className='title-footer-left-two'>
                  <label style={{color: footerTextColor}}>{column2?.title}</label>
                  {
                    column2?.items.map((item, index) => (
                      <div key={`column2-${index}`}>
                        {
                          renderLinkItem(item, siteColor)
                        }
                      </div>
                    ))
                  }
                </div>
              }
            </div>
            {
              showSectionSubscription ?
                <div className='container-footer-right'>
                  <div className='footer-tittle-modern'>
                    <h3 style={{color: footerTextColor}}>{headerSubscription}</h3>
                    <label style={{color: footerTextColor}}>{lineText}</label>
                  </div>
                  <div className='container-subscribe'>
                    <div className='col-keyboard'>
                      <DPTextInput
                        id='keyboard'
                        className='form-control subscription-email-input'
                        placeholder='EMAIL ADDRESS'
                        onChange={inputChange}
                        value={email}
                        style={{
                          backgroundColor: controlBackgroundColor,
                          color: textColor
                        }}
                        disabled={isAdmin}
                      />
                      <DPButton
                        className='btn-subscribe-content'
                        data-testid='btn-subscribe'
                        style={{backgroundColor: primaryColor}}
                        onClick={saveEmailMessage && handleClick}
                      >
                        <ReactSVG
                          src={pathServer.PATH_IMG + 'icon/ic_right_arrow.svg'}
                          className='content-icon-arrow'
                          beforeInjection={svg => {
                            svg.classList.add('icon-arrow')
                            svg.setAttribute('style', 'fill: white')
                          }}
                        />
                      </DPButton>
                      {
                        (message || errors.length > 0) &&
                        <div className='subscribe-message'>
                          {errors.length > 0 ? errors : message}
                        </div>
                      }
                    </div>
                  </div>
                </div> : null
            }
          </div>

      }
      {/*Bottom*/}
      {
        <div
          className={baseTemplateCode === TEMPLATE_MAIN_NAME ? 'footer-bar-bottom' : 'container-menu-footer-modern'}
          style={baseTemplateCode === TEMPLATE_MAIN_NAME ? {backgroundColor: primaryColor} : null}>
          {/*GoDealerGo. All rights reserved css conflicts*/}
          {/*todo:refactor reserved rights*/}
          {
            baseTemplateCode === TEMPLATE_MAIN_NAME ?
              <div className='all-reserved'>
                <label>GoDealerGo. All Rights reserved ©</label>
              </div> :
              <div className='container-copyright'>
                <h5 className='text-copyright' style={{color: footerTextColor}}>
                  GoDealerGo. All rights reserved ©
                </h5>
              </div>
          }
          {/*Social media*/}
          <div
            className={baseTemplateCode === TEMPLATE_MAIN_NAME ? 'network-social' : 'container-icon-social'}>
            {
              !isEmpty(socialMedia?.instagram?.trim()) ?
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/ic_instagram.svg'}
                  className='wrapper-instagram'
                  beforeInjection={svg => svg.classList.add(baseTemplateCode === TEMPLATE_MAIN_NAME ? 'icon-instagram' : 'icon-social-networks')}
                  onClick={() => onSocialMedia(socialMedia.instagram)}
                  data-testid='instagram-icon'
                /> : null
            }
            {
              !isEmpty(socialMedia?.facebook?.trim()) ?
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/ic_facebook.svg'}
                  className='wrapper-facebook'
                  beforeInjection={svg => svg.classList.add(baseTemplateCode === TEMPLATE_MAIN_NAME ? 'icon-facebook' : 'icon-social-networks')}
                  onClick={() => onSocialMedia(socialMedia.facebook)}
                  data-testid='facebook-icon'
                /> : null
            }
            {
              !isEmpty(socialMedia?.twitter?.trim()) ?
                <ReactSVG
                  src={pathServer.PATH_IMG + 'icon/ic_twitter.svg'}
                  className='wrapper-twitter'
                  beforeInjection={svg => svg.classList.add(baseTemplateCode === TEMPLATE_MAIN_NAME ? 'icon-twitter' : 'icon-social-networks')}
                  onClick={() => onSocialMedia(socialMedia.twitter)}
                  data-testid='twitter-icon'
                /> : null
            }
          </div>
        </div>
      }
    </Wrapper>
  )
}

export default LayoutFooter