import React, {useState} from 'react'
import {
  pathServer,
  BOX_BACKGROUND_COLOR,
  BOX_BORDER_COLOR,
  PRIMARY_COLOR,
  SITE_BACKGROUND_COLOR,
  TEXT_COLOR,
  WIDTH_VIEW_MOBILE,
  TEMPLATE_5_NAME,
  LIGHT_THEME, BUTTON_TEXT_COLOR, SECONDARY_COLOR, MODERN_VIEW_2_TEMPLATE_CODE
} from '../Constants'
import {ReactSVG} from 'react-svg'
import {SliderBar} from '../genericComponents/DPCarousel/index'
import {getBaseTemplateCode, goToLink, getPrimaryColorTheme, getButtonTextCapByTemplate} from '../Util'
import DPButton, {BUTTON_TEXT_CAP} from '../genericComponents/DPButton'
import {isEmpty} from "lodash";

const LayoutFinance = ({
                         section,
                         siteColor,
                         src,
                         currentTheme,
                         templateCode,
                         isAdminTemplate = false
                       }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const {
    [PRIMARY_COLOR]: primaryColor,
    [TEXT_COLOR]: textColor,
    [SITE_BACKGROUND_COLOR]: siteBackground,
    [BOX_BORDER_COLOR]: boxBorder,
    [BOX_BACKGROUND_COLOR]: boxBackground
  } = siteColor

  const {description, showButton, steps, title, buttonText, link} = section

  const handleSlideBack = (step, max) => setCurrentStep((step - 1 === -1) ? (max - 1) : (step - 1))

  const handleSlideNext = (step, max) => setCurrentStep((step + 1 === max) ? 0 : step + 1)

  const ApplyButton = ({toLink}) => (
    <DPButton
      buttonClassName='finance-apply'
      textColor={siteColor[BUTTON_TEXT_COLOR]}
      backgroundColor={siteColor[PRIMARY_COLOR]}
      backgroundHoverColor={siteColor[SECONDARY_COLOR]}
      size='DP-MEDIUM'
      onClick={() => {
        if (isAdminTemplate) return
        toLink(link)
      }}
      label={getButtonTextCapByTemplate(buttonText || 'APPLY NOW', templateCode) }
      isAdmin={isAdminTemplate}
    />
  )

  const btnClassName = `btn btn-finance ${(templateCode === MODERN_VIEW_2_TEMPLATE_CODE) ? "btn-finance-mv2" : ""}`

  return (
    <div
      id={getBaseTemplateCode(templateCode) === TEMPLATE_5_NAME ? 'finance5' : 'financeMain'}
      className={getBaseTemplateCode(templateCode) === TEMPLATE_5_NAME ? 'layout-finance5' : 'layout-finance'}
      style={getBaseTemplateCode(templateCode) === TEMPLATE_5_NAME ? {backgroundColor: siteBackground} : null}
    >
      {
        getBaseTemplateCode(templateCode) === TEMPLATE_5_NAME ?
          <>
            <div id="financeHeader5" className="finance-header">
              <h1 style={{color: textColor}}>
                {title.toUpperCase()}
              </h1>
            </div>
            <div id="financeBody" className={'finance-body current-step-' + currentStep}>
              {
                steps.map((step, index) => {
                  const {icon, id, displayValue, showIcon, description} = step
                  return (
                    <div key={id} className={'finance-step-content step-' + index}>
                      <div className="finance-box" style={{border: `1px solid ${boxBorder}`}}>
                        <div className="finance-body-step" style={{backgroundColor: boxBackground}}>
                          {
                            !!showIcon &&
                            <div className="finance-icon">
                              <ReactSVG
                                src={icon}
                                beforeInjection={svg => {
                                  svg.classList.add('finance-icon-step')
                                  svg.setAttribute('style', `fill: ${primaryColor}`)
                                }}
                                id={id}
                              />
                            </div>
                          }
                          <div className="finance-label">
                            <label id={id + 'label'} style={{color: textColor}}>
                              {displayValue.toUpperCase()}
                            </label>
                          </div>
                          <div className="finance-des">
                            <label style={{color: textColor}}>
                              {description}
                            </label>
                          </div>
                        </div>
                        <div className={ currentTheme !== LIGHT_THEME ? "finance-image-dark" : "finance-image"}>
                          {
                            (index === 0) ?
                              <img src={steps[index].imageUrl === "" || isEmpty(steps[index].imageUrl) ? pathServer.PATH_IMG + 'honda-accord.jpg' : steps[index].imageUrl }/> :
                              (index === 1) ?
                                <img src={steps[index].imageUrl === "" || isEmpty(steps[index].imageUrl) ? pathServer.PATH_IMG + 'mercedes-model1.jpeg' : steps[index].imageUrl}/> :
                                <img src={steps[index].imageUrl === "" || isEmpty(steps[index].imageUrl) ? pathServer.PATH_IMG + 'bmw-model1.jpeg' : steps[index].imageUrl}/>
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {
              showButton ?
                <div className="finance-apply-modern-view">
                  <DPButton
                    id={'btnApplyNow'}
                    buttonClassName={btnClassName}
                    onClick={() => {
                      if (isAdminTemplate) return
                      goToLink(link)
                    }}
                    backgroundColor={siteColor[PRIMARY_COLOR]}
                    textColor={siteColor[BUTTON_TEXT_COLOR]}
                    backgroundHoverColor={siteColor[SECONDARY_COLOR]}
                    textCap={BUTTON_TEXT_CAP.CAPITALIZE}
                    label={getButtonTextCapByTemplate('apply now', templateCode)}
                    isAdmin={isAdminTemplate}
                  />
                </div> : null
            }
            <div className="finance-indicators">
              <div className="slide-back">
                <ReactSVG
                  src={window.innerWidth > WIDTH_VIEW_MOBILE ? `${pathServer.PATH_IMG}icon/ic_arrow_back.svg` : `${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                  onClick={() => handleSlideBack(currentStep, steps.length)}
                  beforeInjection={svg => {
                    svg.classList.add('finance-icon-back')
                    svg.setAttribute('style', `fill: ${primaryColor}`)
                  }}
                />
              </div>
              <div className="bar-progress">
                <SliderBar
                  currentPage={currentStep}
                  totalPages={steps.length}
                  primaryColor={primaryColor}
                  heightCurrentBar={5}
                  heightTotalBar={2}
                />
              </div>
              <div className="slide-next">
                <ReactSVG
                  src={window.innerWidth > WIDTH_VIEW_MOBILE ? `${pathServer.PATH_IMG}icon/ic_arrow_next.svg` : `${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                  onClick={() => handleSlideNext(currentStep, steps.length)}
                  beforeInjection={svg => {
                    svg.classList.add('finance-icon-next')
                    svg.setAttribute('style', `fill: ${primaryColor}`)
                  }}
                />
              </div>
            </div>
          </>
          :
          <>
            <div className="body-image">
              <img id="backgroundFinance" src={src} alt={''}/>
            </div>
            <div style={{backgroundColor: currentTheme === LIGHT_THEME ? 'unset' : '#00000099'}}
                 className="body-image blur-background"/>
            <div className="body-components">
              <div className="body-left">
                <div id="bodyLeft" className="body-left-position">
                  <div className="text-get-finance">
                    <label>{title}</label>
                  </div>
                  <div className="text-three-steps">
                    <label style={{color: textColor}}>
                      {description}
                    </label>
                  </div>
                  {
                    showButton ?
                      <ApplyButton toLink={goToLink}/> :
                      null
                  }
                </div>
              </div>
              <div className="body-right">
                <div className="body-right-position">
                  {
                    steps.map(step => {
                      const {icon, id, displayValue, showIcon} = step
                      return (
                        <div key={id} className="finance-rectangle" style={{backgroundColor: siteBackground}}>
                          {
                            !!showIcon &&
                            <div className="finance-image">
                              <ReactSVG
                                src={icon}
                                id={id}
                                beforeInjection={svg => {
                                  svg.classList.add('finance-image-style')
                                  svg.setAttribute('style', `fill: ${getPrimaryColorTheme(templateCode, primaryColor, currentTheme)}`)
                                }}
                              />
                            </div>
                          }
                          <div
                            id={id + 'label'}
                            className="finance-part"
                            style={{
                              color: textColor,
                              width: !!showIcon ? '' : '100%',
                              paddingLeft: !!showIcon ? '' : '0'
                            }}
                          >
                            {displayValue}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <div className="finance-apply-end">
                  {
                    showButton ?
                      <ApplyButton toLink={goToLink}/> :
                      null
                  }
                </div>
              </div>
            </div>
          </>
      }
    </div>
  )

}

export default LayoutFinance