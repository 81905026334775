/**
 * Created by javierl on 3/10/16
 */

import React from 'react'
import {browserHistory, Router} from 'react-router'
import configureStore from './store/configureStore'
import {Provider} from 'react-redux'
import {syncHistoryWithStore} from 'react-router-redux'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import '../common/styles/catalog.scss'
import Routes from './LayoutRenderer.jsx'

const store = configureStore(window.initialState, browserHistory)
const history = syncHistoryWithStore(browserHistory, store)

export default function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider>
        <Router history={history}>
          {Routes}
        </Router>
      </MuiThemeProvider>
    </Provider>
  )
}